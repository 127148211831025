import { Bar } from 'react-chartjs-2';
import getColors from './colors'

const currencies = ['USD', 'CAD', 'EUR', 'GBP', 'INR']

const buildData = data => {
  let labels = Object.keys(data)
  let datasets = []

  let colors = getColors(currencies.length)

  currencies.forEach((currency, i) => {  
    datasets.push({
      label: currency,
      data: Object.values(data).map(value => value[currency]),
      backgroundColor: colors.backgroundColor[i],
      borderColor: colors.borderColor[i],
      borderWidth: 1
    })
  })

  return {
    labels,
    datasets
  }
}

const options = {
  maintainAspectRatio: false,
  plugins: { title: { display: true, text: 'Orders' } },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true
    }
  }
}

const OrdersChart = ({ data }) =>
  <Bar
    data={buildData(data)}
    height={'100%'}
    options={options}/>

export default OrdersChart
