import React, { useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Modal, Button } from "@bitcine/cs-theme"
import { deleteProject } from "../../../../api/project"

const DeleteProject = ({ project, deleteProject, close }) => {
  const [matchTitle, setMatchTitle] = useState(null)
  return (
    <Modal open={true} onClose={() => this.props.close()} title={`Are you sure you want to delete ${project.title}?`}>
      <div className='mt2'>
        <p>To delete this project, please enter the project title in the field below.</p>
        <input
          placeholder='Enter project title exactly to delete.'
          label='Project Title'
          className='cs-input col-12'
          value={matchTitle}
          onChange={e => setMatchTitle(e.target.value)}/>
      </div>
      <div className='mt2 right-align'>
        <Button
          disabled={matchTitle !== project.title}
          onClick={() => {
            deleteProject(project._id)
            close()
          }}>
          Delete Project
        </Button>
      </div>
    </Modal>
  )
}

DeleteProject.propTypes = {
  close: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  deleteProject: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  deleteProject: bindActionCreators(deleteProject, dispatch)
})

export default connect(null, mapDispatchToProps)(DeleteProject)
