import React, { useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Modal, Button } from "@bitcine/cs-theme"
import { transferFile } from "../../../api/files/"

const TransferFile = ({ file, transferFile, close }) =>  {
  const [destinationProjectID, setDestinationProjectID] = useState(null)
  return (
    <Modal clickOutsideCloses open={true} width={1} onClose={close} title={"Transfer File"}>
      <div className='mt2'>
        <p>Enter destination project ID:</p>
        <input type='text' className='cs-input col-12' value={destinationProjectID} onChange={e => setDestinationProjectID(e.target.value)}/>
      </div>
      <div className='mt2 right-align'>
        <Button
          className='ml3'
          disabled={!destinationProjectID}
          onClick={() => transferFile(file._id, file.type, destinationProjectID)}>
          Transfer File
        </Button>
      </div>
    </Modal>
  )
}

const mapDispatchToProps = dispatch => ({
  transferFile: bindActionCreators(transferFile, dispatch)
})

export default connect(null, mapDispatchToProps)(TransferFile)
