import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import CommonDropdown from "../Common/dropdown"
import { Button } from "@bitcine/cs-theme"

class NewAsset extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      facility_id: "",
      serial_number: "",
      size_in_gb: ""
    }
  }
  disabled() {
    return !this.state.size_in_gb || !this.state.serial_number || !this.state.facility_id || this.props.addingNewModel
  }
  create() {
    if (this.disabled()) return
    let data = {
      size_in_gb: this.state.size_in_gb,
      serial_number: this.state.serial_number,
      facility_id: this.state.facility_id
    }
    this.props.createModel(data)
  }
  handleKeyPress(key) {
    if (key !== "Enter") return
    this.create()
  }
  render() {
    return (
      <div>
        <div className='mb2'>
          <CommonDropdown
            containerClassName='col-12'
            options='facilities'
            label='Facility'
            value={this.state.facility_id}
            onChange={({ value }) => this.setState({ facility_id: value })}
            clearable={false}/>
        </div>
        <div className='mb2'>
          <label className='capitalize'>Size In GB</label>
          <input
            value={this.state.size_in_gb || ""}
            className='cs-input col-12'
            onChange={({ target: { value } }) => this.setState({ size_in_gb: value })}/>
        </div>
        <div className='mb2'>
          <label className='capitalize'>Serial Number</label>
          <input
            value={this.state.serial_number || ""}
            className='cs-input col-12'
            onKeyPress={e => this.handleKeyPress(e.key)}
            onChange={({ target: { value } }) => this.setState({ serial_number: value })}/>
        </div>
        <div className='right-align mt3'>
          <Button disabled={this.disabled()} onClick={() => this.create()}>
            Add
          </Button>
        </div>
      </div>
    )
  }
}

NewAsset.propTypes = {
  createModel: PropTypes.func.isRequired,
  addingNewModel: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, null)(NewAsset)
