import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from 'react-router-dom'
import { Status, ButtonDropdown } from '@bitcine/cs-theme'
import { getJob, setJobStatus, deleteJob } from "../../api/job"
import Select from "react-select"
import Details from "./details"
import Notes from "./notes"
import SubTasks from "./subtasks"
import History from "./history"
import Actions from "./actions"
import Breadcrumbs from "./breadcrumbs"
import ErrorModal from "./error"

const Job = ({ match, job, getJob, deleteJob, setJobStatus, selectOptions, showError, history }) => {
  const jobID = match.params.jobID
  useEffect(() => {
    getJob(jobID)
  }, [jobID, getJob])
  return (
    <Status pending={job.status === 'PENDING'} error={job.status === 'ERROR'}>
      {job.status === 'READY' && <div>
        <div className='flex items-center justify-between'>
          <Breadcrumbs/>
          <ButtonDropdown
            button={{
              className: "material-icons link",
              text: "more_vert",
              menu: true
            }}
            dropdown={{
              clickCloses: true,
              content: [
                {
                  text: "Delete Job",
                  icon: "delete_forever",
                  onClick: () => {
                    if (window.confirm("Are you sure you want to delete this job?")) {
                      deleteJob(jobID, () => history.push('/production/open-jobs'))
                    }
                  }
                }
              ]
            }}/>
        </div>
        <div className='flex items-center justify-between my2'>
          <h3>{job.details.title}</h3>
          <>
            {job.status === "UPDATING" ? (
              <div className='flex items-center pr3'>
                <div className='spinner spinner-small mr2'/>
                Updating...
              </div>
            ) : job.parent_job_id && !job.children_jobs ? (
              <div style={{ width: '180px' }}>
                <Select
                  className='col-12'
                  options={selectOptions}
                  clearabled={false}
                  formatOptionLabel={row => (
                    <div>
                      <div className='flex items-center'>
                        <span className={`material-icons mr1 ${row.iconClass}`}>{row.icon}</span>
                        <div>{row.label}</div>
                      </div>
                      {row.disabled && <small className='block'>{row.disabledMessage}</small>}
                    </div>
                  )}
                  onChange={({ value }) => setJobStatus(job._id, value)}
                  value={selectOptions.find(opt => opt.value === job.details.status)}/>
              </div>
            ) : (
              <div className='flex items-center'>
                <span
                  className={`material-icons mr2 ${
                    selectOptions.find(opt => opt.value === job.details.status).iconClass
                  }`}>
                  {selectOptions.find(opt => opt.value === job.details.status).icon}
                </span>
                <div>{job.details.status}</div>
              </div>
            )}
          </>
        </div>
        <div className='flex items-start'>
          <div className='col-6'>
            <Details/>
            <History/>
          </div>
          <div className='col-6 pl3'>
            {job.children_jobs && job.children_jobs.length > 0 ? <SubTasks/> : <Actions/>}
            <Notes/>
          </div>
        </div>
        {showError && <ErrorModal/>}
      </div>}
    </Status>
  )
}

Job.propTypes = {
  job: PropTypes.object.isRequired,
  getJob: PropTypes.func.isRequired,
  nextJobID: PropTypes.string,
  prevJobID: PropTypes.string,
  setJobStatus: PropTypes.func.isRequired,
  selectOptions: PropTypes.array.isRequired,
  showError: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  const code = state.job.details.code || ""
  return {
    job: state.job,
    showError: state.job.newError ? state.job.newError.visible : false,
    selectOptions: [
      {
        value: "Suspended",
        label: "Suspended",
        icon: "alarm_off",
        disabled: state.job.details.status === "Suspended",
        iconClass: "light"
      },
      {
        value: "Pending",
        label: "Pending",
        icon: "hourglass_empty",
        disabled: state.job.details.status === "Pending",
        iconClass: "light"
      },
      {
        value: "In Progress",
        label: "In Progress",
        icon: "play_for_work",
        disabled: state.job.details.status === "In Progress",
        iconClass: "purple"
      },
      {
        value: "Complete",
        label: "Complete",
        icon: "check_circle",
        disabled:
          code.includes("ship_content") || code === "create_return_label"
            ? !state.job.actions.tracking_number && !state.job.actions.is_for_pickup
            : code.includes("rev")
              ? !state.job.actions || !state.job.actions.language_code
              : code.includes("copy_dcp")
                ? !state.job.actions.serial_number
                : false,
        disabledMessage:
          code.includes("ship_content") || code === "create_return_label"
            ? "Tracking number is required"
            : code.includes("rev")
              ? "Language must be selected before completion"
              : code.includes("copy_")
                ? "Serial number is required"
                : "All child jobs must be complete",
        iconClass: "green"
      },
      {
        value: "Error",
        label: "Error",
        icon: "warning",
        disabled: state.job.details.status === "Error",
        iconClass: "red"
      }
    ]
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getJob: bindActionCreators(getJob, dispatch),
    setJobStatus: bindActionCreators(setJobStatus, dispatch),
    deleteJob: bindActionCreators(deleteJob, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Job))
