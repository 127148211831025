import React from "react"
import { connect } from "react-redux"
import CustomerJobs from "../../Customer/jobs"

const Jobs = ({ userID }) =>
  <CustomerJobs queries={{ user_id: userID, parent_id: "null" }}/>

const mapStateToProps = state => ({
  userID: state.user.model._id
})

export default connect(mapStateToProps)(Jobs)