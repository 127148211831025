import React from "react"
import SharedTable from "../Common/table"
import AccessDates from '../Common/access_dates'

const Vouchers = () => (
  <SharedTable
    collection={"vouchers"}
    queries={{}}
    includeSearch={true}
    includeTitle={"Vouchers"}
    isCompact={true}
    includeShowDeletedCheckbox={true}
    tableKeys={[
      { text: "Asset Title" },
      { text: "Type", key: "type" },
      { text: "Code", key: "code" },
      { text: "Organization", key: "organization_name" },
      { text: "Updated", key: "updated_at" }
    ]}
    tableWidths={["auto", 160, 160, 240, 180]}
    tableValues={[
      voucher => <span className='truncate'>{voucher.videos_title}</span>,
      voucher => <span className='truncate'>{voucher.type}</span>,
      voucher => <span className='truncate'>{voucher.code}</span>,
      voucher => <span className='truncate'>{voucher.organization_name}</span>,
      voucher => <AccessDates model={voucher}/>,
    ]}/>
)

export default Vouchers
