import React from "react"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Select from "react-select"
import { Checkbox } from "@bitcine/cs-theme"
import humanFileSize from "../../../helpers/human_file_size"
import { updateOrganization, saveOrganization } from "../../../api/organization"
import formatDate from "../../../helpers/format_date"

const encodingOptions = [
  { value: "hd1080-01", label: "Multi-Output DRM - HD1080-01" },
  { value: "hd720-01", label: "Multi-Output DRM - HD720-01" },
  { value: "sd540", label: "Multi-Output DRM - SD540" }
]

const playerOptions = [
  { value: "bitmovin", label: "Bitmovin" },
  { value: "theoplayer", label: "THEOPlayer" }
]

const logsEndpointOptions = [
  { value: "kinesis", label: "Kinesis" },
  { value: "cinesend", label: "CineSend API" }
]

const Plan = ({ organization, updateOrganization, saveOrganization }) => {
  const updateOrganizationPlan = (key, value, save = false) => {
    updateOrganization("plan", { ...organization.plan, [key]: value })
    if (save) {
      saveOrganization("plan")
    }
  }
  return (
    <div>
      <h4 className='mb2'>Subscription Settings</h4>
      <div className="border border-gray-5 rounded p2">
        <div className='flex justify-between'>
          <h4>
            Current Subscription: {organization.subscription.name}
            {organization.next_storage_plan &&
              <span className='ml2 light small'>
                (Changeover: {formatDate(organization.plan_changeover_date, false)} to {organization.next_storage_plan.name})
              </span>}
            {organization.plan_renewal_date && !organization.is_enterprise &&
              <span className='ml2 light small'>
                (Next Billing Date: {formatDate(organization.plan_renewal_date, false)})
              </span>}
          </h4>
          <Checkbox
            label={"Is Enterprise?"}
            checked={organization.is_enterprise}
            onChange={event => {
              updateOrganization("is_enterprise", !organization.is_enterprise, true)
              saveOrganization("is_enterprise")
            }}/>
        </div>
        {organization.is_enterprise && <div className='mt2 border-top border-gray-5 pt2'>
          <h4>Accessible Applications</h4>
          <div className='flex items-center mt1'>
            {[
              {
                code: "can_access_files",
                label: "CineSend Files"
              },
              {
                code: "can_access_bookings",
                label: "CineSend Bookings"
              },
              {
                code: "can_access_festivals",
                label: "CineSend Requests"
              },
              {
                code: "can_access_venues",
                label: "CineSend Venues"
              },
              {
                code: "can_access_ondemand",
                label: "CineSend On Demand"
              },
              {
                code: "can_access_live",
                label: "CineSend Live"
              }
            ].map(permission => (
              <div key={permission.code} className='mr2'>
                <Checkbox
                  label={permission.label}
                  checked={organization.plan[permission.code] || false}
                  onChange={event => updateOrganizationPlan(permission.code, !organization.plan[permission.code], true)}/>
              </div>
            ))}
          </div>
        </div>}
      </div>
      {organization.plan.can_access_files && <div className="border border-gray-5 rounded p2 mt2">
        <h4>Files Settings</h4>
        <div className='flex items-center'>
          <div className='col-3'>
            <label className='cs-label'>Space Used in GB</label>
            <input disabled={true} value={humanFileSize(organization.subscription.storage.used)} className='cs-input col-12'/>
          </div>
          <div className='col-3 pl2'>
            <label className='cs-label'>Total Space in GB</label>
            <input
              className='cs-input col-12'
              disabled={!organization.is_enterprise}
              value={organization.plan.space_in_GB}
              onChange={e => updateOrganizationPlan('space_in_GB', e.target.value)}
              onBlur={() => saveOrganization("plan")}/>
          </div>
          <div className='col-3 pl2'>
            <label className='cs-label'>Available Screener Links</label>
            <input
              value={organization.available_screeners}
              className='col-12 cs-input'
              onChange={e => updateOrganization("available_screeners", e.target.value)}
              onBlur={() => saveOrganization("available_screeners")}/>
          </div>
          <div className='col-3 pl2'>
            <label className='cs-label'>Monthly Screener Links</label>
            <input
              disabled={!organization.is_enterprise}
              value={organization.plan.preview_links}
              className='col-12 cs-input'
              onChange={e => updateOrganizationPlan('preview_links', e.target.value)}
              onBlur={() => saveOrganization("plan")}/>
          </div>
        </div>
        <div className='flex items-center'>
          <div className='col-3'>
            <label className='cs-label'>Available Forensic Watermark Links</label>
            <input
              value={organization.available_forensic_watermark_screeners}
              className='col-12 cs-input'
              onChange={e => updateOrganization("available_forensic_watermark_screeners", e.target.value)}
              onBlur={() => saveOrganization("available_forensic_watermark_screeners")}/>
          </div>
          <div className='col-3 pl2'>
            <label className='cs-label'>Monthly Forensic Watermark Links</label>
            <input
              disabled={!organization.is_enterprise}
              value={organization.plan.forensic_watermark_preview_links}
              className='col-12 cs-input'
              onChange={e => updateOrganizationPlan('forensic_watermark_preview_links', e.target.value)}
              onBlur={() => saveOrganization("plan")}/>
          </div>
          <div className='col-3 pl2'>
            <label className='cs-label'>Screener Top-Up Date</label>
            <input disabled={true} value={formatDate(organization.screener_cycle_date, false)} className='cs-input col-12'/>
          </div>
        </div>
        <div className='flex items-center mt2'>
          <div>
            <Checkbox
              label={"Ignore Freezing Policies?"}
              checked={organization.is_ignoring_freezing_policies || false}
              onChange={event => {
                updateOrganization("is_ignoring_freezing_policies", !organization.is_ignoring_freezing_policies)
                saveOrganization("is_ignoring_freezing_policies")
              }}/>
          </div>
          <div className='ml2'>
            <Checkbox
              label={"Default Forensic Watermarks?"}
              checked={organization.files_forensic_watermarks_default || false}
              onChange={event => {
                updateOrganization("files_forensic_watermarks_default", !organization.files_forensic_watermarks_default)
                saveOrganization("files_forensic_watermarks_default")
              }}/>
          </div>
          <div className='ml2'>
            <Checkbox
              label={"Can Share HTML Files?"}
              checked={organization.can_share_html_files || false}
              onChange={event => {
                updateOrganization("can_share_html_files", !organization.can_share_html_files)
                saveOrganization("can_share_html_files")
              }}/>
          </div>
        </div>
      </div>}
      {organization.plan.can_access_files && organization.plan.can_access_festivals && <div className="border border-gray-5 rounded p2 mt2">
        <h4>Festivals Settings</h4>
        <div className='flex items-center mt1'>
          <div>
            <Checkbox
              label={"Can Duplicate Submissions to Files?"}
              checked={organization.can_duplicate_to_files || false}
              onChange={event => {
                updateOrganization("can_duplicate_to_files", !organization.can_duplicate_to_files)
                saveOrganization("can_duplicate_to_files")
              }}/>
          </div>
        </div>
      </div>}
      {(organization.plan.can_access_files || organization.plan.can_access_ondemand) && <div className="border border-gray-5 rounded p2 mt2">
        <h4>Screener Settings</h4>
        <div className='flex items-center mt1'>
          {[
            {
              code: "auto_screener_encode_enabled",
              label: "Auto Screener Encoding Enabled?",
              show: organization.is_enterprise
            },
            {
              code: "use_reserved_queue_for_encoding",
              label: "Use Reserved Queue for Encoding?",
              show: organization.is_enterprise
            },
            {
              code: "can_white_label",
              label: "Can White Label?",
              show: true
            }
          ]
          .filter(({ show }) => show)
          .map(permission => (
            <div key={permission.code} className='mr2'>
              <Checkbox
                label={permission.label}
                checked={organization.plan[permission.code] || false}
                onChange={event => updateOrganizationPlan(permission.code, !organization.plan[permission.code], true)}/>
            </div>
          ))}
        </div>
        <div className='flex items-center'>
          {organization.plan.can_white_label && <div className='col-3'>
            <label className='cs-label'>Screeners Domain</label>
            <input
              className='cs-input col-12'
              value={organization.screeners_domain}
              onChange={e => updateOrganization('screeners_domain', e.target.value)}
              onBlur={() => saveOrganization("screeners_domain")}/>
          </div>}
          {organization.plan.can_white_label && <div className='col-3 pl2'>
            <label className='cs-label'>Name of TV app</label>
            <input
              className='cs-input col-12'
              value={organization.screeners_tv_app_name}
              onChange={e => updateOrganization('screeners_tv_app_name', e.target.value)}
              onBlur={() => saveOrganization("screeners_tv_app_name")}/>
          </div>}
          <div className='col-3 pl2'>
            <label className='cs-label'>Encoding Profile</label>
            <Select
              options={encodingOptions}
              value={encodingOptions.find(opt => opt.value === organization.plan.encoding_profile)}
              onChange={({ value }) => updateOrganizationPlan('encoding_profile', value, true)}/>
          </div>
        </div>
      </div>}
      {organization.plan.can_access_ondemand && <div className="border border-gray-5 rounded p2 mt2">
        <h4>On-Demand Settings</h4>
        <div className='mt1'>
          {[
            {
              code: "ondemand_monetization_allowed",
              label: "Monetization Allowed?",
              show: true
            },
            {
              code: "ondemand_source_download_allowed",
              label: "Source Download Allowed?",
              show: true
            },
            {
              code: "ondemand_drm_live_streams_allowed",
              label: "DRM Live Streams Allowed?",
              show: true
            },
            {
              code: "ondemand_timed_events_allowed",
              label: "Timed Events Allowed?",
              show: true
            },
            {
              code: "ondemand_forensic_watermarks_allowed",
              label: "Forensic Watermarks Allowed?",
              show: true
            },
            {
              code: "ondemand_forensic_watermarks_default",
              label: "Default Forensic Watermarks?",
              show: true
            },
            {
              code: "ondemand_hide_tv_voucher_form",
              label: "Hide Voucher Form in tvOS?",
              show: true
            }
          ]
          .filter(({ show }) => show)
          .map(permission => (
            <div key={permission.code} className='mr2 inline-flex'>
              <Checkbox
                label={permission.label}
                checked={organization[permission.code] || false}
                onChange={event => {
                  updateOrganization(permission.code, !organization[permission.code], true)
                  saveOrganization(permission.code)
                }}/>
            </div>
          ))}
        </div>
        <div className='flex items-center'>
          <div className='col-3'>
            <label className='cs-label'>Portal Domain</label>
            <input
              className='cs-input col-12'
              value={organization.ondemand_domain}
              onChange={e => updateOrganization('ondemand_domain', e.target.value)}
              onBlur={() => saveOrganization("ondemand_domain")}/>
          </div>
          <div className='col-3 pl2'>
            <label className='cs-label'>Upload Size Limit in GB</label>
            <input
              className='cs-input col-12'
              value={organization.ondemand_request_size_limit_in_gb}
              placeholder='10'
              onChange={e => updateOrganization('ondemand_request_size_limit_in_gb', e.target.value)}
              onBlur={() => saveOrganization("ondemand_request_size_limit_in_gb")}/>
          </div>
          <div className='col-3 pl2'>
            <label className='cs-label'>Video Player Override</label>
            <Select
              options={playerOptions}
              value={playerOptions.find(opt => opt.value === organization.ondemand_player_code)}
              onChange={({ value }) => {
                updateOrganization('ondemand_player_code', value, true)
                saveOrganization('ondemand_player_code')
              }}/>
          </div>
          <div className='col-3 pl2'>
            <label className='cs-label'>Logs Endpoint Override</label>
            <Select
              options={logsEndpointOptions}
              value={logsEndpointOptions.find(opt => opt.value === organization.session_logs_service)}
              onChange={({ value }) => {
                updateOrganization('session_logs_service', value, true)
                saveOrganization('session_logs_service')
              }}/>
          </div>
        </div>
        <div className='mt2 border-top border-gray-5 pt2'>
          <h4>TV Apps</h4>
          <div className='col-3'>
            <label className='cs-label pt1'>Name of TV app</label>
            <input
              className='cs-input'
              value={organization.ondemand_tv_app_name}
              onChange={e => updateOrganization('ondemand_tv_app_name', e.target.value)}
              onBlur={() => saveOrganization("ondemand_tv_app_name")}/>
          </div>
          <div className="flex items-center mt2">
            {[
              {
                code: "ondemand_apple_tv_enabled",
                version_code: "ondemand_apple_tv_version",
                label: "AppleTV",
                show: true
              },
              {
                code: "ondemand_roku_enabled",
                version_code: "ondemand_roku_version",
                label: "Roku",
                show: true
              },
              {
                code: "ondemand_android_tv_enabled",
                version_code: "ondemand_android_tv_version",
                label: "AndroidTV",
                show: true
              },
              {
                code: "ondemand_fire_tv_enabled",
                version_code: "ondemand_fire_tv_version",
                label: "FireTV",
                show: true
              },
              {
                code: "ondemand_tizen_enabled",
                version_code: "ondemand_tizen_version",
                label: "Tizen",
                show: true
              }
            ]
            .filter(({ show }) => show)
            .map((permission, index) => (
              <div key={permission.code} className={`col-2 ${index > 0 ? 'pl2' : ''}`}>
                <Checkbox
                  label={`${permission.label} Enabled?`}
                  checked={organization[permission.code] || false}
                  onChange={event => {
                    updateOrganization(permission.code, !organization[permission.code], true)
                    saveOrganization(permission.code)
                  }}/>
                <div className='col-12'>
                  <label className='cs-label'>{`${permission.label} Version`}</label>
                  <input
                    className='cs-input col-12'
                    value={organization[permission.code] ? organization[permission.version_code] : ''}
                    disabled={!organization[permission.code]}
                    onChange={e => updateOrganization(permission.version_code, e.target.value)}
                    onBlur={() => saveOrganization(permission.version_code)}/>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>}
      {(organization.plan.can_access_files || organization.plan.can_access_bookings) && <div className="border border-gray-5 rounded p2 mt2">
        <h4>Account Settings</h4>
        <div className='flex items-center mt1'>
          {[
            {
              code: "can_enable_mfa",
              label: "Can Enable MFA?",
              show: true
            },
            {
              code: "can_post_pay",
              label: "Can Use Post Pay?",
              show: true
            },
          ]
          .filter(({ show }) => show)
          .map(permission => (
            <div key={permission.code} className='mr2'>
              <Checkbox
                label={permission.label}
                checked={organization.plan[permission.code] || false}
                onChange={event => updateOrganizationPlan(permission.code, !organization.plan[permission.code], true)}/>
            </div>
          ))}
        </div>
        {organization.plan.can_post_pay && <div className='col-3'>
          <label className='cs-label'>Post-Pay Terms (in days)</label>
          <input
            className='cs-input col-12'
            value={organization.plan.terms_in_days}
            onChange={e => updateOrganizationPlan('terms_in_days', e.target.value)}
            onBlur={() => saveOrganization("plan")}/>
        </div>}
      </div>}
    </div>
  )
}

Plan.propTypes = {
  organization: PropTypes.object.isRequired,
  updateOrganization: PropTypes.func.isRequired,
  saveOrganization: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  organization: state.organization.model
})

const mapDispatchToProps = dispatch => ({
  updateOrganization: bindActionCreators(updateOrganization, dispatch),
  saveOrganization: bindActionCreators(saveOrganization, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Plan)
