import React from "react"
import { withRouter } from "react-router-dom"
import SubNav from '../../components/SubNav'
import Routes from './routes'

const TransactionTables = ({ history }) =>
  <>
    <div style={{ margin: '-1em' }}>
      <SubNav
        compact={false}
        links={[
          { name: 'Invoices', to: "invoices", show: true },
          { name: 'Gift Cards', to: "gift-cards", show: true },
          { name: 'Promotions', to: "promotions", show: true }
        ].filter(opt => opt.show).map(opt => ({
          text: opt.name,
          to: `/transactions/${opt.to}`
        }))}/>
    </div>
    <div className='box-shadow border-radius bg-white p2 mt3'>
      <Routes/>
    </div>
  </>

export default withRouter(TransactionTables)
