export const GLOBALS = {
  ENV:  "production",
  URL: "https://ops.cinesend.com",
  API_URL: "https://api.cinesend.com/api",
  ACCOUNT_REDIRECT_URL: "https://accounts.cinesend.com/login",
  CHROME_CAST_STYLESHEET_URL: "https://s3.amazonaws.com/bitmovin-cast-css/development/styles.min.css",
  S3_PUBLIC: "https://public-assets.cinesend.com",
  S3_ACCESS_KEY: "AKIA2DLCUU6UNPGDOFSF",
  S3_ACCELERATE_ENDPOINT: "https://cinesendcontainer.s3-accelerate.amazonaws.com",
  BITMOVIN_API_KEY: "2a8b1f24-2c3c-4ab1-adeb-2b89af5171f0"
}