import React from "react"
import { Switch, Route } from "react-router-dom"
import MediaManagers from '../../components/MediaManagerTables/media_managers'
import MediaManager from '../../components/MediaManagers/media_manager'
import MediaManagerJobs from '../../components/MediaManagerTables/media_manager_jobs'
import MediaManagerJob from '../../components/MediaManagers/job'
import Cinemas from '../../components/MediaManagerTables/cinemas'
import Cinema from '../../components/Cinema'
import CinemaScreen from '../../components/Cinema/screen'

const Routes = () => 
  <Switch>
    <Route exact path='/devices/media-managers' component={MediaManagers}/>
    <Route path='/devices/media-managers/:mediaManagerID' component={MediaManager}/>
    <Route exact path='/devices/media-manager-jobs' component={MediaManagerJobs}/>
    <Route path='/devices/media-manager-jobs/:mediaManagerJobID' component={MediaManagerJob}/>
    <Route exact path='/devices/cinemas' component={Cinemas}/>
    <Route path='/devices/cinemas/:cinemaID' component={Cinema}/>
    <Route path='/devices/cinema-screens/:cinemaScreenID' component={CinemaScreen}/>
  </Switch>

export default Routes