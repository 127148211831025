import React from "react"
import { Switch, Route } from "react-router-dom"
import DigitalCinemaPackages from '../../components/FilesTables/digital_cinema_packages'
import SourceFiles from '../../components/FilesTables/source_files'
import SourceFile from '../../components/Files/source_file'
import Screeners from '../../components/FilesTables/screeners'
import Reviewers from '../../components/FilesTables/reviewers'
import Reviewer from '../../components/Files/reviewer'
import Uploads from '../../components/FilesTables/uploads'
import AsperaDownloads from '../../components/FilesTables/aspera_downloads'
import FileRequests from '../../components/FilesTables/file_requests'
import SharedFiles from '../../components/FilesTables/shared_files'
import SharedFile from '../../components/Files/shared_file'
import DigitalCinemaPackage from '../../components/Files/digital_cinema_package'

const Routes = () => 
  <Switch>
    <Route exact path='/files/digital-cinema-packages' component={DigitalCinemaPackages}/>
    <Route exact path='/files/source-files' component={SourceFiles}/>
    <Route exact path='/files/screeners' component={Screeners}/>
    <Route exact path='/files/shared-screeners' component={Reviewers}/>
    <Route path='/files/shared-screeners/:sharedScreenerID' component={Reviewer}/>
    <Route exact path='/files/uploads' component={Uploads}/>
    <Route exact path='/files/aspera-downloads' component={AsperaDownloads}/>
    <Route exact path='/files/file-requests' component={FileRequests}/>
    <Route exact path='/files/shared-files' component={SharedFiles}/>
    <Route exact path='/files/shared-files/:sharedFileID' component={SharedFile}/>
    <Route exact path='/files/source-files/:sourceFileID' component={SourceFile}/>
    <Route exact path='/files/digital-cinema-packages/:dcpID' component={DigitalCinemaPackage}/>
  </Switch>

export default Routes