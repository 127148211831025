import React from "react"
import moment from "moment"
import humanFileSize from "../../helpers/human_file_size"
import formatDate from '../../helpers/format_date'
import SharedTable from "../Common/table"

const MediaManagers = () => (
  <SharedTable
    collection={"media-managers"}
    queries={{}}
    includeSearch={true}
    includeTitle={"Media Managers"}
    includeFilter={true}
    includeTags={true}
    filters={[
      {
        label: 'Last Phoned Home',
        key: 'status_updated_at',
        type: 'daterange',
        options: {
          start_date: null,
          end_date: null
        }
      },
      {
        key: 'type',
        options: ['csx', 'cs3', 'cs6'].map(opt => ({ label: opt, value: opt }))
      }
    ]}
    sortKey='status_updated_at'
    sortDirection='DESC'
    includeShowDeletedCheckbox={true}
    isCompact={true}
    tableKeys={[
      { text: "Type" },
      { text: "Name", key: "name" },
      { text: "Device Details", key: "serial_number" },
      { text: "Software Details" },
      { text: "Created", key: "created_at" },
      { text: "Phoned Home", key: "status_updated_at" }
    ]}
    tableWidths={[55, "auto", 160, 200, 160, 160]}
    tableValues={[
      device => (
        <div className='flex items-center'>
          <div
            style={{
              borderRadius: "50%",
              width: "8px",
              height: "8px",
              border: "none",
              backgroundColor: device.is_offline ? "#d3d3d3" : "#04e004",
              marginRight: "6px",
              transition: "transform 0.2s linear"
            }}/>
          <b>{device.type ? device.type.toUpperCase() : "N/A"}</b>
        </div>
      ),
      device => (
        <div className='truncate'>
          <b>{device.name}</b>
          <span className='block light small'>
            {device.kind ? device.kind + " / " : ""}
            {device.owner_name}
          </span>
        </div>
      ),
      device => (
        <div className='truncate'>
          <b>{device.serial_number}</b>
          {device.disk_usage && (
            <span className='block small'>
              {humanFileSize(device.disk_usage.size - device.disk_usage.free)} /{" "}
              <b>{humanFileSize(device.disk_usage.size)}</b>
            </span>
          )}
        </div>
      ),
      device => (
        <div className='block small'>
          <b>Port: {device.ssh_port}</b>
          <div className='light'>
            {device.cmms_status && device.cmms_status.version && device.cmms_status.version.indexOf("cmms") === 0
              ? device.cmms_status.version
              : "cmms: N/A"}
            <br/>
            {device.media_manager_status &&
            device.media_manager_status.version &&
            device.media_manager_status.version.indexOf("digital") === 0
              ? device.media_manager_status.version.replace(".noarch", "")
              : "digital-cinema-media-manager: N/A"}
          </div>
        </div>
      ),
      device => formatDate(device.created_at),
      device => device.status_updated_at
        ? moment
          .utc(device.status_updated_at)
          .local()
          .fromNow()
        : "N/A"
    ]}/>
)

export default MediaManagers
