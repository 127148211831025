import React from "react"
import { connect } from "react-redux"
import OrganizationAsperaDownloads from "../../Customer/aspera_downloads"

const AsperaDownloads = ({ organizationID }) => (
  <OrganizationAsperaDownloads queries={{ organization_id: organizationID }}/>
)

const mapStateToProps = state => ({
  organizationID: state.organization.model._id
})

export default connect(mapStateToProps)(AsperaDownloads)

