import React from "react"
import SharedTable from "../Common/table"

const Cinemas = () =>
  <SharedTable
    collection={"cinemas"}
    queries={{}}
    includeSearch={true}
    includeNewModelButton={true}
    isCompact={true}
    includeTitle={"Cinemas"}
    tableKeys={[{ text: "Name" }, { text: "Address" }, { text: "Circuit" }]}
    tableWidths={[300, "auto", 200]}
    tableValues={[
      cinema => cinema.name,
      cinema => cinema.address + ", " + cinema.city + ", " + cinema.country,
      cinema => cinema.circuit
    ]}/>

export default Cinemas
