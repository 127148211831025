import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateJob } from "../../../api/job"
import { Button } from "@bitcine/cs-theme"

const uuidv4 = require("uuid/v4")

const sdkLocation = "//d3gcli72yxqn2z.cloudfront.net/connect/v4"
const connectSettings = {
  allow_dialogs: "no",
  use_absolute_destination_path: true
}

class Transfer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    const uuid = uuidv4()
    this.uuid = uuid
    this.asperaWeb = new window.AW4.Connect({
      sdkLocation,
      minVersion: "3.6.0",
      id: uuid
    })
    this.asperaWeb.initSession(`nodeConnect-${this.uuid}`)
  }
  initializeTransfer() {
    this.asperaInstaller = new window.AW4.ConnectInstaller({ sdkLocation })

    this.asperaWeb.addEventListener(window.AW4.Connect.EVENT.STATUS, (eventType, status) =>
      this.asperaWebEventListener(eventType, status)
    )

    const isDCP = this.props.jobType === "DCP"

    const downloadCallbacks = {
      success: path => {
        if (!path.dataTransfer.files.length) this.asperaWeb.stop()
        else {
          const transferSpec = {
            ...this.props.actions.aspera_setup.transfer_specs[0].transfer_spec,
            destination_root: path.dataTransfer.files[0].name,
            authentication: "token"
          }
          this.asperaWeb.startTransfer(transferSpec, connectSettings)
          this.asperaWeb.showTransferManager()
        }
      },
      error: err => alert(err)
    }
    const uploadCallbacks = {
      success: path => {
        if (path.dataTransfer.files.length > 1) {
          alert(`Only one ${isDCP ? "folder" : "file"} can be selected.`)
          this.asperaWeb.stop()
        }
        if (path.dataTransfer.files.length === 1) {
          const file = path.dataTransfer.files[0]
          const sourceFilePath = file.name
          const name = file.name
            .split("/")
            .pop()
            .split("\\")
            .pop()
          const fileName = isDCP ? "" : `${this.props.actions.blob_id}.${name.split(".").pop()}`
          const contentDisposition = isDCP ? "attachment;" : 'attachment; filename="' + name + '"'
          const destinationRoot = `${this.props.actions.aspera_setup.transfer_specs[0].transfer_spec.destination_root}${fileName}`

          const transferSpec = {
            ...this.props.actions.aspera_setup.transfer_specs[0].transfer_spec,
            tags: {
              aspera: {
                "cloud-metadata": [{ "Content-Disposition": contentDisposition }]
              }
            },
            paths: [
              {
                source: sourceFilePath
              }
            ],
            create_dir: isDCP, // only create a directory if its a folder upload
            destination_root: destinationRoot,
            authentication: "token"
          }
          const newFileList = file
          newFileList.name = file.name.split("/").pop()
          this.props.updateJob("file_list", [newFileList])
          this.asperaWeb.startTransfer(transferSpec, connectSettings)
          this.asperaWeb.showTransferManager()
          this.setState({ transfer_started: true })
        }
      },
      error: err => alert(err)
    }
    if (this.props.transferType === "Download") {
      const opts = {
        title: "Select Save Location",
        suggestedName: this.props.actions.file_name
      }
      this.asperaWeb.showSaveFileDialog(downloadCallbacks, opts)
    } else {
      const opts = {}
      if (isDCP) this.asperaWeb.showSelectFolderDialog(uploadCallbacks, opts)
      else this.asperaWeb.showSelectFileDialog(uploadCallbacks, opts)
    }
  }
  asperaWebEventListener(eventType, dataStatus) {
    const status = window.AW4.Connect.STATUS
    if (eventType === window.AW4.Connect.EVENT.STATUS) {
      switch (dataStatus) {
        case status.INITIALIZING:
          this.asperaInstaller.showLaunching()
          break
        case status.FAILED:
          this.asperaInstaller.showDownload()
          break
        case status.OUTDATED:
          this.asperaInstaller.showUpdate()
          break
        case status.RUNNING:
          this.asperaInstaller.connected()
          break
        case status.RETRYING:
          break
        default:
          break
      }
    }
  }
  render() {
    const { project, jobType, transferType, needsFiles, actions } = this.props
    if (needsFiles)
      return (
        <div className='py4 my3'>
          <strong>Files Not Available</strong>
          <p>Whoops, it looks like the filmmaker has not supplied the files needed for this job!</p>
        </div>
      )
    return (
      <div>
        <div className='my4'>
          <strong className='mt3'>
            {transferType} {jobType} for {project}
          </strong>
          <p className='mb0'>
            This job requires {transferType}ing the {jobType} for {project}.
          </p>
          <p className='mb0'>
            Path: <b>{actions.key}</b>
          </p>
          <span onClick={() => this.asperaWeb.showTransferManager()} className='block light underline pointer'>
            View Aspera transfers window
          </span>
          <br/>
          {this.state.transfer_started && "Transfer has started."}
        </div>
        <div className='right-align'>
          <Button onClick={() => this.initializeTransfer()}>{transferType}</Button>
        </div>
      </div>
    )
  }
}

Transfer.propTypes = {
  actions: PropTypes.object.isRequired,
  project: PropTypes.string.isRequired,
  isComplete: PropTypes.bool.isRequired,
  jobType: PropTypes.string.isRequired,
  transferType: PropTypes.string.isRequired,
  needsFiles: PropTypes.bool.isRequired,
  updateJob: PropTypes.func.isRequired
}

function mapStateToProps(state, ownProps) {
  return {
    actions: state.job.actions || {},
    project: state.job.details.project_title,
    isComplete: state.job.details.status === "Complete",
    needsFiles:
      !state.job.actions ||
      !state.job.actions.key || (ownProps.transferType === "Download" && !state.job.actions.file_name)
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateJob: bindActionCreators(updateJob, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Transfer)
