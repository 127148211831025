import {
  GET_ORGANIZATION,
  CONVERT_ORGANIZATION_TO_USER,
  DELETE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  SAVE_ORGANIZATION
} from '../../redux/reducers/organization/types'
import { get, post, put, del } from '../fetch'
import { addMessage } from '../messages'

export const getOrganization = organizationID => dispatch =>
  dispatch(
    get(
      GET_ORGANIZATION.REQUEST,
      `admin/organizations/${organizationID}`
    )
  )

export const updateOrganization = (key, value) => dispatch =>
  dispatch({
    type: UPDATE_ORGANIZATION,
    payload: {
      key,
      value
    }
  })

export const saveOrganization = key => (dispatch, getState) =>
  dispatch(
    put(
      SAVE_ORGANIZATION.REQUEST,
      `admin/organizations/${getState().organization.model._id}`,
      {
        key,
        value: getState().organization.model[key]
      },
      () => dispatch(addMessage('Organization saved.'))
    )
  )

export const convertOrganizationToUser = organizationID => dispatch =>
  dispatch(
    post(
      CONVERT_ORGANIZATION_TO_USER,
      `admin/organizations/${organizationID}/convert-to-user`,
      {
        organizationID
      },
      () => dispatch(addMessage('Converted organization to user.'))
    )
  )

export const deleteOrganization = organizationID => dispatch =>
  dispatch(
    del(
      DELETE_ORGANIZATION.REQUEST,
      `admin/organizations/${organizationID}`,
      {
        organizationID
      },
      () => dispatch(addMessage('Deleted organization.'))
    )
  )