import React, { useRef } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Modal, Button } from "@bitcine/cs-theme"
import { initializeBasicUploader } from "../../api/upload/http"
import { initializeAsperaUploader } from '../../api/upload/aspera'
import Aspera from "../Icons/aspera"

const UploadModal = ({ close, initializeBasicUploader, initializeAsperaUploader, isDCP = false, queries }) => {
  const inputFile = useRef(null)
  const opts = isDCP ? { ...queries, is_dcp: true } : queries
  return (
    <Modal
      width={2}
      open={true}
      title={`Upload ${isDCP ? 'DCP' : 'File'}`}
      clickOutsideCloses
      onClose={close}>
      <div className='p3 center'>
        <p className='mb2'>Select a button below to upload your content.</p>
        <Button onClick={() => inputFile.current.click()}>Upload</Button>
        <Button 
          onClick={() => {
            initializeAsperaUploader(opts)
            close()
          }}
          className='white ml2' >
          <Aspera msg='Upload with Aspera'/>
        </Button>
        <input 
          type='file'
          ref={inputFile}
          webkitdirectory={isDCP ? 1 : null}
          style={{ display: "none" }}
          onChange={e => {
            if (isDCP) {
              initializeBasicUploader([...e.target.files].map(file => file), opts)
            }
            else {
              [...e.target.files].forEach(file => {
                initializeBasicUploader(file, opts)
              })
            }
            close()
          }}/>
      </div>
    </Modal>
  )
}

UploadModal.propTypes = {
  initializeBasicUploader: PropTypes.func.isRequired,
  initializeAsperaUploader: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({
  initializeBasicUploader: bindActionCreators(initializeBasicUploader, dispatch),
  initializeAsperaUploader: bindActionCreators(initializeAsperaUploader, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadModal)
