import {
  GET_PROJECT,
  DELETE_PROJECT,
  TRANSFER_PROJECT
} from '../../redux/reducers/project/types'
import { get, post, del } from '../fetch'
import { addMessage } from '../messages'

export const getProject = projectID => dispatch => {
  dispatch(
    get(
      GET_PROJECT.REQUEST,
      `admin/projects/${projectID}`
    )
  )
}

export const transferProject = (projectID, customerID, callback) => dispatch => {
  dispatch(
    post(
      TRANSFER_PROJECT.REQUEST,
      `admin/projects/${projectID}/transfer`,
      {
        customerID
      },
      () => {
        dispatch(addMessage("Project transferred."))
        callback()
      }
    )
  )
}

export const deleteProject = projectID => dispatch => {
  dispatch(
    del(
      DELETE_PROJECT.REQUEST,
      `admin/projects/${projectID}`,
      null,
      () => dispatch(addMessage("Project deleted."))
    )
  )
}
