import React from "react"
import { withRouter } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import formatDate from "../../../helpers/format_date"
import SharedTable from "../../Common/table"
import CopyText from "../../CopyText"

const MediaConvertJobs = ({ match, history }) =>
  <SharedTable
    collection={"media-convert-jobs"}
    queries={{ video_id: match.params.videoID }}
    isCompact={true}
    disableRowClick={true}
    includeTitle={"Media Convert Jobs"}
    tableKeys={[
      { text: "Source File Name" },
      { text: "Type" },
      { text: "Captions" },
      { text: "Media Convert Job ID" },
      { text: "Status" },
      { text: "Updated" },
      { text: "Created" }
    ]}
    tableWidths={["auto", 120, 80, 180, 140, 160, 160]}
    tableValues={[
      job => <div className='truncate flex items-center'>
        {job.source_file?.name || 'N/A'}
        <ReactTooltip id='source-file-id' place='left' effect='solid' className='bold'/>
        {job.source_file?._id && <span
          className='truncate pointer tooltip material-icons ml1'
          data-tip='View source file'
          data-for='source-file-id'
          style={{ fontSize: '14px' }}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            history.push(`/source-files/${job.source_file._id}`)
          }}>
          east
        </span>}
      </div>,
      job => job.type,
      job => job.includes_captions ? 'Yes' : 'No',
      job => <CopyText label={job.job_id}/>,
      job => <div className='flex items-center truncate'>
        {job.status ? job.status : job.result ? job.result.Status : 'N/A'}
        <ReactTooltip id='error' place='bottom' effect='solid' className='bold'/>
        {job.latest_message?.detail?.errorMessage && <span
          className='truncate tooltip material-icons ml1 red'
          data-tip={job.latest_message.detail.errorMessage}
          data-for='error'
          style={{ fontSize: '14px' }}>
          error
        </span>}
      </div>,
      job => formatDate(job.updated_at),
      job => formatDate(job.created_at)
    ]}/>

export default withRouter(MediaConvertJobs)