import React from "react"
import { withRouter } from 'react-router-dom'
import SharedModel from "../Common/model"

const Promotion = ({ match }) => (
  <SharedModel
    modelID={match.params.promotionID}
    headerKey={"promo_code"}
    collection={"promotions"}
    isEditable={true}
    isDeletable={true}/>
)

export default withRouter(Promotion)
