import React from "react"
import { connect } from "react-redux"
import CustomerPayment from "../../Customer/payment"

const Payment = ({ organizationID, defaultCreditCardID }) =>
  <CustomerPayment
    defaultCreditCardID={defaultCreditCardID}
    queries={{ organization_id: organizationID }}/>

const mapStateToProps = state => ({
  defaultCreditCardID: state.organization.model.default_credit_card_id,
  organizationID: state.organization.model._id
})

export default connect(mapStateToProps)(Payment)
