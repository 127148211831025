import React from "react"
import PropTypes from "prop-types"
import { Modal } from "@bitcine/cs-theme"
import SharedModel from "../../Common/model"

const File = ({ collection, modelID, close }) => (
  <Modal open={true} width={3} onClose={close} title={"File Info"}>
    <SharedModel
      modelID={modelID}
      headerKey={"name"}
      subheaderKey={"file_name"}
      collection={collection.replace(/_/g, "-")}
      isEditable={true}
      columnWidth={6}
      isDeletable={false}/>
  </Modal>
)

File.propTypes = {
  collection: PropTypes.string.isRequired,
  modelID: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired
}

export default File
