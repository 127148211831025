import React from "react"
import { connect } from "react-redux"
import formatDate from "../../../helpers/format_date"
import humanFileSize from "../../../helpers/human_file_size"
import SharedTable from "../../Common/table"

const Videos = ({ organizationID }) => (
  <SharedTable
    collection={"videos"}
    queries={{
      organization_id: organizationID
    }}
    includeSearch={true}
    includeTitle="Videos"
    isCompact={true}
    tableKeys={[
      { text: "Title", key: "title" },
      { text: "Status", key: "status" },
      { text: "Encoding Job Status" },
      { text: "Captions" },
      { text: "Encoded Size" },
      { text: "Created At", key: "created_at" }
    ]}
    tableWidths={["auto", 140, 140, 80, 140, 160]}
    tableValues={[
      data => <span className='truncate'>{data.title}</span>,
      data => data.status,
      data => <>
        {data.multi_output_job_status && <>Multi: {data.multi_output_job_status}</>}
        {data.dash_job_status && <span className='block small'>DASH: {data.dash_job_status}</span>}
        {data.hls_job_status && <span className='block small'>HLS: {data.hls_job_status}</span>}
      </>,
      data => data.captions_count,
      data => humanFileSize(data.size),
      data => formatDate(data.created_at)
    ]}/>
)

const mapStateToProps = state => ({
  organizationID: state.organization.model._id
})

export default connect(mapStateToProps)(Videos)
