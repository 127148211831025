import React from "react"
import AccessDates from "../Common/access_dates"
import SharedTable from "../Common/table"

const MediaManagerJobs = () => (
  <SharedTable
    collection={"media-manager-jobs"}
    queries={{}}
    includeSearch={true}
    includeTitle={"Jobs"}
    includeFilter={true}
    filters={[
      {
        key: 'status',
        options: ['Running', 'Queued', 'Pending', 'Retrying', 'Triggered', 'Preparing', 'Cleared', 'Completed', 'Failed'].map(opt => ({ label: opt, value: opt }))
      }
    ]}
    isCompact={true}
    tableKeys={[{ text: "Device" }, { text: "Name" }, { text: "Job Type" }, { text: "Status" }, { text: "Updated" }]}
    tableWidths={[220, "auto", 200, 140, 180]}
    tableValues={[
      job => <span className='truncate'>{job.media_manager_name}</span>,
      job => <span className='truncate'>{job.name}</span>,
      job => <div className='truncate'>{job.code}<br/><span className='block light small'>{job.type}</span></div>,
      job => job.status,
      job => <AccessDates model={job}/>
    ]}/>
)

export default MediaManagerJobs
