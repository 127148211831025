import React from "react"
import PropTypes from "prop-types"
import SharedTable from "../Common/table"
import formatDate from "../../helpers/format_date"
import humanFileSize from "../../helpers/human_file_size"
// import storageClasses from '../../helpers/storage_classes'

const Storage = ({ queries }) => (
  <SharedTable
    collection={"storage-metadata"}
    queries={queries}
    includeTitle={"Storage Metadata"}
    sortKey={"created_at"}
    sortDirection={"DESC"}
    disableRowClick={true}
    isCompact={true}
    tableKeys={[
      { text: "All Apps", key: "total" },
      { text: "Files", key: "files_total" },
      { text: "Requests", key: "festivals_total" },
      { text: "On Demand", key: "ondemand_total" },
      { text: "Date", key: "created_at" }
    ]}
    tableWidths={["auto", 200, 200, 200, 200]}
    tableValues={[
      data => <b>{humanFileSize(data.total)}</b>,
      data => <span>{humanFileSize(data.files_total)}
        {/*<span className='small block'>
          {storageClasses
            .filter(storageClass => !!data['files_' + storageClass['class']])
            .map(storageClass => `${storageClass['label']}: ${humanFileSize(data['files_' + storageClass['class']])}`)
            .join(' | ')}
        </span>*/}
      </span>,
      data => <span>{humanFileSize(data.festivals_total)}
        {/*<span className='small block'>
          {storageClasses
            .filter(storageClass => !!data['festivals_' + storageClass['class']])
            .map(storageClass => `${storageClass['label']}: ${humanFileSize(data['festivals_' + storageClass['class']])}`)
            .join(' | ')}
        </span>*/}
      </span>,
      data => <span>{humanFileSize(data.ondemand_total)}
        {/*<span className='small block'>
          {storageClasses
            .filter(storageClass => !!data['ondemand_' + storageClass['class']])
            .map(storageClass => `${storageClass['label']}: ${humanFileSize(data['ondemand_' + storageClass['class']])}`)
            .join(' | ')}
        </span>*/}
      </span>,
      data => formatDate(data.created_at, false)
    ]}/>
)

Storage.propTypes = {
  queries: PropTypes.object.isRequired
}

export default Storage
