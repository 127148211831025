import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'
import Dashboard from '../components/Dashboard'
import UsersTable from '../components/UsersTable'
import User from '../components/User'
import OrganizationsTable from '../components/OrganizationsTable'
import Organization from '../components/Organization'
import ProjectsTable from '../components/ProjectsTable'
import Project from '../components/Project'
import RequestsTable from '../components/RequestsTable'
import Request from '../components/Request'
import Video from '../components/Video'
import FilesPage from '../pages/Files'
import OnDemandPage from '../pages/OnDemand'
import MediaManagersPage from '../pages/MediaManagers'
import ProductionPage from '../pages/Production'
import TransactionsPage from '../pages/Transactions'
import SettingsPage from '../pages/Settings'

const Routes = () =>
  <Switch>
    <Redirect exact path='/' to='users'/>
    <Redirect exact path='/settings' to='/settings/countries'/>
    <Redirect exact path='/facilities' to='/settings/facilities'/>
    <Redirect exact path='/facilities/:facilityID' to='/settings/facilities/:facilityID'/>
    <Redirect exact path='/countries' to='/settings/countries'/>
    <Redirect exact path='/countries/:countryID' to='/settings/countries/:countryID'/>
    <Redirect exact path='/languages' to='/settings/languages'/>
    <Redirect exact path='/languages/:languageID' to='/settings/languages/:languageID'/>
    <Redirect exact path='/items' to='/settings/items'/>
    <Redirect exact path='/items/:itemID' to='/settings/items/:itemID'/>
    <Redirect exact path='/staff-roles' to='/settings/staff-roles'/>
    <Redirect exact path='/staff-roles/:staffRoleID' to='/settings/staff-roles/:staffRoleID'/>
    <Redirect exact path='/currencies' to='/settings/currencies'/>
    <Redirect exact path='/currencies/:currencyID' to='/settings/currencies/:currencyID'/>
    <Redirect exact path='/tax-rates' to='/settings/tax-rates'/>
    <Redirect exact path='/tax-rates/:taxRateID' to='/settings/tax-rates/:taxRateID'/>
    <Redirect exact path='/tax-regions' to='/settings/tax-reginos'/>
    <Redirect exact path='/tax-regions/:taxRegionID' to='/settings/tax-regions/:taxRegionID'/>
    <Redirect exact path='/country-shipping-costs' to='/settings/booking-rates'/>
    <Redirect exact path='/country-shipping-costs/:bookingRateID' to='/settings/booking-rates/:bookingRateID'/>
    <Redirect exact path='/transactions' to='/transactions/invoices'/>
    <Redirect exact path='/invoices' to='/transactions/invoices'/>
    <Redirect exact path='/invoices/:invoiceID' to='/transactions/invoices/:invoiceID'/>
    <Redirect exact path='/gift-cards' to='/transactions/gift-cards'/>
    <Redirect exact path='/gift-cards/:giftCardID' to='/transactions/gift-cards/:giftCardID'/>
    <Redirect exact path='/aspera-entitlements' to='/transactions/aspera-entitlements'/>
    <Redirect exact path='/aspera-entitlements/:asperaEntitlementID' to='/transactions/aspera-entitlements/:asperaEntitlementID'/>
    <Redirect exact path='/promotions' to='/transactions/promotions'/>
    <Redirect exact path='/promotions/:promotionID' to='/transactions/promotions/:promotionID'/>
    <Redirect exact path='/production' to='/production/open-jobs'/>
    <Redirect exact path='/open-jobs' to='/production/open-jobs'/>
    <Redirect exact path='/jobs' to='/production/jobs'/>
    <Redirect exact path='/jobs/:jobID' to='/production/jobs/:jobID'/>
    <Redirect exact path='/campaigns' to='/production/bookings'/>
    <Redirect exact path='/campaigns/:bookingID' to='/production/bookings/:bookingID'/>
    <Redirect exact path='/content-orders' to='/production/electronic-bookings'/>
    <Redirect exact path='/content-orders/:electronicBookingID' to='/production/electronic-bookings/:electronicBookingID'/>
    <Redirect exact path='/shipments' to='/production/shipments'/>
    <Redirect exact path='/shipments/:shipmentID' to='/production/shipments/:shipmentID'/>
    <Redirect exact path='/assets' to='/production/assets'/>
    <Redirect exact path='/assets/:assetID' to='/production/assets/:assetID'/>
    <Redirect exact path='/devices' to='/devices/media-managers'/>
    <Redirect exact path='/media-managers' to='/devices/media-managers'/>
    <Redirect exact path='/media-managers/:mediaManagerID' to='/devices/media-managers/:mediaManagerID'/>
    <Redirect exact path='/media-manager-jobs' to='/devices/media-manager-jobs'/>
    <Redirect exact path='/media-manager-jobs/:mediaManagerJobID' to='/devices/media-manager-jobs/:mediaManagerJobID'/>
    <Redirect exact path='/cinemas' to='/devices/cinemas'/>
    <Redirect exact path='/cinemas/:cinemaID' to='/devices/cinemas/:cinemaID'/>
    <Redirect exact path='/cinema-screens' to='/devices/cinema-screens'/>
    <Redirect exact path='/cinema-screens/:cinemaScreenID' to='/devices/cinema-screens/:cinemaScreenID'/>
    <Redirect exact path='/ondemand' to='/ondemand/videos'/>
    <Redirect exact path='/videos' to='/ondemand/videos'/>
    <Redirect exact path='/subscribers' to='/ondemand/subscribers'/>
    <Redirect exact path='/subscribers/:subscriberID' to='/ondemand/subscribers/:subscriberID'/>
    <Redirect exact path='/playlists/:playlistID' to='/ondemand/playlists/:playlistID'/>
    <Redirect exact path='/events/:eventID' to='/ondemand/events/:eventID'/>
    <Redirect exact path='/serials/:serialID' to='/ondemand/serials/:serialID'/>
    <Redirect exact path='/media-live-channels/:mediaLiveChannelID' to='/ondemand/media-live-channels/:mediaLiveChannelID'/>
    <Redirect exact path='/vouchers' to='/ondemand/vouchers'/>
    <Redirect exact path='/vouchers/:voucherID' to='/ondemand/vouchers/:voucherID'/>
    <Redirect exact path='/timed-texts' to='/ondemand/timed-texts'/>
    <Redirect exact path='/timed-texts/:timedTextID' to='/ondemand/timed-texts/:timedTextID'/>
    <Redirect exact path='/sessions/:sessionID' to='/ondemand/sessions/:sessionID'/>
    <Redirect exact path='/files' to='/files/digital-cinema-packages'/>
    <Redirect exact path='/digital-cinema-packages' to='/files/digital-cinema-packages'/>
    <Redirect exact path='/digital-cinema-packages/:dcpID' to='/files/digital-cinema-packages/:dcpID'/>
    <Redirect exact path='/downloads' to='/files/shared-files'/>
    <Redirect exact path='/downloads/:downloadID' to='/files/shared-files/:downloadID'/>
    <Redirect exact path='/source-files' to='/files/source-files'/>
    <Redirect exact path='/source-files/:sourceFileID' to='/files/source-files/:sourceFileID'/>
    <Redirect exact path='/screeners' to='/files/screeners'/>
    <Redirect exact path='/screeners/:screenerID' to='/videos/:screenerID'/>
    <Redirect exact path='/shared-screeners' to='/files/shared-screeners'/>
    <Redirect exact path='/shared-screeners/:sharedScreenerID' to='/files/shared-screeners/:sharedScreenerID'/>
    <Route exact path='/dashboard' component={Dashboard}/>
    <Route exact path='/users' component={UsersTable}/>
    <Route path='/users/:userID' component={User}/>
    <Route exact path='/organizations' component={OrganizationsTable}/>
    <Route path='/organizations/:organizationID' component={Organization}/>
    <Route exact path='/projects' component={ProjectsTable}/>
    <Route path='/projects/:projectID' component={Project}/>
    <Route exact path='/requests' component={RequestsTable}/>
    <Route path='/requests/:requestID' component={Request}/>
    <Route path='/videos/:videoID' component={Video}/>
    <Route path='/files' component={FilesPage}/>
    <Route path='/ondemand' component={OnDemandPage}/>
    <Route path='/devices' component={MediaManagersPage}/>
    <Route path='/production' component={ProductionPage}/>
    <Route path='/transactions' component={TransactionsPage}/>
    <Route path='/settings' component={SettingsPage}/>
  </Switch>

export default Routes