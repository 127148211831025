import React from "react"
import SharedTable from "../Common/table"
import formatDate from "../../helpers/format_date"

const SharedFiles = () => (
  <SharedTable
    collection={"downloads"}
    queries={{}}
    includeSearch={true}
    isCompact={true}
    includeTitle={"Shared Files"}
    tableKeys={[
      { text: "File", key: "description" },
      { text: "Project", key: "project_title" },
      { text: "Owner" },
      { text: "Shared To", key: "shared_email" },
      { text: "Created", key: "created_at" }
    ]}
    tableWidths={["auto", 220, 220, 220, 180]}
    tableValues={[
      sharedFile => <span className='truncate'>{sharedFile.description}</span>,
      sharedFile => <span className='truncate'>{sharedFile.project_title}</span>,
      ({ owner_name }) => <span className='triuncate'>{owner_name}</span>,
      sharedFile => (
        <div>
          {sharedFile.shared_email || "Shared via URL"}
          <span className='capitalize small block light'>{sharedFile.method}</span>
        </div>
      ),
      ({ created_at }) => formatDate(created_at)
    ]}/>
)

export default SharedFiles
