import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from 'react-router-dom'
import { Modal, Status, Table, Button, ButtonDropdown } from "@bitcine/cs-theme"
import {
  getFacilityStaff,
  addFacilityStaff,
  updateStaff,
  removeUserFromFacility
} from "../../api/admin"
import CommonDropdown from "../Common/dropdown"
import SharedModel from "../Common/model"

const Staff = ({ match, pending, failed, staff, roles, getFacilityStaff, addFacilityStaff, removeUserFromFacility, updateStaff }) => {
  const facilityID = match.params.facilityID
  const [showAddStaffModal, setShowAddStaffModal] = useState(false)
  const [newStaffUserID, setNewStaffUserID] = useState("")
  useEffect(() => {
    getFacilityStaff(facilityID)
  }, [getFacilityStaff, facilityID])
  return (
    <div>
      <SharedModel
        modelID={facilityID}
        headerKey={"name"}
        collection={"facilities"}
        isEditable={true}
        isDeletable={true}/>
      <div className='flex items-center mt2'>
        <h4 className='flex-auto mt0'>Facility Staff</h4>
        <Button small onClick={() => setShowAddStaffModal(true)}>Add Staff</Button>
      </div>
      <Status pending={pending} error={failed}>
        <Table
          className='my3'
          widths={["auto", 300, 300, 65]}
          header={{
            columns: [{ text: "Name" }, { text: "Email" }, { text: "Role" }, { text: "" }]
          }}
          body={{
            data: staff,
            row: {
              compact: true,
              render: [
                user => user.full_name,
                user => user.email,
                user => roles.find(role => role._id === user.staff_role_id)?.title,
                user => <ButtonDropdown
                  button={{
                    className: "link material-icons",
                    text: "more_vert",
                    style: { width: "48px" }
                  }}
                  dropdown={{
                    content: [
                      {
                        text: "Set Role",
                        icon: "assignment_ind",
                        children: roles.map(role => ({
                          text: role.title,
                          onClick: () => updateStaff(facilityID, user._id, "staff_role_id", role._id)
                        }))
                      },
                      {
                        text: "Remove",
                        icon: "delete",
                        onClick: () => removeUserFromFacility(facilityID, user._id)
                      }
                    ]
                  }}/>
              ]
            }
          }}/>
      </Status>
      {showAddStaffModal && (
        <Modal
          open={true}
          width={2}
          onClose={() => setShowAddStaffModal(false)}
          title={`Add a Staff Member`}>
          <CommonDropdown
            containerClassName='col-12'
            options='users'
            label='Select User'
            value={newStaffUserID}
            onChange={({ value }) => setNewStaffUserID(value)}
            clearable={false}/>
          <div className='right-align mt3'>
            <Button
              disabled={!newStaffUserID}
              onClick={() => {
                addFacilityStaff(facilityID, newStaffUserID)
                setShowAddStaffModal(false)
              }}>
              Save
            </Button>
          </div>
        </Modal>
      )}
    </div>
  )
}

Staff.propTypes = {
  getFacilityStaff: PropTypes.func.isRequired,
  addFacilityStaff: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  staff: PropTypes.array.isRequired,
  updateStaff: PropTypes.func.isRequired,
  removeUserFromFacility: PropTypes.func.isRequired
}

function mapStateToProps(state, context) {
  return {
    staff: state.admin.staff.list,
    roles: state.admin.roles.list,
    pending: state.admin.roles.status === 'PENDING' || state.admin.staff.status === 'PENDING',
    failed: state.admin.roles.status === 'ERROR' || state.admin.staff.status === 'ERROR'
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getFacilityStaff: bindActionCreators(getFacilityStaff, dispatch),
    addFacilityStaff: bindActionCreators(addFacilityStaff, dispatch),
    updateStaff: bindActionCreators(updateStaff, dispatch),
    removeUserFromFacility: bindActionCreators(removeUserFromFacility, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Staff))
