import React from "react"
import SharedTable from "../Common/table"
import formatDate from "../../helpers/format_date"

const AllJobs = () =>
  <SharedTable
    collection={"jobs"}
    queries={{ parent_job_id: null }}
    includeSearch={true}
    includeTitle={"All Jobs"}
    isCompact={true}
    sortKey='completed_date'
    sortDirection='DESC'
    tableKeys={[
      { text: "Title", key: "title" },
      { text: "Project" },
      { text: "Owner" },
      { text: "Completed By" },
      { text: "Completed At", key: "completed_date" }
    ]}
    tableWidths={["auto", 200, 200, 200, 200]}
    tableValues={[
      job => <span className='truncate'>{job.title}</span>,
      job => <span className='truncate'>{job.project_title}</span>,
      job => <span className='truncate'>{job.owner_name}</span>,
      job => <span className='truncate'>{`${job.completed_by} / ${job.facility_name}`}</span>,
      job => formatDate(job.completed_date)
    ]}/>

export default AllJobs
