import React from "react"
import { connect } from "react-redux"
import UserStorageMetadata from "../../Customer/storage_metadata"

const StorageMetadata = ({ userID }) => (
  <UserStorageMetadata queries={{ user_id: userID }}/>
)

const mapStateToProps = state => ({
  userID: state.user.model._id
})

export default connect(mapStateToProps)(StorageMetadata)

