import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { updateJobActions, setJobStatus } from "../../../api/job"

function Copy({ jobID, lineItems, fileName, project, jobType, asset, serialNumber, updateJobActions, setJobStatus }) {
  return (
    <div>
      <strong className='mt2 block'>1. Copy {jobType}</strong>
      <p>
        This job requires copying {fileName} for {project} to a drive.
      </p>
      {lineItems && (
        <div className='py1'>
          <strong>2. Selected Drive</strong>
          {lineItems.map((e, i) => (
            <div key={i}>
              {e.name} x {e.quantity}
            </div>
          ))}
        </div>
      )}
      <strong className='mt2 block'>3. Enter the Asset Serial Number</strong>
      <label>Asset Serial Number</label>
      <input
        className='cs-input'
        value={serialNumber}
        autoFocus
        onChange={({ target: { value } }) => updateJobActions("serial_number", value)}/>
      <small className='right-align mt3 light block'>
        Ensure the {jobType} file is copied successfully before completing this job.
      </small>
    </div>
  )
}

Copy.propTypes = {
  project: PropTypes.string.isRequired,
  jobType: PropTypes.string.isRequired,
  asset: PropTypes.object.isRequired,
  serialNumber: PropTypes.string.isRequired,
  updateJobActions: PropTypes.func.isRequired,
  jobID: PropTypes.string.isRequired,
  setJobStatus: PropTypes.func.isRequired,
  lineItems: PropTypes.array,
  fileName: PropTypes.string.isRequired
}

function mapStateToProps(state) {
  return {
    project: state.job.details.project_title,
    asset: state.job.actions.asset,
    serialNumber: state.job.actions.serial_number || "",
    lineItems: state.job.actions.line_items,
    jobID: state.job._id,
    fileName: state.job.actions.file_name
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateJobActions: bindActionCreators(updateJobActions, dispatch),
    setJobStatus: bindActionCreators(setJobStatus, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Copy)
