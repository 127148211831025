import { Doughnut } from 'react-chartjs-2';
import getColors from './colors'

const buildData = data => ({
  labels: Object.keys(data),
  datasets: [
    {
      label: 'Active Sessions',
      data: Object.values(data),
      ...getColors(Object.keys(data).length)
    }
  ]
})

const OnDemandSessionsChart = ({ data }) =>
  <Doughnut
    data={buildData(data)}
    options={{ maintainAspectRatio: false, plugins: { title: { display: true, text: 'Active Sessions' } } }}/>

export default OnDemandSessionsChart
