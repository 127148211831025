import React from 'react'
import styled from 'styled-components'
import AuthenticationWrapper from './components/AuthenticationWrapper'
import HeaderBar from './components/HeaderBar'
import SideBar from './components/SideBar'
import Messages from './components/Messages'
import Routes from './routes'

const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
`

const ContentContainer = styled.div`
  margin-left: 220px;
  padding-top: 56px;
`

const App = () =>
  <AuthenticationWrapper>
    <PageContainer>
      <HeaderBar/>
      <SideBar/>
      <Messages/>
      <ContentContainer>
        <div className='p2'>
          <Routes/>
        </div>
      </ContentContainer>
    </PageContainer>
  </AuthenticationWrapper>

export default App