import React from "react"
import formatDate from "../../helpers/format_date"
import AccessDates from "../Common/access_dates"
import SharedTable from "../Common/table"

const Reviewers = () => (
  <SharedTable
    collection={"shared-screeners"}
    queries={{}}
    includeSearch={true}
    includeTitle={"Reviewers"}
    isCompact={true}
    tableKeys={[{ text: "Name" }, { text: "Shared To" }, { text: "Last Viewed" }, { text: "Created" }]}
    tableWidths={["auto", 300, 200, 200]}
    tableValues={[
      reviewer => (
        <div>
          {reviewer.name}
          <span className='block light small'>{reviewer.owner_name}</span>
        </div>
      ),
      reviewer =>
        reviewer.share_type === "email" ? (
          <span>Email: {reviewer.shared_email}</span>
        ) : (
          <span>Link: {reviewer.shared_name || "N/A"}</span>
        ),
      reviewer => (reviewer.last_viewed_at ? formatDate(reviewer.last_viewed_at) : "Not Viewed Yet"),
      reviewer => <AccessDates model={reviewer}/>
    ]}/>
)

export default Reviewers
