import React from "react"
import { withRouter } from "react-router-dom"
import SharedModel from "../Common/model"

const TaxRegion = ({ match }) => (
  <SharedModel
    modelID={match.params.taxRegionID}
    headerKey={"country_code"}
    subheaderKey={"state_or_province_code"}
    collection={"tax-regions"}
    isEditable={true}
    isDeletable={true}/>
)

export default withRouter(TaxRegion)
