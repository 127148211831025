import React from "react"
import SharedTable from "../Common/table"

const Facilities = () => (
  <SharedTable
    collection={"facilities"}
    queries={{}}
    includeSearch={true}
    includeTitle={"Facilities"}
    isCompact={true}
    tableKeys={[{ text: "Name", key: "name" }, { text: "Staff Count" }, { text: "Type", key: "type" }]}
    tableWidths={["auto", 140, 140]}
    tableValues={[
      facility => facility.name,
      facility => facility.staff_count,
      facility => <span className='capitalize'>{facility.type}</span>
    ]}/>
)

export default Facilities
