import React from "react"
import { connect } from "react-redux"
import AccessDates from "../../Common/access_dates"
import SharedTable from "../../Common/table"
import humanFileSize from "../../../helpers/human_file_size"

const Requests = ({ organizationID }) => (
  <SharedTable
    collection={"cinerequests"}
    collectionOverride={"requests"}
    includeTitle='Requests'
    isCompact={true}
    queries={{
      requester_id: organizationID
    }}
    includeSearch={true}
    tableKeys={[{ text: "Film Name" }, { text: "Email" }, { text: "Submissions" }, { text: "Size" }, { text: "Created At" }]}
    tableWidths={["auto", 300, 180, 100, 200]}
    tableValues={[
      request => request.film_name,
      request => request.requestee_email,
      request => request.submissions_count,
      request => humanFileSize(request.size),
      request => <AccessDates model={request}/>
    ]}/>
)

const mapStateToProps = state => ({
  organizationID: state.organization.model._id
})

export default connect(mapStateToProps)(Requests)

