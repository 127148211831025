import React from "react"
import SharedTable from "../Common/table"

const Languages = () => (
  <SharedTable
    collection={"languages"}
    queries={{}}
    includeSearch={true}
    includeNewModelButton={true}
    includeTitle={"Languages"}
    isCompact={true}
    sortKey={"label"}
    sortDirection={"ASC"}
    tableKeys={[
      { text: "Label", key: "label" },
      { text: "Code", key: "code" },
      { text: "ISO 639-3 Code", key: "iso_639_3_code" },
      { text: "Captions Only?", key: "is_captions_only" }
    ]}
    tableWidths={["auto", 240, 240, 180]}
    tableValues={[
      language => language.label,
      language => language.code,
      language => language.iso_639_3_code,
      language => language.is_captions_only ? "Yes" : "No"
    ]}/>
)

export default Languages
