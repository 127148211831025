import React from "react"
import { bindActionCreators } from "redux"
import { push } from "react-router-redux"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import formatDate from "../../helpers/format_date"
import SharedTable from "../Common/table"
import humanFileSize from "../..//helpers/human_file_size"
import storageClasses from '../../helpers/storage_classes'

const Storage = ({ push }) => (
  <SharedTable
    collection={"storage-metadata"}
    queries={{}}
    includeTitle={"Storage Metadata"}
    sortKey={"files_total"}
    sortDirection={"DESC"}
    disableRowClick={true}
    isCompact={true}
    overrideOnClick={data => push(`${data.organization_id ? "organizations" : "users"}/${data._id}`)}
    tableKeys={[
      { text: "Customer" },
      { text: "Files", key: "files_total" },
      { text: "Festivals", key: "festivals_total" },
      { text: "On Demand", key: "ondemand_total" },
      { text: "Last Updated", key: "updated_at" }
    ]}
    tableWidths={["auto", 260, 260, 260, 160]}
    tableValues={[
      data => <span className='truncate'>{data.customer_name}</span>,
      data => <span>
        <b>Total: {humanFileSize(data.files_total)}</b>
        <span className='small block'>
          {storageClasses
            .filter(storageClass => !!data['files_' + storageClass['class']])
            .map(storageClass => `${storageClass['label']}: ${humanFileSize(data['files_' + storageClass['class']])}`)
            .join(' | ')}
        </span>
      </span>,
      data => <span>
        <b>Total: {humanFileSize(data.festivals_total)}</b>
        <span className='small block'>
          {storageClasses
            .filter(storageClass => !!data['festivals_' + storageClass['class']])
            .map(storageClass => `${storageClass['label']}: ${humanFileSize(data['festivals_' + storageClass['class']])}`)
            .join(' | ')}
        </span>
      </span>,
      data => <span>
        <b>Total: {humanFileSize(data.ondemand_total)}</b>
        <span className='small block'>
          {storageClasses
            .filter(storageClass => !!data['ondemand_' + storageClass['class']])
            .map(storageClass => `${storageClass['label']}: ${humanFileSize(data['ondemand_' + storageClass['class']])}`)
            .join(' | ')}
        </span>
      </span>,
      data => formatDate(data.updated_at)
    ]}/>
)

Storage.propTypes = {
  push: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch)
})

export default connect(null, mapDispatchToProps)(Storage)
