import React from "react"
import formatDate from '../../helpers/format_date'
import SharedTable from "../Common/table"

const GiftCards = () => (
  <SharedTable
    collection={"gift-cards"}
    queries={{}}
    includeSearch={true}
    includeNewModelButton={true}
    includeTitle={"Gift Cards"}
    isCompact={true}
    tableKeys={[{ text: "Code" }, { text: 'Pin'}, { text: "Owner" }, { text: "Amount" }, { text: 'Currency' }, { text: "Expiry" }]}
    tableWidths={[200, 100, "auto", 200, 160, 180]}
    tableValues={[
      data => data.code,
      data => data.pin,
      ({ owner_name }) => owner_name,
      data => `${data.current_amount.toFixed(2)} of ${data.original_amount.toFixed(2)}`,
      data => `${data.currency_code} (${data.currency_symbol})`,
      ({ expiry_date }) => formatDate(expiry_date)
    ]}/>
)

export default GiftCards
