import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { HeaderBar as Bar } from "@bitcine/cs-theme"
import { logOut } from "../../api/auth"
import Transfers from './transfers'

const HeaderBar = ({ apps, name, logOut }) =>
  <Bar
    productName='Ops'
    showUserDropdown={true}
    name={name}
    apps={apps}
    onLogOut={logOut}
    activeApp={'ops'}>
    <div className='flex justify-end'>
      <Transfers/>
    </div>
  </Bar>

HeaderBar.defaultProps = {
  apps: {},
  name: ""
}

HeaderBar.propTypes = {
  apps: PropTypes.object,
  name: PropTypes.string,
  logOut: PropTypes.func
}

const mapStateToProps = state => ({
  apps: state.auth.user.app_permissions,
  name: state.auth.user.full_name
})

const mapDispatchToProps = dispatch => ({
  logOut: bindActionCreators(logOut, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBar)
