import React from "react"
import PropTypes from "prop-types"
import formatDate from "../../helpers/format_date"
import SharedTable from "../Common/table"

const Payment = ({ queries, defaultCreditCardID }) =>
  <SharedTable
    collection={"credit-cards"}
    queries={queries}
    includeSearch={false}
    includeTitle={"Credit Cards"}
    isCompact={true}
    tableKeys={[
      { text: "Brand", key: "brand" },
      { text: "Name", key: "name" },
      { text: "Last 4", key: "last4" },
      { text: "Expiry" },
      { text: "Created At", key: "created_at" },
      { text: "Default" }
    ]}
    tableWidths={[160, "auto", 100, 140, 220, 65]}
    tableValues={[
      card => card.brand,
      card => card.name,
      card => card.last4,
      card => card.exp_month + " / " + card.exp_year,
      card => formatDate(card.created_at),
      card =>
        card._id === defaultCreditCardID
          ? <span className='material-icons green'>check</span>
          : null
    ]}/>

Payment.propTypes = {
  queries: PropTypes.object.isRequired,
  defaultCreditCardID: PropTypes.string.isRequired
}

export default Payment
