import React from "react"
import SharedTable from "../Common/table"

const Items = () => (
  <SharedTable
    collection={"items"}
    queries={{}}
    includeSearch={true}
    includeNewModelButton={true}
    includeTitle={"Items"}
    isCompact={true}
    tableKeys={[{ text: "Name" }, { text: 'Type'}]}
    tableWidths={["auto", 200]}
    tableValues={[
      data => data.name,
      data => <span className='capitalize'>{data.type}</span>
    ]}/>
)

export default Items
