import React from "react"
import { withRouter } from "react-router-dom"
import SharedModel from "../Common/model"

const SourceFile = ({ match }) =>
  <SharedModel
    modelID={match.params.sourceFileID}
    headerKey={"name"}
    collection={"source-files"}
    includeStorage={true}
    isEditable={true}
    isDeletable={true}/>

export default withRouter(SourceFile)
