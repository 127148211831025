const humanFileSize = bytes => {
  if (bytes === 0) return "0 B"
  // Return empty string if bytes is null
  if (!bytes) return "-"
  bytes = parseInt(bytes)
  // File size calulation method
  const thresh = 1024
  // Less than 1024 bytes should return bytes
  if (Math.abs(bytes) < thresh) return bytes.toFixed(0) + " B"
  // Available units
  const units = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  // Calc
  let u = -1
  do {
    bytes /= thresh
    ++u
  } while (Math.abs(bytes) >= thresh && u < units.length - 1)

  return `${bytes.toFixed(2)} ${units[u]}`
}

export default humanFileSize
