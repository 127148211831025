import {
  ADD_TRANSFER,
  ASPERA_PROGRESS
} from "../../redux/reducers/transfers/types"
import { removeTransfer, updateTransfer } from "../transfer"
import { addMessage } from "../messages"
import moment from "moment"
import { componentFetch } from '../../helpers/component_fetch'

const uuidv4 = require("uuid/v4")

export const initializeAsperaDownload = file => (dispatch, getState) => {
  const uuid = uuidv4()

  componentFetch(
    'POST',
    `downloads?fileID=${file._id}&fileType=${file.type}&method=Aspera`,
    {},
    ({ aspera_setup }) => {
      const sdkLocation = "//d3gcli72yxqn2z.cloudfront.net/connect/v4"

      const asperaWeb = new window.AW4.Connect({
        sdkLocation,
        minVersion: "3.6.0",
        id: uuid,
        dragDropEnabled: false
      })

      const asperaInstaller = new window.AW4.ConnectInstaller({ sdkLocation })

      asperaWeb.initSession(`nodeConnect-${uuid}`)

      asperaWeb.addEventListener(window.AW4.Connect.EVENT.STATUS, (eventType, status) =>
        dispatch(asperaWebEventListener(eventType, status, asperaInstaller))
      )

      const opts = {
        title: `Select Save Location`,
        suggestedName: `${file.name || file.file_name || `cinesend_download`}` // temp remove
      }
      const callBacks = {
        success: path => {
          if (!path.dataTransfer.files.length) asperaWeb.stop()
          else {
            const transferSpec = {
              ...aspera_setup.transfer_specs[0].transfer_spec,
              destination_root: path.dataTransfer.files[0].name,
              authentication: "token",
              create_dir: file.type === "folder" || file.type === "dcp"
            }

            const connectSettings = {
              allow_dialogs: "no",
              use_absolute_destination_path: true
            }

            asperaWeb.addEventListener("transfer", (e, transfers) =>
              dispatch(asperaTransferEvents(e, transfers, file, uuid))
            )

            asperaWeb.startTransfer(transferSpec, connectSettings)
          }
        },
        error: err => {
          dispatch(setError({ message: err.user_message, code: err.code }))
        }
      }

      asperaWeb.showSaveFileDialog(callBacks, opts)

      if (!window.asperaWeb) window.asperaWeb = asperaWeb
    }
  )
}

/**
 * Aspera Web Callback
 * - Handles events around the aspera web events
 *
 * @param eventType (string)
 * @param dataStatus (string)
 *
 * @returns dispatch
 */

export const asperaWebEventListener = (eventType, dataStatus, asperaInstaller) => (dispatch, getState) => {
  const status = window.AW4.Connect.STATUS
  if (eventType === window.AW4.Connect.EVENT.STATUS) {
    switch (dataStatus) {
      case status.INITIALIZING:
        asperaInstaller.showLaunching()
        break
      case status.FAILED:
        asperaInstaller.showDownload()
        break
      case status.OUTDATED:
        asperaInstaller.showUpdate()
        break
      case status.RUNNING:
        asperaInstaller.connected()
        break
      case status.RETRYING:
        break
      default:
        break
    }
  }
}

const asperaTransferEvents = (event, transfers, file, uuid) => (dispatch, getState) => {
  transfers.transfers.forEach(transfer => {
    if (transfer.aspera_connect_settings.app_id === `nodeConnect-${uuid}`) {
      if (!getState().transfers[transfer.uuid]) {
        dispatch(add(transfer, getState().project._id, file.name))
      } else {
        switch (transfer.status) {
          case "initiating":
            if (!getState().transfers[transfer.uuid]) {
              dispatch(add(transfer, getState().project._id, file.name))
            }
            break
          case "queued":
            dispatch(updateTransfer("status", "Queued", transfer.uuid))
            break
          case "running":
            let downloadedBytes = 0
            transfer.files.forEach(f => {
              downloadedBytes += f.bytes_written
            })
            const totalBytes = file.size
            const speed = Math.ceil(transfer.calculated_rate_kbps / 1024)
            const percentage = `${Math.round((downloadedBytes / totalBytes) * 100)}%`
            const timeRemaining = `${moment
              .duration(transfer.remaining_usec / 1000, "milliseconds")
              .humanize()} remaining`

            dispatch({
              type: ASPERA_PROGRESS,
              uuid: transfer.uuid,
              totalTransferredSize: downloadedBytes,
              timeRemaining,
              speed: `${speed.toFixed(0)} Mpbs`,
              percentage,
              totalSize: totalBytes,
              fileName: file.name
            })
            break
          case "completed":
            dispatch(updateTransfer("status", "Complete", transfer.uuid))
            dispatch(addMessage(`${file.name} download is complete`, "check_circle"))
            setTimeout(() => {
              dispatch(removeTransfer(transfer.uuid))
            }, 3000)
            break
          case "removed":
            dispatch(removeTransfer(transfer.uuid))
            break
          case "cancelled":
            dispatch(updateTransfer("status", "Paused", transfer.uuid))
            break
          case "failed":
            dispatch(updateTransfer("status", "Error", transfer.uuid))
            dispatch(updateTransfer("errorMsg", "Download failed", transfer.uuid))
            break
          default:
            break
        }
      }
    }
  })
}

const add = (transfer, projectID, fileName, size) => dispatch => {
  let size = 0
  dispatch({
    type: ADD_TRANSFER,
    uuid: transfer.uuid,
    transfer: {
      type: "download",
      method: "aspera",
      project_id: projectID,
      file_name: fileName,
      status: "Preparing",
      uuid: transfer.uuid,
      current_transferred_size: 0,
      total_size: transfer.files ? transfer.files.forEach(f => (size += f.bytes_expected)) : null
    }
  })
}

const setError = ({ message, code }) => dispatch => {}
