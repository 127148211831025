import React from "react"
import SharedTable from "../Common/table"
import formatDate from "../../helpers/format_date"
import humanFileSize from "../../helpers/human_file_size"

const Projects = () => (
  <SharedTable
    collection={"projects"}
    queries={{}}
    includeSearch={true}
    includeTitle={"Projects"}
    isCompact={true}
    includeFilter={true}
    filters={[
      {
        label: 'Created',
        key: 'created_at',
        type: 'daterange',
        options: {
          start_date: null,
          end_date: null
        }
      },
      {
        label: 'Owner Type',
        key: 'organization_id',
        options: [
          {
            label: 'Personal',
            null: true
          },
          {
            label: 'Organization',
            not_null: true
          }
        ]
      }
    ]}
    tableKeys={[{ text: "Name", key: "title" }, { text: "Created By" }, { text: "Owner" }, { text: "Total Storage", key: "size" }, { text: "Created", key: "created_at" }]}
    tableWidths={["auto", 180, 180, 180, 180]}
    tableValues={[
      project => project.title,
      project => <span className='truncate'>{project.created_by}</span>,
      project => <span className='truncate'>{project.owner_name}</span>,
      project => humanFileSize(project.size),
      project => formatDate(project.created_at)
    ]}/>
)

export default Projects
