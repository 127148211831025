import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Modal, Button, Table } from "@bitcine/cs-theme"
import { addNewManualOrder } from "../../../api/user"

class Cart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      new_order_title: "",
      new_line_item_name: "",
      new_line_item_quantity: 1,
      new_line_item_price: 0,
      new_line_items: []
    }
  }
  addNewLineItem() {
    const lineItems = this.state.new_line_items
    const newLineItem = {
      name: this.state.new_line_item_name,
      quantity: this.state.new_line_item_quantity,
      price: this.state.new_line_item_price
    }

    lineItems.push(newLineItem)

    this.setState({
      new_line_items: lineItems,
      new_line_item_name: "",
      new_line_item_price: 0,
      new_line_item_quantity: 1
    })
  }
  addNewOrder() {
    const orderTitle = this.state.new_order_title
    const lineItems = this.state.new_line_items

    this.props.addNewManualOrder(this.props.userID, lineItems, orderTitle)

    this.setState({
      open: false,
      new_line_items: [],
      new_order_title: ""
    })
  }
  render() {
    const { cart } = this.props
    return (
      <section>
        <div>
          <div className='flex justify-between'>
            <h4>Cart Items</h4>
            <Button small onClick={() => this.setState({ open: true })}>
              Add Manual Order
            </Button>
          </div>

          {cart.orders.map(order => (
            <div key={order._id} className='mt2 p2 border border-gray-5 rounded'>
              <h4>Order Title: {order.title}</h4>
              <Table
                className='my3'
                widths={["auto", 200, 200, 200]}
                header={{
                  columns: [{ text: "Name" }, { text: "Quantity" }, { text: "Price" }, { text: "Total "}]
                }}
                body={{
                  data: order.lineItems,
                  row: {
                    compact: true,
                    render: [
                      data => data.name,
                      data => data.quantity,
                      data => data.price,
                      data => `${cart.currency_symbol}${data.quantity * data.price} ${order.currency}`
                    ]
                  }
                }}/> 
            </div>
          ))}
        </div>
        <Modal
          open={this.state.open}
          width={3}
          onClose={() => this.setState({ open: false })}
          title={`Add Manual Order`}>
          <label>Order Title</label>
          <input
            placeholder='Order Title'
            className='cs-input'
            value={this.state.new_order_title}
            onChange={e => this.setState({ new_order_title: e.target.value })}/>
          <h4>New Line Item:</h4>
          <div className='flex'>
            <div className='col-3 pr1'>
              <label>Name</label>
              <input
                className='col-12 cs-input'
                placeholder='Name'
                value={this.state.new_line_item_name}
                onChange={e => this.setState({ new_line_item_name: e.target.value })}/>
            </div>
            <div className='col-3 pr1'>
              <label>Quantity</label>
              <input
                className='col-12 cs-input'
                value={this.state.new_line_item_quantity}
                placeholder='Quantity'
                onChange={e => this.setState({ new_line_item_quantity: e.target.value })}/>
            </div>
            <div className='col-3 pr1'>
              <label>Price</label>
              <input
                className='col-12 cs-input'
                value={this.state.new_line_item_price}
                placeholder='Price'
                onChange={e => this.setState({ new_line_item_price: e.target.value })}/>
            </div>
            <div className='col-3 pt1'>
              <Button
                disabled={
                  !this.state.new_line_item_name ||
                  !this.state.new_line_item_quantity ||
                  !this.state.new_line_item_price
                }
                onClick={() => this.addNewLineItem()}>
                Add Line Item
              </Button>
            </div>
          </div>
          <h5 className='mt1'>Line Items:</h5>
          {this.state.new_line_items.map((lineItem, i) => {
            return (
              <div key={i}>
                {lineItem.name} - {lineItem.quantity} x {lineItem.price}
              </div>
            )
          })}
          <div className='right-align mt3'>
            <Button disabled={!this.state.new_line_items.length} onClick={() => this.addNewOrder()}>
              Add
            </Button>
          </div>
        </Modal>
      </section>
    )
  }
}

Cart.propTypes = {
  cart: PropTypes.object.isRequired,
  userID: PropTypes.string.isRequired,
  addNewManualOrder: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  cart: state.user.model.cart,
  userID: state.user.model._id
})

const mapDispatchToProps = dispatch => ({
  addNewManualOrder: (userID, lineItems, title) => dispatch(addNewManualOrder(userID, lineItems, title))
})

export default connect(mapStateToProps, mapDispatchToProps)(Cart)
