import React from "react"
import { connect } from "react-redux"
import CustomerJobs from "../../Customer/jobs"

const Jobs = ({ organizationID }) =>
  <CustomerJobs
    queries={{ organization_id: organizationID, parent_id: "null" }}/>

const mapStateToProps = state => ({
  organizationID: state.organization.model._id
})

export default connect(mapStateToProps)(Jobs)

