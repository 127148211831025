import {
  GET_JOB,
  SET_JOB_TRANSFERRING,
  CANCEL_JOB_TRANSFERRING,
  TOGGLE_ADD_NOTE,
  UPDATE_NEW_NOTE,
  ADD_JOB_NOTE_PENDING,
  ADD_JOB_NOTE_FULFILLED,
  DELETE_NOTE_PENDING,
  DELETE_NOTE_FULFILLED,
  TOGGLE_VIEW_ALL_NOTES,
  UPDATE_JOB_FULFILLED,
  SET_JOB_STATUS_PENDING,
  SET_JOB_STATUS_FULFILLED,
  TOGGLE_QA_REPORT,
  UPDATE_QA_REPORT,
  TOGGLE_SHOW_ADD_ISSUE,
  UPDATE_ISSUE,
  SAVE_QA_ISSUE_FULFILLED,
  SAVE_QA_ISSUE_PENDING,
  SET_QA_ISSUE_RESOLVED,
  SAVE_QA_REPORT_PENDING,
  SAVE_QA_REPORT_FULFILLED,
  UPDATE_JOB_ACTIONS,
  UPDATE_ERROR,
  UPLOAD_BITRATE_ANALYSIS_FULFILLED,
  UPLOAD_BITRATE_ANALYSIS_FAILED,
  UPLOAD_BITRATE_ANALYSIS_PENDING,
  UPLOAD_CPL_FULFILLED,
  UPLOAD_CPL_FAILED,
  UPLOAD_CPL_PENDING,
  DELETE_JOB
} from "./types"

export const initialState = {
  details: {},
  children_jobs: [],
  history: [],
  status: "PENDING",
  notes: {
    newNote: {
      visible: false,
      status: "READY",
      value: ""
    },
    viewAll: false,
    list: []
  },
  newError: {
    visible: false,
    code: "",
    message: "",
    sendToFilmmaker: true,
    timecode: ""
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_JOB.PENDING:
    case DELETE_JOB.PENDING:
      return {
        ...state,
        status: "PENDING"
      }
    case GET_JOB.FAILED:
      return {
        ...state,
        status: "ERROR"
      }
    case GET_JOB.FULFILLED:
      return {
        ...action.payload,
        notes: {
          ...state.notes,
          list: action.payload.notes
        },
        newError: state.newError,
        status: "READY"
      }

    case SET_JOB_TRANSFERRING:
      return {
        ...state,
        transferUUID: action.transferUUID
      }

    case CANCEL_JOB_TRANSFERRING:
      return {
        ...state,
        transferUUID: null
      }

    case TOGGLE_ADD_NOTE:
      return {
        ...state,
        notes: {
          ...state.notes,
          newNote: {
            ...state.notes.newNote,
            status: "READY",
            visible: !state.notes.newNote.visible
          }
        }
      }

    case UPDATE_NEW_NOTE:
      return {
        ...state,
        notes: {
          ...state.notes,
          newNote: {
            ...state.notes.newNote,
            value: action.value
          }
        }
      }

    case ADD_JOB_NOTE_PENDING:
      return {
        ...state,
        notes: {
          ...state.notes,
          newNote: {
            ...state.notes.newNote,
            status: "PENDING"
          }
        }
      }

    case ADD_JOB_NOTE_FULFILLED:
      return {
        ...state,
        notes: {
          ...state.notes,
          list: action.payload.notes,
          newNote: {
            ...state.notes.newNote,
            status: "READY",
            value: "",
            visible: false
          }
        }
      }

    case DELETE_NOTE_PENDING:
      return {
        ...state,
        notes: {
          ...state.notes,
          list: state.notes.list.map(note => ({
            ...note,
            status: note._id === action.noteID ? "PENDING" : note.status
          }))
        }
      }

    case DELETE_NOTE_FULFILLED:
      return {
        ...state,
        notes: {
          ...state.notes,
          list: action.payload.notes
        }
      }

    case TOGGLE_VIEW_ALL_NOTES:
      return {
        ...state,
        notes: {
          ...state.notes,
          viewAll: !state.notes.viewAll
        }
      }

    case UPDATE_JOB_FULFILLED:
      return {
        ...action.payload,
        notes: {
          ...state.notes,
          list: action.payload.notes
        },
        status: "READY"
      }

    case SET_JOB_STATUS_PENDING:
      return {
        ...state,
        details: {
          ...state.details,
          status: "UPDATING"
        }
      }

    case SET_JOB_STATUS_FULFILLED:
      return {
        ...state,
        details: {
          ...state.details,
          status: action.payload.details.status
        },
        notes: {
          ...state.notes,
          list: action.payload.notes
        },
        history: action.payload.history,
        newError: initialState.newError
      }

    case TOGGLE_QA_REPORT:
      return {
        ...state,
        qaOpts: state.qaOpts ? { ...state.qaOpts, open: !state.qaOpts.open } : { open: true }
      }

    case UPDATE_QA_REPORT:
      return {
        ...state,
        actions: {
          ...state.actions,
          qa_report: {
            ...state.actions.qa_report,
            [action.field]: {
              ...state.actions.qa_report[action.field],
              [action.key]: action.value
            }
          }
        }
      }

    case UPLOAD_BITRATE_ANALYSIS_PENDING:
      return {
        ...state,
        actions: {
          ...state.actions,
          qa_report: {
            ...state.actions.qa_report,
            bitrate_analysis: {
              status: "PENDING"
            }
          }
        }
      }

    case UPLOAD_BITRATE_ANALYSIS_FULFILLED:
      return {
        ...state,
        actions: {
          ...state.actions,
          qa_report: {
            ...state.actions.qa_report,
            bitrate_analysis: {
              status: "READY",
              list: action.payload
            }
          }
        }
      }

    case UPLOAD_BITRATE_ANALYSIS_FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          qa_report: {
            ...state.actions.qa_report,
            bitrate_analysis: {
              status: "ERROR"
            }
          }
        }
      }

    case UPLOAD_CPL_PENDING:
      return {
        ...state,
        actions: {
          ...state.actions,
          qa_report: {
            ...state.actions.qa_report,
            cpl: {
              status: "PENDING"
            }
          }
        }
      }

    case UPLOAD_CPL_FULFILLED:
      return {
        ...state,
        actions: {
          ...state.actions,
          qa_report: {
            ...state.actions.qa_report,
            cpl: {
              status: "READY",
              list: action.payload
            }
          }
        }
      }

    case UPLOAD_CPL_FAILED:
      return {
        ...state,
        actions: {
          ...state.actions,
          qa_report: {
            ...state.actions.qa_report,
            cpl: {
              status: "ERROR"
            }
          }
        }
      }

    case TOGGLE_SHOW_ADD_ISSUE:
      return {
        ...state,
        newIssue: state.newIssue
          ? null
          : {
            timecode: "",
            type: "",
            severity: 1,
            description: "",
            status: "READY"
          },
        actions: {
          ...state.actions
        }
      }

    case UPDATE_ISSUE:
      return {
        ...state,
        newIssue: {
          ...state.newIssue,
          [action.key]: action.value
        }
      }

    case SAVE_QA_ISSUE_PENDING:
      return {
        ...state,
        newIssue: {
          ...state.newIssue,
          status: "PENDING"
        }
      }

    case SAVE_QA_ISSUE_FULFILLED:
      return {
        ...state,
        newIssue: null,
        actions: action.payload.actions
      }

    case SET_QA_ISSUE_RESOLVED:
      return {
        ...state,
        actions: {
          ...state.actions,
          qa_report: {
            ...state.actions.qa_report,
            issues: state.actions.qa_report.issues.map((issue, i) => ({
              ...issue,
              is_resolved: i === action.index ? true : issue.is_resolved,
              resolved_by: i === action.index ? action.user : issue.resolved_by
            }))
          }
        }
      }

    case SAVE_QA_REPORT_PENDING:
      return {
        ...state,
        actions: {
          ...state.actions,
          savingQAReport: true
        }
      }

    case SAVE_QA_REPORT_FULFILLED:
      return {
        ...state,
        actions: {
          ...state.actions,
          savingQAReport: false
        }
      }

    case UPDATE_JOB_ACTIONS:
      return {
        ...state,
        actions: {
          ...state.actions,
          [action.key]: action.value
        }
      }

    case UPDATE_ERROR:
      return {
        ...state,
        newError: {
          ...state.newError,
          [action.key]: action.value
        }
      }

    default:
      return state
  }
}
