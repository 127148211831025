import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import humanFileSize from "../../../helpers/human_file_size"
import AccessDates from "../../Common/access_dates"
import SharedTable from "../../Common/table"

const Uploads = ({ projectID }) =>
  <SharedTable
    collection={"uploads"}
    queries={{
      project_id: projectID
    }}
    disableRowClick={true}
    isCompact={true}
    tableKeys={[
      { text: "Method", key: "upload_method" },
      { text: "File", key: "file_name" },
      { text: "Status", key: "status" },
      { text: "Updated", key: "updated_at" }
    ]}
    tableWidths={[90, "auto", 220, 220]}
    tableValues={[
      upload => upload.upload_method,
      upload => upload.file_name,
      upload =>
        <div>
          {upload.status}
          <span className='block light small'>
            {humanFileSize(upload.current_size)} of {" "}
            {humanFileSize(upload.total_size)}
          </span>
        </div>,
      upload => <AccessDates model={upload}/>
    ]}/>

Uploads.propTypes = {
  projectID: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  projectID: state.project.model._id
})

export default connect(mapStateToProps)(Uploads)
