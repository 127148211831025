import React from "react"
import SharedTable from "../Common/table"
import formatDate from "../../helpers/format_date"
import humanFileSize from "../../helpers/human_file_size"

const Requests = () =>
  <SharedTable
    collection={"cinerequests"}
    collectionOverride={"requests"}
    queries={{}}
    includeSearch={true}
    includeTitle={"Requests"}
    isCompact={true}
    tableKeys={[
      { text: "Title", key: "film_name" },
      { text: "Owner", key: "created_by" },
      { text: "Last Submission", key: "last_submission_at" },
      { text: "Storage", key: "size" },
      { text: "Created", key: "created_at" }
    ]}
    tableWidths={["auto", 180, 180, 180, 180]}
    tableValues={[
      request => request.film_name,
      request => request.owner_name,
      request =>
        <div>
          {formatDate(request.last_submission_at)}
          <span className='block light small'>
            Submissions: {request.submissions_count || 0}
          </span>
        </div>,
      request => humanFileSize(request.size),
      request => formatDate(request.created_at)
    ]}/>

export default Requests
