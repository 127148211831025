import React from "react"
import { withRouter } from 'react-router-dom'
import SharedModel from "../Common/model"

const Playlist = ({ match }) =>
  <SharedModel
    modelID={match.params.playlistID}
    headerKey={"title"}
    subheaderKey={"_id"}
    collection={"playlists"}
    isEditable={false}
    isDeletable={false}/>

export default withRouter(Playlist)