import { Doughnut } from 'react-chartjs-2';
import getColors from './colors'

const buildData = data => ({
  labels: ['Organization Jobs', 'User Jobs'],
  datasets: [
    {
      data: [data.organization_jobs_count, data.personal_jobs_count],
      ...getColors(2)
    }
  ]
})

const JobsChart = ({ data }) =>
  <Doughnut data={buildData(data)} options={{ maintainAspectRatio: false, plugins: { title: { display: true, text: 'Total Jobs' } } }}/>

export default JobsChart
