import React from "react"
import { withRouter } from 'react-router-dom'
import SharedModel from "../Common/model"

const TaxRate = ({ match }) => (
  <SharedModel
    modelID={match.params.taxRateID}
    headerKey={"code"}
    subheaderKey={"rate"}
    collection={"tax-rates"}
    isEditable={true}
    isDeletable={true}/>
)

export default withRouter(TaxRate)
