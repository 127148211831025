import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"

const History = ({ history }) => {
  return (
    <div className='box-shadow rounded p2 mb2 bg-white'>
      <h4 className='mb2'>Job History</h4>
      {Object.keys(history).length === 0 ? (
        <div className='py4 center light'>
          <span className='material-icons block gray h1'>history</span>
          <strong>No History</strong>
        </div>
      ) : (
        Object.keys(history).map((group, i) => (
          <div key={i}>
            <strong className='bg-gray-6 pb1 pt2 px2 block'>{history[group].key}</strong>
            {history[group].list.map(item => (
              <div className='p2 border-top border-gray-6' key={item._id}>
                <div className='flex items-center'>
                  <div className='flex-auto'>{item.label}</div>
                  {moment
                    .utc(item.created_at)
                    .local()
                    .format("h:mm a")}
                </div>
                <div className='mt1 small'>
                  By: <strong>{item.user_name}</strong>
                </div>
              </div>
            ))}
          </div>
        ))
      )}
    </div>
  )
}

History.propTypes = {
  history: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  history: state.job.history.reduce((groups, item) => {
    const type = moment
      .utc(item.created_at)
      .local()
      .format("YYYY-MM-DD")
    groups[type] = groups[type] || { key: "", list: [] }
    groups[type].key = moment(type).format("dddd, MMMM Do YYYY")
    groups[type].list.push(item)
    return groups
  }, [])
})

export default connect(
  mapStateToProps,
  null
)(History)
