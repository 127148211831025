import React from "react"
import { connect } from "react-redux"
import { ButtonDropdown } from "@bitcine/cs-theme"
import AccessDates from "../../Common/access_dates"
import SharedTable from '../../Common/table'

const Emails = ({ userID }) =>
  <SharedTable
    collection={"emails"}
    queries={{ user_id: userID }}
    includeSearch={false}
    isCompact={true}
    includeTitle="Emails"
    disableRowClick={true}
    tableKeys={[{ text: "Subject" }, { text: "From" }, { text: "To" }, { text: "Dates "}, { text: "" }]}
    tableWidths={["auto", 220, 220, 180, 65]}
    tableValues={[
      email => <span className='truncate'>{email.subject}</span>,
      email => <span className='truncate'>{email.sender ? `${email.sender.name} (${email.sender.email})` : "N/A"}</span>,
      email => <span className='truncate'>{email.recipients ? <span>
        {email.recipients.map((r, i) =>
          <span key={i}>{`${r.name} (${r.email})`}</span>
        )}
      </span>
      : "N/A"}</span>,
      email => <AccessDates model={email}/>,
      email =>
        <ButtonDropdown
          button={{
            className: "link material-icons",
            text: "more_vert",
            style: { width: "48px" }
          }}
          dropdown={{
            clickCloses: true,
            content: [
              {
                text: "View Content",
                onClick: () => {
                  window.open(
                    "https://mandrillapp.com/activity/content?id=x_" +
                      email.response[0]._id,
                    "_blank"
                  )
                }
              }
            ]
          }}/>
      ]}
  />

const mapStateToProps = state => ({
  userID: state.user.model._id
})

export default connect(mapStateToProps)(Emails)
