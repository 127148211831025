import React from "react"
import { withRouter } from "react-router-dom"
import ReactTooltip from 'react-tooltip'
import humanFileSize from "../../helpers/human_file_size"
import formatDate from "../../helpers/format_date"
import SharedTable from "../Common/table"
import { GLOBALS } from '../../config'

const Users = ({ params, history }) => (
  <SharedTable
    collection={"users"}
    queries={{}}
    includeTitle={"Users"}
    includeSearch={true}
    //includeButton={true}
    //onButtonClick={() => history.push(`/user_exports/`)}
    //buttonText={"View User Exports"}
    includeShowDeletedCheckbox={true}
    includeFilter={true}
    filters={[
      {
        label: 'Last Login',
        key: 'last_login_at',
        type: 'daterange',
        options: {
          start_date: null,
          end_date: null
        }
      },
      {
        label: 'Subscription',
        key: 'subscription_name',
        options: ['Standard', 'Free', 'Enterprise'].map(opt => ({ label: opt, value: opt }))
      },
      {
        label: 'Account Type',
        key: 'organization_id',
        options: [
          {
            label: 'Personal',
            null: true
          },
          {
            label: 'Organization',
            not_null: true
          }
        ]
      }
    ]}
    isCompact={true}
    sortKey={"last_login_at"}
    sortDirection={"DESC"}
    tableKeys={[
      { text: "Name", key: "full_name" },
      { text: "Email", key: "email" },
      { text: "Subscription" },
      { text: "Created", key: "created_at" },
      { text: "Last Login", key: "last_login_at" },
      { text: "Files Storage", key: 'files_storage_used' },
      { text: "% Used", key: 'files_storage_percentage' },
      { text: "All Storage", key: 'storage_total' },
    ]}
    tableWidths={["auto", 160, 160, 160, 160, 160, 80, 100]}
    tableValues={[
      user => <div className='flex items-center bold truncate'>
        <ReactTooltip place='left' effect='solid' className='bold'/>
        {user.full_name}
        <span
          className='truncate pointer tooltip material-icons ml1'
          data-tip={`Ghost ${user.full_name}`}
          style={{ fontSize: '14px' }}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            window.open(GLOBALS.API_URL + "/admin/ghostUser?userID=" + user._id, "_self")
          }}>
          east
        </span>
      </div>,
      user => <div className='truncate'>{user.email}</div>,
      user => (
        <span className='truncate'>
          {user.subscription_name}
          {user.organization_name ? <small className='block light'>{user.organization_name}</small> : null}
        </span>
      ),
      user => formatDate(user.created_at),
      user => formatDate(user.last_login_at),
      user => humanFileSize(user.files_storage_used) + ' / ' + humanFileSize(user.files_storage_total),
      user => user.files_storage_percentage + '%',
      user => humanFileSize(user.storage_total),
    ]}/>
)

export default withRouter(Users)
