import React from "react"
import { withRouter } from 'react-router-dom'
import SharedModel from "../Common/model"

const Serial = ({ match }) =>
  <SharedModel
    modelID={match.params.serialID}
    headerKey={"title"}
    subheaderKey={"_id"}
    collection={"serials"}
    isEditable={false}
    isDeletable={false}/>

export default withRouter(Serial)