import React from "react"
import PropTypes from "prop-types"
import SharedTable from "../Common/table"
import formatDate from '../../helpers/format_date'
import humanFileSize from "../../helpers/human_file_size"

const Projects = ({ queries }) =>
  <SharedTable
    collection={"projects"}
    queries={queries}
    includeTitle={"Projects"}
    includeSearch={false}
    isCompact={true}
    sortKey={"created_at"}
    sortDirection={"DESC"}
    tableKeys={[
      { text: "Title", key: "title" },
      { text: "Created By", key: "created_by" },
      { text: "Created At", key: "created_at" },
      { text: "Size", key: "size" }
    ]}
    tableWidths={["auto", 200, 200, 200]}
    tableValues={[
      project => project.title,
      project => project.created_by,
      project => formatDate(project.created_at),
      project => humanFileSize(project.size)
    ]}/>

Projects.propTypes = {
  queries: PropTypes.object.isRequired
}

export default Projects
