import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import SharedTable from "../../Common/table"
import AccessDates from "../../Common/access_dates"

const SharedFiles = ({ projectID }) => (
  <SharedTable
    collection={"downloads"}
    queries={{ project_id: projectID }}
    includeSearch={false}
    isCompact={true}
    tableKeys={[
      { text: "Shared File" },
      { text: "Shared To", key: "shared_email" },
      { text: "Created", key: "created_at" }
    ]}
    tableWidths={["auto", 260, 220]}
    tableValues={[
      sharedFile => (
        <div className='truncate'>
          {sharedFile.description}
          <span className='block light small'>{sharedFile.project_title}</span>
        </div>
      ),
      sharedFile => (
        <div>
          {sharedFile.shared_email || "Shared by URL"}
          <span className='capitalize small block light'>{sharedFile.method}</span>
        </div>
      ),
      sharedFile => <AccessDates model={sharedFile}/>
    ]}/>
)

SharedFiles.propTypes = {
  projectID: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  projectID: state.project.model._id
})

export default connect(mapStateToProps)(SharedFiles)
