import { UPDATE_FILES_FILTER } from "./types"

import update from "immutability-helper"
import _ from "lodash"

const initialState = {
  search: "",
  sortKey: "",
  sortDirection: "",
  renameFileID: "",
  tags: [],
  types: [],
  uploadFileModal: {
    visible: false,
    type: null
  },
  status: "PENDING",
  activeHeaders: ["type", "status"]
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_FILES_FILTER:
      switch (action.key) {
        case "types":
          const typeIndex = _.findIndex(state.types, type => type.to === action.value.to)
          return update(state, {
            types: typeIndex !== -1 ? { $splice: [[typeIndex, 1]] } : { $push: [action.value] }
          })

        case "tags":
          const tagIndex = _.findIndex(state.tags, tag => tag._id === action.value._id)
          return update(state, {
            tags: tagIndex !== -1 ? { $splice: [[tagIndex, 1]] } : { $push: [action.value] }
          })

        default:
          return update(state, {
            [action.key]: { $set: action.value }
          })
      }

    default:
      return state
  }
}
