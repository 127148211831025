import React from 'react'
import {
  FaUsers,
  FaBuilding,
  FaFilm,
  FaCloud,
  FaCogs,
  FaMoneyCheckAlt,
  FaWrench,
  FaServer,
  // FaChartPie
} from "react-icons/fa"
import {
  MdOndemandVideo,
  MdCloudDone
} from 'react-icons/md'

export const LINKS = [
  // {
  //   text: "Dashboard",
  //   to: "/dashboard",
  //   icon: <FaChartPie size='1.6em'/>,
  //   show: true
  // },
  {
    text: "Users",
    to: "/users",
    icon: <FaUsers size='1.6em'/>,
    role: "customers.can_view_users"
  },
  {
    text: "Organizations",
    to: "/organizations",
    icon: <FaBuilding size='1.5em'/>,
    role: "customers.can_view_organizations"
  },
  {
    text: "Projects",
    to: "/projects",
    icon: <FaFilm size='1.6em'/>,
    role: "projects.can_view_projects"
  },
  {
    text: "Files",
    to: "/files",
    icon: <FaCloud size='1.5em'/>,
    role: "projects.can_view_files"
  },
  {
    text: "On Demand",
    to: "/ondemand",
    icon: <MdOndemandVideo size='1.6em'/>,
    role: "ondemand.can_view_ondemand"
  },
  {
    text: "Requests",
    to: "/requests",
    icon: <MdCloudDone size='1.6em'/>,
    role: "requests.can_view_requests"
  },
  {
    text: "Media Managers",
    to: "/devices",
    icon: <FaServer size='1.6em'/>,
    role: "mediamanagers.can_view_media_managers"
  },
  {
    text: "Production",
    to: "/production",
    icon: <FaWrench size='1.6em'/>,
    role: "production.can_view_production"
  },
  {
    text: "Transactions",
    to: "/transactions",
    icon: <FaMoneyCheckAlt size='1.6em'/>,
    role: "transactions.can_view_transactions"
  },
  {
    text: "Settings",
    to: "/settings",
    icon: <FaCogs size='1.6em'/>,
    role: "admin.can_view_admin_settings"
  }
]