import { S3_FINE_UPLOADER_DEFAULTS } from "../../constants"
import { TRANSFER_PROGRESS, UPDATE_CHILD_TRANSFER } from "../../redux/reducers/transfers/types"

import { createUpload, updateUpload, completeUpload } from "../upload"
import { addTransfer, updateTransfer } from "../transfer"
import { parseData, getFileData } from "./utils"
import { addMessage } from "../messages"

export const initializeBasicUploader = (selectedFiles, specifications = {}) => dispatch => {

  const { isDirectory, uuid, name, opts } = parseData(selectedFiles, specifications)

  dispatch(addMessage(`Preparing to upload ${name}...`, "info", "cloud_upload"))

  if (!window.fineUploader) dispatch(attachUploaderToWindow())

  dispatch(createUpload(opts, endpointDetails => {

    dispatch(addTransfer(uuid, endpointDetails.upload_details))
    const files = isDirectory ? selectedFiles : [selectedFiles]
    dispatch(startTransfer(files, uuid, isDirectory, specifications, endpointDetails))

  }))

}

const startTransfer = (files, uuid, isDirectory, specifications, endpointDetails) => (dispatch, getState) => {

  files.forEach(file => {

    const { parsedFile, endpoint, fileUUID } = getFileData(
      file,
      isDirectory,
      uuid,
      endpointDetails,
      specifications
    )

    /**
     * Add the child transfer to redux state
     */
    const transfer = getState().transfers[uuid]
    dispatch(
      updateTransfer(
        "childrenTransfers",
        {
          ...transfer.childrenTransfers,
          [fileUUID]: {
            current_size: 0,
            total_size: parsedFile.size,
            uuid: fileUUID,
            name: parsedFile.name
          }
        },
        uuid
      )
    )

    /**
     * Add the child transfer fineuploader
     * Note: This also starts the transfer
     */

    window.fineUploader.addFiles([parsedFile], null, endpoint)
  })

}

/**
 * Attach fine uploader to window for app-wide access
 * Note: This could be refactored to be tied into redux, but it seems to have performace issues
 */

const attachUploaderToWindow = () => (dispatch, getState) => {
  const qq = require("fine-uploader/lib/s3")

  window.fineUploader = new qq.s3.FineUploaderBasic({
    ...S3_FINE_UPLOADER_DEFAULTS,
    blobProperties: {
      name: fileID => {
        const file = window.fineUploader.getFile(fileID)
        return file.upload_details.file_name
      }
    },
    objectProperties: {
      key: fileId => {
        const file = window.fineUploader.getFile(fileId)
        return file.destination
      }
    },
    callbacks: {
      onSubmit: (...opts) => dispatch(onSubmit(...opts)),
      onProgress: (...opts) => dispatch(onProgress(...opts)),
      onUpload: (...opts) => dispatch(onUpload(...opts)),
      onComplete: (...opts) => dispatch(onComplete(...opts)),
      onAutoRetry: (...opts) => dispatch(onAutoRetry(...opts)),
      onError: (...opts) => dispatch(onError(...opts)),
      onCancel: (...opts) => dispatch(onCancel(...opts))
    }
  })
}

const onSubmit = (fileID, name) => dispatch => {
  window.fineUploader.setParams(
    {
      "Content-Disposition": 'attachment; filename="' + name + '"'
    },
    fileID
  )
}

const onProgress = (fileID, name, uploaded, total) => (dispatch, getState) => {

  const file = window.fineUploader.getFile(fileID)
  const parentTransfer = getState().transfers[file.parentUUID]
  //window.dispatchEvent(window.uploadingEvent)

  /**
   * When a child transfer is updated it should add to its parent total transfer progress
   * The parent transfer is the one shown in the UI, and the one updated on the server
   */

  let totalUploadedSize = 0
  Object.keys(parentTransfer.childrenTransfers).forEach(key => {
    if (parentTransfer.childrenTransfers[key].uuid === file.transferUUID) {
      totalUploadedSize += parseFloat(uploaded)
    }
    else {
      totalUploadedSize += parseFloat(parentTransfer.childrenTransfers[key].current_size)
    }
  })

  const progress = `${Math.floor(totalUploadedSize / parentTransfer.total_size * 100)}%`

  dispatch({
    type: TRANSFER_PROGRESS,
    transferUUID: file.transferUUID,
    total_size: parentTransfer.total_size,
    current_size: parseFloat(uploaded),
    parentUUID: file.parentUUID,
    parent_current_size: totalUploadedSize,
    progress
  })

  dispatch(updateUpload(parentTransfer))
}

const onUpload = fileID => dispatch => {
  const file = window.fineUploader.getFile(fileID)
  dispatch({
    type: UPDATE_CHILD_TRANSFER,
    parentUUID: file.parentUUID,
    transferUUID: file.transferUUID,
    key: "fineUploaderUUID",
    value: fileID
  })
  dispatch(updateTransfer("start_time", new Date().getTime(), file.parentUUID))
}

const onComplete = fileID => (dispatch, getState) => {
  const file = window.fineUploader.getFile(fileID)
  const transfer = getState().transfers[file.parentUUID]
  //window.dispatchEvent(window.uploadingEvent)
  /**
   * Ensure all of the child transfers are complete before marking the parent transfer complete
   * Some transfers may have many children, with some being < 10 bytes and some > 100 GB
   */

  let allTransfersComplete = true

  Object.keys(transfer.childrenTransfers).forEach(key => {
    if (
      transfer.childrenTransfers[key].current_size !==
      transfer.childrenTransfers[key].total_size
    ) {
      allTransfersComplete = false
      return
    }
  })

  /**
   * If all of the child transfers are complete, update state and post to server
   */

  if (allTransfersComplete && transfer.status !== "Complete") {
    dispatch(
      completeUpload({
        uuid: file.parentUUID,
        total_size: transfer.total_size,
        name: transfer.name,
        upload_id: transfer.upload_id
      })
    )
  }
}

const onAutoRetry = (fileID, name, attemptNumber) => dispatch => {
  const file = window.fineUploader.getFile(fileID)
  dispatch(updateTransfer("errorMsg", `Retrying transfer... attempt ${attemptNumber}`, file.parentUUID))
}

const onError = (fileID, fileName, error) => dispatch => {
  const file = window.fineUploader.getFile(fileID)
  dispatch(updateTransfer("status", `Error`, file.parentUUID))
  dispatch(updateTransfer("errorMsg", error, file.parentUUID))
}

const onCancel = fileID => dispatch => {
  const file = window.fineUploader.getFile(fileID)
  dispatch(updateTransfer("status", `Paused`, file.parentUUID))
}