import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import CommonDropdown from "../Common/dropdown"
import { Button } from "@bitcine/cs-theme"

class UpdateAsset extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      festival_name: props.model.festival_name,
      due_back_in: "",
      facility_id: props.model.facility_id
    }
  }
  checkOutAsset() {
    this.props.updateModel(
      this.props.model._id,
      {
        festival_name: this.state.festival_name,
        due_back_in: this.state.due_back_in,
        status: "Checked Out"
      },
      "Checked Out!"
    )
  }
  checkInAsset() {
    this.props.updateModel(
      this.props.model._id,
      {
        facility_id: this.state.facility_id,
        festival_name: null,
        dcp_id: null,
        due_date: null,
        status: "Available"
      },
      "Checked In!"
    )
  }
  render() {
    return (
      <div>
        {this.props.model.status === "Available" ? (
          <div>
            <div className='mb2'>
              <label className='capitalize'>Festival Name</label>
              <input
                value={this.state.festival_name || ""}
                className='cs-input col-12'
                onChange={({ target: { value } }) => this.setState({ festival_name: value })}/>
            </div>
            <div>
              <label>Due Back In</label>
              <input
                value={this.state.due_back_in}
                placeholder='Number of Days'
                className='cs-input col-12'
                onChange={({ target: { value } }) => this.setState({ due_back_in: value })}/>
            </div>
            <div className='right-align mt3'>
              <Button
                disabled={!this.state.festival_name || this.state.updatingModel}
                onClick={() => this.checkOutAsset()}>
                Checkout
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <CommonDropdown
              containerClassName='col-12'
              options='facilities'
              label='Facility'
              value={this.state.facility_id}
              onChange={({ value }) => this.setState({ facility_id: value })}
              clearable={false}/>
            <div className='right-align mt3'>
              <Button
                disabled={!this.state.facility_id || this.state.updatingModel}
                onClick={() => this.checkInAsset()}>
                Check In
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

UpdateAsset.propTypes = {
  updateModel: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, null)(UpdateAsset)
