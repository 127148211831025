import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { deleteNote } from "../../api/job"
import { Button } from "@bitcine/cs-theme"

const Note = ({ note, deleteNote, userID, hasBorder }) => {
  return (
    <div className={`py2 ${hasBorder ? "border-top border-gray-5" : ""}`}>
      <p>{note.label}</p>
      <div className='flex items-center'>
        <small className='flex-auto'>
          By: <strong>{note.user_name}</strong> on{" "}
          <i>
            {moment
              .utc(note.created_at)
              .local()
              .format("MMM Do, YYYY @ h:mm a")}
          </i>
        </small>
        {note.user_id === userID && (
          <Button onClick={() => deleteNote(note._id)} disabled={note.status === "PENDING"} className='link ml1'>
            <small>{note.status === "PENDING" ? "Deleting..." : "Delete"}</small>
          </Button>
        )}
      </div>
    </div>
  )
}

Note.propTypes = {
  note: PropTypes.object.isRequired
}

Note.propTypes = {
  note: PropTypes.object.isRequired,
  deleteNote: PropTypes.func.isRequired,
  userID: PropTypes.string.isRequired,
  hasBorder: PropTypes.bool
}

const mapStateToProps = state => ({
  userID: state.auth.user._id
})

const mapDispatchToProps = dispatch => ({
  deleteNote: bindActionCreators(deleteNote, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Note)
