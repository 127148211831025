import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import formatDate from "../../../helpers/format_date"
import SharedTable from "../../Common/table"

const FileRequests = ({ projectID }) =>
  <SharedTable
    collection={"file-requests"}
    queries={{ project_id: projectID }}
    includeSearch={false}
    disableRowClick={true}
    isCompact={true}
    tableKeys={[
      { text: "Project" },
      { text: "Request Name" },
      { text: "Requester" },
      { text: "Status" },
      { text: "Created", key: "created_at" }
    ]}
    tableWidths={["auto", 200, 200, 140, 140]}
    tableValues={[
      fileRequest => fileRequest.project_title,
      fileRequest => fileRequest.title,
      fileRequest => fileRequest.requestee_name,
      fileRequest => fileRequest.status,
      fileRequest => formatDate(fileRequest.created_at)
    ]}/>

FileRequests.propTypes = {
  projectID: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  projectID: state.project.model._id
})

export default connect(mapStateToProps)(FileRequests)
