import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { deleteOrganization } from "../../../api/organization"
import { recalculateStorage } from "../../../api/customer"
import humanFileSize from "../../../helpers/human_file_size"
import SharedModel from "../../Common/model"
import { GLOBALS } from '../../../config'

const General = ({
  organization,
  deleteOrganization,
  recalculateStorage
}) => (
  <section>
    <SharedModel
      headerKey='name'
      subheaderKey='owner_name'
      imageKey="logo_url"
      includeButton={true}
      buttonText={<span>👻<span className='ml1'>Ghost {organization.owner_name}</span></span>}
      onButtonClick={() => {
        window.open(GLOBALS.API_URL + "/admin/ghostUser?userID=" + organization.owner_id, "_self")
      }}
      modelID={organization._id}
      collection={"organizations"}
      deletionMessage="Deleting this organization deletes all related models."
      isEditable={true}
      isDeletable={true}/>
    <div className='mt2 border-top border-gray-5 pt2 flex'/>
    <div className='flex items-center col-3 border border-gray-5 px2 py1 small'>
      <div className='col-12'>
        <div className='flex items-center justify-between'>
          <span className='mr2'>Total Spent:</span>
          <strong>{`${organization.total_spent || "0"}`}</strong>
        </div>
        <div className='flex items-center justify-between mt1'>
          <span className='mr2'>Files:</span>
          <div className='flex items-center'>
            <strong className={`${organization.subscription.storage.used > organization.subscription.storage.total ? "red" : ""}`}>
              {humanFileSize(organization.subscription.storage.used)} used of {humanFileSize(organization.subscription.storage.total)}
            </strong>
            <span
              style={{ fontSize: "12px" }}
              className='material-icons pointer gray ml1'
              onClick={() => {
                if (window.confirm("Do you want to recalculate Files storage?")) {
                  recalculateStorage(organization._id)
                }
              }}>
              refresh
            </span>
          </div>
        </div>
        <div className='flex items-center justify-between mt1'>
          <span className='mr2'>Festivals:</span>
          <strong>{humanFileSize(organization.subscription.festivals_storage.used)} stored</strong>
        </div>
        <div className='flex items-center justify-between mt1'>
          <span className='mr2'>Entitlements:</span>
          <strong>{humanFileSize(organization.aspera_entitlement_bytes_remaining)} remaining</strong>
        </div>
      </div>
    </div>
  </section>
)

General.propTypes = {
  organization: PropTypes.object.isRequired,
  deleteOrganization: PropTypes.func.isRequired,
  recalculateStorage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  organization: state.organization.model
})

const mapDispatchToProps = dispatch => ({
  deleteOrganization: bindActionCreators(deleteOrganization, dispatch),
  recalculateStorage: bindActionCreators(recalculateStorage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(General)
