import {
  AUTH_CHECK
} from "./types"

const initialState = {
  status: "UNAUTHENTICATED",
  user: {}
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case AUTH_CHECK.PENDING:
      return {
        ...state,
        status: "UNAUTHENTICATED"
      }
    case AUTH_CHECK.FULFILLED:
      return {
        ...state,
        status: "AUTHENTICATED",
        user: action.payload
      }
    default:
      return state
  }
}
