import React from "react"
import SharedTable from "../Common/table"

const StaffRoles = () => (
  <SharedTable
    collection={"staff-roles"}
    queries={{}}
    includeTitle={"Staff Roles"}
    isCompact={true}
    tableKeys={[{ text: "Title" }]}
    tableWidths={["auto"]}
    tableValues={[
      data => data.title
    ]}/>
)

export default StaffRoles
