import React from "react"
import { Switch, Redirect, Route } from "react-router-dom"
import Details from './components/details'
import TimedTexts from './components/timed_texts'
import MediaConvertJobs from './components/media_convert_jobs'
import Sessions from './components/sessions'
import Reviewers from './components/reviewers'
import Storage from './components/storage'
import Uploads from './components/uploads'

const Routes = () => 
  <Switch>
    <Redirect exact path='/videos/:videoID' to='/videos/:videoID/details'/>
    <Route path='/videos/:videoID/details' component={Details}/>
    <Route path='/videos/:videoID/timed-texts' component={TimedTexts}/>
    <Route path='/videos/:videoID/media-convert-jobs' component={MediaConvertJobs}/>
    <Route path='/videos/:videoID/sessions' component={Sessions}/>
    <Route path='/videos/:videoID/reviewers' component={Reviewers}/>
    <Route path='/videos/:videoID/storage' component={Storage}/>
    <Route path='/videos/:videoID/uploads' component={Uploads}/>
  </Switch>

export default Routes