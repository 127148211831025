import {
  GET_FILE_STORAGE,
  REMOVE_FILE_STORAGE
} from "./types"

const initialState = {
  files: [],
  status: "PENDING"
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_FILE_STORAGE.PENDING:
    case REMOVE_FILE_STORAGE.PENDING:
      return {
        ...state,
        files: [],
        status: "PENDING"
      }
    case GET_FILE_STORAGE.FAILED:
    case REMOVE_FILE_STORAGE.FAILED:
      return {
        ...state,
        files: [],
        status: "FAILED"
      }
    case GET_FILE_STORAGE.FULFILLED:
    case REMOVE_FILE_STORAGE.FULFILLED:
      return {
        ...state,
        files: action.payload,
        status: "READY"
      }
    default:
      return state
  }
}
