const d3 = require("d3")

const frameIndexToTimeCode = (frameIndex, editRate) => {
  if (frameIndex < 0) return ""

  var seconds = Math.floor(frameIndex / editRate)
  var minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const frames = Math.floor(frameIndex - seconds * editRate)
  seconds = Math.floor(seconds % 60)
  minutes = Math.floor(minutes % 60)

  return pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2) + ":" + pad(frames, 2)
}
/// test
const pad = (num, size) => {
  const s = `000000000${num}`
  return s.substr(s.length - size)
}

export const buildChart = data => {
  const wrapWidth = document.getElementById("svg-wrap").offsetWidth
  const margin = { top: 0, right: 0, bottom: 30, left: 75 }
  const height = 300 - margin.top - margin.bottom
  const width = wrapWidth - margin.left - margin.right
  const svg = d3.select("#svg")
  const g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")")
  const x = d3.scaleLinear().range([0, width])
  const y = d3.scaleLinear().range([height, 50])
  const line = d3
    .line()
    .x((d, i) => x(i))
    .y(d => y(d))

  x.domain([0, data.length])
  y.domain([0, height])

  g.append("g")
    .attr("transform", "translate(0," + height + ")")
    .style("text-anchor", "center")
    .call(
      d3
        .axisBottom(x)
        .ticks(width < 400 ? 3 : 5)
        .tickPadding(10)
        .tickFormat((d, i) => frameIndexToTimeCode(d, 24))
    )

  g.append("g")
    .call(
      d3
        .axisLeft(y)
        .ticks(5)
        .tickPadding(10)
        .tickFormat((d, i) => `${d} Mbit/s`)
    )
    .attr("text-anchor", "end")

  g.append("path")
    .datum(data)
    .attr("fill", "none")
    .attr("stroke", "#FB0F3B")
    .attr("stroke-linejoin", "round")
    .attr("stroke-linecap", "round")
    .attr("stroke-width", 1.5)
    .attr("d", line)
}
