import React from "react"
import moment from "moment"
import SharedTable from "../Common/table"
import NewAsset from "./new_asset"
import UpdateAsset from "./update_asset"

const Assets = () =>
  <SharedTable
    collection={"assets"}
    queries={{}}
    dateFilters={[
      {
        label: "Overdue Drives",
        filter_value: "due_date",
        comparison: "<="
      }
    ]}
    includeSearch={true}
    includeTitle={"Assets"}
    newModelComponent={<NewAsset/>}
    updateModelComponent={<UpdateAsset/>}
    updateModelButtonText={asset =>
      asset.status === "Available" ? "Check Out" : "Check In"}
    updateModelColumnWidth={130}
    tableKeys={[
      { text: "Details", key: "serial_number" },
      { text: "Content" },
      { text: "Status", key: "status" },
      { text: "Due Date", key: "due_date" },
      { text: "Last Updated", key: "updated_at" }
    ]}
    tableWidths={[160, "auto", 135, 135, 135]}
    tableValues={[
      asset =>
        <div>
          <b>
            {asset.serial_number}
          </b>
          <div className='block small light'>
            {asset.size_in_gb} GB
          </div>
        </div>,
      asset => asset.content,
      asset =>
        <div>
          <b>
            {asset.status}
          </b>
          <div className='block small light'>
            {asset.facility_name}
          </div>
        </div>,
      asset =>
        <span
          style={{
            color: `${asset.due_date
              ? moment() > moment(asset.due_date) ? "red" : "black"
              : "black"}`
          }}>
          {asset.due_date ? moment.utc(asset.due_date).local().fromNow() : ""}
        </span>,
      asset => moment.utc(asset.updated_at).local().fromNow()
    ]}/>

export default Assets
