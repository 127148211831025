import React from "react"
import { Switch, Route } from "react-router-dom"
import Invoices from '../../components/TransactionTables/invoices'
import Invoice from '../../components/Transactions/invoice'
import GiftCards from '../../components/TransactionTables/gift_cards'
import GiftCard from '../../components/Transactions/gift_card'
import Promotions from '../../components/TransactionTables/promotions'
import Promotion from '../../components/Transactions/promotion'
import AsperaEntitlement from '../../components/Transactions/aspera_entitlement'

const Routes = () => 
  <Switch>
    <Route exact path='/transactions/invoices' component={Invoices}/>
    <Route path='/transactions/invoices/:invoiceID' component={Invoice}/>
    <Route exact path='/transactions/gift-cards' component={GiftCards}/>
    <Route path='/transactions/gift-cards/:giftCardID' component={GiftCard}/>
    <Route exact path='/transactions/promotions' component={Promotions}/>
    <Route path='/transactions/promotions/:promotionID' component={Promotion}/>
    <Route path='/transactions/aspera-entitlements/:asperaEntitlementID' component={AsperaEntitlement}/>
  </Switch>

export default Routes