const storageClasses = [
  {
    class: 'standard', 
    label: 'STA'
  },
  { 
    class: 'standard_ia',
    label: 'IA', 
  },
  {
    class: 'intelligent_tiering',
    label: 'ITL'
  },
  {
    class: 'glacier',
    label: 'GLC'
  }
]

export default storageClasses