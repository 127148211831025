import React, { useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from 'react-router-dom'
import { Status } from "@bitcine/cs-theme"
import SubNav from '../SubNav'
import Routes from './routes'
import { getVideo } from "../../api/video"

const Video = ({ match, getVideo, video, isFetched, history }) => {
  const videoID = match.params.videoID
  useEffect(() => {
    getVideo(videoID)
  }, [getVideo, videoID])
  const ownerLink = video.project_id ? `/projects/${video.project_id}`
    : video.organization_id ? `/organizations/${video.organization_id}`
    : `/users/${video.user_id}`
  return (
    <Status pending={!isFetched}>
      {isFetched &&
        <>
          <div style={{ margin: '-1em' }}>
            <SubNav 
              subtitle={video.owner_name}
              title={video.title || video.name}
              onBackButtonClick={() => history.push('/videos')}
              links={[
                { name: 'Details', to: "details", show: true },
                { name: 'Timed Text Tracks', to: "timed-texts", show: true },
                { name: 'Media Convert Jobs', to: "media-convert-jobs", show: true },
                { name: 'Sessions', to: "sessions", show: true },
                { name: 'Reviewers', to: "reviewers", show: true },
                { name: 'Storage', to: "storage", show: true },
                { name: 'Uploads', to: "uploads", show: true }
              ].filter(opt => opt.show).map(opt => ({
                text: opt.name,
                to: `/videos/${video._id}/${opt.to}`
              }))}
              includeButton={{
                to: ownerLink,
                label: video.owner_name
              }}/>
          </div>
          <div className='box-shadow border-radius bg-white p2 mt3'>
            <Routes/>
          </div>
        </>}
    </Status>
  )
}

const mapStateToProps = state => ({
  video: state.video.model,
  isFetched: state.video.status === 'READY'
})

const mapDispatchToProps = dispatch => ({
  getVideo: bindActionCreators(getVideo, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Video))
