import React from "react"
import { withRouter } from "react-router-dom"
import SubNav from '../../components/SubNav'
import Routes from './routes'

const TransactionTables = ({ history }) =>
  <>
    <div style={{ margin: '-1em' }}>
      <SubNav
        compact={false}
        links={[
          { name: 'Open Jobs', to: "open-jobs", show: true },
          { name: 'All Jobs', to: "jobs", show: true },
          { name: 'Bookings', to: "bookings", show: true },
          { name: 'E-Bookings', to: "electronic-bookings", show: true },
          { name: 'Shipments', to: "shipments", show: true },
          { name: 'Assets', to: "assets", show: true },
          { name: 'Storage', to: "storage", show: false }
        ].filter(opt => opt.show).map(opt => ({
          text: opt.name,
          to: `/production/${opt.to}`
        }))}/>
    </div>
    <div className='box-shadow border-radius bg-white p2 mt3'>
      <Routes/>
    </div>
  </>

export default withRouter(TransactionTables)
