import React, { useState } from "react"
import PropTypes from "prop-types"
import { componentFetch } from "../../helpers/component_fetch"
import useDidMount from "@rooks/use-did-mount"
import Select from "react-select"

const api_map = {
  countries: "admin/models/countries",
  timezones: "utilities/timezones",
  currencies: "admin/models/currencies",
  languages: "utilities/languages",
  staff: "admin/staff",
  media_managers: "admin/models/media-managers",
  organizations: 'admin/models/organizations/reduced?select=["_id","name"]',
  facilities: "admin/models/facilities",
  projects: "admin/models/projects",
  users: "admin/models/users",
  sites: "admin/models/sites"
}

const api_parse = {
  countries: countries =>
    countries.map(country => ({
      ...country,
      label: country.country,
      value: country.country_code
    })),
  timezones: timezones => timezones,
  currencies: currencies =>
    currencies.map(currency => ({
      ...currency,
      label: currency.currency_code,
      value: currency.currency_code
    })),
  languages: languages => languages,
  staff: staff =>
    staff.map(person => ({ label: person.full_name, value: person._id })),
  media_managers: managers =>
    managers.map(mm => ({
      ...mm,
      label: mm.name + " (" + mm.serial_number + ")",
      value: mm._id
    })),
  organizations: organizations =>
    organizations.map(org => ({ ...org, label: org.name, value: org._id })),
  facilities: facilities =>
    facilities.map(facility => ({
      ...facility,
      label: facility.name,
      value: facility._id
    })),
  projects: projects =>
    projects.map(project => ({
      ...project,
      label: project.title,
      value: project._id
    })),
  users: users =>
    users.map(user => ({
      ...user,
      label: user.email,
      value: user._id
    })),
  sites: sites =>
    sites.map(site => ({
      ...site,
      label: site.description,
      value: site._id
    }))
}

const buildCustomOptions = options => {
  if (options.length > 0) {
    if (typeof options[0] === "string") {
      return options.map(opt => ({ value: opt, label: opt }))
    }
  }
  return options
}

const CommonDropdown = ({ label, options, value, containerClassName, ...rest }) => {
  const id = label.toLowerCase()
  const custom_options = Array.isArray(options)
  const [opts, setOpts] = useState(
    custom_options ? buildCustomOptions(options) : null
  )
  const [error, setError] = useState(false)
  useDidMount(() => {
    if (!custom_options) {
      componentFetch('GET', api_map[options], {}, res => setOpts(api_parse[options](res)), () => setError(true))
    }
  })
  return (
    <div className={containerClassName}>
      {error
        ? "An error occurred."
        : <>
          <label htmlFor={id}>
            {label}
          </label>
          <Select
            id={id}
            isLoading={!opts}
            isClearable={true}
            value={opts ? opts.find(opt => opt.value === value) : null}
            options={opts || []}
            {...rest}/>
        </>}
    </div>
  )
}

CommonDropdown.defaultProps = {
  containerClassName: "",
  value: ""
}

CommonDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  containerClassName: PropTypes.string
}

export default CommonDropdown
