import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import JSONInput from 'react-json-editor-ajrm'
import locale    from 'react-json-editor-ajrm/locale/en'
import { Status, Table, Modal, Button } from "@bitcine/cs-theme"
import Aspera from "../Icons/aspera"
import humanFileSize from "../../helpers/human_file_size"
import { getFileStorage, getModelStorage, removeStorage } from "../../api/storage"
import { initializeAsperaDownload } from "../../api/download/aspera"

const StorageTable = ({ collection, modelID, file, getModelStorage, getFileStorage, status, files, removeStorage, initializeAsperaDownload }) => {
  const _id = file?._id
  const type = file?.type
  useEffect(() => {
    if (collection && modelID) {
      getModelStorage(collection, modelID)
    }
    else {
      getFileStorage({ _id, type })
    }
  }, [collection, modelID, _id, type, getModelStorage, getFileStorage])
  const [showMetadata, setShowMetadata] = useState(null)
  return (
    <Status className='small' pending={status === "PENDING"} error={status === "ERROR"}>
      {file && <span className='light small'>{file.key}</span>}
      <Table
        className='my3'
        widths={["auto", 120, 140, 120]}
        header={{
          columns: [{ text: "File Name" }, { text: "Size" }, { text: "Storage" }, { text: "" }]
        }}
        body={{
          data: files,
          row: {
            compact: true,
            render: [
              ({ name }) => <b>{name}</b>,
              ({ size }) => humanFileSize(size),
              data => <div className='truncate'>
                {data.storage_class}{data.archive_status && ` / ${data.archive_status}`}
              </div>,
              data => (
                file ? <div className='col-12 flex items-center justify-end muted'>
                  {data.storage_class !== "GLACIER" && data.archive_status !== "ARCHIVE_ACCESS" && (
                    <span
                      onClick={() => window.open(data.download_url, "_blank")}
                      className='material-icons link pointer mr1'>
                      cloud_download
                    </span>
                  )}
                  {data.metadata && <span
                    onClick={() => setShowMetadata(data.metadata)}
                    className='material-icons link pointer'>
                    list
                  </span>}
                  <span
                    onClick={() => {
                      if (window.confirm("Are you sure you want to remove this?")) {
                        removeStorage(file, data.path)
                      }
                    }}
                    className='material-icons link pointer mr1'>
                    delete
                  </span>
                </div> : null
              )
            ]
          }
        }}/>
      {file && <Button className='white' onClick={() => initializeAsperaDownload(file)}>
        <Aspera msg='Download with Aspera'/>
      </Button>}
      {!!showMetadata && typeof window !== "undefined" && (
        <Modal
          open={!!showMetadata}
          width={2}
          clickOutsideCloses 
          onClose={() => setShowMetadata(null)}
          title={"Storage Metadata"}>
          <JSONInput
            locale={locale}
            placeholder={showMetadata}
            confirmGood={false}
            viewOnly={true}
            height="100%"
            width="100%"/>
        </Modal>
      )}
    </Status>
  )
}

StorageTable.propTypes = {
  status: PropTypes.string.isRequired,
  file: PropTypes.object.isRequired,
  files: PropTypes.array.isRequired,
  getFileStorage: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  initializeAsperaDownload: PropTypes.func.isRequired,
  removeStorage: PropTypes.func.isRequired,
  getModelStorage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  status: state.storage.status,
  files: state.storage.files
})

const mapDispatchToProps = dispatch => ({
  getFileStorage: bindActionCreators(getFileStorage, dispatch),
  initializeAsperaDownload: bindActionCreators(initializeAsperaDownload, dispatch),
  removeStorage: bindActionCreators(removeStorage, dispatch),
  getModelStorage: bindActionCreators(getModelStorage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(StorageTable)
