import React from "react"
import SharedTable from "../Common/table"

const TaxRates = () => (
  <SharedTable
    collection={"tax-rates"}
    queries={{}}
    includeSearch={true}
    includeNewModelButton={true}
    isCompact={true}
    includeTitle={"Tax Rates"}
    tableKeys={[{ text: "Code" }, { text: "Rate" }]}
    tableWidths={["auto", 300]}
    tableValues={[({ code }) => code.toUpperCase(), ({ rate }) => rate * 100 + "%"]}/>
)

export default TaxRates
