import React from "react"
import { withRouter } from "react-router-dom"
import SubNav from '../../components/SubNav'
import Routes from './routes'

const OnDemand = ({ history }) =>
  <>
    <div style={{ margin: '-1em' }}>
      <SubNav
        compact={false}
        links={[
          { name: 'Videos', to: "videos", show: true },
          { name: 'Playlists', to: "playlists", show: true },
          { name: 'Events', to: "events", show: true },
          { name: 'Serials', to: "serials", show: true },
          { name: 'Subscribers', to: "subscribers", show: true },
          { name: 'Vouchers', to: "vouchers", show: true },
          { name: 'MediaLive Channels', to: "media-live-channels", show: true },
        ].filter(opt => opt.show).map(opt => ({
          text: opt.name,
          to: `/ondemand/${opt.to}`
        }))}/>
    </div>
    <div className='box-shadow border-radius bg-white p2 mt3'>
      <Routes/>
    </div>
  </>

export default withRouter(OnDemand)
