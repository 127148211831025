import {
  GET_OVERRIDE_ITEMS,
  ADD_OVERRIDE_ITEM,
  UPDATE_OVERRIDE_ITEM,
  DELETE_OVERRIDE_ITEM,
} from "./types"

export const initialState = {
  override_items: {
    status: "PENDING",
    list: []
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_OVERRIDE_ITEMS.FULFILLED:
    case ADD_OVERRIDE_ITEM.FULFILLED:
    case UPDATE_OVERRIDE_ITEM.FULFILLED:
    case DELETE_OVERRIDE_ITEM.FULFILLED:
      return {
        ...state,
        override_items: {
          status: "READY",
          list: action.payload
        }
      }

    default:
      return state
  }
}
