import React from "react"
import { withRouter } from 'react-router-dom'
import SharedModel from "../Common/model"

const Language = ({ match }) => (
  <SharedModel
    modelID={match.params.languageID}
    headerKey={"label"}
    collection={"languages"}
    isEditable={true}
    isDeletable={true}/>
)

export default withRouter(Language)
