import {
  GET_USER,
  ADD_MANUAL_ORDER_FULFILLED
} from "./types"

export const initialState = {
  model: {},
  status: 'PENDING'
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER.PENDING:
      return {
        ...state,
        status: "PENDING"
      }
    case GET_USER.FULFILLED:
    case ADD_MANUAL_ORDER_FULFILLED:
      return {
        ...state,
        status: "READY",
        model: action.payload
      }
    default:
      return state
  }
}
