import {
  GET_VIDEO
} from '../../redux/reducers/video/types'
import { get } from '../fetch'

export const getVideo = videoID => dispatch => {
  dispatch(
    get(
      GET_VIDEO.REQUEST,
      `admin/models/videos/${videoID}`
    )
  )
}