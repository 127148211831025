import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Select from "react-select"
import { Modal, Button } from "@bitcine/cs-theme"
import ReactTooltip from 'react-tooltip'
import SharedTable from "../Common/table"
import CommonDropdown from "../Common/dropdown"
import humanFileSize from "../../helpers/human_file_size"
import { componentFetch } from "../../helpers/component_fetch"
import formatDate from "../../helpers/format_date"
import { addMessage } from "../../api/messages"
import { GLOBALS } from '../../config'

const orgOptions = [
  { value: "Producer", label: "Producer" },
  { value: "Post-House", label: "Post-House" },
  { value: "Distributor", label: "Distributor" },
  { value: "Festival", label: "Festival" },
  { value: "Venue", label: "CineSend for Venues" },
  { value: "Other", label: "Other" }
]

class Organizations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      add_org_modal: false,
      queries: {}
    }
  }
  addOrganization() {
    componentFetch(
      "POST",
      "admin/organizations",
      {
        name: this.state.org_name,
        ownerEmail: this.state.owner_email,
        ownerName: this.state.owner_name,
        currencyCode: this.state.currency,
        countryCode: this.state.country,
        accountType: this.state.account_type
      },
      res => (res.message ? this.setState({ api_status: res.message }) : this.setState({ list: res.list })),
      this.props.addMessage("Organization Added")
    )
  }
  render() {
    return (
      <div>
        <SharedTable
          collection={"organizations"}
          queries={this.state.queries}
          includeSearch={true}
          includeFilter={true}
          filters={[
            {
              label: 'Subscription',
              key: 'subscription_name',
              options: ['Enterprise', 'Standard', 'Team', 'Free'].map(opt => ({ label: opt, value: opt }))
            },
            {
              label: 'Account Type',
              key: 'account_type',
              options: ['Producer', 'Festival', 'Cinema', 'Distributor'].map(opt => ({ label: opt, value: opt }))
            }
          ]}
          includeTitle={"Organizations"}
          includeButton={true}
          buttonText={"Add Organization"}
          isCompact={true}
          onButtonClick={() => this.setState({ add_org_modal: true })}
          tableKeys={[
            { text: "Name", key: "name" },
            { text: "Owner", key: "owner" },
            { text: "Subscription" },
            { text: "Users" },
            { text: "Created", key: "created_at" },
            { text: "Files Storage", key: 'files_storage_used' },
            { text: "% Used", key: 'files_storage_percentage' },
            { text: "All Storage", key: 'storage_total' },
          ]}
          tableWidths={["auto", 200, 100, 80, 160, 160, 80, 100]}
          tableValues={[
            organization => <b>{organization.name}</b>,
            organization => <div className='flex items-center truncate'>
              <ReactTooltip place='left' effect='solid' className='bold'/>
              <span className='truncate'>{organization.owner_name}</span>
              <span
                className='pointer tooltip material-icons ml1'
                data-tip={`Ghost ${organization.owner_name}`}
                style={{ fontSize: '14px' }}
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  window.open(GLOBALS.API_URL + "/admin/ghostUser?userID=" + organization.owner_id, "_self")
                }}>
                east
              </span>
            </div>,
            organization => organization.subscription_name,
            organization => organization.user_count,
            organization => formatDate(organization.created_at),
            organization => humanFileSize(organization.files_storage_used) + ' / ' + humanFileSize(organization.files_storage_total),
            organization => organization.files_storage_percentage + '%',
            organization => humanFileSize(organization.storage_total),
          ]}/>
        <Modal
          title='Add New Organization'
          open={this.state.add_org_modal}
          onClose={() => this.setState({ add_org_modal: false })}>
          <React.Fragment>
            <label>Organization Name</label>
            <input
              value={this.state.org_name}
              className='col-12 mb1 cs-input'
              onChange={({ target: { value } }) => this.setState({ org_name: value })}/>
            <label>Owner Name</label>
            <input
              value={this.state.owner_name}
              className='col-12 mb1 cs-input'
              onChange={({ target: { value } }) => this.setState({ owner_name: value })}/>
            <label>Owner Email</label>
            <input
              value={this.state.owner_email}
              className='col-12 mb1 cs-input'
              onChange={({ target: { value } }) => this.setState({ owner_email: value })}/>
            <label>Account Type</label>
            <Select
              options={orgOptions}
              onChange={({ value }) => this.setState({ account_type: value })}
              value={orgOptions.find(opt => opt === this.state.account_type)}
              className='mb1'/>
            <CommonDropdown
              containerClassName='col-12 mb1'
              options='currencies'
              label='Currency'
              value={this.state.currency}
              onChange={({ value }) => {
                this.setState({ currency: value })
              }}
              clearable={false}/>
            <CommonDropdown
              containerClassName='col-12 mb1'
              options='countries'
              label='Country'
              value={this.state.country}
              onChange={({ value }) => {
                this.setState({ country: value })
              }}
              clearable={false}/>
            <div className='mt3 flex justify-end'>
              <Button
                disabled={
                  !this.state.currency ||
                  !this.state.country ||
                  !this.state.owner_email ||
                  !this.state.owner_name ||
                  !this.state.org_name
                }
                onClick={() => {
                  this.addOrganization()
                  this.setState({ add_org_modal: false })
                }}>
                Add Organization
              </Button>
            </div>
          </React.Fragment>
        </Modal>
      </div>
    )
  }
}

Organizations.propTypes = {
  addMessage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  addMessage: bindActionCreators(addMessage, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Organizations)
