import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Button } from "@bitcine/cs-theme"

import {
  toggleQAReport,
  updateQAReport,
  toggleShowAddIssue,
  saveNewIssue,
  updateNewIssue,
  setQAIssueResolved
} from "../../../api/job"
import { QA_DEFAULTS } from "../../../constants"
import Select from "react-select"
import DatePicker from "react-datepicker"
import CPL from "../QA/cpl"
import BitRate from "../QA/bitrate"

function QA({
  project,
  isComplete,
  toggleQAReport,
  qaOpts,
  updateQAReport,
  qaReport,
  toggleShowAddIssue,
  newIssue,
  updateNewIssue,
  saveNewIssue,
  setQAIssueResolved,
  savingQAReport
}) {
  return (
    <div>
      <div className='my4'>
        <strong>QA DCP</strong>
        <p>This job requires completing a QA report for {project} DCP.</p>
      </div>
      <div className='right-align mt3'>
        <Button className='white' onClick={toggleQAReport}>
          Open QA Report
        </Button>
        {!isComplete && (
          <small className='mt2 light block'>Ensure the QA report is correct before completing this job.</small>
        )}
      </div>
      {qaOpts && qaOpts.open && (
        <div className={`fixed bg-gray-6 top-0 left-0 right-0 bottom-0 overflow-scroll pb3`}>
          <div className='bg-white border-bottom border-gray-5 py2 fixed top-0 left-0 right-0 z4'>
            <div className='max-width-4 mx-auto flex items-center '>
              <h3 className='flex-auto'>QA Report for {project}</h3>
              <div className='relative mr3'>
                {savingQAReport && <span className='light mr3'>Saving QA Report...</span>}
                <Button className='small' onClick={toggleShowAddIssue}>
                  Add Issue
                </Button>
                {newIssue && (
                  <div className={`absolute right-0 top-100 mt1 bg-white rounded box-shadow p2 z4`}>
                    <h4>New Issue</h4>
                    <label>Timecode</label>
                    <input
                      value={newIssue.timecode}
                      className='cs-input col-12'
                      placeholder='HH:MM:SS:FF'
                      onChange={({ target }) => updateNewIssue("timecode", target.value, false)}/>
                    <label className='mt1'>Type</label>
                    <Select
                      options={["Video", "Audio", "Timed Text"].map(option => ({ value: option, label: option }))}
                      onChange={({ value }) => updateNewIssue("type", value, false)}
                      value={newIssue.type}
                      clearable={false}
                      searchable={false}/>
                    <label className='mt1'>Severity</label>
                    <div className='flex items-center'>
                      {["1", "2", "3", "4", "5"].map((sev, i) => (
                        <div key={i} className='mr1 flex items-center'>
                          <input
                            type='radio'
                            id={`__severity-${i}`}
                            checked={newIssue.severity === parseFloat(sev)}
                            onChange={() => updateNewIssue("severity", parseFloat(sev), false)}/>
                          <label htmlFor={`__severity-${i}`}>{sev}</label>
                        </div>
                      ))}
                    </div>
                    <label className='mt1'>Description</label>
                    <textarea
                      value={newIssue.description}
                      className='cs-input col-12'
                      placeholder='Provide an short description of the issue...'
                      onChange={({ target }) => updateNewIssue("description", target.value, false)}/>
                    <div className='right-align mt3'>
                      <Button onClick={toggleShowAddIssue} className='white mr2 small'>
                        Cancel
                      </Button>
                      <Button
                        disabled={
                          !newIssue.description || !newIssue.timecode || !newIssue.type || newIssue.status === "PENDING"
                        }
                        onClick={saveNewIssue}
                        className={`small ${newIssue.status === "PENDING" ? "pending" : ""}`}>
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <Button className='link' onClick={toggleQAReport}>
                <h1 className='material-icons mb0'>close</h1>
              </Button>
            </div>
          </div>
          <div className='flex items-stretch max-width-4 mx-auto mt4 py3'>
            <div className='col-7 pr3'>
              <BitRate/>
              <CPL/>
              {QA_DEFAULTS.items.map((opt, i) => (
                <div key={i} className='p3 rounded bg-white border-gray-5 border mb3'>
                  <h4>{opt.title}</h4>
                  <p>{opt.description}</p>
                  {opt.items.map((item, k) => (
                    <div key={k} className='py1'>
                      {item.type === "input" ? (
                        <div>
                          <label>{item.label}</label>
                          <input
                            value={qaReport[opt.key][item.key] || ""}
                            className='cs-input col-12'
                            onBlur={({ target }) => updateQAReport(opt.key, item.key, target.value, true)}
                            onChange={({ target }) => updateQAReport(opt.key, item.key, target.value, false)}/>
                        </div>
                      ) : item.type === "checkbox" ? (
                        <div className='flex items-center'>
                          <input
                            type='checkbox'
                            id={`${opt.key}-${item.key}-${k}`}
                            checked={qaReport[opt.key][item.key] || false}
                            onChange={() => updateQAReport(opt.key, item.key, !qaReport[opt.key][item.key], true)}/>
                          <label className='ml2' htmlFor={`${opt.key}-${item.key}-${k}`}>
                            {item.label}
                          </label>
                        </div>
                      ) : item.type === "select" ? (
                        <div>
                          <label>{item.label}</label>
                          <Select
                            options={item.options.map(option => ({ value: option, label: option }))}
                            onChange={value => updateQAReport(opt.key, item.key, value, true)}
                            value={qaReport[opt.key][item.key] || ""}
                            clearable={false}
                            searchable={false}/>
                        </div>
                      ) : item.type === "date" ? (
                        <div>
                          <label>{item.label}</label>
                          <DatePicker
                            className='col-12 block'
                            selected={qaReport[opt.key][item.key] ? new Date(qaReport[opt.key][item.key]) : null}
                            onChange={value => updateQAReport(opt.key, item.key, value.toIsoString(), true)}/>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div className='col-5 pl3 border-left border-gray-5'>
              <div className='p3 rounded bg-white border-gray-5 border mb3'>
                <h4>Issues</h4>
                {qaReport.issues.length === 0 ? (
                  <div className='py4 mt2 center'>
                    <span className='material-icons light block'>check_circle</span>
                    <strong className='block light'>No Issues!</strong>
                  </div>
                ) : (
                  qaReport.issues.map((issue, i) => (
                    <div className={`${i > 0 ? "border-top border-gray-5" : ""} py2`} key={i}>
                      <div className='flex items-center'>
                        <strong className='flex-auto'>{issue.type}</strong>
                        {issue.is_resolved ? (
                          <span className='material-icons green'>
                            <small>check_circle</small>
                          </span>
                        ) : (
                          <Button onClick={() => setQAIssueResolved(i)} className='link auto-height underline'>
                            <small>Set as resolved</small>
                          </Button>
                        )}
                      </div>
                      <small className='light block'>
                        Timecode: <strong>{issue.timecode}</strong>
                      </small>
                      <small className='light block'>
                        Added By: <strong>{issue.added_by}</strong>
                      </small>
                      <small className='light block'>
                        Severity: <strong>{issue.severity}</strong>
                      </small>
                      {issue.is_resolved && (
                        <small className='light block'>
                          Resolved By: <strong>{issue.resolved_by}</strong>
                        </small>
                      )}
                      <p>{issue.description}</p>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

QA.propTypes = {
  project: PropTypes.string.isRequired,
  isComplete: PropTypes.bool.isRequired,
  toggleQAReport: PropTypes.func.isRequired,
  qaOpts: PropTypes.object,
  updateQAReport: PropTypes.func.isRequired,
  qaReport: PropTypes.object.isRequired,
  toggleShowAddIssue: PropTypes.func.isRequired,
  newIssue: PropTypes.object,
  updateNewIssue: PropTypes.func.isRequired,
  saveNewIssue: PropTypes.func.isRequired,
  setQAIssueResolved: PropTypes.func.isRequired,
  savingQAReport: PropTypes.bool
}

function mapStateToProps(state) {
  return {
    project: state.job.details.project_title,
    isComplete: state.job.details.status === "Complete",
    qaOpts: state.job.qaOpts,
    qaReport: state.job.actions.qa_report,
    newIssue: state.job.newIssue,
    savingQAReport: state.job.actions.savingQAReport
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toggleQAReport: bindActionCreators(toggleQAReport, dispatch),
    updateQAReport: bindActionCreators(updateQAReport, dispatch),
    toggleShowAddIssue: bindActionCreators(toggleShowAddIssue, dispatch),
    updateNewIssue: bindActionCreators(updateNewIssue, dispatch),
    saveNewIssue: bindActionCreators(saveNewIssue, dispatch),
    setQAIssueResolved: bindActionCreators(setQAIssueResolved, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(QA)
