import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import dayjsPluginUTC from "dayjs-plugin-utc"
import relativeTime from "dayjs/plugin/relativeTime"
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore, { history } from './redux/configureStore'
import '@bitcine/cs-theme/dist/index.scss'
import './index.css';
import '../node_modules/react-grid-layout/css/styles.css'
import '../node_modules/react-resizable/css/styles.css'

const store = configureStore()

dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)
dayjs.extend(dayjsPluginUTC)

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
