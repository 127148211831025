import React from "react"
import { Switch, Route } from "react-router-dom"
import OpenJobs from '../../components/ProductionTables/open_jobs'
import Job from '../../components/Job'
import AllJobs from '../../components/ProductionTables/all_jobs'
import Bookings from '../../components/ProductionTables/bookings'
import Booking from '../../components/Production/booking'
import ElectronicBookings from '../../components/ProductionTables/electronic_bookings'
import ElectronicBooking from '../../components/Production/electronic_booking'
import Shipments from '../../components/ProductionTables/shipments'
import Shipment from '../../components/Production/shipment'
import Assets from '../../components/ProductionTables/assets'
import Asset from '../../components/Production/asset'
import Storage from '../../components/ProductionTables/storage'

const Routes = () => 
  <Switch>
    <Route path='/production/open-jobs' component={OpenJobs}/>
    <Route exact path='/production/jobs' component={AllJobs}/>
    <Route path='/production/jobs/:jobID' component={Job}/>
    <Route exact path='/production/bookings' component={Bookings}/>
    <Route path='/production/bookings/:bookingID' component={Booking}/>
    <Route exact path='/production/electronic-bookings' component={ElectronicBookings}/>
    <Route path='/production/electronic-bookings/:contentOrderID' component={ElectronicBooking}/>
    <Route exact path='/production/shipments' component={Shipments}/>
    <Route path='/production/shipments/:shipmentID' component={Shipment}/>
    <Route exact path='/production/assets' component={Assets}/>
    <Route path='/production/assets/:assetID' component={Asset}/>
    <Route path='/production/storage' component={Storage}/>
  </Switch>

export default Routes