import React from "react"
import PropTypes from "prop-types"
import SharedTable from "../Common/table"
import AccessDates from "../Common/access_dates"
import humanFileSize from "../../helpers/human_file_size"

const AsperaDownloads = ({ queries, includeSearch, includeTitle }) => (
  <SharedTable
    collection={"aspera-downloads"}
    queries={queries}
    includeSearch={includeSearch}
    includeTitle={includeTitle ? "Aspera Downloads" : null}
    disableRowClick={true}
    isCompact={true}
    sortKey={"updated_at"}
    tableKeys={[
      { text: "File Name", key: "file_name" },
      { text: "Downloader" },
      { text: "Updated", key: "updated_at" }
    ]}
    tableWidths={["auto", 360, 220]}
    tableValues={[
      download => (
        <div>
          {download.file_name}
          <span className='block light small'>
            {download.status} - {humanFileSize(download.current_size)} of {humanFileSize(download.total_size)}
          </span>
        </div>
      ),
      download => (
        <div>
          {download.downloader}
          <span className='small block light'>{download.location || download.device}</span>
        </div>
      ),
      download => <AccessDates model={download}/>
    ]}/>
)

AsperaDownloads.propTypes = {
  queries: PropTypes.object.isRequired,
  includeSearch: PropTypes.bool.isRequired,
  includeTitle: PropTypes.bool.isRequired
}

AsperaDownloads.defaultProps = {
  includeSearch: false,
  includeTitle: false
}

export default AsperaDownloads
