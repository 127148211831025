export const GET_OVERRIDE_ITEMS = {
  REQUEST: "GET_OVERRIDE_ITEMS",
  PENDING: "GET_OVERRIDE_ITEMS_PENDING",
  FAILED: "GET_OVERRIDE_ITEMS_FAILED",
  FULFILLED: "GET_OVERRIDE_ITEMS_FULFILLED"
}

export const ADD_OVERRIDE_ITEM = {
  REQUEST: "ADD_OVERRIDE_ITEM",
  PENDING: "ADD_OVERRIDE_ITEM_PENDING",
  FAILED: "ADD_OVERRIDE_ITEM_FAILED",
  FULFILLED: "ADD_OVERRIDE_ITEM_FULFILLED"
}

export const UPDATE_OVERRIDE_ITEM = {
  REQUEST: "UPDATE_OVERRIDE_ITEM",
  PENDING: "UPDATE_OVERRIDE_ITEM_PENDING",
  FAILED: "UPDATE_OVERRIDE_ITEM_FAILED",
  FULFILLED: "UPDATE_OVERRIDE_ITEM_FULFILLED"
}

export const DELETE_OVERRIDE_ITEM = {
  REQUEST: "DELETE_OVERRIDE_ITEM",
  PENDING: "DELETE_OVERRIDE_ITEM_PENDING",
  FAILED: "DELETE_OVERRIDE_ITEM_FAILED",
  FULFILLED: "DELETE_OVERRIDE_ITEM_FULFILLED"
}

export const RECALCULATE_STORAGE = {
  REQUEST: "RECALCULATE_STORAGE",
  PENDING: "RECALCULATE_STORAGE_PENDING",
  FAILED: "RECALCULATE_STORAGE_FAILED",
  FULFILLED: "RECALCULATE_STORAGE_FULFILLED"
}