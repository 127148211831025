import React from "react"
import { withRouter } from "react-router-dom"
import SharedModel from "../Common/model"

const Asset = ({ match }) =>
  <SharedModel
    modelID={match.params.assetID}
    headerKey={"serial_number"}
    subheaderKey={"content"}
    collection={"assets"}
    isEditable={true}
    isDeletable={true}/>

export default withRouter(Asset)
