import React from "react"
import SharedTable from "../Common/table"
import Address from "../Common/address"
import AccessDates from '../Common/access_dates'

const Shipments = () => (
  <SharedTable
    collection={"shipments"}
    queries={{}}
    includeSearch={true}
    includeTitle={"Shipments"}
    isCompact={true}
    tableKeys={[
      { text: "Title", key: "title" },
      { text: "Tracking Number", key: "tracking_number" },
      { text: "Destination", key: "destination" },
      { text: "Updated", key: "updated_at" }
    ]}
    tableWidths={["auto", 200, 250, 180]}
    tableValues={[
      data => <span className='truncate'>{data.title}<span className='small light block'>{data.status}</span></span>,
      data => <span>{data.courier ? data.courier.toUpperCase() + ' / ' : null}{data.tracking_number}</span>,
      data => (data.destination_address ? <Address address={data.destination_address}/> : ""),
      data => <AccessDates model={data}/>
    ]}/>
)

export default Shipments
