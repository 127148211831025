import React from "react"
import SharedTable from "../Common/table"

const Countries = () => (
  <SharedTable
    collection={"countries"}
    queries={{}}
    includeSearch={true}
    includeNewModelButton={true}
    includeTitle={"Countries"}
    isCompact={true}
    sortKey={"country"}
    sortDirection={"ASC"}
    tableKeys={[{ text: "Name", key: "country" }, { text: "Code", key: "country_code" }]}
    tableWidths={["auto", 140]}
    tableValues={[
      country => country.country,
      country => country.country_code
    ]}/>
)

export default Countries
