import React from "react"
import { withRouter } from "react-router-dom"
import SubNav from '../../components/SubNav'
import Routes from './routes'

const Files = ({ history }) =>
  <>
    <div style={{ margin: '-1em' }}>
      <SubNav
        compact={false}
        links={[
          { name: 'DCPs', to: "digital-cinema-packages", show: true },
          { name: 'Source Files', to: "source-files", show: true },
          { name: 'Screeners', to: "screeners", show: true },
          { name: 'Reviewers', to: "shared-screeners", show: true },
          { name: 'Uploads', to: "uploads", show: true },
          { name: 'Aspera Downloads', to: "aspera-downloads", show: true },
          { name: 'File Requests', to: "file-requests", show: true },
          { name: 'Shared Files', to: "shared-files", show: true }
        ].filter(opt => opt.show).map(opt => ({
          text: opt.name,
          to: `/files/${opt.to}`
        }))}/>
    </div>
    <div className='box-shadow border-radius bg-white p2 mt3'>
      <Routes/>
    </div>
  </>

export default withRouter(Files)
