import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Modal, Button } from "@bitcine/cs-theme"
import SharedTable from "../Common/table"
import { addMessage } from "../../api/messages"
import { componentFetch } from "../../helpers/component_fetch"
import { push } from "react-router-redux"

class Currencies extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      queries: {}
    }
  }
  addCurrency(code, symbol) {
    componentFetch(
      "POST",
      `admin/models/currencies`,
      {
        currency_code: code,
        currency_symbol: symbol
      },
      res => this.props.push(`/currencies/${res._id}`),
      this.props.addMessage("Added!")
    )
  }
  render() {
    return (
      <div>
        <SharedTable
          collection={"currencies"}
          queries={this.state.queries}
          includeSearch={true}
          includeTitle={"Currencies"}
          includeButton={true}
          isCompact={true}
          buttonText={"Add Currency"}
          onButtonClick={() => this.setState({ open: true })}
          tableKeys={[{ text: "Code" }, { text: "Symbol" }, { text: "Account Active" }, { text: "Payment Active" }]}
          tableWidths={["auto", 180, 180, 180]}
          tableValues={[
            currency => currency.currency_code,
            currency => currency.currency_symbol,
            currency => (
              <span>
                {currency.active && (
                  <i style={{ color: "green" }} className='material-icons ml1'>
                    check_circle
                  </i>
                )}
              </span>
            ),
            currency => (
              <span>
                {currency.payment_active && (
                  <i style={{ color: "green" }} className='material-icons ml1'>
                    check_circle
                  </i>
                )}
              </span>
            )
          ]}/>
        <Modal open={this.state.open} width={3} onClose={() => this.setState({ open: false })} title={`Add Currency`}>
          <div className='flex'>
            <input className='cs-input' placeholder='Code' onChange={e => this.setState({ code: e.target.value })}/>
            <input
              className='ml3 cs-input'
              placeholder='Symbol'
              onChange={e => this.setState({ symbol: e.target.value })}/>
          </div>
          <div className='right-align mt3'>
            <Button className='mr1' onClick={() => this.setState({ open: false })}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.addCurrency(this.state.code, this.state.symbol)
                this.setState({ open: false })
              }}>
              Add
            </Button>
          </div>
        </Modal>
      </div>
    )
  }
}

Currencies.propTypes = {
  addMessage: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  addMessage: bindActionCreators(addMessage, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(null, mapDispatchToProps)(Currencies)
