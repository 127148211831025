import React from "react"
import { withRouter } from "react-router-dom"
import SharedModel from "../Common/model"

const AsperaEntitlement = ({ match }) => (
  <SharedModel
    modelID={match.params.asperaEntitlementID}
    headerKey={"owner_name"}
    collection={"aspera-entitlements"}
    isEditable={true}
    isDeletable={true}/>
)

export default withRouter(AsperaEntitlement)
