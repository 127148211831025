import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { push } from "react-router-redux"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { Button } from "@bitcine/cs-theme"
import Transfer from "./actions/transfer"
import Validate from "./actions/validate"
import Generate from "./actions/generate"
import QA from "./actions/qa"
import Copy from "./actions/copy"
import Verify from "./actions/verify"
import Label from "./actions/label"
import Rev from "./actions/rev"
import Shipping from "./actions/shipping"
import ManualJob from "./actions/manualJob"

const renderComponent = {
  download_source_file: <Transfer transferType='Download' jobType='Source File'/>,
  download_audio_track: <Transfer transferType='Download' jobType='Audio Track'/>,
  download_subtitle_track: <Transfer transferType='Download' jobType='Subtitle Track'/>,
  upload_dcp: <Transfer transferType='Upload' jobType='DCP'/>,
  upload_source_file: <Transfer transferType='Upload' jobType='Source File'/>,
  upload_closed_caption_track: <Transfer transferType='Upload' jobType='Closed Captions Track'/>,
  download_closed_captions_track: <Transfer transferType='Download' jobType='Closed Captions Track'/>,
  download_closed_caption: <Transfer transferType='Download' jobType='Closed Captions Track'/>,
  upload_quality_inspection_to_azure: <Transfer transferType='Upload' jobType='Document'/>,
  upload_disc_output: <Transfer transferType='Upload' jobType='ISO'/>,
  download_disc_output: <Transfer transferType='Download' jobType='ISO'/>,
  download_dcp: <Transfer transferType='Download' jobType='DCP'/>,
  validate_source_file: <Validate jobType='Source File'/>,
  validate_disc_output: <Validate jobType='ISO'/>,
  validate_audio_track: <Validate jobType='Audio Track'/>,
  validate_dcp: <Validate jobType='DCP'/>,
  validate_closed_captions_track: <Validate jobType='Captions'/>,
  validate_closed_caption: <Validate jobType='Captions'/>,
  validate_subtitle_track: <Validate jobType='Subtitles'/>,
  qa_dcp: <QA/>,
  manual_job_description: <ManualJob/>,
  copy_dcp_to_drive: <Copy jobType='DCP'/>,
  copy_source_file_to_drive: <Copy jobType='Source File'/>,
  copy_disc_output_to_drive: <Copy jobType='ISO'/>,
  verify_drive_content_dcp: <Verify jobType='DCP'/>,
  create_dcp_label: <Label jobType='DCP'/>,
  create_return_label: <Shipping jobType='Return Shipping Label' text='creating a return shipping label'/>,
  ship_content: <Shipping jobType='Ship Content' text='creating a shipping label'/>,
  ship_content_to_cinema: <Shipping jobType='Ship Content' text='creating a shipping label'/>,
  create_closed_captions: <Generate jobType='Closed Captions Track'/>,
  generate_dcp: <Generate jobType='DCP'/>,
  create_iso_dvd: <Generate jobType='ISO - DVD'/>,
  create_iso_blu_ray: <Generate jobType='ISO - Blu-ray'/>,
  burn_and_test_discs_blu_ray: <Generate jobType='Burn Discs - Blu-ray'/>,
  burn_and_test_discs_dvd: <Generate jobType='Burn Discs - DVD'/>,
  order_closed_captions_in_rev: <Rev/>,
  format_conversion: <Generate jobType='Converted Content'/>
}

class Actions extends React.Component {
  constructor() {
    super()
    this.state = { acknowledged: false }
  }
  render() {
    const { code, isSuspended, isCompleted, nextJobID } = this.props
    return (
      <div className='box-shadow rounded p2 mb2 bg-white relative'>
        <h4>Actions</h4>
        {isSuspended && !this.state.acknowledged ? (
          <div className='center p2'>
            <h4>Suspended</h4>
            <p>This job is suspended because all previous required jobs have not been completed.</p>
            <Button onClick={() => this.setState({ acknowledged: true })} className='white mt1'>
              Continue
            </Button>
          </div>
        ) : (
          renderComponent[code]
        )}
        {isCompleted && (
          <div className='pb2 mt2 pt3 border-top border-gray-5 flex items-center'>
            <h4 className='flex-auto green bold flex items-center mb0'>
              <span className='material-icons mr1'>check_circle</span>
              This task is complete
            </h4>
            {nextJobID && (
              <Link to={`/jobs/${nextJobID}`}>
                <Button className='flex items-center justify-center'>
                  Next Task
                  <span className='material-icons'>keyboard_arrow_right</span>
                </Button>
              </Link>
            )}
          </div>
        )}
      </div>
    )
  }
}

Actions.propTypes = {
  code: PropTypes.string.isRequired,
  isSuspended: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  nextJobID: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    code: state.job.details.code,
    isSuspended: state.job.details.status === "Suspended",
    isCompleted: state.job.details.status === "Complete",
    nextJobID: state.job.next_job_id || ""
  }
}

function mapDispatchToProps(dispatch) {
  return {
    push: bindActionCreators(push, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Actions)
