import {
  UPDATE_FACILITY,
  GET_FACILITY_STAFF,
  ADD_FACILITY_STAFF,
  UPDATE_STAFF,
  GET_STAFF_ROLES,
  REMOVE_USER_FROM_FACILITY
} from "../../redux/reducers/admin/types"
import { get, post, put, del } from '../fetch'

export const updateFacility = (facilityID, key, value) => dispatch =>
  dispatch(
    put(
      UPDATE_FACILITY.REQUEST,
      `admin/models/facilities/${facilityID}`,
      {
        key,
        value
      }
    )
  )

export const getFacilityStaff = facilityID => dispatch => {
  dispatch(
    get(
      GET_FACILITY_STAFF.REQUEST,
      `admin/facilities/${facilityID}/staff`
    )
  )
  dispatch(
    get(
      GET_STAFF_ROLES.REQUEST,
      `admin/roles`
    )
  )
}

export const addFacilityStaff = (facilityID, userID) => (dispatch, getState) => {
  dispatch(
    post(
      ADD_FACILITY_STAFF.REQUEST,
      `admin/facilities/${facilityID}/staff`,
      {
        userID
      }
    )
  )
}

export const updateStaff = (facilityID, staffID, key, value) => dispatch =>
  dispatch(
    post(
      UPDATE_STAFF.REQUEST,
      `admin/facilities/${facilityID}/staff/${staffID}`,
      {
        key,
        value
      }
    )
  )

export const removeUserFromFacility = (facilityID, userID) => (dispatch, getState) => {
  dispatch(
    del(
      REMOVE_USER_FROM_FACILITY.REQUEST,
      `admin/facilities/${facilityID}/staff/${userID}`
    )
  )
}
