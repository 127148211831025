import React, { useState } from "react"
import { connect } from "react-redux"
import { Button } from "@bitcine/cs-theme"
import TransferProject from "./modals/transfer_project"
import DeleteProject from "./modals/delete_project"

const Settings = ({ project }) => {
  const [showTransferModal, setShowTransferModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  return (
    <>
      <div>
        <Button onClick={() => setShowTransferModal(true)}>Transfer Project</Button>
        <Button className='ml2' onClick={() => setShowDeleteModal(true)}>Delete Project</Button>
      </div>
      {showTransferModal && (
        <TransferProject
          close={() => setShowTransferModal(false)}
          project={project}/>
      )}
      {showDeleteModal && (
        <DeleteProject
          close={() => setShowDeleteModal(false)}
          project={project}/>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  project: state.project.model
})

export default connect(mapStateToProps)(Settings)
