import {
  GET_FACILITIES,
  GET_FACILITY_STAFF,
  GET_STAFF_ROLES,
  UPDATE_STAFF,
  ADD_FACILITY_STAFF,
  REMOVE_USER_FROM_FACILITY
} from './types'

export const initialState = {
  facilities: {
    status: 'PENDING',
    list: [],
  },
  facility: {
    status: 'PENDING',
    model: {}
  },
  staff: {
    status: 'PENDING',
    list: []
  },
  roles: {
    status: 'PENDING',
    list: []
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_FACILITIES.PENDING:
      return {
        ...state,
        facilities: {
          ...state.facilities,
          status: 'PENDING'
        }
      }
    case GET_FACILITIES.FULFILLED:
      return {
        ...state,
        facilities: {
          ...state.facilities,
          status: 'READY',
          list: action.payload.list
        }
      }
    case GET_FACILITIES.FAILED:
      return {
        ...state,
        facilities: {
          ...state.facilities,
          status: 'ERROR'
        }
      }
    case GET_STAFF_ROLES.PENDING:
      return {
        ...state,
        roles: {
          ...state.roles,
          status: 'PENDING'
        }
      }
    case GET_STAFF_ROLES.FULFILLED:
      return {
        ...state,
        roles: {
          ...state.roles,
          status: 'READY',
          list: action.payload
        }
      }
    case GET_STAFF_ROLES.FAILED:
      return {
        ...state,
        roles: {
          ...state.roles,
          status: 'ERROR'
        }
      }
    case GET_FACILITY_STAFF.PENDING:
    case UPDATE_STAFF.PENDING:
    case ADD_FACILITY_STAFF.PENDING:
    case REMOVE_USER_FROM_FACILITY.PENDING:
      return {
        ...state,
        staff: {
          ...state.staff,
          status: 'PENDING'
        }
      }
    case GET_FACILITY_STAFF.FULFILLED:
    case UPDATE_STAFF.FULFILLED:
    case ADD_FACILITY_STAFF.FULFILLED:
    case REMOVE_USER_FROM_FACILITY.FULFILLED:
      return {
        ...state,
        staff: {
          ...state.staff,
          status: 'READY',
          list: action.payload
        }
      }
    case GET_FACILITY_STAFF.FAILED:
    case UPDATE_STAFF.FAILED:
    case ADD_FACILITY_STAFF.FAILED:
    case REMOVE_USER_FROM_FACILITY.FAILED:
      return {
        ...state,
        staff: {
          ...state.staff,
          status: 'ERROR'
        }
      }
    default:
      return state
  }
}
