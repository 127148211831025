import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import DatePicker from "react-datepicker"
import { updateJob } from "../../api/job"
import { Link } from "react-router-dom"
import formatDate from "../../helpers/format_date"
import { Table, Button } from "@bitcine/cs-theme"
import AssignUser from "./assign"
//import ChangeFacility from "./change_facility"
import { GLOBALS } from '../../config'

const getDetails = job => {
  return [
    {
      key: 'Delivery Date',
      value: formatDate(job.details.delivery_date, false),
      icon: <span className='light material-icons pr1'>theaters</span>,
      show: !!job.details.delivery_date
    },
    {
      key: 'Due Date',
      value: formatDate(job.details.due_date, false),
      icon: <DatePicker
        withPortal
        customInput={<span className='link material-icons light pointer mr1'>date_range</span>}
        selected={job.details.due_date ? new Date(job.details.due_date) : null}
        onChange={e => updateJob("due_date", e.toISOString())}/>,
      show: true
    },
    {
      key: 'Assigned To',
      value: job.details.operator_name,
      icon: <AssignUser/>,
      show: true
    },
    {
      key: 'Project',
      value: job.details.project_title,
      icon: <Link to={`/projects/${job.details.project_id}`} className='rounded flex items-center'>
        <span className='light material-icons'>arrow_forward</span>
      </Link>,
      show: !!job.details.project_id
    },
    {
      key: 'Customer',
      value: job.details.customer_name,
      icon: <Link
        to={`/${job.details.customer_type}s/${job.details.customer_id}`}
        className='rounded flex items-center'>
        <span className='light material-icons'>arrow_forward</span>
      </Link>,
      show: true,
    },
    {
      key: 'Facility',
      value: job.details.facility_name,
      //icon: <span className='material-icons light'>building</span>,
      show: true
    }
  ].filter(opt => opt.show)
}

const Details = ({ job, updateJob }) => {
  return (
    <div className='box-shadow rounded p2 mb2 bg-white'>
      <div className='flex items-center'>
        <h4 className='flex-auto'>Details</h4>
        {job.details.invoice_id && <a
          href={`${GLOBALS.API_URL}/settings/invoices/${job.details.invoice_id}`}
          target='_blank'
          rel='noopener noreferrer'
          className='ml2'
          disabled={!job.details.invoice_id}>
          <Button className='outline small'>View Invoice</Button>
        </a>}
      </div>
      <Table
        className='mt2'
        widths={[120, 'auto', 100]}
        header={{
          columns: ['', '', '']
        }}
        body={{
          data: getDetails(job),
          row: {
            compact: true,
            render: [
              data => data.key,
              data => <span className='truncate'>{data.value}</span>,
              data => data.icon
            ]
          }
        }}/>
    </div>
  )
}

Details.propTypes = {
  job: PropTypes.object.isRequired,
  updateJob: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  job: state.job
})

const mapDispatchToProps = dispatch => ({
  updateJob: bindActionCreators(updateJob, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Details)
