import {
  GET_COUNTRIES,
  GET_TIMEZONES,
  GET_LANGUAGES,
  GET_CURRENCIES,
  GET_ITEM_PRICES,
  GET_TAGS,
  CREATE_TAG
} from "./types"

export const initialState = {
  countries: [],
  timezones: [],
  languages: [],
  currencies: [],
  storage_plans: {
    list: []
  },
  items: {
    list: []
  },
  tags: {
    status: 'PENDING',
    list: []
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_CURRENCIES.FULFILLED:
      return {
        ...state,
        currencies: action.result,
        status: "READY"
      }

    case GET_LANGUAGES.FULFILLED:
      return {
        ...state,
        languages: action.result,
        status: "READY"
      }

    case GET_TIMEZONES.FULFILLED:
      return {
        ...state,
        timezones: action.result,
        status: "READY"
      }

    case GET_COUNTRIES.FULFILLED:
      return {
        ...state,
        countries: action.payload,
        status: "READY"
      }

    case GET_ITEM_PRICES.FULFILLED:
      return {
        ...state,
        items: {
          list: action.payload.list
        }
      }

    case GET_TAGS.FULFILLED:
      return {
        ...state,
        tags: {
          status: 'READY',
          list: action.payload
        }
      }

    case CREATE_TAG.FULFILLED:
      return {
        ...state,
        tags: {
          ...state.tags,
          list: action.payload
        }
      }

    default:
      return state
  }
}
