import React, { useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from 'react-router-dom'
import { Status } from "@bitcine/cs-theme"
import SubNav from '../SubNav'
import Routes from './routes'
import { getProject } from "../../api/project"

const Project = ({ match, getProject, project, isFetched, history }) => {
  const projectID = match.params.projectID
  useEffect(() => {
    getProject(projectID)
  }, [getProject, projectID])
  const ownerLink = project.organization_id ? `/organizations/${project.organization_id}` : `/users/${project.user_id}`
  return (
    <Status pending={!isFetched}>
      {isFetched &&
        <>
          <div style={{ margin: '-1em' }}>
            <SubNav 
              subtitle={project.owner_name}
              title={project.title}
              onBackButtonClick={() => history.push('/projects')}
              links={[
                { name: 'Files', to: "files", show: true },
                { name: 'Uploads', to: "uploads", show: true },
                { name: 'Shared Files', to: "shared-files", show: true },
                { name: 'Aspera Downloads', to: "aspera-downloads", show: true },
                { name: 'File Requests', to: "file-requests", show: true },
                { name: 'Screeners', to: "screeners", show: true },
                { name: 'Settings', to: "settings", show: true }
              ].filter(opt => opt.show).map(opt => ({
                text: opt.name,
                to: `/projects/${project._id}/${opt.to}`
              }))}
              includeButton={{
                to: ownerLink,
                label: project.owner_name
              }}/>
          </div>
          <div className='box-shadow border-radius bg-white p2 mt3'>
            <Routes/>
          </div>
        </>}
    </Status>
  )
}

const mapStateToProps = state => ({
  project: state.project.model,
  isFetched: state.project.status === 'READY'
})

const mapDispatchToProps = dispatch => ({
  getProject: bindActionCreators(getProject, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Project))
