import React from "react"
import { withRouter } from 'react-router-dom'
import SharedModel from "../Common/model"
import SharedTable from "../Common/table"

const Cinema = ({ match }) =>
  <>
    <SharedModel
      modelID={match.params.cinemaID}
      headerKey={"name"}
      subheaderKey={"address"}
      collection={"cinemas"}
      isEditable={true}
      isDeletable={true}/>
    <SharedTable
      className='mt3'
      collection={"cinema-screens"}
      queries={{ cinema_id: match.params.cinemaID }}
      includeSearch={false}
      isCompact={true}
      includeNewModelButton={true}
      includeTitle={"Screens"}
      tableKeys={[
        { text: "Screen ID" },
        { text: "Resolution" },
        { text: "Integrator" }
      ]}
      tableWidths={[100, 100, "auto"]}
      tableValues={[
        ({ screen_id }) => screen_id,
        ({ resolution }) => resolution,
        ({ device_integrator }) => device_integrator
      ]}/>
  </>

export default withRouter(Cinema)
