import React from "react"
import { withRouter } from "react-router-dom"
import SubNav from '../../components/SubNav'
import Routes from './routes'

const MediaManagerTables = ({ history }) =>
  <>
    <div style={{ margin: '-1em' }}>
      <SubNav
        compact={false}
        links={[
          { name: 'Media Managers', to: "media-managers", show: true },
          { name: 'Jobs', to: "media-manager-jobs", show: true },
          { name: 'TDL / Cinemas', to: "cinemas", show: true }
        ].filter(opt => opt.show).map(opt => ({
          text: opt.name,
          to: `/devices/${opt.to}`
        }))}/>
    </div>
    <div className='box-shadow border-radius bg-white p2 mt3'>
      <Routes/>
    </div>
  </>

export default withRouter(MediaManagerTables)
