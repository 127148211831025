import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Select from "react-select"
import { Table, Status, Modal, ButtonDropdown, Button } from "@bitcine/cs-theme"
import { getOverrideItems, addOverrideItem, updateOverrideItem, removeOverrideItem } from "../../api/customer"
import { getItemPrices } from "../../api/utilities"

class Items extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmation: false
    }
  }
  componentDidMount() {
    this.props.getOverrideItems(this.props.customerID, this.props.customerType)
    this.props.getItemPrices()
  }
  render() {
    const { customerID, customerType, status, removeOverrideItem, overrideItems } = this.props
    console.log(overrideItems)
    return (
      <Status pending={status === "PENDING"}>
        <Table
          className='my3'
          widths={["auto", 250, 100, 100, 100, 65]}
          header={{
            columns: [
              { text: "Name" },
              { text: "Code" },
              { text: "Original Price" },
              { text: "Override Price" },
              { text: "Currency" },
              { text: "" }
            ]
          }}
          body={{
            data: overrideItems,
            row: {
              render: [
                item => item.name,
                item => item.code,
                item => item.price.toFixed(2),
                item => item.override_price.toFixed(2),
                item => item.currency_code,
                item => (
                  <ButtonDropdown
                    button={{
                      className: "link material-icons",
                      text: "more_vert",
                      style: { width: "48px" }
                    }}
                    dropdown={{
                      clickCloses: true,
                      content: [
                        {
                          text: "Update Price",
                          onClick: () =>
                            this.setState({
                              show_update_modal: true,
                              item: item
                            })
                        },
                        {
                          text: "Remove Item",
                          onClick: () => {
                            removeOverrideItem(customerID, customerType, item.item_id)
                          }
                        }
                      ]
                    }}/>
                )
              ]
            }
          }}/>
        <div className='flex items-center mr2'>
          <Button className='ml3 mt3' onClick={() => this.setState({ show_add_modal: true })}>
            Add Override Item
          </Button>
        </div>
        {this.state.show_add_modal && this.renderAddItemModal()}
        {this.state.show_update_modal && this.renderUpdateItemModal()}
      </Status>
    )
  }
  renderAddItemModal() {
    return (
      <Modal open={true} width={1} onClose={() => this.setState({ show_add_modal: false })} title={`Add Override Item`}>
        <p>Select the item from the dropdown below to add it to the override items for this customer.</p>
        <Select
          options={this.props.items}
          onChange={({ value }) => this.setState({ itemID: value })}
          value={this.props.items.find(item => item.value === this.state.itemID)}/>
        <div className='mt2 right-align'>
          <Button
            className='ml3'
            onClick={() => {
              this.props.addOverrideItem(this.props.customerID, this.props.customerType, this.state.itemID)
              this.setState({ show_add_modal: false })
            }}>
            Add Override Item
          </Button>
        </div>
      </Modal>
    )
  }
  renderUpdateItemModal() {
    return (
      <Modal
        open={true}
        width={1}
        onClose={() => this.setState({ show_update_modal: false })}
        title={`Update Item Price`}>
        <p>Input the price in {this.state.item.currency_code}:</p>
        <label>Override Item Price:</label>
        <input
          value={this.state.price}
          className='cs-input col-12 mb1'
          onChange={({ target: { value } }) => this.setState({ price: value })}/>
        <div className='mt2 right-align'>
          <Button
            className='ml3'
            onClick={() => {
              this.props.updateOverrideItem(
                this.props.customerID,
                this.props.customerType,
                this.state.item.item_id,
                this.state.price
              )
              this.setState({ show_update_modal: false })
            }}>
            Update Override Item
          </Button>
        </div>
      </Modal>
    )
  }
}

Items.propTypes = {
  status: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  customerID: PropTypes.string.isRequired,
  customerType: PropTypes.string.isRequired,
  getOverrideItems: PropTypes.func.isRequired,
  addOverrideItem: PropTypes.func.isRequired,
  updateOverrideItem: PropTypes.func.isRequired,
  removeOverrideItem: PropTypes.func.isRequired,
  getItemPrices: PropTypes.func.isRequired,
  overrideItems: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  items: state.utilities.items.list.map(item => ({
    value: item._id,
    label: item.name
  })),
  status: state.customer.override_items.status,
  overrideItems: state.customer.override_items.list
})

const mapDispatchToProps = dispatch => ({
  getItemPrices: bindActionCreators(getItemPrices, dispatch),
  getOverrideItems: bindActionCreators(getOverrideItems, dispatch),
  addOverrideItem: bindActionCreators(addOverrideItem, dispatch),
  updateOverrideItem: bindActionCreators(updateOverrideItem, dispatch),
  removeOverrideItem: bindActionCreators(removeOverrideItem, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Items)
