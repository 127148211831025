import React, { useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Modal, Button, Status } from "@bitcine/cs-theme"
import { transferProject } from "../../../../api/project"

const TransferProject = ({ project, transferProject, close }) => {
  const [customerID, setCustomerID] = useState(null)
  const [transferring, setTransferring] = useState(false)
  return (
    <Modal open={true} width={1} onClose={close} title={"Transfer Project"}>
      <Status className='mt2' pending={transferring}>
        <p>To transfer <b>{project.title}</b>, please enter the organization or user ID in the field below.</p>
        <input
          placeholder='Enter customer ID.'
          label='Customer ID'
          className='cs-input col-12'
          value={customerID}
          onChange={e => setCustomerID(e.target.value)}/>
        <div className='right-align mt2'>
          <Button
            className='ml3'
            disabled={!customerID}
            onClick={e => {
              setTransferring(true)
              transferProject(project._id, customerID, () => {
                close()
              })
            }}>
            Transfer Project
          </Button>
          </div>
      </Status>
    </Modal>
  )
}

const mapDispatchToProps = dispatch => ({
  transferProject: bindActionCreators(transferProject, dispatch)
})

export default connect(null, mapDispatchToProps)(TransferProject)
