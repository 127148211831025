export const GET_FACILITIES = {
  REQUEST: "GET_FACILITIES",
  PENDING: "GET_FACILITIES_PENDING",
  FAILED: "GET_FACILITIES_FAILED",
  FULFILLED: "GET_FACILITIES_FULFILLED"
}

export const ADD_FACILITY_STAFF = {
  REQUEST: "ADD_FACILITY_STAFF",
  PENDING: "ADD_FACILITY_STAFF_PENDING",
  FAILED: "ADD_FACILITY_STAFF_FAILED",
  FULFILLED: "ADD_FACILITY_STAFF_FULFILLED"
}

export const GET_FACILITY_STAFF = {
  REQUEST: "GET_FACILITY_STAFF",
  PENDING: "GET_FACILITY_STAFF_PENDING",
  FAILED: "GET_FACILITY_STAFF_FAILED",
  FULFILLED: "GET_FACILITY_STAFF_FULFILLED"
}

export const GET_STAFF_ROLES = {
  REQUEST: "GET_STAFF_ROLES",
  PENDING: "GET_STAFF_ROLES_PENDING",
  FAILED: "GET_STAFF_ROLES_FAILED",
  FULFILLED: "GET_STAFF_ROLES_FULFILLED"
}

export const UPDATE_FACILITY = {
  REQUEST: "UPDATE_FACILITY",
  PENDING: "UPDATE_FACILITY_PENDING",
  FAILED: "UPDATE_FACILITY_FAILED",
  FULFILLED: "UPDATE_FACILITY_FULFILLED"
}

export const UPDATE_STAFF = {
  REQUEST: "UPDATE_STAFF",
  PENDING: "UPDATE_STAFF_PENDING",
  FAILED: "UPDATE_STAFF_FAILED",
  FULFILLED: "UPDATE_STAFF_FULFILLED"
}

export const REMOVE_USER_FROM_FACILITY = {
  REQUEST: "REMOVE_USER_FROM_FACILITY",
  PENDING: "REMOVE_USER_FROM_FACILITY_PENDING",
  FAILED: "REMOVE_USER_FROM_FACILITY_FAILED",
  FULFILLED: "REMOVE_USER_FROM_FACILITY_FULFILLED"
}