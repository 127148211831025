import React from "react"
import { connect } from "react-redux"
import OverrideItems from "../../Customer/override_items"

const Items = ({ organizationID }) =>
  <OverrideItems
    customerID={organizationID}
    customerType={"organization"}/>

const mapStateToProps = state => ({
  organizationID: state.organization.model._id
})

export default connect(mapStateToProps)(Items)

