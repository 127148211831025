import { S3_FINE_UPLOADER_DEFAULTS } from "../../constants"
import { addMessage } from "../messages"
import { post } from '../fetch'

export const S3FineUploader = (file, key, type = null, modelID = null) => dispatch => {
  const qq = require("fine-uploader/lib/s3")

  let uploader = new qq.s3.FineUploaderBasic({
    ...S3_FINE_UPLOADER_DEFAULTS,
    blobProperties: {
      name: fileID => {
        return new Promise(resolve => resolve(file.name))
      }
    },
    objectProperties: {
      key: fileId => {
        return key + file.name
      }
    },
    callbacks: {
      onError: (fileID, fileName, error) => {
        addMessage(`Error Uploading`)
      },
      onComplete: () => {
        dispatch(addMessage(`Upload Succesful`))
        if (type === "bitrate") {
          post(
            'UDPATE_JOB_BITRATE'
            `admin/jobs/${modelID}/bitratejob`,
            {
              fileName: file.name
            }
          )
        }
      }
    }
  })

  uploader.addFiles([file], null, `${S3_FINE_UPLOADER_DEFAULTS.request.endpoint}`)
}

// export default connect(null, mapDispatchToProps)(S3FineUploader)