import { Doughnut } from 'react-chartjs-2';
import getColors from './colors'

const buildData = data => ({
  labels: ['Organization Users', 'Personal Users'],
  datasets: [
    {
      data: [data.organization_users_count, data.personal_users_count],
      ...getColors(2)
    }
  ]
})

const UsersChart = ({ data }) =>
  <Doughnut
    data={buildData(data)}
    options={{ maintainAspectRatio: false, plugins: { title: { display: true, text: 'Total Users' } } }}/>

export default UsersChart
