export const GET_USER = {
  REQUEST: 'GET_USER',
  PENDING: 'GET_USER_PENDING',
  FAILED: 'GET_USER_FAILED',
  FULFILLED: 'GET_USER_FULFILLED'
}

export const ADD_MANUAL_ORDER_FULFILLED = "ADD_MANUAL_ORDER_FULFILLED"
export const ADD_MANUAL_ORDER = "ADD_MANUAL_ORDER"

export const CONVERT_USER_TO_ORGANIZATION = "CONVERT_USER_TO_ORGANIZATION"