import React, { useState } from "react"
import { connect } from "react-redux"
import { Modal } from "@bitcine/cs-theme"
import SharedTable from "../../../components/Common/table"
import AccessDates from "../../Common/access_dates"

const AccessDevices = ({ userID }) => {
  let [viewDevice, setViewDevice] = useState(null)
  return (
    <React.Fragment>
      <SharedTable
        collection={"access-devices"}
        queries={{ user_id: userID }}
        includeSearch={false}
        isCompact={true}
        overrideOnClick={device => {
          setViewDevice(device)
        }}
        tableKeys={[{ text: "Location" }, { text: "Connection" }, { text: "Device" }, { text: "Dates" }]}
        tableWidths={[240, "auto", 240, 180]}
        tableValues={[
          ({ location }) => <span className='truncate'>{location}</span>,
          ({ connection_details }) => <span className='truncate'>{connection_details}</span>,
          ({ device }) => <span className='truncate'>{device}</span>,
          data => <AccessDates model={data}/>
        ]}/>
      {!!viewDevice && (
        <Modal open={!!viewDevice} onClose={() => setViewDevice(null)} title={"Access Device Logins"}>
          <SharedTable
            collection={"logins"}
            queries={{ access_device_id: viewDevice._id }}
            includeSearch={false}
            disableRowClick={true}
            isCompact={true}
            tableKeys={[{ text: "Status" }, { text: "Updated" }]}
            tableWidths={["auto", 220]}
            tableValues={[
              ({ failed }) => (
                <div className='flex items-center'>
                  <span className={`material-icons mr1 ${failed ? "red" : "green"}`}>
                    {failed ? "block" : "check_circle"}
                  </span>
                  {failed ? "Incorrect Login" : "Successful Login"}
                </div>
              ),
              data => <AccessDates model={data}/>
            ]}/>
        </Modal>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  userID: state.user.model._id
})

export default connect(mapStateToProps)(AccessDevices)