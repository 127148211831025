import React from "react"
import SharedTable from "../Common/table"
import formatDate from "../../helpers/format_date"
import moment from "moment"
import NewJob from "./new_job"

const Jobs = () =>
  <SharedTable
    collection={"jobs"}
    queries={{ status: "Pending", parent_job_id: null, is_parent_job: true }}
    fetchURL={"admin/open-jobs"}
    includeSearch={true}
    includeTitle={"Open Jobs"}
    newModelComponent={<NewJob/>}
    sortKey='due_date'
    sortDirection='ASC'
    tableKeys={[
      { text: "Title", key: "title" },
      { text: "Project" },
      { text: "Assigned To" },
      { text: "Due", key: "due_at" }
    ]}
    tableWidths={["auto", 220, 220, 220]}
    tableValues={[
      job =>
        <div>
          <b>
            {job.title}
          </b>
          <div className='block small'>
            {job.status} ({job.children_status.completion} completed)
            {job.children_status.overdue > 0 &&
              <span className='ml1 red'>
                &#8729; {job.children_status.overdue} Overdue
              </span>}
            {job.children_status.near_due > 0 &&
              <span className='ml1 orange'>
                &#8729; {job.children_status.near_due} Due Soon
              </span>}
          </div>
        </div>,
      job =>
        <div>
          <b>
            {job.project_title}
          </b>
          <div className='block small'>
            {job.owner_name}
          </div>
        </div>,
      job =>
        <div>
          <b>
            {job.operator_name}
          </b>
          <div className='block small'>
            {job.facility_name}
          </div>
        </div>,
      job =>
        <div>
          {job.due_date
            ? <b
              className={`${moment(job.due_date) < moment()
                ? "red"
                : moment(job.due_date) < moment().add(2, "days")
                  ? "orange"
                  : ""}`}>
              {formatDate(job.due_date, false)}
            </b>
            : "N/A"}
          <div className='block small'>
            Created: {formatDate(job.created_at)}
          </div>
        </div>
    ]}/>

export default Jobs
