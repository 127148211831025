import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Modal, Button } from "@bitcine/cs-theme"
import SharedTable from "../Common/table"
import { componentFetch } from "../../helpers/component_fetch"
import { addMessage } from "../../api/messages"
import { push } from "react-router-redux"
import CommonDropdown from '../Common/dropdown'

class CountryShippingCosts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      new_country: {},
      queries: {}
    }
  }
  addNewCountryShippingCost() {
    componentFetch(
      "POST",
      `admin/models/country-shipping-costs`,
      {
        country_id: this.state.new_country._id,
        country_code: this.state.new_country.country_code
      },
      res => this.props.push(`/booking-terrory-prices/${res._id}`),
      this.props.addMessage("Added!")
    )
  }
  render() {
    return (
      <div>
        <SharedTable
          collection={"country-shipping-costs"}
          queries={this.state.queries}
          includeSearch={true}
          includeTitle={"Booking Territory Rates"}
          includeButton={true}
          isCompact={true}
          buttonText={"Add Booking Territory Price"}
          onButtonClick={() => this.setState({ open: true })}
          tableKeys={[{ text: "Country", key: "country_code" }, { text: "For" }, { text: "Prices", key: "prices" }]}
          tableWidths={[200, 200, "auto"]}
          tableValues={[
            data => data.country_code,
            data => (data.organization ? data.organization.name : "Default"),
            data => JSON.stringify(data.prices)
          ]}/>
        <Modal
          open={this.state.open}
          width={3}
          onClose={() => this.setState({ open: false })}
          title={`Add Country Shipping Cost`}>
          <div>
            <div className='flex flex-wrap col-4'>
              <CommonDropdown
                containerClassName='col-12'
                options='countries'
                label='Country'
                value={this.state.new_country._id}
                onChange={value => {
                  this.setState({ new_country: value })
                }}
                clearable={false}/>
            </div>
            <div className='right-align mt3'>
              <Button className='mr1' onClick={() => this.setState({ open: false })}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  this.addNewCountryShippingCost()
                  this.setState({ open: false })
                }}>
                Add
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

CountryShippingCosts.propTypes = {
  params: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  addMessage: bindActionCreators(addMessage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CountryShippingCosts)
