import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import copy from 'copy-to-clipboard'
import ReactTooltip from 'react-tooltip'
import { addMessage } from '../../api/messages'

const CopyText = ({ textToCopy = null, label, className = '', addMessage }) =>
  <span
    className={`pointer ${className}`}
    data-tip={'Click to copy!'}
    onClick={e => {
      e.preventDefault()
      e.stopPropagation()
      copy(textToCopy ? textToCopy : label)
      addMessage('Copied!')
    }}
    >
    {label}
    <ReactTooltip place='bottom' effect='solid' className='bold'/>
  </span>

const mapDispatchToProps = dispatch => ({
  addMessage: bindActionCreators(addMessage, dispatch)
})

export default connect(null, mapDispatchToProps)(CopyText)