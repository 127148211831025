import React from "react"
import SharedTable from "../Common/table"
import formatDate from "../../helpers/format_date"

const Invoices = () =>
  <SharedTable
    collection={"invoices"}
    queries={{}}
    includeSearch={true}
    includeTitle={"Invoices"}
    isCompact={true}
    tableKeys={[
      { text: "Invoice Number" },
      { text: "Description" },
      { text: "Owner" },
      { text: "Total" },
      { text: "Created At" }
    ]}
    tableWidths={[130, "auto", 200, 140, 220]}
    tableValues={[
      invoice =>
        <span>
          {invoice.invoice_number} {invoice.is_refunded ? ` (Refunded)` : ``}
        </span>,
      invoice => invoice.description,
      invoice =>
        <div>
          {invoice.owner_name}
          <br/>
          <div className='block small light'>
            Project: {invoice.project_title}
          </div>
        </div>,
      invoice =>
        <div>
          {invoice.total_formatted}
          {invoice.payment_sources &&
            invoice.payment_sources[0] &&
            invoice.payment_sources[0].type === "post_pay" &&
            <React.Fragment>
              <br/>
              <div className='block small light'>
                {invoice.payment_sources[0].card_information}
              </div>
            </React.Fragment>}
        </div>,
      invoice => formatDate(invoice.created_at)
    ]}/>

export default Invoices
