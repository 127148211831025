import React from "react"
import { withRouter } from "react-router-dom"
import SharedModel from "../Common/model"

const Campaign = ({ match }) => (
  <SharedModel
    modelID={match.params.bookingID}
    collection={"campaigns"}
    hideHeader={true}
    headerKey={"name"}
    isEditable={false}
    isDeletable={false}/>
)

export default withRouter(Campaign)
