import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { ButtonDropdown } from "@bitcine/cs-theme"
import Transfer from "./transfer"

const Transfers = ({ transfers, hasInProgressTransfers }) => {
  const transfersLength = Object.keys(transfers).length
  return (
    <div className='flex items-center justify-center mr3 relative'>
      {!!transfersLength && <ButtonDropdown
        button={{
          className: 'link',
          text: <div className='flex items-center'>
            <span className={`material-icons mr1 ${hasInProgressTransfers ? "spinning" : ""}`}>cached</span>
            <span>Transfers</span>
            {transfersLength > 0 && (
              <span
                style={{
                  width: "20px",
                  height: "20px",
                  top: "-.1rem",
                  right: "-.5rem",
                  border: "3px solid white"
                }}
                className='absolute flex items-center justify-center small bg-purple white circle white bold'>
                {transfersLength}
              </span>
            )}
          </div>
        }}
        dropdown={{
          anchor: "right",
          clickCloses: true,
          content:
          <div className='flex transferDropdown'>
            <div className='col-12 pr2'>
              {Object.keys(transfers).map((key, i) => (
                <Transfer isFirst={i === 0} transfer={transfers[key]} key={transfers[key].uuid}/>
              ))}
            </div>
          </div>
        }}/>}
    </div>
  )
}

const mapStateToProps = state => ({
  transfers: state.transfers,
  hasInProgressTransfers: Object.keys(state.transfers).length > 0
})

const mapDispatchToProps = dispatch => ({})

Transfers.propTypes = {
  transfers: PropTypes.object.isRequired,
  hasInProgressTransfers: PropTypes.bool.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Transfers)
