import React from "react"
import { withRouter } from "react-router-dom"
import SharedModel from "../Common/model"

const Job = ({ match }) =>
  <SharedModel
    modelID={match.params.mediaManagerJobID}
    headerKey={"name"}
    collection={"media-manager-jobs"}
    isEditable={true}
    isDeletable={true}/>

export default withRouter(Job)
