import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from 'react-router-dom'
import PropTypes from "prop-types"
import { Modal, Status } from "@bitcine/cs-theme"
import CineSendPlayer from "@bitcine/cinesend-player"
import { addMessage } from "../../../api/messages"
import { componentFetch } from '../../../helpers/component_fetch'
import SharedModel from "../../Common/model"

const ENCODING_TYPE_DRM = 'drm'
const ENCODING_TYPE_PROXY = 'mp4_360p'
const ENCODING_EMBED_CAPTIONS = 'embed_captions'

class Details extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      player: null,
      show_debug_modal: false,
      show_error_modal: false,
      error_logs: [],
      endpoints: null,
      endpoint_status: "PENDING",
      screener: {}
    }
  }
  getEndpoints = streamType => {
    this.setState({ show_player_modal: true })
    componentFetch(
      'GET',
      `admin/screeners/${this.props.match.params.videoID}/endpoints/${streamType}`,
      null,
      res => {
        this.setState({
          endpoint_status: "READY",
          endpoints: res.data.endpoints,
          config: res.data.config
        })
      }
    )
  }
  reencode = (videoID, type) => {
    if (window.confirm("Are you sure you want to re-encode this screener?")) {
        componentFetch(
        'POST',
        `admin/screeners/${videoID}/reencode`,
        {
          type
        },
        res => this.props.addMessage("Triggered re-encode!"),
        res => this.props.addMessage("Failed to trigger re-encode: " + res.message, 'error')
      )
    }
  }
  render() {
    const { match } = this.props
    return (
      <React.Fragment>
        <SharedModel
          modelID={match.params.videoID}
          headerKey={"title"}
          subheaderKey={"name"}
          collection={"videos"}
          isEditable={true}
          isDeletable={false}
          config={this.state.config}
          isButtonDropdownEnabled={true}
          setModel={screener => this.setState({ screener })}
          buttonDropdownContent={[
            {
              text: "Play Video Stream",
              icon: "play_circle_outline",
              children: [
                {
                  text: "Play DRM Stream",
                  onClick: () => this.getEndpoints(ENCODING_TYPE_DRM)
                },
                {
                  text: "Play Proxy Stream",
                  onClick: () => this.getEndpoints(ENCODING_TYPE_PROXY)
                }
              ],
              breakAfter: true
            },
            {
              text: "Trigger Encode",
              icon: "cloud_circle",
              children: [
                {
                  text: "Trigger DRM Encode",
                  onClick: () => this.reencode(match.params.videoID, ENCODING_TYPE_DRM)
                },
                {
                  text: "Trigger Proxy Encode",
                  onClick: () => this.reencode(match.params.videoID, ENCODING_TYPE_PROXY)
                },
                {
                  text: "Trigger Captions Embedding",
                  onClick: () => this.reencode(match.params.videoID, ENCODING_EMBED_CAPTIONS)
                },
              ],
              breakAfter: true
            },
            {
              text: "View Source File",
              icon: "movie",
              onClick: () => this.props.history.push(`/source-files/${this.state.screener.source_file_id}`),
              hide: !this.state.screener.source_file_id
            }
          ].filter(opt => !opt.hide)}/>
        <Modal
          title={"Watch Video"}
          width={3}
          clickOutsideCloses 
          open={this.state.show_player_modal}
          onClose={() => this.setState({ show_player_modal: false, endpoints: null, endpoint_status: "PENDING" })}>
          <Status pending={this.state.endpoint_status === "PENDING"} error={this.state.endpoint_status === "ERROR"}>
            {this.state.endpoints &&
              <CineSendPlayer
                playerID={"cinesend-player-id"}
                playerCode={localStorage.getItem('cs-player') || 'bitmovin'}
                onVideoChange={video => console.log("Video set to: ", video)}
                onPlayerCreation={player => this.setState({ player })}
                onPlayerEvent={event => console.log("Event log: ", event)}
                autoPlay={false}
                fillViewport={false}
                debug={true}
                activeIndex={0}
                endpoints={this.state.endpoints}
              />}
          </Status>
        </Modal>
      </React.Fragment>
    )
  }
}

Details.propTypes = {
  params: PropTypes.object,
  addMessage: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  user: state.auth.user
})

const mapDispatchToProps = dispatch => ({
  addMessage: bindActionCreators(addMessage, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Details))