import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { updateJobActions, setJobStatus } from "../../../api/job"

function Shipping({
  jobID,
  project,
  jobType,
  text,
  destinationAddress,
  originAddress,
  fedex,
  updateJobActions,
  setJobStatus,
  trackingNumber,
  lineItems,
  isForPickup
}) {
  return (
    <div>
      <div>
        <strong className='mt2'>1. {jobType}</strong>
        <p>
          This job requires {text} for {project} and entering a tracking number.
        </p>
        <div className='caption flex items-start'>
          {destinationAddress && (
            <div>
              <strong>Destination Address</strong>
              <div>{destinationAddress.organization}</div>
              <div>{destinationAddress.name}</div>
              <div>{destinationAddress.address_line1}</div>
              <div>{destinationAddress.address_line2}</div>
              <div>
                {destinationAddress.city}, {destinationAddress.state_or_province_code},{" "}
                {destinationAddress.country_code}
              </div>
              <div>{destinationAddress.postal_code}</div>
              <div>{destinationAddress.phone_number}</div>
            </div>
          )}
          {originAddress && (
            <div className='pl4'>
              <strong>Origin Address</strong>
              <div>{originAddress.organization}</div>
              <div>{originAddress.address_line1}</div>
              <div>{originAddress.address_line2}</div>
              <div>
                {originAddress.city}, {originAddress.state_or_province_code}, {originAddress.country_code}
              </div>
              <div>{originAddress.postal_code}</div>
              <div>{originAddress.phone_number}</div>
            </div>
          )}
        </div>
        {lineItems && (
          <div className='py1'>
            <strong>2. Ensure The Required Content Has Been Packed</strong>
            {lineItems.map((e, i) => (
              <div key={i}>
                {e.name} x {e.quantity}
              </div>
            ))}
          </div>
        )}
        {fedex && (
          <div className='py1 flex items-center my2'>
            <div className='flex-auto'>
              <strong className='block'>Shipping</strong>
              <small className='light'>
                <span className='capitalize'>{fedex.shipping_type}</span>
                <div> ● Expected delivery by {fedex.delivery_date}</div>
              </small>
            </div>
            <span>{fedex.rate_formatted}</span>
          </div>
        )}
      </div>
      {isForPickup ? (
        <div>
          <strong className='mt3'>3. Customer Pickup</strong>
          <p>Ensure the customer picked up the drive before completing this job.</p>
        </div>
      ) : (
        <div>
          <strong className='mt3'>3. Add Tracking Number</strong>
          <label>Tracking Number</label>
          <input
            className='cs-input'
            value={trackingNumber}
            autoFocus
            onChange={({ target }) => updateJobActions("tracking_number", target.value)}/>
          <small className='right-align mt3 light block'>
            Ensure the tracking number is entered before completing this job.
          </small>
        </div>
      )}
    </div>
  )
}

Shipping.propTypes = {
  project: PropTypes.string.isRequired,
  jobType: PropTypes.string.isRequired,
  destinationAddress: PropTypes.object,
  originAddress: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  fedex: PropTypes.object.isRequired,
  updateJobActions: PropTypes.func.isRequired,
  jobID: PropTypes.string.isRequired,
  setJobStatus: PropTypes.func.isRequired,
  trackingNumber: PropTypes.string.isRequired,
  lineItems: PropTypes.array,
  text: PropTypes.string,
  isForPickup: PropTypes.bool
}

function mapStateToProps(state) {
  return {
    jobID: state.job._id,
    project: state.job.details.project_title,
    destinationAddress: state.job.actions.destination_address,
    originAddress: state.job.actions.origin_address || "",
    trackingNumber: state.job.actions.tracking_number || "",
    lineItems: state.job.actions.line_items,
    fedex: state.job.actions.shipping_rate,
    isForPickup: state.job.actions.is_for_pickup
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateJobActions: bindActionCreators(updateJobActions, dispatch),
    setJobStatus: bindActionCreators(setJobStatus, dispatch)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Shipping)
