import {
  GET_COUNTRIES,
  GET_TIMEZONES,
  GET_LANGUAGES,
  GET_CURRENCIES,
  GET_ITEM_PRICES,
  GET_TAGS,
  CREATE_TAG
} from '../../redux/reducers/utilities/types'
import { get, put } from '../fetch'

export const getCurrencies = (customerID, customerType) => dispatch => {
  dispatch(
    get(
      GET_CURRENCIES.REQUEST,
      `utilities/currencies`
    )
  )
}

export const getCountries = (customerID, customerType) => dispatch => {
  dispatch(
    get(
      GET_COUNTRIES.REQUEST,
      `utilities/countries`
    )
  )
}

export const getLanguages = (customerID, customerType) => dispatch => {
  dispatch(
    get(
      GET_LANGUAGES.REQUEST,
      `utilities/languages`
    )
  )
}

export const getTimezones = (customerID, customerType) => dispatch => {
  dispatch(
    get(
      GET_TIMEZONES.REQUEST,
      `utilities/timezones`
    )
  )
}

export const getItemPrices = (customerID, customerType) => dispatch => {
  dispatch(
    get(
      GET_ITEM_PRICES.REQUEST,
      `admin/allItems`
    )
  )
}

export const getTags = () => dispatch =>
  dispatch(
    get(
      GET_TAGS.REQUEST,
      `admin/tags`
    )
  )

export const createTag = tag => dispatch =>
  dispatch(
    put(
      CREATE_TAG.REQUEST,
      `admin/tags`,
      { tag }
    )
  )