import React from "react"
import { withRouter } from "react-router-dom"
import SharedModel from "../Common/model"
import SharedTable from "../Common/table"
import AccessDates from "../Common/access_dates"

const MediaManager = ({ match }) =>
  <React.Fragment>
    <SharedModel
      modelID={match.params.mediaManagerID}
      headerKey={"name"}
      collection={"media-managers"}
      isEditable={true}
      isDeletable={true}/>
    <SharedTable
      className='mt3'
      collection={"media-manager-jobs"}
      queries={{ media_manager_id: match.params.mediaManagerID }}
      includeSearch={true}
      includeTitle={"Jobs"}
      isCompact={true}
      tableKeys={[
        { text: "Name" },
        { text: "Status" },
        { text: "Dates" }
      ]}
      tableWidths={["auto", 140, 180]}
      tableValues={[
        job => <span>{job.name}<br/><span className='small block light'>{job.code} / {job.type}</span></span>,
        job => job.status,
        job => <AccessDates model={job}/>
      ]}/>
  </React.Fragment>

export default withRouter(MediaManager)
