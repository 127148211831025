/* global AW4 */
import uuidv4 from "uuid/v4"
import dayjs from 'dayjs'

import { TRANSFER_PROGRESS } from "../../redux/reducers/transfers/types"

import { createUpload, completeUpload } from "../upload"
import { addTransfer, updateTransfer } from "../transfer"
import { addMessage } from "../messages"


/*
 *.This function initializes Aspera and prompts the user to select one or more files
 */
export const initializeAsperaUploader = specifications => dispatch => {

  const sdkLocation = "//d3gcli72yxqn2z.cloudfront.net/connect/v4"

  const uuid = uuidv4()
  const asperaWeb = new AW4.Connect({
    sdkLocation,
    minVersion: "3.6.0",
    id: uuid
  })

  const asperaInstaller = new AW4.ConnectInstaller({ sdkLocation })

  asperaWeb.initSession(`nodeConnect-${uuid}`)

  asperaWeb.addEventListener(AW4.Connect.EVENT.STATUS, (eventType, status) =>
    dispatch(asperaWebEventListener(eventType, status, asperaInstaller))
  )

  asperaWeb.addEventListener("transfer", (e, asperaObject) => 
    dispatch(asperaTransferEvents(e, asperaObject, uuid))
  )

  const callbacks = {
    success: ({ dataTransfer }) => {
      if (!dataTransfer.files || !dataTransfer.files.length) {
        asperaWeb.stop()
        return
      }
      dataTransfer.files.forEach(file => dispatch(initializeUpload(asperaWeb, uuid, file, specifications)))
    },
    error: res => alert(res.data.message || 'Failed to upload, please try again')
  }

  if (specifications.is_dcp) asperaWeb.showSelectFolderDialog(callbacks, null)
  else asperaWeb.showSelectFileDialog(callbacks, null)
}

const initializeUpload = (asperaWeb, uuid, file, specifications) => dispatch => {
  const name = file.name.split("/").pop()
  const data = {
    method: "Aspera",
    file_list: [{
      ...file,
      name
    }],
    specifications
  }

  dispatch(addMessage(`Preparing to upload ${name}...`, "info", "cloud_upload"))

  dispatch(createUpload(data, res => {
    dispatch(startTransfer(file, asperaWeb, res.aspera_setup, res.upload_details, file.name, specifications.is_dcp))
  }))
}

/*
 *.This function will start the upload using Aspera Connect
 */
const startTransfer = (file, asperaWeb, asperaSetup, upload, sourceFilePath, isDCP) => dispatch => {
  const sourceFilePath = file.name
  const name = file.name.split("/").pop()
  const contentDisposition = isDCP ? "attachment;" : 'attachment; filename="' + name + '"'
  const transferSpec = {
    ...asperaSetup,
    tags: { aspera: { "cloud-metadata": [{ "Content-Disposition": contentDisposition }] } },
    paths: [{ source: sourceFilePath }],
    create_dir: isDCP,
    destination_root: upload.destination,
    authentication: "token"
  }
  const connectSettings = {
    allow_dialogs: "no",
    use_absolute_destination_path: false,
    upload_details: { ...upload, uuid: file.uuid }
  }
  asperaWeb.startTransfer(transferSpec, connectSettings)
}

const asperaWebEventListener = (eventType, dataStatus, asperaInstaller) => (dispatch, getState) => {
  const status = AW4.Connect.STATUS
  if (eventType === AW4.Connect.EVENT.STATUS) {
    switch (dataStatus) {
      case status.INITIALIZING:
        asperaInstaller.showLaunching()
        break
      case status.FAILED:
        asperaInstaller.showDownload()
        break
      case status.OUTDATED:
        asperaInstaller.showUpdate()
        break
      case status.RUNNING:
        asperaInstaller.connected()
        break
      case status.RETRYING:
        break
      default:
        break
    }
  }
}

const asperaTransferEvents = (event, asperaObject, uuid, type) => (dispatch, getState) => {
  asperaObject.transfers
    .filter(transfer => transfer.aspera_connect_settings.app_id === `nodeConnect-${uuid}`)
    .forEach(transfer => {
      const uploadDetails = transfer.aspera_connect_settings.upload_details
      switch (transfer.status) {
        case "initiating":
          break
        case "queued":
          dispatch(updateTransfer("status", "Queued", transfer.uuid))
          break
        case "running":
          dispatch(handleRunning(transfer, uploadDetails))
          break
        case "completed":
          dispatch(handleCompleted(transfer.uuid))
          break
        case "removed":
          break
        case "cancelled":
          dispatch(updateTransfer("status", "Paused", transfer.uuid))
          break
        case "failed":
          dispatch(updateTransfer("status", "Error", transfer.uuid))
          break
        default:
          break
      }
    })
}

const handleRunning = (transfer, uploadDetails) => (dispatch, getState) => {

  let parentTransfer = getState().transfers[transfer.uuid]
  if (!parentTransfer) {
    dispatch(addTransfer(transfer.uuid, uploadDetails))
  }

  let currentSize = 0
  const totalSize = transfer.bytes_expected
  transfer.files.forEach(f => {
    currentSize += f.bytes_written
  })
  const speed = Math.ceil(transfer.calculated_rate_kbps / 1024)
  const progress = `${Math.round((currentSize / totalSize) * 100)}%`
  const timeRemaining = `Will be finished ${dayjs().to(dayjs().add(transfer.remaining_usec / 1000, "milliseconds"))}`

  dispatch({
    type: TRANSFER_PROGRESS,
    parentUUID: transfer.uuid,
    current_size: currentSize,
    parent_current_size: currentSize,
    time_remaining: timeRemaining,
    speed: `${speed.toFixed(0)} mbps`,
    total_size: totalSize,
    progress
  })
}

const handleCompleted = transferUUID => (dispatch, getState) => {
  const transfer = getState().transfers[transferUUID]
  dispatch(
    completeUpload({
      method: "Aspera",
      uuid: transferUUID,
      total_size: transfer.total_size,
      name: transfer.name,
      upload_id: transfer.upload_id
    })
  )
  dispatch({
    type: TRANSFER_PROGRESS,
    uuid: transferUUID,
    current_size: transfer.total_size,
    parent_current_size: transfer.total_size,
    time_remaining: null,
    status: "Complete",
    total_size: transfer.total_size,
    progress: "100%"
  })
}