export const GET_ORGANIZATION = {
  REQUEST: 'GET_ORGANIZATION',
  PENDING: 'GET_ORGANIZATION_PENDING',
  FAILED: 'GET_ORGANIZATION_FAILED',
  FULFILLED: 'GET_ORGANIZATION_FULFILLED'
}

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'

export const SAVE_ORGANIZATION = {
  REQUEST: 'SAVE_ORGANIZATION',
  PENDING: 'SAVE_ORGANIZATION_PENDING',
  FAILED: 'SAVE_ORGANIZATION_FAILED',
  FULFILLED: 'SAVE_ORGANIZATION_FULFILLED'
}

export const DELETE_ORGANIZATION = {
  REQUEST: 'DELETE_ORGANIZATION',
  PENDING: 'DELETE_ORGANIZATION_PENDING',
  FAILED: 'DELETE_ORGANIZATION_FAILED',
  FULFILLED: 'DELETE_ORGANIZATION_FULFILLED'
}

export const CONVERT_ORGANIZATION_TO_USER = 'CONVERT_ORGANIZATION_TO_USER'