import React from "react"
import formatDate from '../../helpers/format_date'
import SharedTable from "../Common/table"

const Promotions = () => (
  <SharedTable
    collection={"promotions"}
    queries={{}}
    includeSearch={true}
    includeNewModelButton={true}
    includeTitle={"Promotions"}
    isCompact={true}
    tableKeys={[{ text: "Code" }, { text: 'Starts At'}, { text: "Expires At" }]}
    tableWidths={["auto", 200, 200]}
    tableValues={[
      data => data.promo_code,
      data => formatDate(data.start_date),
      data => formatDate(data.expiry_date)
    ]}/>
)

export default Promotions
