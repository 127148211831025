import {
  GET_VIDEO
} from "./types"

export const initialState = {
  model: {},
  status: 'PENDING'
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_VIDEO.PENDING:
      return {
        ...state,
        status: "PENDING"
      }
    case GET_VIDEO.FULFILLED:
      return {
        ...state,
        status: "READY",
        model: action.payload.model
      }
    default:
      return state
  }
}
