import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import humanFileSize from "../../helpers/human_file_size"
import { removeTransfer, updateTransfer } from "../../api/transfer"

class Transfer extends React.Component {
  constructor(props) {
    super(props)
    this.cancel = this.cancel.bind(this)
    this.pauseOrResume = this.pauseOrResume.bind(this)
  }
  /**
   * Cancels an in progess upload
   * If method is http, it will cancel all current chunk requests
   */
  cancel() {
    const {
      transfer: { method, uuid },
      updateTransfer,
      removeTransfer
    } = this.props
    if (window.confirm("Are you sure you want to cancel this transfer?")) {
      updateTransfer("status", "Cancelling...", uuid)
      if (method === "aspera") {
        this.cancelAspera()
      } else {
        this.cancelHTTP()
      }
      removeTransfer(uuid)
    }
  }
  cancelHTTP() {
    const { transfer: { childrenTransfers } } = this.props
    Object.keys(childrenTransfers).forEach(key =>
      window.fineUploader.cancel(childrenTransfers[key].fineUploaderUUID)
    )
  }
  cancelAspera() {
    window.asperaWeb.removeTransfer(this.props.transfer.uuid)
  }
  /**
   * Pauses an HTTP Upload
   * Note: This is not in use
   * There are numerous bugs from fineuploader for pausing/resuming http transfers to s3
   * Chunks continue to transfer, but fineupload marks the transfer as 'paused' internally
   */
  pauseHTTP() {
    const { transfer: { childrenTransfers } } = this.props
    Object.keys(childrenTransfers).forEach(key => {
      // const pause = window.fineUploader.pauseUpload(
      //     childrenTransfers[key].fineUploaderUUID
      // )
    })
  }
  pauseAspera() {
    window.asperaWeb.stopTransfer(this.props.transfer.uuid)
  }
  resumeAspera() {
    window.asperaWeb.resumeTransfer(this.props.transfer.uuid)
  }
  /**
   * Resumes an HTTP Upload
   * Note: This is not in use
   * See above not about pause
   */
  resumeHTTP() {
    const { transfer: { childrenTransfers } } = this.props
    Object.keys(childrenTransfers).forEach(key =>
      window.fineUploader.continueUpload(
        childrenTransfers[key].fineUploaderUUID
      )
    )
  }
  pauseOrResume() {
    const { transfer: { status, method, uuid } } = this.props
    if (status === "Transferring") {
      updateTransfer("status", "Pausing...", uuid)
      if (method === "http") {
        this.pauseHTTP()
      } else {
        this.pauseAspera()
      }
    } else {
      if (method === "http") {
        this.resumeHTTP()
      } else {
        this.resumeAspera()
      }
    }
  }
  getChildrenTransfersLength() {
    return this.props.transfer.method === "http"
      ? Object.keys(this.props.transfer.childrenTransfers).length
      : null
  }
  render() {
    const { transfer } = this.props
    return (
      <div className={`${this.props.isFirst ? "" : "border-top border-gray-5 py1"} p2`}>
        <strong className='block truncate'>
          {transfer.name}
        </strong>
        <div className='flex items-center'>
          <div className='bg-gray-5 rounded relative pt1 overflow-hidden my1 flex-auto'>
            <div
              style={{ width: transfer.progress }}
              className={`absolute top-0 left-0 bottom-0 overflow-hidden z4 col-12 ${
                transfer.status === "Error" ? "bg-red" : transfer.status === "Complete" ? "bg-green" : "bg-purple"}`}/>
          </div>
          {transfer.status !== "Complete" &&
            <div className='flex items-center'>
              {transfer.method === "aspera" &&
                <span
                  style={{ width: "40px" }}
                  onClick={this.pauseOrResume}
                  className='underline inline-block small ml2 pointer'>
                  {transfer.status === "Transferring" ? "Pause" : "Resume"}
                </span>}
              <span
                style={{ width: "40px" }}
                onClick={this.cancel}
                className='red underline inline-block ml2 small pointer'>
                Cancel
              </span>
            </div>}
        </div>
        <small className='block'>
          {transfer.status === "Complete"
            ? <strong className='green'>Complete</strong>
            : transfer.status === "Error"
              ? <span className='red'>
                {transfer.status} - {transfer.errorMsg}
              </span>
              : <div>
                <div>
                  <span className='capitalize'>{transfer.status}</span> -{" "}
                  {humanFileSize(transfer.current_size)} /{" "}
                  {humanFileSize(transfer.total_size)}
                </div>
                {transfer.method === "http" &&
                    <div>
                      Transferring&nbsp;
                      {Object.keys(transfer.childrenTransfers).filter(key => {
                        return (
                          transfer.childrenTransfers[key].current_size ===
                          transfer.childrenTransfers[key].total_size
                        )
                      }).length || 1}
                      &nbsp;of&nbsp;
                      {this.getChildrenTransfersLength()} File{this.getChildrenTransfersLength() === 1 ? "" : "s"}
                    </div>}
                {transfer.method === "aspera" &&
                    <div>
                      {transfer.time_remaining
                        ? `${transfer.time_remaining} - ${transfer.speed}`
                        : `Calculating remaining time...`}
                    </div>}
              </div>}
        </small>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  removeTransfer: bindActionCreators(removeTransfer, dispatch),
  updateTransfer: bindActionCreators(updateTransfer, dispatch)
})

Transfer.propTypes = {
  transfer: PropTypes.object.isRequired,
  removeTransfer: PropTypes.func.isRequired,
  updateTransfer: PropTypes.func.isRequired,
  isFirst: PropTypes.bool.isRequired
}

export default connect(null, mapDispatchToProps)(Transfer)
