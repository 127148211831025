import React from "react"
import { withRouter } from 'react-router-dom'
import formatDate from "../../../helpers/format_date"
import SharedTable from "../../Common/table"

const Reviewers = ({ match }) =>
  <SharedTable
    collection={"shared-screeners"}
    queries={{ screener_id: match.params.videoID }}
    includeSearch={true}
    isCompact={true}
    includeTitle={"Reviewers"}
    tableKeys={[
      { text: "Owner" },
      { text: "Shared To" },
      { text: "Last Viewed" },
      { text: "Created" }
    ]}
    tableWidths={["auto", 200, 200, 200]}
    tableValues={[
      reviewer => reviewer.owner,
      reviewer => (
        <div>
          {reviewer.shared_name}
          <br/>
          <div className='block light small'>Email: {reviewer.shared_email || "N/A"}</div>
        </div>
      ),
      reviewer => formatDate(reviewer.last_viewed_at),
      reviewer => formatDate(reviewer.created_at)
    ]}/>

export default withRouter(Reviewers)