import React from "react"

const iconClass = "material-icons"
const iconStyle = { color: "lightgray", fontSize: "24px" }
const frozenStyle = { color: "lightblue", fontSize: "24px" }
const defrostingStyle = { color: "orange", fontSize: "24px" }
const spinningClass = "spinning"

const Document = () => (
  <span className={iconClass} style={iconStyle}>
    insert_drive_file
  </span>
)

const DCP = () => (
  <span className={iconClass} style={iconStyle}>
    movie
  </span>
)

const Captions = () => (
  <span className={iconClass} style={iconStyle}>
    closed_caption
  </span>
)

const Subtitles = () => (
  <span className={iconClass} style={iconStyle}>
    subtitles
  </span>
)

const AudioTrack = () => (
  <span className={iconClass} style={iconStyle}>
    speaker
  </span>
)

const MultiAudioTrack = () => (
  <span className={iconClass} style={iconStyle}>
    speaker_group
  </span>
)

const Disc = () => (
  <span className={iconClass} style={iconStyle}>
    album
  </span>
)

const Folder = () => (
  <span className={iconClass} style={iconStyle}>
    folder
  </span>
)

const SourceFile = () => (
  <span className={iconClass} style={iconStyle}>
    movie
  </span>
)

const Frozen = () => (
  <span className={iconClass} style={frozenStyle}>
    ac_unit
  </span>
)

const Freezing = () => (
  <span className={`${iconClass} ${spinningClass}`} style={frozenStyle}>
    ac_unit
  </span>
)

const Defrosting = () => (
  <span className={`${iconClass} ${spinningClass}`} style={defrostingStyle}>
    ac_unit
  </span>
)

export {
  Document,
  DCP,
  Captions,
  Subtitles,
  SourceFile,
  AudioTrack,
  MultiAudioTrack,
  Disc,
  Folder,
  Frozen,
  Freezing,
  Defrosting
}
