import React from "react"
import { withRouter } from "react-router-dom"
import SharedTable from "../Common/table"
import SharedModel from "../Common/model"
import AccessDates from "../Common/access_dates"
import msToTimecode from "../../helpers/ms_to_timecode"

const Reviewer = ({ match }) => (
  <div>
    <SharedModel
      modelID={match.params.sharedScreenerID}
      headerKey={"name"}
      collection={"shared-screeners"}
      isEditable={false}
      isDeletable={false}/>
    <SharedTable
      className='mt3'
      collection={"screener-logs"}
      queries={{
        shared_screener_id: match.params.sharedScreenerID
      }}
      includeSearch={true}
      includeTitle={"Logs"}
      disableRowClick={true}
      isCompact={true}
      tableKeys={[{ text: "Type" }, { text: "Timecode"}, { text: 'Location' }, { text: "Connection" }, { text: "Updated" }]}
      tableWidths={[200, 200, 200, "auto", 180]}
      tableValues={[
        data => <span className='capitalize'>{data.log_type.replace("_", " ")}</span>,
        data => data.timecode && <span className='block light small'>{msToTimecode(data.timecode)}</span>,
        data => <span className='truncate'>{data.location}</span>,
        data => <span className='truncate'>{data.connection}<span className='small block light'>{data.device}</span></span>,
        data => <AccessDates model={data}/>
      ]}/>
  </div>
)

export default withRouter(Reviewer)
