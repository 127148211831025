import React from "react"
import { connect } from "react-redux"
import CustomerInvoices from "../../Customer/invoices"

const Invoices = ({ organizationID }) =>
  <CustomerInvoices queries={{ organization_id: organizationID }}/>

const mapStateToProps = state => ({
  organizationID: state.organization.model._id
})

export default connect(mapStateToProps)(Invoices)

