import React from "react"
import SharedTable from "../Common/table"
import formatDate from "../../helpers/format_date"

const Bookings = () => (
  <SharedTable
    collection={"campaigns"}
    includeSearch={true}
    includeTitle={"Bookings"}
    isCompact={true}
    tableKeys={[
      { text: "Booking", key: "campaign" },
      { text: "Content", key: "contents_title" },
      { text: "Organization", key: "organization_name" },
      { text: "Cinemas", key: "cinemas" },
      { text: "Starts", key: "starts_at" },
      { text: "Ends", key: "ends_at" }
    ]}
    tableWidths={[220, "auto", 220, 220, 100, 100]}
    tableValues={[
      campaign => <span className='truncate'>{campaign.name}</span>,
      campaign => <span className='truncate'>{campaign.contents_title}</span>,
      campaign => <span className='truncate'>{campaign.organization_name}</span>,
      campaign => <span className='truncate'>{campaign.cinemas_title}</span>,
      campaign => formatDate(campaign.starts_at, false),
      campaign => formatDate(campaign.ends_at, false)
    ]}/>
)

export default Bookings
