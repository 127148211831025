import React from "react"
import { withRouter } from 'react-router-dom'
import SharedModel from "../Common/model"

const StaffRole = ({ match }) => (
  <SharedModel
    modelID={match.params.staffRoleID}
    headerKey={"title"}
    collection={"staff-roles"}
    isEditable={true}
    isDeletable={true}/>
)

export default withRouter(StaffRole)
