import React from "react"
import Files from "../ProjectFiles"

const DCPs = () =>
  <Files
    title={"Digital Cinema Packages"}
    includeBreadcrumb={false}
    collectionOverride={'digital-cinema-packages'}
    queries={{
      model_type: "dcp"
    }}/>

export default DCPs
