import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import { Button } from "@bitcine/cs-theme"

class NewJob extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "",
      description: "",
      due_date: new Date(),
      project_id: ""
    }
  }
  disabled() {
    return !this.state.title || !this.state.description || !this.state.due_date || this.props.addingNewModel
  }
  create() {
    if (this.disabled()) return
    let data = {
      title: this.state.title,
      description: this.state.description,
      project_id: this.state.project_id,
      due_date: this.state.due_date
    }
    let overrideURL = "admin/jobs/createJob"
    this.props.createModel(data, overrideURL)
  }
  render() {
    return (
      <div>
        <div>
          <span>Job Title</span>
          <input
            className='cs-input col-12'
            placeholder='Job Title'
            onChange={e => this.setState({ title: e.target.value })}/>
        </div>
        <div className='mt1'>
          <label className='cs-label'>Due Date</label>
          <div>
            <DatePicker
              minDate={new Date()}
              className='col-12 cs-input'
              selected={this.state.due_date}
              onChange={value => this.setState({ due_date: value })}/>
          </div>
        </div>
        <div className='mt1'>
          <label className='cs-label'>Project ID</label>
          <input
            className='cs-input col-12'
            placeholder='Project ID'
            onChange={e => this.setState({ project_id: e.target.value })}/>
        </div>
        <div className='mt1'>
          <span>Job Description</span>
          <textarea rows={5} onChange={e => this.setState({ description: e.target.value })} className='col-12 cs-input'/>
        </div>
        <div className='right-align mt2'>
          <Button disabled={this.disabled()} onClick={() => this.create()}>
            Add
          </Button>
        </div>
      </div>
    )
  }
}

NewJob.propTypes = {
  createModel: PropTypes.func.isRequired,
  addingNewModel: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps, null)(NewJob)
