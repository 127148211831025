import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

const Breadcrumbs = ({ job }) => {
  return (
    <div className='flex items-center'>
      <Link to='/jobs'>All Jobs</Link>
      <span className='material-icons mx1'>keyboard_arrow_right</span>
      <Link
        className={!job.parent_job_id ? "bold" : ""}
        to={`/jobs/${job.grandparent_job_id || job.parent_job_id || job._id}`}>
        {job.grandparent_job_title || job.parent_job_title || job.details.title} for {job.details.project_title}
      </Link>
      {job.parent_job_id && (
        <span className='flex items-center'>
          <span className='material-icons mx1'>keyboard_arrow_right</span>
          <Link
            className={!job.grandparent_job_id ? "bold" : ""}
            to={`/jobs/${job.grandparent_job_id ? job.parent_job_id : job._id}`}>
            {job.grandparent_job_id ? job.parent_job_title : job.details.title}
          </Link>
        </span>
      )}
      {job.grandparent_job_id && (
        <span className='flex items-center'>
          <span className='material-icons mx1'>keyboard_arrow_right</span>
          <Link className='bold' to={`/jobs/${job._id}`}>
            {job.details.title}
          </Link>
        </span>
      )}
    </div>
  )
}

Breadcrumbs.propTypes = {
  job: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  job: state.job
})

export default connect(
  mapStateToProps,
  null
)(Breadcrumbs)
