import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import * as Icons from "./icons"

class Icon extends React.Component {
  render() {
    const file = this.props.data

    if (file.started_freezing_at) {
      return <Icons.Freezing/>
    } else if (file.started_defrosting_at) {
      return <Icons.Defrosting/>
    } else if (file.frozen_at) {
      return <Icons.Frozen/>
    }

    const map = {
      document: <Icons.Document/>,
      dcp: <Icons.DCP/>,
      subtitle_track: <Icons.Subtitles/>,
      closed_caption: <Icons.Captions/>,
      folder: <Icons.Folder/>,
      source_file: <Icons.SourceFile/>,
      audio_track: <Icons.AudioTrack/>,
      disc_output: <Icons.Disc/>
    }

    let result = map[file.type]

    return result ? result : null
  }
}

const mapStateToProps = state => ({})

Icon.propTypes = {
  data: PropTypes.object.isRequired
}

export default connect(mapStateToProps, null)(Icon)
