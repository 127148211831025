import React from "react"
import SharedTable from "../Common/table"
import humanFileSize from "../../helpers/human_file_size"
import formatDate from "../../helpers/format_date"

const Videos = () => (
  <SharedTable
    collection={"videos"}
    queries={{project_id: null}}
    includeSearch={true}
    includeTitle={"Videos"}
    isCompact={true}
    includeShowDeletedCheckbox={true}
    tableKeys={[
      { text: "Title", key: "title" },
      { text: "Owner" },
      { text: "Status", key: "status" },
      { text: "Captions" },
      { text: "Encoded Size", key: "size" },
      { text: "Updated", key: "updated_at" },
      { text: "Created", key: "created_at" }
    ]}
    tableWidths={["auto", 160, 140, 100, 140, 140, 140]}
    tableValues={[
      video => <span className='truncate'>{video.title}</span>,
      video => <span className='truncate'>{video.owner_name || 'N/A'}</span>,
      video => <span className='truncate'>{video.status}</span>,
      video => video.captions_count,
      video => humanFileSize(video.size),
      video => formatDate(video.updated_at),
      video => formatDate(video.created_at)
    ]}/>
)

export default Videos
