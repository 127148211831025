import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import DatePicker from 'react-datepicker'
import ReactTooltip from 'react-tooltip'
import JSONInput from 'react-json-editor-ajrm'
import locale    from 'react-json-editor-ajrm/locale/en'
import { addMessage } from "../../api/messages"
import { Table, Status, Modal, Checkbox, Button, ButtonDropdown } from "@bitcine/cs-theme"
import Breadcrumb from "../Breadcrumb"
import Tags from './tags'
import { componentFetch } from "../../helpers/component_fetch"

const CustomDatePicker = ({ label, value, onClick }) =>
  <button
    className='cs-button white'
    style={{ minWidth: '180px', paddingLeft: '40px', paddingRight: '40px' }}
    onClick={onClick}>{value ? value : label}
  </button>

class SharedTable extends React.Component {
  constructor(props) {
    super(props)
    this.getURL = "admin/models/" + props.collection + "/search"
    this.timeout = 0
    let sortDirection = props.sortDirection || "DESC"
    let sortKey = props.sortKey || "created_at"
    let { tableKeys, tableWidths, tableValues } = this.getUpdatedTableValues()
    let savedState = this.getSavedState()
    this.state = {
      status: "PENDING",
      list: [],
      size: 0,
      breadcrumb: [],
      folder_id: null,
      sort: { direction: sortDirection, key: sortKey },
      queries: props.queries,
      search: "",
      take: 25,
      page: 0,
      show_deleted_models: false,
      date_filters: [],
      selected_filters: this.props.filters,
      show_new_modal: false,
      adding_new_model: false,
      show_update_model: false,
      model_to_update: null,
      updating_model: false,
      showModelJSON: null,
      tableKeys,
      tableWidths,
      tableValues,
      ...savedState
    }
  }
  componentDidMount() {
    this.get()
  }
  get() {
    const url = this.props.fetchURL || this.getURL
    const query = this.buildQuery()
    this.saveState()
    this.setState({ status: "PENDING" }, () => {
      componentFetch(
        'POST',
        url,
        query,
        ({ list, size, search, breadcrumb }) => {
          if (search === this.state.search) this.setState({ list, size, breadcrumb, status: "READY" })
        },
        err => this.setState({ status: "ERROR" })
      )
    })
  }
  getLocalStorage() {
    const opsQueries = window.localStorage.getItem('cinesend-ops-state')
    if (!opsQueries) {
      return {}
    }
    return JSON.parse(opsQueries)
  }
  getKey() {
    return this.props.collection + JSON.stringify(this.props.queries)
  }
  getSavedState() {
    const key = this.getKey()
    const savedState = this.getLocalStorage()
    if (savedState && savedState[key]) {
      return savedState[key]
    }
    return null
  }
  saveState() {
    const key = this.getKey()
    let savedState = this.getLocalStorage()
    savedState[key] = {
      page: this.state.page,
      take: this.state.take,
      search: this.state.search,
      // queries: this.state.queries,
      sort: this.state.sort,
      selected_filters: this.state.selected_filters,
      show_deleted_models: this.state.show_deleted_models,
    }
    window.localStorage.setItem('cinesend-ops-state', JSON.stringify(savedState))
  }
  buildQuery() {
    const {
      page,
      take,
      search,
      queries,
      sort: { direction, key }
    } = this.state
    if (this.props.includeBreadcrumb) {
      queries.folder_id = this.state.folder_id
    }
    return {
      skip: page * take,
      take,
      search,
      queries,
      filters: this.state.selected_filters,
      dateFilters: this.state.date_filters,
      showDeleted: this.state.show_deleted_models,
      sortKey: key,
      sortDirection: direction
    }
  }
  onSortChange(sort) {
    this.setState({ sort, page: 0 }, () => this.get())
  }
  onSearchChange(search) {
    this.setState({ search })
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => {
      this.setState({ page: 0 }, () => this.get())
    }, 200)
  }
  onPageChange(page) {
    this.setState({ page }, () => this.get())
  }
  createNewModel(data = {}, overrideURL) {
    const url = overrideURL || `admin/models/${this.props.collection}`
    this.setState(
      { adding_new_model: true },
      componentFetch("POST", url, { ...this.props.queries, ...data }, res => {
        this.setState(
          {
            adding_new_model: false,
            show_new_modal: false
          },
          () => {
            this.props.addMessage("Created!")
            if (!!data) this.get()
            else this.props.history.push(`/${this.props.collection}/${res._id}`)
          }
        )
      })
    )
  }
  sanitizeModelJSON(model) {
    for(const key in model) {
      if (typeof model[key] === 'string') {
        model[key] = model[key].replace(/"/g, "'");
      }
    }
    return model
  }
  toggleDateFilter(filter) {
    let filters = this.state.date_filters
    let index = filters.indexOf(filter)
    if (index !== -1) {
      filters.splice(index)
    } else {
      filters.push(filter)
    }
    this.setState({ date_filters: filters })
  }
  updateRow(id, data, message) {
    this.setState(
      { updating_model: true },
      componentFetch("PUT", `admin/models/${this.props.collection}/${id}`, data, res => {
        this.setState(
          {
            updating_model: false,
            show_update_modal: false,
            model_to_update: null
          },
          () => {
            this.props.addMessage(message)
            this.get()
          }
        )
      })
    )
  }
  handleBreadcrumb(data) {
    if (data.type === "folder") {
      this.setState({ folder_id: data._id }, () => this.get())
      return true
    }
  }
  getSelectedFiltersCount() {
    var count = 0
    this.state.selected_filters.forEach(filter => {
      if (filter.type === 'daterange') {
        if (filter.options.start_date || filter.options.end_date) {
          count++
        }
      }
      else {
        filter.options.forEach(option => {
          if (option.checked) {
            count++
          }
        })
      }
    })
    return count
  }
  getUpdatedTableValues() {
    let { tableKeys, tableWidths, tableValues } = this.props
    if (!tableKeys || !tableWidths || !tableValues) {
      return { tableKeys, tableWidths, tableValues }
    }

    let newTableKeys = tableKeys
    let newTableWidths = tableWidths
    let newTableValues = tableValues

    let { updateModelComponent, updateModelColumnWidth, updateModelButtonText } = this.props
    if (updateModelComponent && updateModelColumnWidth && updateModelButtonText) {
      newTableKeys.push({ text: "" })
      tableWidths.push(updateModelColumnWidth)  
      newTableValues.push(model => (
        <Button
          style={{ width: "100px" }}
          className={`white small`}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            this.setState({ show_update_modal: true, model_to_update: model })
          }}>
          {updateModelButtonText(model)}
        </Button>
      ))
    }
      
    if (this.props.includeTags) {
      newTableKeys.push({ text: "" })
      tableWidths.push(60)
      newTableValues.push(model => (
        <>
          <span
            style={{ fontSize: '14px', color: model.ops_tags ? 'green' : 'gray' }}
            className={`pointer material-icons`}
            data-tip={model.ops_tags ? model.ops_tags.join(", ") : 'No tags, click to add!'}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              this.setState({ show_tags_modal: true, model_to_update: model })
            }}>
            sell
          </span>
          <ReactTooltip place='left' effect='solid' className='bold'/>
        </>
      ))
    }

    return {
      tableKeys: newTableKeys,
      tableWidths: newTableWidths,
      tableValues: newTableValues
    }
  }
  render() {
    let { collection } = this.props
    let { tableKeys, tableWidths, tableValues, breadcrumb, list, size, status, showModelJSON } = this.state
    let selectedFiltersCount = this.getSelectedFiltersCount()
    return (
      <div className={this.props.className}>
        <div className='mb2 flex items-center'>
          {this.props.includeTitle && <h3 className='flex-auto mt0 capitalize'>{this.props.includeTitle}</h3>}
          {this.props.includeBreadcrumb && (
            <Breadcrumb
              breadcrumb={breadcrumb}
              onAllClick={() => {
                this.setState({ folder_id: null }, e => this.get())
              }}
              onCrumbClick={crumb => {
                this.setState({ folder_id: crumb._id }, e => this.get())
              }}/>
          )}
          {this.props.includeShowDeletedCheckbox && (
            <React.Fragment>
              <label>Include Deleted</label>
              <Checkbox
                checked={this.state.show_deleted_models}
                onChange={event => this.setState({ show_deleted_models: event.target.checked }, () => this.get())}/>
            </React.Fragment>
          )}
          {this.props.dateFilters &&
            this.props.dateFilters.map(filter => (
              <React.Fragment key={filter.value}>
                <label>{filter.label}</label>
                <Checkbox
                  checked={this.state.date_filters.indexOf(filter) !== -1}
                  onChange={event => {
                    this.toggleDateFilter(filter)
                    this.get()
                  }}/>
              </React.Fragment>
            ))}
          {this.props.includeSearch && (
            <input
              className='cs-input ml1'
              value={this.state.search}
              onChange={e => this.onSearchChange(e.target.value)}
              placeholder={size ? `Search ${size} items...` : `Search...`}/>
          )}
          {this.props.includeFilter && (
            <ButtonDropdown
              button={{
                className: "white ml1",
                text: (
                  <div className='flex items-center ml1'>
                    <span className='material-icons'>
                      filter_list
                    </span>
                    <span className='mx1'>
                      Filters
                    </span>
                    {selectedFiltersCount > 0 &&
                      <span className='mr1 white px1 bg-red rounded'>
                        {selectedFiltersCount}
                      </span>}
                  </div>
                )
              }}
              dropdown={{
                clickCloses: false,
                content: (
                  <div className='flex items-stretch p2'>
                    {this.props.filters.map(({ key, type, label, options }, i) => (
                      <div key={key} className={`mr2 ${i < this.props.filters.length - 1 ? 'border-right border-gray-5 pr2' : ''}`}>
                        <strong className='block capitalize mb1'>{label || key.replace(/_/g, ' ')}</strong>
                        {
                          type === 'daterange' ? <div>
                            <div>
                              <DatePicker
                                selected={this.state.selected_filters[i].options.start_date
                                  ? new Date(this.state.selected_filters[i].options.start_date)
                                  : null}
                                onChange={date => this.setState({
                                  selected_filters: this.state.selected_filters.map((filter, x) => x !== i ? filter : ({
                                    ...filter,
                                    options: {
                                      ...filter.options,
                                      start_date: date
                                    }
                                  }))
                                }, () => this.get())}
                                isClearable
                                dateFormat="MMM d, yyyy"
                                customInput={<CustomDatePicker label='Start Date'/>}/>
                            </div>
                            <div className='mt1'>
                              <DatePicker
                                selected={this.state.selected_filters[i].options.end_date
                                  ? new Date(this.state.selected_filters[i].options.end_date)
                                  : null}
                                onChange={date => this.setState({
                                  selected_filters: this.state.selected_filters.map((filter, x) => x !== i ? filter : ({
                                    ...filter,
                                    options: {
                                      ...filter.options,
                                      end_date: date
                                    }
                                  }))
                                }, () => this.get())}
                                isClearable
                                dateFormat="MMM d, yyyy"
                                customInput={<CustomDatePicker label='End Date'/>}/>
                            </div>
                          </div> :
                          options.map((value, j) =>
                            <div key={j}>
                              <Checkbox
                                checked={
                                  this.state.selected_filters[i].options[j] &&
                                  this.state.selected_filters[i].options[j].checked
                                }
                                onChange={() => this.setState({
                                  selected_filters: this.state.selected_filters.map((filter, x) => x !== i ? filter : ({
                                    ...filter,
                                    options: filter.options.map((value, y) => ({ ...value, checked: y === j ? !value.checked : value.checked }))
                                  }))
                                }, () => this.get())}
                                className='mt1'
                                label={value.label}/>
                            </div>
                          )
                        }
                       </div>
                    ))}
                  </div>)
              }}/>
          )}
          {this.props.includeButton && (
            <Button className='ml1' onClick={() => this.props.onButtonClick()}>
              {this.props.buttonText}
            </Button>
          )}
          {this.props.includeComponent && this.props.component}
          {this.props.includeNewModelButton && (
            <Button className='ml1' disabled={this.state.adding_new_model} onClick={() => this.createNewModel()}>
              Create New
            </Button>
          )}
          {!!this.props.newModelComponent && (
            <Button
              className='ml1'
              disabled={this.state.adding_new_model}
              onClick={() => this.setState({ show_new_modal: true })}>
              Create New
            </Button>
          )}
        </div>
        <Status pending={status === "PENDING"} error={status === "ERROR"}>
          <Table
            widths={tableWidths}
            header={{
              sorting: {
                key: this.state.sort.key,
                direction: this.state.sort.direction,
                onSortChange: sorting => this.onSortChange(sorting)
              },
              columns: tableKeys
            }}
            body={{
              data: list,
              row: {
                onClick: (event, data, index) => {
                  if (this.props.includeBreadcrumb) {
                    if (this.handleBreadcrumb(data)) {
                      return
                    }
                  }
                  if (typeof this.props.overrideOnClick === "function") {
                    this.props.overrideOnClick(data)
                    return
                  }
                  else if (this.props.disableRowClick) {
                    this.setState({ showModelJSON: data })
                    return
                  }
                  const link = this.props.collectionOverride ? this.props.collectionOverride : collection
                  this.props.history.push(`/${link}/${data._id}`)
                },
                compact: this.props.isCompact || false,
                render: tableValues
              }
            }}
            paginate={{
              currentPage: this.state.page,
              pageCount: Math.ceil(size / this.state.take),
              onPageChange: page => this.onPageChange(page)
            }}/>
        </Status>
        {this.state.show_new_modal && (
          <Modal
            open={this.state.show_new_modal}
            width={1}
            onClose={() => this.setState({ show_new_modal: false })}
            title={`Create New`}>
            {React.cloneElement(this.props.newModelComponent, {
              createModel: (data, overrideURL = null) => this.createNewModel(data, overrideURL),
              addingNewModel: this.state.adding_new_model
            })}
          </Modal>
        )}
        {this.state.show_update_modal && (
          <Modal
            open={this.state.show_update_modal}
            width={1}
            onClose={() => this.setState({ show_update_modal: null })}
            title={`Update`}>
            {React.cloneElement(this.props.updateModelComponent, {
              model: this.state.model_to_update,
              updateModel: (id, data, message = "Updated!") => this.updateRow(id, data, message),
              updatingModel: this.state.updating_model
            })}
          </Modal>
        )}
        {this.state.show_tags_modal &&
          <Tags
            collection={this.props.collection}
            model={this.state.model_to_update}
            refresh={() => this.get()}
            onClose={() => this.setState({ show_tags_modal: false })}/>}
        {!!showModelJSON && typeof window !== "undefined" && (
          <Modal
            open={!!showModelJSON}
            width={3}
            onClose={() => this.setState({ showModelJSON: null })}
            title={"All Properties"}>
            <>
              <JSONInput
                placeholder={this.sanitizeModelJSON(showModelJSON)}
                confirmGood={false}
                locale={locale}
                viewOnly={true}
                height="100%"
                width="100%"/>
              {/*<div className='flex justify-end mt2'>
                <Button
                  disabled={!this.tryParseJSON(JSON.stringify(showModelJSON))}
                  onClick={() => {
                    setTimeout(() => {
                      this.setState({ model: { ...model, ...showModelJSON }, showModelJSON: null }, () => {
                        this.saveModel()
                      })
                    }, 500)
                  }}>
                  Save Values
                </Button>
              </div>*/}
            </>
          </Modal>
        )}
      </div>
    )
  }
}

SharedTable.defaultProps = {
  filters: []
}

SharedTable.propTypes = {
  className: PropTypes.string,
  collection: PropTypes.string.isRequired,
  fetchURL: PropTypes.string,
  queries: PropTypes.object.isRequired,
  dateFilters: PropTypes.array,
  tableKeys: PropTypes.array.isRequired,
  tableWidths: PropTypes.array.isRequired,
  tableValues: PropTypes.array.isRequired,
  sortDirection: PropTypes.string,
  sortKey: PropTypes.string,
  includeTitle: PropTypes.string,
  includeSearch: PropTypes.bool,
  includeBreadcrumb: PropTypes.bool,
  includeNewModelButton: PropTypes.bool,
  newModelComponent: PropTypes.node,
  updateModelComponent: PropTypes.node,
  updateModelColumnWidth: PropTypes.number,
  updateModelButtonText: PropTypes.func,
  includeShowDeletedCheckbox: PropTypes.bool,
  includeButton: PropTypes.bool,
  onButtonClick: PropTypes.func,
  buttonText: PropTypes.string,
  includeComponent: PropTypes.bool,
  component: PropTypes.node,
  disableRowClick: PropTypes.bool,
  overrideOnClick: PropTypes.func,
  addMessage: PropTypes.func.isRequired,
  isCompact: PropTypes.bool,
  includeTags: PropTypes.bool
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  addMessage: bindActionCreators(addMessage, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SharedTable))