import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Files from "../../ProjectFiles"

const ProjectFiles = ({ projectID }) =>
  <Files
    includeBreadcrumb={true}
    includeUploadButton={true}
    includeStorageTransitionButton={true}
    queries={{
      project_id: projectID
    }}/>

ProjectFiles.propTypes = {
  projectID: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  projectID: state.project.model._id
})

export default connect(mapStateToProps)(ProjectFiles)
