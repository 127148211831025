import React from "react"
import { connect } from "react-redux"
import AccessDates from "../../Common/access_dates"
import SharedTable from "../../Common/table"
import humanFileSize from "../../../helpers/human_file_size"
import formatDate from "../../../helpers/format_date"

const Entitlements = ({ organizationID }) => (
  <SharedTable
    className='mt2'
    collection={"aspera-entitlements"}
    queries={{
      organization_id: organizationID
    }}
    isCompact={true}
    includeTitle='Aspera Entitlements'
    includeNewModelButton={true}
    tableKeys={[{ text: "Name" }, { text: "Item Key" }, { text: "Used" }, { text: "Expires" }, { text: "Updated" }]}
    tableWidths={["auto", 200, 200, 200, 220]}
    tableValues={[
      entitlement => entitlement.name,
      entitlement => entitlement.key,
      entitlement => humanFileSize(entitlement.bytes_used) + " of " + humanFileSize(entitlement.bytes_total),
      entitlement => formatDate(entitlement.expires_at),
      entitlement => <AccessDates model={entitlement}/>
    ]}/>
)

const mapStateToProps = state => ({
  organizationID: state.organization.model._id
})

export default connect(mapStateToProps)(Entitlements)

