import React from "react"
import formatDate from "../../helpers/format_date"
import SharedTable from "../Common/table"

const Screeners = () => (
  <SharedTable
    collection={"screeners"}
    queries={{organization_id: null}}
    includeSearch={true}
    includeTitle={"Screeners"}
    isCompact={true}
    tableKeys={[
      { text: "Screener", key: "title" },
      { text: "Project" },
      { text: "Status", key: "status" },
      { text: "Profile", key: "encoding_profile" },
      { text: "Updated", key: "updated_at" },
      { text: "Created", key: "created_at" }
    ]}
    tableWidths={["auto", 180, 120, 120, 160, 160]}
    tableValues={[
      screener => screener.title,
      screener => screener.project_title || "N/A",
      screener => screener.status,
      screener => screener.encoding_profile,
      screener => formatDate(screener.updated_at, true),
      screener => formatDate(screener.created_at, true)
    ]}/>
)

export default Screeners
