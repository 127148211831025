import React from "react"
import { connect } from "react-redux"
import formatDate from "../../../helpers/format_date"
import SharedTable from "../../Common/table"

const Bookings = ({ organizationID }) => (
  <SharedTable
    collection={"campaigns"}
    queries={{
      organization_id: organizationID
    }}
    includeSearch={false}
    includeTitle='Bookings'
    isCompact={true}
    tableKeys={[
      { text: "Name", key: "name" },
      { text: "Submitted", key: "submitted_at" },
      { text: "Cinema Count" },
      { text: "Created At", key: "created_at" }
    ]}
    tableWidths={["auto", 200, 180, 200]}
    tableValues={[
      campaign => campaign.name,
      campaign => formatDate(campaign.submitted_at),
      campaign => campaign.cinema_count,
      campaign => formatDate(campaign.created_at)
    ]}/>
)

const mapStateToProps = state => ({
  organizationID: state.organization.model._id
})

export default connect(mapStateToProps)(Bookings)

