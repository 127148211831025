import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { REJECTION_REASONS } from "../../constants"
import { updateError, saveError } from "../../api/job"
import { Modal, Button } from "@bitcine/cs-theme"

function ErrorModal({ updateError, job, error, allowMessageEdit, saveError, requiresTimecode }) {
  return (
    <Modal open={true} width={2} onClose={() => updateError("visible", false)} title={`Set Error`}>
      {job.details.code === "validate_source_file" ? (
        <div>
          <strong>Quick Error Selection</strong>
          {REJECTION_REASONS[job.details.code].map((res, i) => (
            <div className='py1' key={i}>
              <div className='flex items-center'>
                <input
                  type='radio'
                  id={`__res-${i}`}
                  onChange={() => {
                    updateError("code", res.code)
                    updateError("message", REJECTION_REASONS[job.details.code].find(r => r.code === res.code).message)
                  }}
                  checked={error.code === res.code}/>
                <label htmlFor={`__res-${i}`} className='ml1'>
                  {res.code}
                </label>
              </div>
              {error.code && error.code === res.code && res.requires_timecode && (
                <div className='pl3'>
                  <label>
                    Timecode of Error<sup className='red'>*</sup>
                  </label>
                  <input
                    className='cs-input'
                    value={error.timecode || ""}
                    onChange={({ target }) => updateError("timecode", target.value)}/>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : null}
      {allowMessageEdit && (
        <div className='mt2'>
          <label>Error Message</label>
          <textarea
            placeholder='Describe the error'
            maxLength={200}
            className='col-12'
            onChange={({ target }) => updateError("message", target.value)}
            value={error.message
              .replace("%%--PROJECT_NAME--%%", job.details.project_title)
              .replace("%%--TIMECODE--%%", error.timecode)}/>
          <div className='mt1 flex items-center'>
            <input
              type='checkbox'
              id='__filmmaker'
              checked={error.sendToFilmmaker}
              onChange={() => updateError("sendToFilmmaker", !error.sendToFilmmaker)}/>
            <label htmlFor='__filmmaker' className='ml1'>
              Send this error message to the filmmaker
            </label>
          </div>
        </div>
      )}
      <div className='flex justify-end mt2'>
        <Button
          disabled={
            !allowMessageEdit ||
            (requiresTimecode && !error.timecode) ||
            !error.message ||
            job.details.status === "UPDATING"
          }
          className={job.details.status === "UPDATING" ? "pending" : ""}
          onClick={saveError}>
          Save Error
        </Button>
      </div>
    </Modal>
  )
}

ErrorModal.propTypes = {
  updateError: PropTypes.func.isRequired,
  job: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  allowMessageEdit: PropTypes.bool.isRequired,
  saveError: PropTypes.func.isRequired,
  requiresTimecode: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  const requiresTimecode =
    state.job.newError.code &&
    state.job.details.code === "validate_source_file" &&
    (!REJECTION_REASONS[state.job.details.code].find(r => r.code === state.job.newError.code).requires_timecode ||
      (REJECTION_REASONS[state.job.details.code].find(r => r.code === state.job.newError.code).requires_timecode &&
        state.job.newError.timecode))
  return {
    job: state.job,
    error: state.job.newError,
    requiresTimecode: requiresTimecode ? true : false,
    allowMessageEdit: state.job.details.code !== "validate_source_file" || requiresTimecode ? true : false
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateError: bindActionCreators(updateError, dispatch),
    saveError: bindActionCreators(saveError, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal)
