import { removeTransfer, updateTransfer } from "../transfer"
import { put, post } from '../fetch'
import { addMessage } from "../messages"

export const createUpload = (opts, callback) => (dispatch) =>
  dispatch(
    post(
      'CREATE_UPLOAD',
      'uploads',
      opts,
      callback
    )
  )

export const updateUpload = transfer => (dispatch, getState) => {
  if (transfer.transfer_iteration_token % 120 === 0) {
    dispatch(
      put(
        'UPDATE_UPLOAD',
        `uploads/${transfer.upload_id}`,
        {
          current_size: transfer.current_size,
          status: transfer.status,
          progress: transfer.progress
        }
      ) 
    )
  }
}

export const completeUpload = transfer => (dispatch, getState) => {
  dispatch(updateTransfer("current_size", transfer.total_size, transfer.uuid))
  dispatch(updateTransfer("status", "Complete", transfer.uuid))
  dispatch(
    put(
      'UPDATE_UPLOAD',
      `uploads/${transfer.upload_id}`,
      {
        total_size: transfer.total_size,
        status: "Complete"
      },
      () => {
        dispatch(addMessage(`Completed upload of ${transfer.name}, please refresh.`))
        dispatch(removeTransfer(transfer.uuid))
      }
    )
  )
}
