import React from "react"
import { withRouter } from 'react-router-dom'
import { GLOBALS } from '../../config'
import formatDate from "../../helpers/format_date"
import SharedTable from "../Common/table"
import SharedModel from "../Common/model"

const Subscriber = ({ match }) =>
  <>
    <SharedModel
      modelID={match.params.subscriberID}
      headerKey={"email"}
      subheaderKey={"name"}
      collection={"subscribers"}
      includeButton={true}
      buttonText={<span>👻<span className='ml1'>Ghost Subscriber</span></span>}
      onButtonClick={() => {
        if (window.confirm("Ghosting subscribers should only be used for troubleshooting purposes. All video sessions will be tracked to your IP address.")) {
          window.open(`${GLOBALS.API_URL}/ondemand/admin/subscribers/${match.params.subscriberID}/ghost`)
        }
      }}
      isEditable={false}
      isDeletable={false}/>
    <div className='mt2'/>
    <SharedTable
      collection={"sessions"}
      queries={{ subscriber_id: match.params.subscriberID }}
      includeSearch={true}
      isCompact={true}
      includeTitle={"Sessions"}
      tableKeys={[
        { text: 'Asset' } ,
        { text: "Location" },
        { text: "Connection" },
        { text: "Opened" },
        { text: "Watched" },
        { text: "Last Viewed" },
        { text: "Created" }
      ]}
      tableWidths={["auto", 180, 200, 80, 80, 140, 140]}
      tableValues={[
        data => <div className='truncate'>{data.video_title}</div>,
        data => <div className='truncate'>
          {data.browser && <div>
             <span>{data.browser.os.name} {data.browser.os.version}</span>
             <span className='block light small'>{data.browser.browser.name} {data.browser.browser.version}</span>
          </div>}
        </div>,
        data => <div className='truncate'>
          {data.location && <div>
             <span>{data.location.city}, {data.location.state_or_province_code}, {data.location.country_code}</span>
             <span className='block light small'>{data.location.organization}</span>
          </div>}
        </div>,
        data => data.is_opened ? 'Yes' : 'No',
        data => (data.percentage_watched || 0) + '%',
        data => formatDate(data.last_viewed_at),
        data => formatDate(data.created_at)
      ]}/>
  </>

export default withRouter(Subscriber)