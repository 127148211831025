import {
  ADD_TRANSFER,
  REMOVE_TRANSFER,
  UPDATE_TRANSFER,
  ASPERA_PROGRESS,
  TRANSFER_PROGRESS,
  UPDATE_CHILD_TRANSFER
} from "./types"

const initialState = {}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_TRANSFER:
      return {
        ...state,
        [action.uuid]: {
          ...action.transfer
        }
      }

    case REMOVE_TRANSFER:
      const s = state
      delete s[action.uuid]
      return {
        ...s
      }

    case UPDATE_TRANSFER:
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          [action.key]: action.value
        }
      }

    case UPDATE_CHILD_TRANSFER:
      return {
        ...state,
        [action.parentUUID]: {
          ...state[action.parentUUID],
          childrenTransfers: {
            ...state[action.parentUUID].childrenTransfers,
            [action.transferUUID]: {
              ...state[action.parentUUID].childrenTransfers[
                action.transferUUID
              ],
              [action.key]: action.value
            }
          }
        }
      }

    case TRANSFER_PROGRESS:
      return {
        ...state,
        [action.parentUUID]: {
          ...state[action.parentUUID],
          current_size: action.parent_current_size,
          total_size: action.total_size,
          progress: action.progress,
          speeds: action.newSpeeds,
          speed: action.speed,
          time_remaining: action.time_remaining,
          status: "Transferring",
          errorMsg: "",
          transfer_iteration_token: parseFloat(
            state[action.parentUUID].transfer_iteration_token + 1
          ),
          childrenTransfers: {
            ...state[action.parentUUID].childrenTransfers,
            [action.transferUUID]: {
              ...state[action.parentUUID].childrenTransfers[
                action.transferUUID
              ],
              current_size: action.current_size
            }
          }
        }
      }

    case ASPERA_PROGRESS:
      return {
        ...state,
        [action.uuid]: {
          ...state[action.uuid],
          current_size: action.current_size,
          progress: action.progress,
          speed: action.speed,
          time_remaining: action.time_remaining,
          status: "Transferring",
          total_size: action.total_size,
          transfer_iteration_token: state[action.uuid].transfer_iteration_token
            ? state[action.uuid].transfer_iteration_token + 1
            : null
        }
      }

    default:
      return state
  }
}
