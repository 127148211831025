import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { updateJob } from "../../api/job"
import { getFacilityStaff } from '../../api/admin'
import { bindActionCreators } from "redux"
import { Modal, Button, Status } from "@bitcine/cs-theme"

class AssignUser extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: false, new_operator: props.assignee._id }
  }
  componentWillMount() {
    this.props.getFacilityStaff(this.props.facilityID)
  }
  render() {
    const { staff, status, jobName, updateJob } = this.props
    return (
      <div>
        <span onClick={() => this.setState({ open: true })} className='link material-icons auto-height pointer light mr1'>
          person_add
        </span> 
        <Modal
          open={this.state.open}
          width={2}
          onClose={() => this.setState({ open: false, new_operator: null })}
          title={`Assign ` + jobName}>
          <Status pending={status === 'PENDING'}>
            {staff.map((operator, i) => (
              <div className={`flex items-center py1 ${i > 0 ? "border-top border-gray-5" : ""}`} key={operator._id}>
                <input
                  type='checkbox'
                  id={operator._id}
                  name={operator._id}
                  checked={operator._id === this.state.new_operator}
                  onChange={() => this.setState({ new_operator: operator._id })}/>
                <label className='ml1' htmlFor={operator._id}>
                  {operator.full_name}
                </label>
              </div>
            ))}
            <div className='right-align mt3'>
              <Button
                onClick={() => {
                  updateJob("operator_id", this.state.new_operator)
                  this.setState({ open: false })
                }}>
                Save
              </Button>
            </div>
          </Status>
        </Modal>
      </div>
    )
  }
}

AssignUser.propTypes = {
  assignee: PropTypes.object.isRequired,
  staff: PropTypes.array.isRequired,
  jobName: PropTypes.string.isRequired,
  updateJob: PropTypes.func.isRequired,
  getFacilityStaff: PropTypes.func.isRequired,
  userID: PropTypes.string.isRequired
}

function mapStateToProps(state) {
  return {
    assignee: {
      _id: state.job.details.operator_id,
      name: state.job.details.operator_name || "Not Assigned"
    },
    jobName: state.job.details.title,
    staff: state.admin.staff.list,
    status: state.admin.staff.status,
    userID: state.auth.user._id,
    facilityID: state.job.details.facility_id
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateJob: bindActionCreators(updateJob, dispatch),
    getFacilityStaff: bindActionCreators(getFacilityStaff, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignUser)
