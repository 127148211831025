import { GET_FILE_STORAGE, REMOVE_FILE_STORAGE } from "../../redux/reducers/storage/types"
import { get, del } from '../fetch'
import { addMessage } from '../messages'

/**
 *  Get storage content for specific file.
 *
 * @returns dispatch
 */
export const getFileStorage = file => dispatch => {
  dispatch(
    get(
      GET_FILE_STORAGE.REQUEST,
      `files/storage?fileID=${file._id}&fileType=${file.type}`,
    )
  )
}

/**
 *  Get storage content for specific collection and modelID.
 *
 * @returns dispatch
 */
export const getModelStorage = (collection, modelID) => dispatch => {
  dispatch(
    get(
      GET_FILE_STORAGE.REQUEST,
      `admin/models/${collection}/${modelID}/storage`,
    )
  )
}

/**
 *  Removes specified storage content for a file.
 *
 * @returns dispatch
 */
export const removeStorage = (file, path) => dispatch => {
  dispatch(
    del(
      REMOVE_FILE_STORAGE.REQUEST,
      `admin/files/${file._id}/storage?fileID=${file._id}&fileType=${file.type}`,
      {
        fileID: file._id,
        fileType: file.type,
        path
      },
      () => dispatch(addMessage("Item deleted from file storage!"))
    )
  )
}
