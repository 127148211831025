import { 
  GET_OVERRIDE_ITEMS,
  ADD_OVERRIDE_ITEM,
  UPDATE_OVERRIDE_ITEM,
  DELETE_OVERRIDE_ITEM,
  RECALCULATE_STORAGE } from '../../redux/reducers/customer/types'
import { get, post, put, del } from '../fetch'
import { addMessage } from '../messages'

export const recalculateStorage = (customerID, customerType) => dispatch => {
  dispatch(
    post(
      RECALCULATE_STORAGE.REQUEST,
      `admin/customers/recalculate-storage`,
      {
        customerID,
        customerType
      },
      () => dispatch(addMessage('Storage recalculated! Please refresh the page.'))
    )
  )
}

export const getOverrideItems = (customerID, customerType) => dispatch => {
  dispatch(
    get(
      GET_OVERRIDE_ITEMS.REQUEST,
      `admin/customers/override-items?customerID=${customerID}&customerType=${customerType}`
    )
  )
}

export const addOverrideItem = (customerID, customerType, itemID) => dispatch => {
  dispatch(
    post(
      ADD_OVERRIDE_ITEM.REQUEST,
      `admin/customers/override-items`,
      {
        customerID,
        customerType,
        itemID
      },
      () => dispatch(addMessage('Item added.'))
    )
  )
}

export const updateOverrideItem = (customerID, customerType, itemID, price) => dispatch => {
  dispatch(
    put(
      UPDATE_OVERRIDE_ITEM.REQUEST,
      `admin/customers/override-items`,
      {
        customerID,
        customerType,
        itemID,
        price
      },
      () => dispatch(addMessage('Item updated.'))
    )
  )
}

export const removeOverrideItem = (customerID, customerType, itemID) => dispatch => {
  dispatch(
    del(
      DELETE_OVERRIDE_ITEM.REQUEST,
      `admin/customers/override-items`,
      {
        customerID,
        customerType,
        itemID
      },
      () => dispatch(addMessage('Item removed.'))
    )
  )
}