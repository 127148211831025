import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

function ManualJob({ jobTitle, description }) {
  return (
    <div>
      <div className='my4'>
        <strong className='mt3'>Description for {jobTitle} </strong>
        <p>{description}</p>
      </div>
    </div>
  )
}

ManualJob.propTypes = {
  jobTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
}

function mapStateToProps(state) {
  return {
    description: state.job.actions.specifications,
    jobTitle: state.job.details.title
  }
}

export default connect(mapStateToProps)(ManualJob)
