import React from "react"
import { Switch, Redirect, Route } from "react-router-dom"
import General from './components/general'
import Projects from './components/projects'
import Invoices from './components/invoices'
import Items from './components/items'
import Emails from './components/emails'
import AccessDevices from './components/access_devices'
import Payment from './components/payment'
import Cart from './components/cart'
import Jobs from './components/jobs'
import Storage from './components/storage_metadata'

const Routes = () => 
  <Switch>
    <Redirect exact path='/users/:userID' to='/users/:userID/general'/>
    <Route path='/users/:userID/general' component={General}/>
    <Route path='/users/:userID/projects' component={Projects}/>
    <Route path='/users/:userID/invoices' component={Invoices}/>
    <Route path='/users/:userID/items' component={Items}/>
    <Route path='/users/:userID/emails' component={Emails}/>
    <Route path='/users/:userID/access-devices' component={AccessDevices}/>
    <Route path='/users/:userID/payment' component={Payment}/>
    <Route path='/users/:userID/cart' component={Cart}/>
    <Route path='/users/:userID/jobs' component={Jobs}/>
    <Route path='/users/:userID/storage' component={Storage}/>
  </Switch>

export default Routes