import {
  GET_PROJECT
} from "./types"

export const initialState = {
  model: {},
  status: 'PENDING'
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PROJECT.PENDING:
      return {
        ...state,
        status: "PENDING"
      }
    case GET_PROJECT.FULFILLED:
      return {
        ...state,
        status: "READY",
        model: action.payload
      }
    default:
      return state
  }
}
