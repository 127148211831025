import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import CreatableSelect from 'react-select/creatable'
import { Status, Modal } from "@bitcine/cs-theme"
import { getTags, createTag } from "../../api/utilities"
import { addMessage } from "../../api/messages"
import { componentFetch } from "../../helpers/component_fetch"

const Tags = ({ onClose, collection, model, dropdownOptions, tagsStatus, getTags, createTag, addMessage, refresh }) => {
  useEffect(() => {
    if (tagsStatus !== 'READY') {
      getTags()
    }
  }, [getTags, tagsStatus])
  const [selectedTags, setSelectedTags] = useState(model.ops_tags || [])
  const [saving, setSaving] = useState(false)
  const updateTags = newTags => {
    setSaving(true)
    componentFetch(
      "PUT",
      `admin/models/${collection}/${model._id}`,
      { ops_tags: newTags },
      res => {
        addMessage("Tags updated!")
        setSelectedTags(newTags)
        refresh()
        setSaving(false)
      }
    )
  }
  return (
    <Modal 
      open
      title='Update Tags'
      onClose={onClose}>
      <Status pending={tagsStatus === 'PENDING' || saving}>
        <p>Add or remove tags here. You can create a new tag by typing and pressing enter.</p>
        <CreatableSelect
          isMulti={true}
          options={dropdownOptions}
          value={dropdownOptions.filter(tag => selectedTags.includes(tag.value))}
          onCreateOption={value => {
            createTag(value)
            updateTags([...selectedTags, value])
          }}
          onChange={values => updateTags(values.map(({ value }) => value))}/>
      </Status>
    </Modal>
  )
}

Tags.propTypes = {
  model: PropTypes.object,
  tags: PropTypes.array,
}

const mapStateToProps = state => ({
  dropdownOptions: state.utilities.tags.list.map(tag => ({ label: tag.label, value: tag.label })),
  tagsStatus: state.utilities.tags.status
})

const mapDispatchToProps = dispatch => ({
  getTags: bindActionCreators(getTags, dispatch),
  createTag: bindActionCreators(createTag, dispatch),
  addMessage: bindActionCreators(addMessage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Tags)