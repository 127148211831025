import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

function Validate({ project, jobType }) {
  return (
    <div>
      <div className='my4'>
        <strong className='mt3'>
          Validate {jobType} for {project}
        </strong>
        <p>
          This job requires validating the file for {project} {jobType}.
        </p>
      </div>
      <small className='right-align mt3 light block'>
        Ensure the {jobType} file is valid before completing this job.
      </small>
    </div>
  )
}

Validate.propTypes = {
  project: PropTypes.string.isRequired,
  jobType: PropTypes.string.isRequired
}

function mapStateToProps(state) {
  return {
    project: state.job.details.project_title
  }
}

function mapDispatchToProps() {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Validate)
