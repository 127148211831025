import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Select from "react-select"
import { Modal, Button } from "@bitcine/cs-theme"
import Header from './header'
import SharedModel from "../../Common/model"
import { convertUser } from "../../../api/user"
import { GLOBALS } from '../../../config'

class General extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      convertOpen: false
    }
  }
  render() {
    const { user } = this.props
    return (
      <section>
        <SharedModel
          headerKey={"full_name"}
          subheaderKey={"email"}
          modelID={user._id}
          collection={"users"}
          includeButton={true}
          buttonText={<span>👻<span className='ml1'>Ghost User</span></span>}
          onButtonClick={() => {
            window.open(GLOBALS.API_URL + "/admin/ghostUser?userID=" + user._id, "_self")
          }}
          isEditable={true}
          isDeletable={true}/>
        {!user.organization_id && <div className='mt2 border-top border-gray-5 pt2 flex items-start justify-between'>
          <Header user={user}/>
          <Button
            className='small mt2'
            disabled={user.organization_id}
            onClick={() => this.setState({ convertOpen: true })}>
            Convert User to Org
          </Button>
        </div>}
        <Modal
          open={this.state.convertOpen}
          width={1}
          onClose={() => this.setState({ convertOpen: false })}
          title={`Convert User to Organization`}>
          <div>
            <label>Organization Name</label>
            <input className='col-12 mb1 cs-input' onChange={e => this.setState({ orgName: e.target.value })}/>
            <label>Account Type</label>
            <Select
              options={[
                { value: "Producer", label: "Producer" },
                { value: "Post-House", label: "Post-House" },
                { value: "Distributor", label: "Distributor" },
                { value: "Festival", label: "Festival" },
                { value: "Venue", label: "CineSend for Venues" }
              ]}
              onChange={opt => this.setState({ accountType: opt })}
              value={this.state.accountType}
              className='mb1'/>
            <div className='mt3 flex justify-end'>
              <Button
                disabled={!this.state.orgName || !this.state.accountType}
                onClick={() => {
                  this.props.convertUser(user, this.state.orgName, this.state.accountType.value)
                  this.setState({ convertOpen: false })
                }}>
                Convert User
              </Button>
            </div>
          </div>
        </Modal>
      </section>
    )
  }
}

General.propTypes = {
  user: PropTypes.object.isRequired,
  convertUser: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: state.user.model
})

const mapDispatchToProps = dispatch => ({
  convertUser: bindActionCreators(convertUser, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(General)
