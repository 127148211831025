import React from "react"
import { connect } from 'react-redux'
import { withRouter, NavLink } from "react-router-dom"
import styled from "styled-components"
import { LINKS } from './links'

const Container = styled.div`
  position: fixed;
  height: 100%;
  width: 220px;
  margin-top: 56px;
`

const Icon = styled.div`
  width: 26px;
  height: 22px;
`

const canUserViewItem = (roleCode, permissions) => {
  const [code, permission] = roleCode.split('.')
  return permissions[code] && permissions[code][permission]
}

const Sidebar = ({ user }) => (
  <Container className='box-shadow pt2 pb3 bg-white overflow-auto col-12'>
    {LINKS.filter(link => link.show || canUserViewItem(link.role, user.role.permissions))
      .map(link =>
      <NavLink
        key={link.to}
        to={link.to}
        className='border-left border-white pl1 pr3 py1 block col-12'
        style={{ borderWidth: "4px" }}
        activeStyle={{ borderColor: "#FB0F3B", backgroundColor: "#eee" }}
        activeClassName='bold'>
        <div className='flex items-center'>
          <Icon className='flex items-center'>{link.icon}</Icon>
          <span className='ml1'>{link.text}</span>
        </div>
      </NavLink>
    )}
  </Container>
)

const mapStateToProps = state => ({
  user: state.auth.user
})

export default withRouter(connect(mapStateToProps)(Sidebar))
