import React from "react"
import { withRouter } from 'react-router-dom'
import SharedModel from "../Common/model"

const Currency = ({ match }) => (
  <SharedModel
    modelID={match.params.currencyID}
    headerKey={"currency_code"}
    collection={"currencies"}
    isEditable={true}
    isDeletable={true}/>
)

export default withRouter(Currency)
