import React from "react"
import { connect } from "react-redux"
import CustomerProjects from "../../Customer/projects"

const Projects = ({ userID }) =>
  <CustomerProjects queries={{ user_id: userID }}/>

const mapStateToProps = state => ({
  userID: state.user.model._id
})

export default connect(mapStateToProps)(Projects)