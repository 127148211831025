import React from "react"
import { withRouter } from 'react-router-dom'
import formatDate from "../../../helpers/format_date"
import SharedTable from "../../Common/table"

const Sessions = ({ match }) =>
  <SharedTable
    collection={"sessions"}
    queries={{ video_id: match.params.videoID }}
    includeSearch={true}
    isCompact={true}
    disableRowClick={true}
    includeTitle={"Sessions"}
    tableKeys={[
      { text: "Location" },
      { text: "Connection" },
      { text: "Accessed By "},
      { text: "Times Watched" },
      { text: "Watched" },
      { text: "Last Viewed" },
      { text: "Created" }
    ]}
    tableWidths={["auto", 200, 140, 140, 140, 140, 140]}
    tableValues={[
      data => <div className='truncate'>
        {data.browser && <div>
           <span>{data.browser.os.name} {data.browser.os.version}</span>
           <span className='block light small'>{data.browser.browser.name} {data.browser.browser.version}</span>
        </div>}
      </div>,
      data => <div className='truncate'>
        {data.location && <div>
           <span>{data.location.city}, {data.location.state_or_province_code}, {data.location.country_code}</span>
           <span className='block light small'>{data.location.organization}</span>
        </div>}
      </div>,
      data => data.accessed_by,
      data => data.times_watched,
      data => (data.percentage_watched || 0) + '%',
      data => formatDate(data.last_viewed_at),
      data => formatDate(data.created_at)
    ]}/>

export default withRouter(Sessions)