import { Line } from 'react-chartjs-2';
import getColors from './colors'

const buildData = data => ({
  labels: Object.keys(data),
  datasets: [
    {
      label: '# of Uploads',
      data: Object.values(data),
      ...getColors(1)
    }
  ]
})

const RecentUploads = ({ data }) =>
  <Line
    data={buildData(data)}
    height={'100%'}
    options={{ maintainAspectRatio: false, plugins: { title: { display: true, text: 'Recent Uploads' } } }}/>

export default RecentUploads
