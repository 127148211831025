import React from "react"
import SharedTable from "../Common/table"

const TaxRegions = () => (
  <SharedTable
    collection={"tax-regions"}
    queries={{}}
    includeSearch={true}
    includeNewModelButton={true}
    isCompact={true}
    includeTitle={"Tax Regions"}
    tableKeys={[{ text: "Country" }, { text: "State / Province" }]}
    tableWidths={["auto", 300]}
    tableValues={[({ country_code }) => country_code, ({ state_or_province_code }) => state_or_province_code]}/>
)

export default TaxRegions
