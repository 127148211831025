import React, { useState } from "react"
import PropTypes from "prop-types"
import { push } from "react-router-redux"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import SharedTable from "../Common/table"
import formatDate from "../../helpers/format_date"
import { ButtonDropdown } from "@bitcine/cs-theme"
import StatusModal from "./order_status_modal"

const ContentOrders = ({ push }) => {
  const [activeOrder, setActiveOrder] = useState(null)
  return (
    <React.Fragment>
      <SharedTable
        collection={"content-orders"}
        queries={{}}
        sortKey='created_at'
        sortDirection='DESC'
        includeSearch={true}
        includeTitle={"E-Bookings"}
        includeFilter={true}
        filters={[
          {
            key: 'status',
            options: ['Acknowledged', 'Accepted', 'PendingFulfillment', 'CompositionUpdate', 'Rejected', 'Completed', 'Cancel', 'CancelledAfterCompletion'].map(opt => ({ label: opt, value: opt }))
          }
        ]}
        isCompact={true}
        tableKeys={[
          { text: "Title" },
          { text: "Status" },
          { text: "Destination" },
          { text: "Delivery" },
          { text: "Created" },
          { text: "" }
        ]}
        tableWidths={["auto", 200, 300, 180, 180, 65]}
        tableValues={[
          data => (
            <div className='truncate'>
              <b>{data.title}</b>
              <span className='block small'>{data.description}</span>
            </div>
          ),
          data => data.status,
          ({ data }) =>
            data && (
              <div classNAme='truncate'>
                {data.deliveryAddress.siteName}
                {data.deliveryAddress.circuitName && (
                  <small className='ml1'>({data.deliveryAddress.circuitName})</small>
                )}
                <span className='block light small'>
                  {data.deliveryAddress.city}, {data.deliveryAddress.territory}
                </span>
              </div>
            ),
          ({ data }) => (
            <div>
              <b>Delivery: {data ? formatDate(data.deliveryDate, false) : "N/A"}</b>
              <div className='block small light'>Start: {data ? formatDate(data.startDate, false) : "N/A"}</div>
            </div>
          ),
          data => formatDate(data.created_at),
          data => (
            <ButtonDropdown
              button={{
                className: "material-icons link",
                menu: true,
                text: "more_vert"
              }}
              dropdown={{
                clickCloses: true,
                content: [
                  {
                    text: "Update Status",
                    icon: "flag",
                    onClick: () => setActiveOrder(data),
                    show: true
                  }
                ].filter(opt => opt.show)
              }}/>
          )
        ]}/>
      {activeOrder && <StatusModal order={activeOrder} close={() => setActiveOrder(null)}/>}
    </React.Fragment>
  )
}

ContentOrders.propTypes = {
  push: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch)
})

export default connect(null, mapDispatchToProps)(ContentOrders)
