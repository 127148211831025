import React from "react"
import { withRouter } from 'react-router-dom'
import formatDate from "../../helpers/format_date"
import SharedModel from "../Common/model"
import SharedTable from "../Common/table"

const Session = ({ match }) =>
  <>
    <SharedModel
      modelID={match.params.sessionID}
      headerKey={"video_title"}
      subheaderKey={"accessed_by"}
      collection={"sessions"}
      isEditable={false}
      isDeletable={false}/>
    <div className='mt2'/>
    <SharedTable
      collection={"session-logs"}
      queries={{ session_id: match.params.sessionID }}
      isCompact={true}
      disableRowClick={true}
      sortDirection={'DESC'}
      sortKey={'timestamp'}
      includeTitle={"Session Logs"}
      tableKeys={[
        { text: "Type" },
        { text: "Timecode" },
        { text: "Start"},
        { text: "End" },
        { text: "Timestamp" },
        { text: "Created" }
      ]}
      tableWidths={["auto", 180, 180, 180, 180, 140]}
      tableValues={[
        data => data.log_type,
        data => data.timecode,
        data => data.start_time,
        data => data.end_time,
        data => data.timestamp,
        data => formatDate(data.created_at)
      ]}/>
  </>

export default withRouter(Session)