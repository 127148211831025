import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import JSONInput from 'react-json-editor-ajrm'
import locale    from 'react-json-editor-ajrm/locale/en'
import { withRouter } from 'react-router-dom'
import { Modal, Status, ButtonDropdown, Checkbox, Button } from "@bitcine/cs-theme"
import { addMessage } from "../../api/messages"
import { componentFetch } from "../../helpers/component_fetch"
import formatDate from "../../helpers/format_date"
import DatePicker from "react-datepicker"
import CommonDropdown from "./dropdown"
import StorageTable from '../StorageTable'

class SharedModel extends React.Component {
  constructor(props) {
    super(props)
    this.getURL = "admin/models/" + props.collection + "/" + props.modelID
    this.state = {
      status: "PENDING",
      fields: [],
      model: {},
      original_model: {},
      delete_open: false,
      notes_open: false,
      force_delete: false,
      showJSONField: null,
      showJSONValue: null,
      showModelJSON: null
    }
  }
  componentDidMount() {
    this.setState({ status: "PENDING" }, () => {
      componentFetch(
        'GET',
        this.getURL,
        null,
        res => this.modelLoaded(res),
        err => this.setState({ status: "ERROR" })
      )
    })
  }
  modelLoaded({ model, fields }) {
    this.setState({
      model,
      original_model: model,
      fields,
      saving: false,
      status: "READY"
    })
    if (typeof this.props.setModel === "function") {
      this.props.setModel(model)
    }
  }
  saveModel() {
    let newValues = { admin_notes: this.state.model.admin_notes }
    this.state.fields.forEach(field => {
      if (this.state.model[field.key] !== this.state.original_model[field.key]) {
        newValues[field.key] = this.state.model[field.key]
      }
    })
    // TODO: Here this should validate JSON editable fields and ensure they are still JSON...
    this.setState({ saving: true })
    componentFetch(
      "PUT",
      `admin/models/${this.props.collection}/${this.props.modelID}`,
      newValues,
      res => {
        this.modelLoaded(res)
        this.props.addMessage("Updated!")
      }
    )
  }
  deleteModel() {
    let url = `admin/models/${this.props.collection}/${this.props.modelID}`
    if (this.state.force_delete) url += `/force`
    this.setState({ deleting: true, delete_open: false, force_delete: false })
    componentFetch("DELETE", url, null, res => {
      if (res.success) {
        this.props.addMessage("Deleted!")
        this.props.history.push(`/${this.props.collection}`)
      } else {
        this.props.addMessage("Error deleting model: " + res.message)
      }
      this.setState({ deleting: false })
    })
  }
  restoreModel() {
    let url = `admin/models/${this.props.collection}/${this.props.modelID}/restore`
    this.setState({ restoring: true })
    componentFetch("POST", url, null, res => {
      if (res.success) {
        this.props.addMessage("Restored!")
        window.location.reload()
      } else {
        this.props.addMessage("Error restoring model: " + res.message)
      }
      this.setState({ restoring: false })
    })
  }
  tryParseJSON(jsonString) {
    try {
      var o = JSON.parse(jsonString)
      if (o && typeof o === "object") {
        return o
      }
    } catch (e) {}
    return false
  }
  render() {
    const {
      collection,
      imageKey,
      headerKey,
      subheaderKey,
      hideHeader,
      isButtonDropdownEnabled,
      buttonDropdownContent,
      columnWidth,
      deletionMessage
    } = this.props
    const { model, fields, showJSONField, showJSONValue, showModelJSON } = this.state
    return ( 
      <Status
        pending={this.state.status === "PENDING" || this.state.saving}
        error={this.state.status === "ERROR"}>
        <div className='mb2 flex items-start'>
          <div className='flex'>
            {imageKey && model[imageKey] && (
              <div
                style={{
                  minWidth: "50px",
                  height: "50px"
                }}
                className='items-center justify-center mr2'>
                <img
                  className='block'
                  src={model[imageKey]}
                  alt={model[headerKey]}
                  style={{
                    maxHeight: "100%"
                  }}/>
              </div>
            )}
          </div>
          <h3 className='flex-auto'>
            {model[headerKey] || (!hideHeader && `Untitled ${collection} model`)}
            {subheaderKey && <div className='light small'>{model[subheaderKey]}</div>}
          </h3> 
          {model.deleted_at &&
            <div className='mr2 mt1 white bold small italic'>
              <span className='px1 bg-gray-2 rounded'>Deleted {formatDate(model.deleted_at)}</span>
              {model.hard_deleted_at &&
              <div className='px1 bg-red rounded' style={{ marginTop: '2px' }}>
                Hard Deleted {formatDate(model.hard_deleted_at)}
              </div>}
            </div>}
          {isButtonDropdownEnabled && (
            <ButtonDropdown
              button={{
                className: "material-icons link",
                text: "settings",
                menu: true
              }}
              dropdown={{
                clickCloses: true,
                content: buttonDropdownContent
              }}/>
          )}
          {this.props.includeButton && (
            <Button className='ml1 small white' onClick={() => this.props.onButtonClick()}>
              {this.props.buttonText}
            </Button>
          )}
          <Button
            className={`ml1 white small ${this.state.saving ? "pending" : ""}`}
            onClick={() => this.setState({ notes_open: true })}>
            Notes
          </Button>
          <Button
            className={`ml1 white small ${this.state.saving ? "pending" : ""}`}
            disabled={!this.props.isEditable || this.state.saving}
            onClick={() => this.saveModel()}>
            Save
          </Button>
          <Button
            className={`ml1 white small`}
            onClick={() => this.setState({ showModelJSON: model })}>
            All Values
          </Button>
          {model.project_id && (
            <Button className={`ml1 white small`} onClick={() => this.props.history.push(`/projects/${model.project_id}`)}>
              View Project
            </Button>
          )}
          {model.deleted_at ? (
            <>
              <Button
                disabled={this.state.deleting}
                className={`ml1 small ${this.state.deleting ? "pending" : ""}`}
                onClick={() => this.setState({ delete_open: true, force_delete: true })}>
                Force Delete
              </Button>
              <Button
                disabled={this.state.restoring}
                className={`ml1 small ${this.state.restoring ? "pending" : ""}`}
                onClick={() => this.restoreModel()}>
                Restore
              </Button>
            </>
          ) : (
            <Button
              disabled={!this.props.isDeletable || this.state.deleting}
              className={`ml1 small ${this.state.deleting ? "pending" : ""}`}
              onClick={() => this.setState({ delete_open: true })}>
              Delete
            </Button>
          )}
        </div>
        <div className='flex flex-wrap'>
          {fields.filter(field => !field.hidden).map((field, i) => (
            <div key={i} className={`mb1 col-${columnWidth} ${(i + 1) % (12/columnWidth) !== 0 ? 'pr1' : ''}`}>
              {field.type !== "dropdown" && <label className='capitalize'>{field.label}</label>}
              {(field.type === "integer" || field.type === "string") && (
                <input
                  className='col-12 cs-input'
                  disabled={!field.editable}
                  placeholder={field.label}
                  value={model[field.key]}
                  onChange={e =>
                    this.setState({
                      model: {
                        ...model,
                        [field.key]: e.target.value
                      }
                    })
                  }/>
              )}
              {field.type === "date" && (
                <div className='flex items-center col-12'>
                  <DatePicker
                    className='cs-input col-12'
                    wrapperClassName='col-12'
                    disabled={!field.editable}
                    selected={model[field.key] ? new Date(model[field.key]) : null}
                    onChange={value => {
                      this.setState({
                        model: {
                          ...model,
                          [field.key]: value
                        }
                      })
                    }}/>
                  {field.editable && (
                    <i
                      className='material-icons pointer ml1'
                      onClick={() =>
                        this.setState({
                          model: {
                            ...model,
                            [field.key]: null
                          }
                        })
                      }>
                      close
                    </i>
                  )}
                </div>
              )}
              {field.type === "dropdown" && (
                <CommonDropdown
                  containerClassName='col-12'
                  options={field.collection}
                  label={field.label}
                  value={model[field.key]}
                  disabled={!field.editable}
                  onChange={({ value }) => {
                    this.setState({
                      model: {
                        ...model,
                        [field.key]: value
                      }
                    })
                  }}
                  clearable={false}/>
              )}
              {field.type === "boolean" && (
                <div>
                  <Checkbox
                    disabled={!field.editable}
                    checked={model[field.key] || false}
                    onChange={event => {
                      this.setState({
                        model: {
                          ...model,
                          [field.key]: !model[field.key]
                        }
                      })
                    }}/>
                 </div>
              )}
              {field.type === "json" && (
                <Button
                  className='white block col-12'
                  onClick={() =>
                    this.setState({
                      showJSONField: field,
                      showJSONValue: model[field.key]
                    })
                  }>
                  Show {field.label}
                </Button>
              )}
            </div>
          ))}
          {fields.length === 0 && <JSONInput
            placeholder={model}
            locale={locale}
            confirmGood={false}
            viewOnly={true}
            height="100%"
            width="100%"/>}
        </div>
        <Modal
          open={this.state.delete_open}
          width={1}
          clickOutsideCloses 
          onClose={() => this.setState({ delete_open: false })}
          title={`Are you sure you want to delete ${model[headerKey]}?`}>
          <React.Fragment>
            {this.state.force_delete && (
              <h4>You are FULLY deleting this model. Do not do this if there is still cloud storage attached.</h4>
            )}
            {deletionMessage &&
              <div
                style={{ background: `rgba(251, 15, 59, 0.2)` }}
                className='border border-red rounded p2 bold'>{deletionMessage}</div>}
            <div className='right-align mt3'>
              <Button className='mr1' onClick={() => this.setState({ delete_open: false })}>
                Cancel
              </Button>
              <Button disabled={this.state.deleting} onClick={() => this.deleteModel()}>
                Delete
              </Button>
            </div>
          </React.Fragment>
        </Modal>
        {!!showJSONField && typeof window !== "undefined" && (
          <Modal
            open={!!showJSONField}
            width={3}
            clickOutsideCloses 
            onClose={() => this.setState({ showJSONField: null })}
            title={showJSONField.label}>
            <>
              <JSONInput
                placeholder={showJSONValue}
                onChange={content => this.setState({ showJSONValue: content.jsObject})}
                confirmGood={false}
                locale={locale}
                viewOnly={!showJSONField.editable}
                height="100%"
                width="100%"/>
              {showJSONField.editable && <div className='flex justify-end mt2'>
                <Button
                  disabled={!this.tryParseJSON(JSON.stringify(showJSONValue))}
                  onClick={() => {
                    setTimeout(() => {
                      this.setState({ model: { ...model, [showJSONField.key]: showJSONValue }, showJSONField: null, showJSONValue: null })
                    }, 500)
                  }}>
                  Set New Value
                </Button>
               </div>}
            </>
          </Modal>
        )}
        {!!showModelJSON && typeof window !== "undefined" && (
          <Modal
            open={!!showModelJSON}
            width={3}
            onClose={() => this.setState({ showModelJSON: null })}
            title={"All Properties"}>
            <>
              <JSONInput
                placeholder={showModelJSON}
                onChange={content => this.setState({ showModelJSON: content.jsObject})}
                confirmGood={true}
                locale={locale}
                viewOnly={false}
                height="100%"
                width="100%"/>
              {<div className='flex justify-end mt2'>
                <Button
                  disabled={
                    !this.tryParseJSON(JSON.stringify(showModelJSON))
                    || JSON.stringify(this.state.model) === JSON.stringify(this.state.showModelJSON)
                  }
                  onClick={() => {
                    setTimeout(() => {
                      this.setState({ model: { ...model, ...showModelJSON }, showModelJSON: null }, () => {
                        this.saveModel()
                      })
                    }, 500)
                  }}>
                  Save Values
                </Button>
              </div>}
            </>
          </Modal>
        )}
        {!!this.state.notes_open && (
          <Modal
            open={true}
            width={3}
            onClose={() => this.setState({ notes_open: false })}
            title={"All Notes"}>
            <>
              <textarea
                className='col-12 cs-textarea cs-input'
                placeholder='Add notes here...'
                value={model.admin_notes}
                onChange={e =>
                  this.setState({
                    model: {
                      ...model,
                      admin_notes: e.target.value
                    }
                  })
                }/>
              <div className='flex justify-end mt2'>
                <Button
                  onClick={() => {
                    this.saveModel()
                    this.setState({ notes_open: false })
                  }}>
                  Save Notes
                </Button>
              </div>
            </>
          </Modal>
        )}
        {this.props.includeStorage &&
          <div className='mt4 pt2 border-top border-gray-5'>
            <h4>Cloud Storage</h4>
            <StorageTable file={model}/>
          </div>
        }
      </Status>
    )
  }
}

SharedModel.propTypes = {
  collection: PropTypes.string.isRequired,
  modelID: PropTypes.string.isRequired,
  headerKey: PropTypes.string.isRequired,
  subheaderKey: PropTypes.string,
  hideHeader: PropTypes.bool,
  isEditable: PropTypes.bool.isRequired,
  isDeletable: PropTypes.bool.isRequired,
  addMessage: PropTypes.func.isRequired,
  isButtonDropdownEnabled: PropTypes.bool,
  buttonDropdownContent: PropTypes.array,
  setModel: PropTypes.func,
  columnWidth: PropTypes.number.isRequired,
  includeStorage: PropTypes.bool,
  includeButton: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func,
  buttonText: PropTypes.string,
  imageKey: PropTypes.string,
  deletionMessage: PropTypes.string
}

SharedModel.defaultProps = {
  columnWidth: 3,
  includeStorage: false,
  includeButton: false
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  addMessage: bindActionCreators(addMessage, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SharedModel))
