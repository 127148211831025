import {
  ADD_TRANSFER,
  UPDATE_TRANSFER,
  REMOVE_TRANSFER
} from "../../redux/reducers/transfers/types"
import { createTransfer } from "../upload/utils"

export const addTransfer = (uuid, uploadDetails) => dispatch =>
  dispatch({
    type: ADD_TRANSFER,
    uuid,
    transfer: createTransfer(uploadDetails, uuid)
  })

export const updateTransfer = (key, value, uuid) => dispatch =>
  dispatch({
    type: UPDATE_TRANSFER,
    uuid,
    key,
    value
  })

export const removeTransfer = uuid => dispatch =>
  dispatch({
    type: REMOVE_TRANSFER,
    uuid
  })
