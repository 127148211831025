import React from "react"
import { connect } from "react-redux"
import SharedTable from "../../Common/table"
import formatDate from "../../../helpers/format_date"

const Team = ({ organizationID }) =>
  <SharedTable
    collection={"users"}
    queries={{
      organization_id: organizationID
    }}
    includeSearch={false}
    isCompact={true}
    includeTitle={"Users"}
    tableKeys={[
      { text: "Full Name", key: "full_name" },
      { text: "Email", key: "email" },
      { text: "Role", key: "role.name" },
      { text: "Status", key: "status" },
      { text: "Created", key: "created_at" },
      { text: "Last Login", key: "last_login_at" },
    ]}
    tableWidths={["auto", 180, 180, 180, 180, 180]}
    tableValues={[
      user => user.full_name,
      user => user.email,
      user => user.role ? user.role.name : "No Role",
      user => user.is_suspended
        ? 'Suspended' 
        : user.is_pending_invite_acceptance
          ? 'Pending Invite'
          : 'Active',
      user => formatDate(user.created_at),
      user => formatDate(user.last_login_at),
    ]}/>

const mapStateToProps = state => ({
  organizationID: state.organization.model._id
})

export default connect(mapStateToProps)(Team)
