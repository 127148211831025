import React from "react"
import { connect } from "react-redux"
import OrganizationStorageMetadata from "../../Customer/storage_metadata"

const StorageMetadata = ({ organizationID }) => (
  <OrganizationStorageMetadata queries={{ organization_id: organizationID }}/>
)

const mapStateToProps = state => ({
  organizationID: state.organization.model._id
})

export default connect(mapStateToProps)(StorageMetadata)

