import React from "react"
import { connect } from "react-redux"
import ProjectAsperaDownloads from "../../Customer/aspera_downloads"

const AsperaDownloads = ({ projectID }) =>
  <ProjectAsperaDownloads queries={{ project_id: projectID }}/>

const mapStateToProps = state => ({
  projectID: state.project.model._id
})

export default connect(mapStateToProps)(AsperaDownloads)
