import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Dropzone from "react-dropzone"
import { S3FineUploader } from "../../../api/upload/basic_http"
import { bindActionCreators } from "redux"
import { Button } from "@bitcine/cs-theme"
import { componentFetch } from '../../../helpers/component_fetch'
import DataChart from "./chart"

class BitRate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      status: ""
    }
  }
  componentDidMount() {
    this.checkParse(this.props.job_id)
  }
  getS3Key(file, job_id) {
    this.setState({ status: "PENDING" })
    componentFetch('GET', `admin/jobs/${job_id}/bitrate`, res => {
      this.props.S3FineUploader(file, res.key, "bitrate", job_id)
      //check if parsed file completed
      let count = 0
      this.interval = setInterval(() => {
        if (++count >= 4 || this.state.status !== "PENDING") {
          clearInterval(this.interval)
        }
        this.checkParse(job_id)
      }, 3000)
    }, error => {
      this.setState({ status: "ERROR" })
    })
  }
  checkParse(job_id) {
    componentFetch('GET', `admin/jobs/${job_id}/check/bitrate`, res => {
      if (res.data) {
        this.setState({ data: res.data, status: "READY" })
      }
    }, error => {
      this.setState({ status: "ERROR" })
    })
  }
  render() {
    return (
      <div className='p3 rounded bg-white border-gray-5 border mb3'>
        <div className='flex items-center'>
          <h4 className='flex-auto mb0'>Bitrate Analysis</h4>
          <Dropzone
            accept='.html'
            multiple={false}
            disabled={this.state.status === "PENDING"}
            className='dropzone'
            onDrop={(accepted, rejected) => this.getS3Key(accepted[0], this.props.job_id)}>
            <Button disabled={this.state.status === "PENDING"} className='small white mr2'>
              Upload
            </Button>
          </Dropzone>
        </div>
        {!this.state.status ? (
          <div className='py4 mt2 center'>
            <span className='light material-icons block'>insert_chart</span>
            <strong className='block light'>No Bitrate Analysis Uploaded</strong>
          </div>
        ) : this.state.status === "PENDING" ? (
          <div className='py4 center'>
            <div className='spinner'/>
            <small className='block'>Parsing... This can take a few minutes...</small>
          </div>
        ) : this.state.status === "ERROR" ? (
          <div className='py4 mt2 center'>
            <span className='light material-icons block'>warning</span>
            <strong className='block light'>Error</strong>
            <p>An error occured while parsing this file</p>
          </div>
        ) : (
          <DataChart data={this.state.data}/>
        )}
      </div>
    )
  }
}

BitRate.propTypes = {
  S3FineUploader: PropTypes.func.isRequired,
  job_id: PropTypes.string.isRequired
}

function mapStateToProps(state) {
  return {
    job_id: state.job._id
  }
}

const mapDispatchToProps = dispatch => ({
  S3FineUploader: bindActionCreators(S3FineUploader, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(BitRate)
