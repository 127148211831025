import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import Dropzone from "react-dropzone"
import { uploadCPL } from "../../../api/job"
import { Button } from "@bitcine/cs-theme"

const CPL = ({ qaReport, uploadCPL }) => {
  return (
    <div className='p3 rounded bg-white border-gray-5 border mb3'>
      <div className='flex items-center'>
        <h4 className='flex-auto mb0'>CPL</h4>
        <Dropzone
          accept='.xml'
          multiple={false}
          disabled={qaReport.cpl && qaReport.cpl.status === "PENDING"}
          className='dropzone'
          onDrop={(accepted, rejected) => uploadCPL(accepted[0])}>
          <Button disabled={qaReport.cpl && qaReport.cpl.status === "PENDING"} className='small white mr2'>
            Upload
          </Button>
        </Dropzone>
      </div>
      {!qaReport.cpl ? (
        <div className='py4 mt2 center'>
          <span className='light material-icons block'>class</span>
          <strong className='block light'>No CPL Uploaded</strong>
        </div>
      ) : qaReport.cpl.status === "PENDING" ? (
        <div className='py4 center'>
          <div className='spinner'/>
          <small className='block'>Parsing... This can take a few minutes...</small>
        </div>
      ) : qaReport.cpl.status === "ERROR" ? (
        <div className='py4 mt2 center'>
          <span className='light material-icons block'>warning</span>
          <strong className='block light'>Error</strong>
          <p>An error occured while parsing this file</p>
        </div>
      ) : (
        ["id", "annotation_text", "content_kind", "frame_rate", "issue_date", "total_runtime"].map((item, i) => (
          <div className={i > 0 ? "mt1" : "mt3"} key={i}>
            <label className='capitalize'>{item}</label>
            <input value={qaReport.cpl.list[item]} disabled className='cs-input col-12'/>
          </div>
        ))
      )}
    </div>
  )
}

CPL.propTypes = {
  uploadCPL: PropTypes.func.isRequired,
  qaReport: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    qaReport: state.job.actions.qa_report
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uploadCPL: bindActionCreators(uploadCPL, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CPL)
