import React from "react"
import { connect } from "react-redux"
import OverrideItems from "../../Customer/override_items"

const Items = ({ userID }) =>
  <OverrideItems customerID={userID} customerType={"user"}/>

const mapStateToProps = state => ({
  userID: state.user.model._id
})

export default connect(mapStateToProps)(Items)
