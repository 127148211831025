import React from "react"
import SharedTable from "../Common/table"
import AccessDates from '../Common/access_dates'

const LiveChannels = () => (
  <SharedTable
    collection={"media-live-channels"}
    queries={{}}
    includeSearch={true}
    includeTitle={"AWS Media Live Channels"}
    isCompact={true}
    includeShowDeletedCheckbox={true}
    tableKeys={[
      { text: "Asset Title", key: "asset_title" },
      { text: "ID", key: "channel_id" },
      { text: "Name", key: "channel_name" },
      { text: "Status", key: "status" },
      { text: "Updated", key: "updated_at" },
    ]}
    tableWidths={["auto", 200, 200, 120, 180]}
    tableValues={[
      subscriber => <span className='truncate'>{subscriber.asset_title}</span>,
      subscriber => <span className='truncate'>{subscriber.channel_id}</span>,
      subscriber => <span className='truncate'>{subscriber.channel_name}</span>,
      subscriber => <span className='truncate'>{subscriber.status}</span>,
      subscriber => <AccessDates model={subscriber}/>,
    ]}/>
)

export default LiveChannels
