import { GLOBALS } from "./config"

export const S3_FINE_UPLOADER_DEFAULTS = {
  debug: false,
  signature: {
    endpoint: `${GLOBALS.API_URL}/s3/signature`
  },
  request: {
    endpoint: GLOBALS.S3_ACCELERATE_ENDPOINT,
    accessKey: GLOBALS.S3_ACCESS_KEY
  },
  retry: {
    enableAuto: true
  },
  deleteFile: {
    enabled: true
  },
  cors: {
    expected: true
  },
  iframeSupport: {
    localBlankPagePath: "/blank.html"
  },
  chunking: {
    /**
     * Part Size
     * s3 only accepts chunked transfers with less than 10,000 chunks
     * Since we need to expect a max upload size of 200gb, our chunk size is 20MB
     */

    partSize: 20000000, // in bytes

    enabled: true,
    concurrent: {
      enabled: true
    }
  }
}

export const FILTERS = [
  { label: "Incomplete", value: "" },
  { label: "Pending", value: "Pending" },
  { label: "In Progress", value: "In Progress" },
  { label: "Suspended", value: "Suspended" },
  { label: "Error", value: "Error" }
]

export const SEARCH_TIMER = 1500

const defaults = [
  {
    type: "checkbox",
    label: "Does the color of the picture look correct??",
    key: "is_color_correct"
  },
  {
    type: "checkbox",
    label:
      "If the audio is 3.1 or above, does the dialogue come from the center channel?",
    key: "is_dialogue_from_center"
  },
  {
    type: "checkbox",
    label:
      "If the audio is 3.1 or above, is there signal on all channels as expected?",
    key: "is_signal_on_all_channels"
  },
  {
    type: "checkbox",
    label: "Is the soundtrack a tolerable volume at setting 5.5?",
    key: "is_soundtrack_tolerable"
  },
  {
    type: "checkbox",
    label: "Are the subtitles in sync?",
    key: "is_subtitles_in_sync"
  },
  {
    type: "checkbox",
    label: "Is audio and video in sync?",
    key: "is_audio_and_video_in_sync"
  }
]

const outerDefaults = [
  {
    type: "checkbox",
    label: "Is there a 2-pop or any other leader?",
    key: "is_2_pop_or_leader"
  },
  {
    type: "checkbox",
    label: "If titles are over black, is the black true?",
    key: "is_black_true"
  }
]

export const QA_DEFAULTS = {
  issues: [],
  items: [
    {
      title: "Initial Visual Check",
      description: "",
      key: "initial_visual",
      items: [
        {
          type: "select",
          label: "Container",
          options: ["Flat", "Scope", "Full"],
          key: "container"
        },
        {
          type: "select",
          label: "Resolution",
          options: ["2K", "4K"],
          key: "resolution"
        },
        {
          type: "select",
          label: "Internal Aspect Ratio",
          options: ["None", "1.78", "Other"],
          key: "internal_aspect_ratio"
        },
        {
          type: "select",
          label: "Audio Format",
          options: ["2.0", "3.1", "5.1", "7.1"],
          key: "audio_format"
        },
        {
          type: "checkbox",
          label: "Are Subtitles Present?",
          key: "is_subtitle_present"
        },
        {
          type: "checkbox",
          label: "Is the ContentKind correct?",
          key: "is_contentkind_correct"
        },
        {
          type: "checkbox",
          label: "Is the DCND correct?",
          key: "is_dcnc_correct"
        }
      ]
    },
    {
      title: "Beginning Checklist",
      description: "",
      key: "beginning",
      items: [
        ...outerDefaults,
        {
          type: "input",
          label: "What logos are present before picture?",
          key: "logos_present"
        },
        ...defaults
      ]
    },
    {
      title: "Middle Checklist",
      description: "",
      key: "middle",
      items: [...defaults]
    },
    {
      title: "End Checklist",
      description: "",
      key: "end",
      items: [...outerDefaults, ...defaults]
    }
  ]
}

const endMessage =
  "Please re-supply or reply to this email for more information about the error!"

export const REJECTION_REASONS = {
  validate_source_file: [
    {
      code: "Source file is corrupt",
      requires_timecode: true,
      message: `An error was found while our operators were checking the source file for %%--PROJECT_NAME--%% at %%--TIMECODE--%%. ${endMessage}`
    },
    {
      code: "Audio and video duration do not match",
      requires_timecode: false,
      message: `Our operators have identified that the audio and video duration of the source file for %%--PROJECT_NAME--%% does not match. ${endMessage}`
    },
    {
      code: "Video was exported with the wrong pixel ratio",
      requires_timecode: false,
      message: `Our operators have identified that the source file for %%--PROJECT_NAME--%% was exported with the wrong pixel ratio. ${endMessage}`
    },
    {
      code: "Bad mix - audio is too loud",
      requires_timecode: false,
      message: `Our operators have identified that the audio is too loud in the source file for %%--PROJECT_NAME--%%. ${endMessage}`
    },
    {
      code: "Bad mix - audio is too low",
      requires_timecode: false,
      message: `Our operators have identified that the audio is too low in the source file for %%--PROJECT_NAME--%%. ${endMessage}`
    },
    {
      code: "Frame glitch",
      requires_timecode: true,
      message: `Our operators have identified a frame glitch at %%--TIMECODE--%% of the source file for %%--PROJECT_NAME--%%. ${endMessage}`
    },
    {
      code: "Confirm if missing subtitles is intentional",
      requires_timecode: false,
      message: `Before proceeding with work, our operators need to confirm that the source file for %%--PROJECT_NAME--%% is intentionally missing subtitles. ${endMessage}`
    },
    {
      code: "Custom Error",
      message: ""
    }
  ],
  audio_track: [],
  captions: [],
  subtitles: []
}
