import React from "react"
import Files from "../ProjectFiles"

const SourceFiles = () =>
  <Files
    title={"Source Files"}
    includeBreadcrumb={false}
    collectionOverride={'source-files'}
    queries={{
      model_type: "source_file"
    }}/>

export default SourceFiles
