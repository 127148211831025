import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import auth from './auth/reducer'
import customer from './customer/reducer'
import user from './user/reducer'
import organization from './organization/reducer'
import project from './project/reducer'
import messages from './messages/reducer'
import transfers from './transfers/reducer'
import storage from './storage/reducer'
import utilities from './utilities/reducer'
import files from './files/reducer'
import admin from './admin/reducer'
import job from './job/reducer'
import video from './video/reducer'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth,
  customer,
  user,
  organization,
  messages,
  utilities,
  transfers,
  files,
  storage,
  project,
  job,
  video,
  admin
})

export default createRootReducer
