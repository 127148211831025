import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Modal, Status, Checkbox, Button } from "@bitcine/cs-theme"
import { addMessage } from "../../../api/messages"
import { componentFetch } from "../../../helpers/component_fetch"

const ExplodeDCPModal = ({ file, close, addMessage }) => {
  const [subfolders, setSubfolders] = useState([])
  const [selectedChildren, setSelectedChildren] = useState([])
  const [pending, setPending] = useState(true)
  useEffect(() => {
    componentFetch(
      "GET",
      `admin/digital-cinema-packages/${file._id}/subfolders`,
      null,
      res => {
        setSubfolders(res.subfolders)
        setPending(false)
      }
    )
  }, [file])
  return (
    <Modal
      open={true}
      width={2}
      onClose={close}
      title={`Explode DCP: ${file.name}`}>
      <Status pending={pending}>
        {subfolders.length > 0 ?
          <>
            <div className='mb2 bg-red white bold rounded p2 box-shadow'>Warning: ensure you know what you are doing before using this function.</div>
            <p className='mb2'>Exploding a DCP into its children will create DCPs from each subfolder found directly in the root of this DCP. The root DCP will be removed when all chilren DCPs are created. Note: this does not add or copy storage.</p>
            <Checkbox
              checked={selectedChildren.length === 0}
              label='Explode to All Children'
              onChange={() => selectedChildren.length > 0 ? setSelectedChildren([]) : setSelectedChildren(subfolders)}/>
            <p className='border-top border-gray-4 mt2 pt2'>Or select which children DCPs to create. Note, this will retain the root DCP.</p>
            <div className='mb2'>
              {subfolders.map((subfolder, index) => <div key={index}>
                <Checkbox
                  checked={selectedChildren.includes(subfolder)}
                  disabled={selectedChildren.length === 0}
                  label={subfolder}
                  onChange={() => selectedChildren.includes(subfolder)
                    ? setSelectedChildren(selectedChildren.filter(child => child !== subfolder))
                    : setSelectedChildren([...selectedChildren, subfolder])}/>
              </div>)}
            </div>
            <div className='right-align'>
              <Button
                disabled={pending}
                onClick={() => {
                  setPending(true)
                  componentFetch(
                    "POST",
                    `admin/digital-cinema-packages/${file._id}/explode`,
                    { children: selectedChildren },
                    res => {
                      addMessage(res.message)
                      close()
                    }
                  )
                }}>
                Explode DCP
              </Button>
            </div>
          </> :
          <div className='bg-red white bold rounded p2 box-shadow'>
            No subfolders were found in this DCP. You cannot explode a DCP without subfolders.
          </div>}
      </Status>
    </Modal>
  )
}

ExplodeDCPModal.propTypes = {
  file: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  addMessage: bindActionCreators(addMessage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ExplodeDCPModal)
