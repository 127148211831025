import React from "react"
import { Switch, Redirect, Route } from "react-router-dom"
import Files from './components/files'
import Uploads from './components/uploads'
import SharedFiles from './components/shared_files'
import AsperaDownloads from './components/aspera_downloads'
import FileRequests from './components/file_requests'
import Screeners from './components/screeners'
import Settings from './components/settings'

const Routes = () => 
  <Switch>
    <Redirect exact path='/projects/:projectID' to='/projects/:projectID/files'/>
    <Route path='/projects/:projectID/files' component={Files}/>
    <Route path='/projects/:projectID/uploads' component={Uploads}/>
    <Route path='/projects/:projectID/shared-files' component={SharedFiles}/>
    <Route path='/projects/:projectID/aspera-downloads' component={AsperaDownloads}/>
    <Route path='/projects/:projectID/file-requests' component={FileRequests}/>
    <Route path='/projects/:projectID/screeners' component={Screeners}/>
    <Route path='/projects/:projectID/settings' component={Settings}/>
  </Switch>

export default Routes