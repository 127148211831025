import React from "react"
import PropTypes from "prop-types"
import formatDate from "../../helpers/format_date"
import AccessDates from "../Common/access_dates"
import SharedTable from "../Common/table"

const Jobs = ({ queries }) => (
  <SharedTable
    collection={"jobs"}
    queries={{
      ...queries,
      parent_id: "null"
    }}
    includeSearch={false}
    isCompact={true}
    includeTitle={"Jobs"}
    tableKeys={[{ text: "Title" }, { text: "Status" }, { text: "Updated" }]}
    tableWidths={["auto", 300, 220]}
    tableValues={[
      job => (
        <div>
          {job.title}
          <span className='small block light'>{job.project_title}</span>
        </div>
      ),
      job => (
        <div>
          {job.status}
          <span className='small block light'>Due: {formatDate(job.due_date)}</span>
        </div>
      ),
      job => <AccessDates model={job}/>
    ]}/>
)

Jobs.propTypes = {
  queries: PropTypes.object.isRequired
}

export default Jobs
