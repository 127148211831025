import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from "redux"
import { Modal, Checkbox, Button } from "@bitcine/cs-theme"
import SharedModel from "../Common/model"
import { componentFetch } from "../../helpers/component_fetch"
import { addMessage } from "../../api/messages"
import { GLOBALS } from '../../config'

class Invoice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      invoice: {},
      open_refund: false,
      api_status: ""
    }
  }
  refundInvoice() {
    componentFetch(
      "POST",
      `admin/refundInvoice`,
      {
        invoiceID: this.state.invoice._id,
        mark_jobs_complete: this.state.mark_jobs_complete,
        refundReason: this.state.refund_reason,
        amount: this.state.refund_amount
      },
      res => {
        res.message ? this.setState({ api_status: res.message }) : this.setState({ open_refund: false })
      }
    )
  }
  setModel(model) {
    this.setState({ invoice: model })
  }
  render() {
    const { match } = this.props
    const { invoice } = this.state
    return (
      <div>
        <SharedModel
          modelID={match.params.invoiceID}
          headerKey={"invoice_number"}
          setModel={model => this.setModel(model)}
          collection={"invoices"}
          isEditable={false}
          isDeletable={true}
          isButtonDropdownEnabled={true}
          buttonDropdownContent={[
            {
              text: "View Customer",
              onClick: () =>
                this.props.history.push(
                  `${invoice.organization_id ? "organizations/" + invoice.organization_id : "users/" + invoice.user_id}`
                )
            },
            {
              text: "View Invoice PDF",
              onClick: () => window.open(GLOBALS.API_URL + "/settings/invoices/" + match.params.invoiceID, "_blank")
            },
            {
              text: "Refund Invoice",
              onClick: (event, data, index) => this.setState({ open_refund: true, refund_amount: invoice.amount })
            }
          ]}/>
        <Modal
          open={this.state.open_refund}
          width={2}
          onClose={() => this.setState({ open_refund: false, api_status: "" })}
          title={`Refund Invoice`}>
          <div>
            {this.state.api_status !== "" ? (
              <div className='block red'>Error: {this.state.api_status}</div>
            ) : (
              <div>
                <p className='mb2'>Are you sure you want to refund this invoice?</p>
                <label htmlFor='rr'>Refund Reason</label>
                <input
                  id='rr'
                  className='col-12 mb2 cs-input'
                  placeholder='Refund Reason'
                  value={this.state.refund_reason}
                  onChange={({ target: { value } }) => this.setState({ refund_reason: value })}/>
                <label htmlFor='ra'>Refund Amount</label>
                <input
                  id='ra'
                  className='col-12 mb2 cs-input'
                  placeholder='Amount'
                  value={this.state.refund_amount}
                  onChange={({ target: { value } }) => this.setState({ refund_amount: value })}/>
                <Checkbox
                  label='Mark Related Jobs as Complete'
                  checked={this.state.mark_jobs_complete || false}
                  onChange={() =>
                    this.setState({
                      mark_jobs_complete: !this.state.mark_jobs_complete
                    })
                  }/>
              </div>
            )}
            <Button onClick={() => this.setState({ open_refund: false, api_status: "" })}>Close</Button>
            {this.state.api_status === "" && (
              <Button className='ml2' onClick={() => this.refundInvoice()}>
                Refund
              </Button>
            )}
          </div>
        </Modal>
      </div>
    )
  }
}

Invoice.propTypes = {
  params: PropTypes.object.isRequired,
  addMessage: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  addMessage: bindActionCreators(addMessage, dispatch)
})

export default withRouter(connect(null, mapDispatchToProps)(Invoice))
