import React from "react"
import { withRouter } from 'react-router-dom'
import SharedModel from "../Common/model"

const GiftCard = ({ match }) => (
  <SharedModel
    modelID={match.params.giftCardID}
    headerKey={"code"}
    subheaderKey={"owner_name"}
    collection={"gift-cards"}
    isEditable={true}
    isDeletable={true}/>
)

export default withRouter(GiftCard)
