import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

function Generate({ project, specs, isComplete, jobType }) {
  return (
    <div>
      <div>
        <strong className='mt3'>
          Generate {jobType}
        </strong>
        <p className='mb1'>
          This job requires creating the {jobType} for {project}.
        </p>
        {specs
          ? specs.map((spec, i) =>
            <div
              key={i}
              className={`${i === specs.length - 1
                ? "border-bottom"
                : ""} border-top border-gray-5 py1 flex items-center`}>
              <div className='flex-auto'>
                {spec.property}
              </div>
              <div>
                {spec.value === true
                  ? <span className='material-icons green'>check</span>
                  : spec.value === false
                    ? <span className='material-icons red'>close</span>
                    : `${spec.value}`}
              </div>
            </div>
          )
          : <div className='light'>No specs are available.</div>}
      </div>
      {!isComplete &&
        <small className='mt2 light block right-align'>
          Ensure the {jobType} is created before completing this job.
        </small>}
    </div>
  )
}

Generate.propTypes = {
  project: PropTypes.string.isRequired,
  specs: PropTypes.array.isRequired,
  isComplete: PropTypes.bool.isRequired,
  jobType: PropTypes.string.isRequired
}

function mapStateToProps(state) {
  return {
    project: state.job.details.project_title,
    specs: state.job.actions ? state.job.actions.specifications : null,
    isComplete: state.job.details.status === "Complete"
  }
}

function mapDispatchToProps() {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Generate)
