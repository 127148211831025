import {
  GET_JOB,
  SET_JOB_STATUS,
  TOGGLE_ADD_NOTE,
  ADD_JOB_NOTE,
  UPDATE_NEW_NOTE,
  DELETE_NOTE,
  TOGGLE_VIEW_ALL_NOTES,
  UPDATE_JOB,
  DELETE_JOB,
  TOGGLE_QA_REPORT,
  UPDATE_QA_REPORT,
  TOGGLE_SHOW_ADD_ISSUE,
  UPDATE_ISSUE,
  SAVE_QA_ISSUE,
  SET_QA_ISSUE_RESOLVED,
  SAVE_QA_REPORT,
  UPDATE_ERROR,
  UPLOAD_BITRATE_ANALYSIS,
  UPLOAD_CPL,
  UPDATE_JOB_ACTIONS
} from "../../redux/reducers/job/types"
import { get, post, del } from '../fetch'
import { addMessage } from "../messages"
/**
 *  Get Job
 *
 * @returns dispatch
 */

export const getJob = jobID => dispatch =>
  dispatch(
    get(
      GET_JOB.REQUEST,
      `admin/jobs/${jobID}`
    )
  )

/**
 *  Delete a Job
 *
 * @returns dispatch
 */

export const deleteJob = (jobID, callback) => (dispatch, getState) => dispatch(
  del(
    DELETE_JOB.REQUEST,
    `admin/jobs/${jobID}`,
    {},
    () => {
      dispatch(addMessage("Job Deleted!"))
      callback()
    }
  )
)

/**
 *  Set job comple
 * @param {jobID} -
 * @param {status} - the job status (one of Complete, Pending, Suspended, Issue, In Progress)
 * @returns dispatch
 */

export function setJobStatus(jobID, value) {
  if (value === "Error")
    return (dispatch, getState) => {
      dispatch(updateError("visible", true))
      dispatch(
        updateError(
          "sendToFilmmaker",
          getState().job.details.code.includes("validate")
        )
      )
    }
  else
    return (dispatch, getState) => {
      const { job, transfers } = getState()
      let extraParams = {}
      if (value === "Complete") {
        if (
          job.details.code.includes("ship_") ||
          job.details.code === "create_return_label"
        ) {
          extraParams = { tracking_number: job.actions.tracking_number }
        }
        if (
          job.details.code.includes("copy_dcp") ||
          job.details.code.includes("copy_source")
        ) {
          extraParams = { serial_number: job.actions.serial_number }
        }
        if (job.details.code.includes("rev")) {
          extraParams = { language_code: job.actions.language_code }
        }
        if (
          job.details.code === "kdm_requests" ||
          job.details.code.includes("upload")
        ) {
          if (transfers.list && Array.isArray(transfers.list)) {
            const transfer = transfers.list.find(
              t => t.job.id === jobID && t.type === "upload"
            )
            if (transfer && transfer.file_list) {
              extraParams = {
                file_list: transfer.file_list
              }
            }
          }
        }
      }
      dispatch(
        post(
          SET_JOB_STATUS,
          `admin/jobs/${jobID}/status`,
          { value: value, ...extraParams }
        )
      )
    }
}

/**
 *  New error
 * @param {key} - the key to update
 * @param {value}
 * @returns dispatch
 */

export function updateError(key, value) {
  return {
    type: UPDATE_ERROR,
    key: key,
    value: value
  }
}

export function saveError() {
  return (dispatch, getState) => {
    const { job } = getState()
    const error = job.newError
    const constructedMessage = error.message
      .replace("%%--PROJECT_NAME--%%", job.details.project_title)
      .replace("%%--TIMECODE--%%", error.timecode)
    dispatch(
      post(
        SET_JOB_STATUS,
        `admin/jobs/${job._id}/status`,
        {
          message: constructedMessage,
          code: error.code,
          send_to_filmmaker: error.sendToFilmmaker,
          value: "Error"
        },
        () => dispatch(addMessage(`Error saved ${error.sendToFilmmaker ? "and notice sent" : ""}`))
      )
    )
  }
}

/**
 *  Update job
 *
 * @param {key}
 * @param {value}
 * @returns dispatch
 */

export function updateJob(key, value) {
  return (dispatch, getState) =>
    dispatch(
      post(
        UPDATE_JOB,
        `admin/jobs/${getState().job._id}`,
        {
          key: key,
          value: value
        },
        () => dispatch(addMessage("Job Updated!"))
      )
    )
}

/**
 *  Toggle add note
 *
 * @returns dispatch
 */

export function toggleAddNote() {
  return {
    type: TOGGLE_ADD_NOTE
  }
}

/**
 *  Toggle view all notes
 *
 * @returns dispatch
 */

export function toggleViewAllNotes() {
  return {
    type: TOGGLE_VIEW_ALL_NOTES
  }
}

/**
 * Update the new note
 * @param {value} - the value
 *
 * @returns dispatch
 */

export function updateNewNote(value) {
  return {
    type: UPDATE_NEW_NOTE,
    value: value
  }
}

/**
 *  Add a Job Note
 *
 * @returns dispatch
 */

export function addNewNote() {
  return (dispatch, getState) =>
    dispatch(
      post(
        ADD_JOB_NOTE,
        `admin/jobs/${getState().job._id}/notes`,
        { value: getState().job.notes.newNote.value },
        () => dispatch(addMessage("Note Added!"))
      )
    )
}

/**
 *  Delete a Job Note
 *
 * @returns dispatch
 */

export function deleteNote(noteID) {
  return (dispatch, getState) =>
    dispatch(
      del(
        DELETE_NOTE,
        `admin/jobs/${getState().job._id}/notes/${noteID}`,
        {},
        () => dispatch(addMessage("Note Deleted!"))
      )
    )
}

/**
 *  Toggle QA Visibility
 *
 * @returns dispatch
 */

export function toggleQAReport() {
  return {
    type: TOGGLE_QA_REPORT
  }
}

export function updateQAReport(field, key, value, shouldSave) {
  return dispatch => {
    dispatch({
      type: UPDATE_QA_REPORT,
      value: value,
      field: field,
      key: key
    })

    if (shouldSave) dispatch(saveQAReport())
  }
}

function saveQAReport() {
  return (dispatch, getState) => {
    const { job } = getState()
    dispatch(
      post(
        SAVE_QA_REPORT,
        `admin/jobs/${job._id}`,
        {
          key: "qa_report",
          value: job.actions.qa_report
        }
      )
    )
  }
}

export function toggleShowAddIssue() {
  return {
    type: TOGGLE_SHOW_ADD_ISSUE
  }
}

export function updateNewIssue(key, value) {
  return {
    type: UPDATE_ISSUE,
    key: key,
    value: value
  }
}

export function saveNewIssue() {
  return (dispatch, getState) => {
    const { job, authUser } = getState()
    const newIssue = job.newIssue
    dispatch(
      post(
        SAVE_QA_ISSUE,
        `admin/jobs/${getState().job._id}`,
        {
          key: "qa_report",
          value: {
            ...job.actions.qa_report,
            issues: [
              ...job.actions.qa_report.issues,
              {
                timecode: newIssue.timecode,
                severity: newIssue.severity,
                description: newIssue.description,
                type: newIssue.type,
                added_by: authUser.full_name,
                is_resolved: false
              }
            ]
          }
        },
        () => dispatch(addMessage("Issue Addded!"))
      )
    )
  }
}

export function setQAIssueResolved(index) {
  return (dispatch, getState) => {
    const { authUser } = getState()
    dispatch({
      type: SET_QA_ISSUE_RESOLVED,
      index: index,
      user: authUser.full_name
    })
    dispatch(saveQAReport())
  }
}

export function updateJobActions(key, value) {
  return {
    type: UPDATE_JOB_ACTIONS,
    key,
    value
  }
}

export function uploadBitrateAnalysis(file) {
  const formdata = new FormData()
  formdata.append("media", file)
  return dispatch =>
    dispatch(
      post(
        UPLOAD_BITRATE_ANALYSIS,
        'parseBitrateAnalysis',
        formdata,
        () => dispatch(saveQAReport())
      )
    )
}

export function uploadCPL(file) {
  const formdata = new FormData()
  formdata.append("media", file)
  return dispatch =>
    dispatch(
      post(
        UPLOAD_CPL,
        'parseCPL',
        formdata,
        () => dispatch(saveQAReport()) 
      )
    )
}
