import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from 'react-router-dom'
import { componentFetch } from "../../helpers/component_fetch"
import { addMessage } from "../../api/messages"
import PropTypes from "prop-types"
import { Status, Button } from "@bitcine/cs-theme"

class CountryShippingCost extends React.Component {
  constructor(props) {
    super(props)
    this.getURL = "admin/models/country-shipping-costs/" + props.match.params.bookingRateID
    this.getCurrenciesURL = "admin/models/currencies/"
    this.getTaxURL = "admin/taxes"
    this.state = {
      status: "PENDING",
      currenciesStatus: "PENDING",
      countryShippingCost: {
        country: {}
      },
      currencies: [],
      openConfirmDelete: false
    }
  }
  componentDidMount() {
    this.get()
  }
  get() {
    this.setState({ status: "PENDING" }, () => {
      componentFetch('GET', `${this.getURL}`, null, countryShippingCost => {
        this.setState({ countryShippingCost: countryShippingCost.model, status: "Ready" })
      }, err => this.setState({ status: "ERROR" }))
    })
    this.setState({ currenciesStatus: "PENDING" }, () => {
      componentFetch('GET', `${this.getCurrenciesURL}`, null, currencies => {
        this.setState({ currencies, currenciesStatus: "Ready" })
      }, err => this.setState({ currenciesStatus: "ERROR" }))
    })
  }
  updateCountryShippingCost() {
    const countryShippingCost = this.state.countryShippingCost
    componentFetch(
      "PUT",
      `admin/models/country-shipping-costs/${countryShippingCost._id}`,
      {
        prices: countryShippingCost.prices
      },
      res => (res.message ? this.setState({ api_status: res.message }) : this.setState({ countryShippingCost: res })),
      this.props.addMessage("Updated")
    )
  }
  editCountryShippingCostPrices(currencyCode, value) {
    let countryShippingCost = this.state.countryShippingCost
    let prices = countryShippingCost.prices ? countryShippingCost.prices : {}
    prices[currencyCode] = value
    countryShippingCost.prices = prices
    this.setState({ countryShippingCost: countryShippingCost })
  }
  render() {
    const { countryShippingCost, status, currencies, currenciesStatus } = this.state
    return (
      <Status
        pending={status === "PENDING" || currenciesStatus === "PENDING"}
        error={status === "ERROR" || currenciesStatus === "ERROR"}>
        <div className='flex items-center justify-between'>
          <h3>{countryShippingCost.country.country}</h3>
          {countryShippingCost.organization && <h4>{countryShippingCost.organization.name}</h4>}
        </div>
        <table>
          <tbody>
            {currencies
              .filter(currency => {
                return currency.active
              })
              .map(currency => (
                <tr key={`row-${currency.currency_code}`}>
                  <td>{currency.currency_code}</td>
                  <td>
                    <input
                      style={{ maxWidth: "100px", minWidth: "100px" }}
                      className='inline-block cs-input'
                      value={countryShippingCost.prices ? countryShippingCost.prices[currency.currency_code] : ""}
                      onChange={e => this.editCountryShippingCostPrices(currency.currency_code, e.target.value)}/>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <Button className='mt2' onClick={() => this.updateCountryShippingCost()}>
          Save
        </Button>
      </Status>
    )
  }
}

CountryShippingCost.propTypes = {
  params: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
  addMessage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  currencies: state.utilities.currencies.filter(currency => currency.active)
})

const mapDispatchToProps = dispatch => ({
  addMessage: bindActionCreators(addMessage, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CountryShippingCost))
