import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Facilities from '../../components/SettingsTables/facilities'
import Facility from '../../components/Settings/facility'
import Countries from '../../components/SettingsTables/countries'
import Country from '../../components/Settings/country'
import Languages from '../../components/SettingsTables/languages'
import Language from '../../components/Settings/language'
import StaffRoles from '../../components/SettingsTables/staff_roles'
import StaffRole from '../../components/Settings/staff_role'
import Items from '../../components/SettingsTables/items'
import Item from '../../components/Settings/item'
import TaxRates from '../../components/SettingsTables/tax_rates'
import TaxRate from '../../components/Settings/tax_rate'
import TaxRegions from '../../components/SettingsTables/tax_regions'
import TaxRegion from '../../components/Settings/tax_region'
import Currencies from '../../components/SettingsTables/currencies'
import Currency from '../../components/Settings/currency'
import BookingRates from '../../components/SettingsTables/booking_rates'
import BookingRate from '../../components/Settings/booking_rate'

const Routes = () =>
  <Switch>
    <Route exact path='/settings/facilities' component={Facilities}/>
    <Route path='/settings/facilities/:facilityID' component={Facility}/>
    <Route exact path='/settings/countries' component={Countries}/>
    <Route path='/settings/countries/:countryID' component={Country}/>
    <Route exact path='/settings/languages' component={Languages}/>
    <Route path='/settings/languages/:languageID' component={Language}/>
    <Route exact path='/settings/staff-roles' component={StaffRoles}/>
    <Route path='/settings/staff-roles/:staffRoleID' component={StaffRole}/>
    <Route exact path='/settings/items' component={Items}/>
    <Route path='/settings/items/:itemID' component={Item}/>
    <Route exact path='/settings/currencies' component={Currencies}/>
    <Route path='/settings/currencies/:currencyID' component={Currency}/>
    <Route exact path='/settings/tax-rates' component={TaxRates}/>
    <Route path='/settings/tax-rates/:taxRateID' component={TaxRate}/>
    <Route exact path='/settings/tax-regions' component={TaxRegions}/>
    <Route path='/settings/tax-regions/:taxRegionID' component={TaxRegion}/>
    <Route exact path='/settings/booking-rates' component={BookingRates}/>
    <Route path='/settings/booking-rates/:bookingRateID' component={BookingRate}/>
  </Switch>

export default Routes