import React, { useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { Status } from '@bitcine/cs-theme'
import SubNav from '../SubNav'
import Routes from './routes'
import { getOrganization } from "../../api/organization"

const Organization = ({ organization, isFetched, getOrganization, match, history }) => {
  const organizationID = match.params.organizationID
  useEffect(() => {
    getOrganization(organizationID)
  }, [getOrganization, organizationID])
  return (
    <Status pending={!isFetched}>
      {isFetched &&
        <>
          <div style={{ margin: '-1em' }}>
            <SubNav 
              subtitle={organization.owner_name}
              title={organization.name}
              onBackButtonClick={() => history.push('/organizations')}
              links={[
                { name: "General", to: "general", show: true },
                { name: "Plan", to: "plan", show: true },
                { name: "Team", to: "team", show: true },
                { name: "Projects", to: "projects", show: organization.plan.can_access_files || !organization.is_enterprise },
                { name: "Requests", to: "requests", show: organization.plan.can_access_festivals },
                { name: "Videos", to: "videos", show: organization.plan.can_access_ondemand },
                { name: "Entitlements", to: "aspera-entitlements", show: organization.plan.can_access_files || organization.plan.can_access_festivals || !organization.is_enterprise },
                { name: "Aspera Downloads", to: "aspera-downloads", show: false },
                { name: "Items", to: "items", show: organization.plan.can_access_files },
                { name: "Invoices", to: "invoices", show: true },
                { name: "Payment", to: "payment", show: false },
                { name: "Jobs", to: "jobs", show: organization.plan.can_access_files || organization.plan.can_access_bookings },
                { name: "Bookings", to: "bookings", show: organization.plan.can_access_bookings },
                { name: "Booking Prices", to: "booking-prices", show: organization.plan.can_access_bookings },
                { name: "Storage", to: "storage", show: true }
              ].filter(opt => opt.show).map(opt => ({
                text: opt.name,
                to: `/organizations/${organization._id}/${opt.to}`
              }))}/>
          </div>
          <div className='box-shadow border-radius bg-white p2 mt3'>
            <Routes/>
          </div>
        </>}
    </Status>
  )
}

const mapStateToProps = state => ({
  organization: state.organization.model,
  isFetched: state.organization.status === 'READY'
})

const mapDispatchToProps = dispatch => ({
  getOrganization: bindActionCreators(getOrganization, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Organization))
