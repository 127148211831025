import React from "react"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { ButtonDropdown } from "@bitcine/cs-theme"
import SharedTable from "../Common/table"
import Icon from "./icon"
import StorageModal from "./modals/storage"
import DownloadToBox from "./modals/download_to_box"
import ExplodeDCPModal from './modals/explode_dcp'
import File from "./modals/file"
import TransferModal from "./modals/transfer_file"
import UploadModal from '../Modals/upload'
import formatDate from "../../helpers/format_date"
import humanFileSize from "../../helpers/human_file_size"
import { freezeFile, defrostFile, freezeProject, defrostProject } from "../../api/files"
import { initializeAsperaDownload } from '../../api/download/aspera'
import Aspera from "../../media/aspera.svg"

class Files extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      transfer_modal_file: null,
      storage_modal_file: null,
      download_to_box_file: null,
      file_to_view: null,
      is_dcp_upload: null
    }
  }
  getDropdownContent(file) {
    return [
      {
        text: "View Project",
        icon: "movie",
        onClick: () => this.props.history.push(`/projects/${file.project_id}`),
        show: file.project_id && !this.props.includeBreadcrumb
      },
      {
        text: "View Request",
        icon: "movie",
        onClick: () => this.props.history.push(`/requests/${file.cinerequest_id}`),
        show: file.cinerequest_id && !this.props.includeBreadcrumb
      },
      {
        text: "Transfer",
        icon: "compare_arrows",
        onClick: () => this.setState({ transfer_modal_file: file }),
        show: !!file.project_id
      },
      {
        text: "Download to Media Manager",
        icon: "cloud_download",
        onClick: () => this.setState({ download_to_box_file: file }),
        show: file.type === "dcp" || (file.type === "folder" && file.is_valid_dcp)
      },
      {
        text: "Download with Aspera",
        icon: (
          <img
            src={Aspera}
            alt='Aspera'
            style={{ width: "21px" }}
            />
        ),
        onClick: () => this.props.initializeAsperaDownload(file),
        show: file.type === 'folder'
      },
      {
        text: "View File Info",
        icon: "info",
        onClick: () => this.setState({ file_to_view: file }),
        show: file.type !== "folder"
      },
      {
        text: "View Cloud Storage",
        icon: "storage",
        onClick: () => this.setState({ storage_modal_file: file }),
        show: file.type !== "folder"
      },
      {
        text: "Explode DCP",
        icon: "read_more",
        onClick: () => this.setState({ explode_dcp: file }),
        show: file.type === "dcp"
      },
      {
        text: "Defrost File",
        icon: "local_fire_department",
        show: file.frozen_at && file.type !== "folder" && !file.started_defrosting_at,
        children: [
          {
            text: (
              <span>
                Defrost <span className='small ml1'>(Standard 6-8hrs | $0.0025/GB)</span>
              </span>
            ),
            onClick: () => {
              if (window.confirm("Are you sure?")) {
                this.props.defrostFile(file._id, 0)
              }
            }
          },
          {
            text: (
              <span>
                Defrost <span className='small ml1'>(Expedited 5-20min | $0.03/GB)</span>
              </span>
            ),
            onClick: () => {
              if (window.confirm("Are you sure?")) {
                this.props.defrostFile(file._id, 1)
              }
            }
          }
        ]
      },
      {
        text: "Freeze File",
        icon: "ac_unit",
        onClick: () => {
          if (window.confirm("Are you sure?")) {
            this.props.freezeFile(file._id)
          }
        },
        show: !file.frozen_at && file.type !== "folder" && !file.started_defrosting_at
      }
    ].filter(opt => opt.show)
  }
  render() {
    const { queries, includeUploadButton, includeStorageTransitionButton, includeExpiryDate, collectionOverride } = this.props
    return (
      <React.Fragment>
        <SharedTable
          collection={"project-files"}
          queries={queries}
          includeBreadcrumb={this.props.includeBreadcrumb}
          includeTitle={this.props.title}
          includeSearch={true}
          includeShowDeletedCheckbox={true}
          includeComponent={includeUploadButton || includeStorageTransitionButton}
          component={<>
            {includeUploadButton && <ButtonDropdown
              button={{ text: "Upload", className: "ml2" }}
              dropdown={{
                clickCloses: true,
                content: [
                  {
                    text: "Upload File",
                    icon: 'insert_drive_file',
                    onClick: () =>this.setState({ show_upload_modal: true, is_dcp_upload: false })
                  },
                  {
                    text: "Upload DCP",
                    icon: 'movie',
                    onClick: () =>this.setState({ show_upload_modal: true, is_dcp_upload: true })
                  }
                ]
              }}/>}
            {includeStorageTransitionButton && <ButtonDropdown
              button={{
                className: "material-icons link mx1",
                text: "more_vert",
                menu: true,
              }}
              dropdown={{
                clickCloses: true,
                content: [
                  {
                    text: {
                      title: "Freeze Project",
                      description: "Will freeze all files in project that are not already frozen."
                    },
                    icon: 'ac_unit',
                    onClick: () => window.confirm("Are you sure you want to freeze this project?") && this.props.freezeProject(queries.project_id)
                  },
                  {
                    text: {
                      title: "Defrost Project",
                      description: "Will defrost all files in project that are frozen."
                    },
                    icon: 'local_fire_department',
                    onClick: () => window.confirm("Are you sure you want to defrost this project?") && this.props.defrostProject(queries.project_id)
                  }
                ]
              }}/>}
          </>}
          sortKey={"created_at"}
          sortDirection={"DESC"}
          isCompact={true}
          overrideOnClick={collectionOverride ?
            file => this.props.history.push(`/${collectionOverride}/${file._id}`) :
            file => this.setState({ storage_modal_file: file })
          }
          tableKeys={[
            { text: "" },
            { text: "File", key: "name" },
            { text: "Kind" },
            { text: "Size", key: "size" },
            { text: "Created", key: "created_at" },
            { text: "Last Accessed", key: "last_accessed_at" },
            ...includeExpiryDate ? [{ text: "Expires At", key: "expires_at" }] : [],
            { text: "" }
          ]}
          tableWidths={[45, "auto", 140, 100, 180, 180, ...includeExpiryDate ? [180] : [], 65]}
          tableValues={[
            data => <Icon data={data}/>,
            data => (
              <span className='truncate'>
                <b>{data.name || data.file_name || data.title}</b>
                <small className='block light ml1' style={{ lineHeight: "10px" }}>
                  {data.frozen_at && ` Frozen ${formatDate(data.frozen_at)}`}
                  {data.started_freezing_at && ` Started Freezing ${formatDate(data.started_freezing_at)}`}
                  {data.started_defrosting_at && ` / Started Defrosting ${formatDate(data.started_defrosting_at)}`}
                </small>
              </span>
            ),
            data => data.kind,
            data => humanFileSize(data.size, true),
            data => formatDate(data.created_at),
            data => formatDate(data.last_accessed_at),
            ...includeExpiryDate ? [data => formatDate(data.expires_at)] : [],
            data => (
              <ButtonDropdown
                button={{
                  className: "material-icons link",
                  text: "more_vert",
                  menu: true
                }}
                dropdown={{
                  clickCloses: true,
                  content: this.getDropdownContent(data)
                }}/>
            )
          ]}/>
        {!!this.state.transfer_modal_file && (
          <TransferModal
            close={() => this.setState({ transfer_modal_file: null })}
            file={this.state.transfer_modal_file}/>
        )}
        {!!this.state.storage_modal_file && (
          <StorageModal
            close={() => this.setState({ storage_modal_file: null })}
            file={this.state.storage_modal_file}/>
        )}
        {!!this.state.download_to_box_file && (
          <DownloadToBox
            close={() => this.setState({ download_to_box_file: null })}
            file={this.state.download_to_box_file}/>
        )}
        {!!this.state.explode_dcp && (
          <ExplodeDCPModal
            close={() => this.setState({ explode_dcp: null })}
            file={this.state.explode_dcp}/>
        )}
        {!!this.state.show_upload_modal && (
          <UploadModal
            isDCP={this.state.is_dcp_upload}
            close={() => this.setState({ show_upload_modal: false })}
            queries={queries}/>
        )}
        {!!this.state.file_to_view && (
          <File
            close={() => this.setState({ file_to_view: null })}
            modelID={this.state.file_to_view._id}
            collection={this.state.file_to_view.collection}
            file={this.state.download_to_box_file}/>
        )}
      </React.Fragment>
    )
  }
}

Files.defaultProps = {
  collectionOverride: null,
  includeStorageTransitionButton: false,
  includeExpiryDate: false
}

Files.propTypes = {
  queries: PropTypes.object.isRequired,
  includeBreadcrumb: PropTypes.bool.isRequired,
  title: PropTypes.string,
  freezeFile: PropTypes.func.isRequired,
  defrostFile: PropTypes.func.isRequired,
  collectionOverride: PropTypes.string,
  initializeAsperaDownload: PropTypes.func.isRequired,
  includeStorageTransitionButton: PropTypes.bool.isRequired,
  freezeProject: PropTypes.func.isRequired,
  defrostProject: PropTypes.func.isRequired,
  includeExpiryDate: PropTypes.bool.isRequired
}

const mapDispatchToProps = dispatch => ({
  freezeFile: bindActionCreators(freezeFile, dispatch),
  defrostFile: bindActionCreators(defrostFile, dispatch),
  initializeAsperaDownload: bindActionCreators(initializeAsperaDownload, dispatch),
  freezeProject: bindActionCreators(freezeProject, dispatch),
  defrostProject: bindActionCreators(defrostProject, dispatch)
})

export default withRouter(connect(null, mapDispatchToProps)(Files))
