import { S3_FINE_UPLOADER_DEFAULTS } from "../../constants"
import uuidv4 from "uuid/v4"

/**
 * Parses
 *
 * @param {Object} file
 * @param {Object} specifications
 *
 * @returns {Object}
 */

export const parseData = (file, specifications, method = "HTTP") => ({
  isDirectory: isDirectory(file),
  size: calculateFileSize(file),
  name: parseFileName(file),
  path: parseFilePath(file),
  uuid: uuidv4(),
  opts: {
    method,
    file_list: [
      {
        isDirectory: isDirectory(file),
        name: parseFileName(file),
        path: parseFilePath(file),
        size: calculateFileSize(file)
      }
    ],
    specifications
  }
})

/**
 * Parses the correct file path
 *
 * @param {Object} file
 *
 * @returns {String} path
 */

const parseFilePath = file => (isDirectory(file) ? "" : file.preview)

/**
 * Validates if the transfer is a directory or file
 *
 * @param {Object} file
 *
 * @returns {Bool} directory
 */

const isDirectory = file => file.length > 0

/**
 * Calculates the size of a transfer
 * Note: Transfers can have one file or be a directory with many files
 *
 * @param {Object} file
 *
 * @returns {Int} total size
 */

const calculateFileSize = file =>
  parseFloat(isDirectory(file) ? file.reduce((totalSize, file) => totalSize + file.size, 0) : file.size)

/**
 * Parses the file name
 * Note: Transfers that are directories need the pathname added to the file name
 *
 * @param {Object} file
 *
 * @returns {String} file name
 */

const parseFileName = file => (isDirectory(file) ? file[0].webkitRelativePath.split("/")[0] : file.name)

/**
 * Creates a transfer object to add to state
 *
 * @param {Object} uploadDetails - returned from the server
 * @param {Int} size
 * @param {Bool} isDirectory
 * @param {String} uuid
 *
 * @returns {Object} transfer
 */

export const createTransfer = (uploadDetails, uuid, callback = null) => ({
  upload_id: uploadDetails._id,
  name: uploadDetails.file_name,
  total_size: uploadDetails.total_size,
  current_size: 0,
  progress: "0%",
  status: "Preparing",
  isParent: true,
  method: uploadDetails.upload_method.toLowerCase(),
  type: "upload",
  uuid,
  transfer_iteration_token: 0,
  childrenTransfers: {},
  callback
})

/**
 * Get File Data
 *
 * @param {Object} file
 * @param {String} parentUUID - the transfers partent id
 * @param {Object} endpointDetails
 * @param {Object} specifications
 * @param {Function} callback
 *
 * @returns {Object}
 */

export const getFileData = (file, isPartOfDirectory, uuid, endpointDetails, specifications = {}) => {
  const fileUUID = uuidv4()
  const endpoint = `${S3_FINE_UPLOADER_DEFAULTS.request.endpoint}`

  let destination = endpointDetails.upload_details.destination
  if (isPartOfDirectory) {
    destination = destination + file.webkitRelativePath
  }

  file.parentUUID = uuid
  file.transferUUID = fileUUID
  file.is_part_of_directory = isPartOfDirectory
  file.upload_details = endpointDetails.upload_details
  file.destination = destination

  return { parsedFile: file, fileUUID, endpoint }
}
