import React from "react"
import { Switch, Redirect, Route } from "react-router-dom"
import AsperaDownloads from './components/aspera_downloads'
import AsperaEntitlements from './components/aspera_entitlements'
import Bookings from './components/bookings'
import BookingPrices from './components/booking_prices'
import Requests from './components/requests'
import General from './components/general'
import Invoices from './components/invoices'
import Items from './components/items'
import Jobs from './components/jobs'
import Payment from './components/payment'
import Plan from './components/plan'
import Projects from './components/projects'
import Team from './components/team'
import Videos from './components/videos'
import Storage from './components/storage_metadata'

const Routes = () => 
  <Switch>
    <Redirect exact path='/organizations/:organizationID' to='/organizations/:organizationID/general'/>
    <Route path='/organizations/:organizationID/aspera-downloads' component={AsperaDownloads}/>
    <Route path='/organizations/:organizationID/aspera-entitlements' component={AsperaEntitlements}/>
    <Route path='/organizations/:organizationID/bookings' component={Bookings}/>
    <Route path='/organizations/:organizationID/booking-prices' component={BookingPrices}/>
    <Route path='/organizations/:organizationID/requests' component={Requests}/>
    <Route path='/organizations/:organizationID/general' component={General}/>
    <Route path='/organizations/:organizationID/invoices' component={Invoices}/>
    <Route path='/organizations/:organizationID/items' component={Items}/>
    <Route path='/organizations/:organizationID/jobs' component={Jobs}/>
    <Route path='/organizations/:organizationID/payment' component={Payment}/>
    <Route path='/organizations/:organizationID/plan' component={Plan}/>
    <Route path='/organizations/:organizationID/projects' component={Projects}/>
    <Route path='/organizations/:organizationID/team' component={Team}/>
    <Route path='/organizations/:organizationID/videos' component={Videos}/>
    <Route path='/organizations/:organizationID/storage' component={Storage}/>
  </Switch>

export default Routes