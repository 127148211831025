import React from "react"
import PropTypes from "prop-types"
import SharedTable from "../Common/table"
import formatDate from "../../helpers/format_date"

const Invoices = ({ queries }) =>
  <SharedTable
    collection={"invoices"}
    queries={queries}
    includeSearch={false}
    includeTitle={"Invoices"}
    isCompact={true}
    tableKeys={[
      { text: "Invoice Number" },
      { text: "Description" },
      { text: "Total" },
      { text: "Created At" }
    ]}
    tableWidths={[200, "auto", 180, 220]}
    tableValues={[
      invoice =>
        <span>
          {invoice.invoice_number} {invoice.is_refunded ? ` (Refunded)` : ``}
        </span>,
      invoice => invoice.description,
      invoice => invoice.total_formatted,
      invoice => formatDate(invoice.created_at)
    ]}/>

Invoices.propTypes = {
  queries: PropTypes.object.isRequired
}

export default Invoices
