import React from "react"
import { withRouter } from "react-router-dom"
import SharedModel from "../Common/model"

const DigitalCinemaPackage = ({ match }) =>
  <SharedModel
    modelID={match.params.dcpID}
    headerKey={"name"}
    collection={"digital-cinema-packages"}
    includeStorage={true}
    isEditable={true}
    isDeletable={true}/>

export default withRouter(DigitalCinemaPackage)
