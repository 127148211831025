import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import Select from "react-select"
import { Status, Modal, Button } from "@bitcine/cs-theme"
import { addMessage } from "../../api/messages"
import { componentFetch } from "../../helpers/component_fetch"

class OrderStatusModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: props.order.status,
      status_list: [],
      updating: false
    }
  }
  componentDidMount() {
    componentFetch(
      'GET',
      `distributors/order-statuses`,
      null,
      res => this.setState({ status_list: res })
    )
  }
  updateOrder() {
    this.setState(
      { updating: true },
      componentFetch(
        "POST",
        `distributors/content-orders/${this.props.order._id}`,
        {
          status: this.state.status
        },
        res => {
          if (res.exception) {
            this.props.addMessage(res.message)
            this.setState({ updating: false })
          } else {
            this.props.addMessage("Successfully updated status!")
            this.props.close()
            //this.props.setModel(res)
          }
        }
      )
    )
  }
  render() {
    const { updating, status_list, status } = this.state
    return (
      <Modal open={true} width={3} onClose={() => this.props.close()} title={"Update Order Status"}>
        <Status pending={status_list.length === 0 || updating}>
          <b className='mb2'>Updating the order status here sends an update to the distributor.</b>
          <Select options={status_list} onChange={({ value }) => this.setState({ status: value })} value={this.state.status_list.find(opt => opt === status)}/>
          <div className='mt2 right-align'>
            <Button disabled={updating} onClick={() => this.updateOrder()}>
              Update Status
            </Button>
          </div>
        </Status>
      </Modal>
    )
  }
}

OrderStatusModal.propTypes = {
  close: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  addMessage: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
  addMessage: bindActionCreators(addMessage, dispatch)
})

export default connect(null, mapDispatchToProps)(OrderStatusModal)
