import React from "react"
import { withRouter } from "react-router-dom"
import SharedModel from "../Common/model"
import SharedTable from "../Common/table"
import AccessDates from "../Common/access_dates"
import formatDate from "../../helpers/format_date"

const ContentOrder = ({ match }) => (
  <div>
    <SharedModel
      modelID={match.params.contentOrderID}
      headerKey={"title"}
      subheaderKey={"order_id"}
      collection={"content-orders"}
      isEditable={false}
      isDeletable={false}/>
    <SharedTable
      collection={"content-order-transactions"}
      queries={{ content_order_id: match.params.contentOrderID }}
      isCompact={true}
      tableKeys={[
        { text: "Content Order ID" },
        { text: "Type" },
        { text: "Compositions" },
        { text: "Destination" },
        { text: "Delivery" },
        { text: "Last Updated" }
      ]}
      disableRowClick={true}
      tableWidths={[120, 120, 120, "auto", 180, 180]}
      tableValues={[
        ({ data }) => data.contentOrderId,
        ({ data }) => data.transactionType,
        ({ data }) => data.numberOfCompositions,
        ({ data }) =>
          data && (
            <div>
              {data.deliveryAddress.siteName}
              {data.deliveryAddress.circuitName && <small className='ml1'>({data.deliveryAddress.circuitName})</small>}
              <span className='block light small'>
                {data.deliveryAddress.city}, {data.deliveryAddress.territory}
              </span>
            </div>
          ),
        ({ data }) => (
          <div>
            <b>Delivery: {data ? formatDate(data.deliveryDate, false) : "N/A"}</b>
            <div className='block small light'>Start: {data ? formatDate(data.startDate, false) : "N/A"}</div>
          </div>
        ),
        data => <AccessDates model={data}/>
      ]}/>
  </div>
)

export default withRouter(ContentOrder)
