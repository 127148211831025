import React from "react"
import formatDate from "../../helpers/format_date"
import SharedTable from "../Common/table"

const FileRequests = () =>
  <SharedTable
    collection={"file-requests"}
    queries={{}}
    includeSearch={true}
    disableRowClick={true}
    isCompact={true}
    includeTitle={"File Requests"}
    tableKeys={[
      { text: "Project" },
      { text: "Request Name" },
      { text: "Requester" },
      { text: "Status" },
      { text: "Created", key: "created_at" }
    ]}
    tableWidths={["auto", 220, 220, 100, 180]}
    tableValues={[
      fileRequest => fileRequest.project_title,
      fileRequest => fileRequest.title,
      fileRequest => fileRequest.requestee_name,
      fileRequest => <span className='capitalize'>{fileRequest.status}</span>,
      fileRequest => formatDate(fileRequest.created_at)
    ]}/>

export default FileRequests
