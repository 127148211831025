import React from "react"
import { withRouter } from "react-router-dom"
import SharedModel from "../Common/model"

const CinemaScreen = ({ match }) => (
  <SharedModel
    modelID={match.params.cinemaScreenID}
    headerKey={"cinema_name"}
    subheaderKey={"screen_id"}
    collection={"cinema-screens"}
    isEditable={true}
    isDeletable={true}/>
)

export default withRouter(CinemaScreen)
