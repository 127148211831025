import React, { useState, useEffect } from "react"
import { Status } from '@bitcine/cs-theme'
import RGL, { WidthProvider } from "react-grid-layout"
import { get } from '../../helpers/component_fetch'
import Users from './users'
import Projects from './projects'
import Jobs from './jobs'
import Orders from './orders'
import RecentUploads from './recent_uploads'
import OnDemandSessions from './ondemand_sessions'
import OnDemandActiveSessions from './ondemand_active_sessions'
import OnDemandVideos from './ondemand_videos'
import OnDemandPublishingSchedule from './ondemand_publishing_schedule'
import RecentEncodes from './recent_encodes'
import RecentLogins from './recent_logins'

const ReactGridLayout = WidthProvider(RGL)
const DASHBOARD_COOKIE = 'dashboard-layout'

const Dashboard = () => {

  const [kpi, setKPI] = useState(null)
  const [layout, setLayout] = useState(getDefaultLayout())
  useEffect(() => {
    get('admin/kpi', res => {
      setKPI(res)
    })
  }, [])

  const onLayoutChange = layout => {
    global.localStorage.setItem(DASHBOARD_COOKIE, JSON.stringify(layout))
    setLayout(layout)
  }

  const props = {
    className: "layout",
    cols: 12,
    rowHeight: 45,
    onLayoutChange: function() {}
  }

  return (
    <Status pending={!kpi}>
      {kpi &&
        <div className='pb4' style={{ maxWidth: '1400px' }}>
          <ReactGridLayout
            {...props}
            layout={layout}
            onLayoutChange={onLayoutChange}>
            {[
              <OnDemandActiveSessions data={kpi.ondemand_active_sessions}/>,
              <OnDemandSessions data={kpi.ondemand_sessions}/>,
              <OnDemandPublishingSchedule data={kpi.ondemand_publishing_schedule}/>,
              <OnDemandVideos data={kpi.ondemand_videos}/>,
              <Users data={kpi.users}/>,
              <Projects data={kpi.projects}/>,
              <Jobs data={kpi.jobs}/>,
              <Orders data={kpi.orders}/>,
              <RecentLogins data={kpi.recent_logins}/>,
              <RecentEncodes data={kpi.recent_encodes}/>,
              <RecentUploads data={kpi.recent_uploads}/>
            ].map((chart, i) =>
              <div key={i + 1} className='rounded p2 mr1 mb1 bg-white box-shadow'>{chart}</div>
            )}
          </ReactGridLayout>
        </div>
      }
    </Status>
  )
}

const defaultLayout = [{"w":5,"h":10,"x":0,"y":0,"i":"1","moved":false,"static":false},{"w":7,"h":10,"x":5,"y":0,"i":"2","moved":false,"static":false},{"w":8,"h":6,"x":4,"y":22,"i":"3","moved":false,"static":false},{"w":4,"h":6,"x":0,"y":22,"i":"4","moved":false,"static":false},{"w":2,"h":5,"x":0,"y":17,"i":"5","moved":false,"static":false},{"w":2,"h":5,"x":0,"y":28,"i":"6","moved":false,"static":false},{"w":2,"h":7,"x":10,"y":10,"i":"7","moved":false,"static":false},{"w":10,"h":7,"x":0,"y":10,"i":"8","moved":false,"static":false},{"w":10,"h":5,"x":2,"y":17,"i":"9","moved":false,"static":false},{"w":12,"h":7,"x":0,"y":33,"i":"10","moved":false,"static":false},{"w":10,"h":5,"x":2,"y":28,"i":"11","moved":false,"static":false}]

const getDefaultLayout = () => {
  let layout = defaultLayout
  try {
    const cookie = global.localStorage.getItem(DASHBOARD_COOKIE)
    if (cookie) {
      layout = JSON.parse(cookie)
    }
  } catch (e) {
    /*Ignore*/
  }
  return layout
}

export default Dashboard
