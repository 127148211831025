import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import humanFileSize from "../../../helpers/human_file_size"
import { recalculateStorage } from "../../../api/customer"

const Header = ({ user, recalculateStorage }) =>
  <>
    {user && <div className='flex mb1'>
      {!user.organization_id && (
        <div className='flex items-center border border-gray-5 px2 py1 small'>
          <div>
            <div className='flex items-center justify-between'>
              <span className='mr2'>Subscription:</span>
              <strong>{user.subscription.name}</strong>
            </div>
            <div className='flex items-center justify-between mt1'>
              <span className='mr2'>Total Spent:</span>
              <strong>{user.total_spent || "0"}</strong>
            </div>
            <div className='flex items-center justify-between mt1'>
              <span className='mr2'>Files:</span>
              <div className='flex items-center'>
                <strong className={`${user.subscription.storage.used > user.subscription.storage.total ? "red" : ""}`}>
                  {humanFileSize(user.subscription.storage.used)} used of {humanFileSize(user.subscription.storage.total)}
                </strong>
                <span
                  style={{ fontSize: "12px" }}
                  className='material-icons pointer gray ml1'
                  onClick={() => {
                    if (window.confirm("Do you want to recalculate Files storage?")) {
                      recalculateStorage(user._id)
                    }
                  }}>
                  refresh
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>}
  </>

const mapDispatchToProps = dispatch => ({
  recalculateStorage: bindActionCreators(recalculateStorage, dispatch)
})

export default connect(null, mapDispatchToProps)(Header)
