import React from "react"
import { withRouter } from 'react-router-dom'
import SharedModel from "../Common/model"

const Item = ({ match }) => (
  <SharedModel
    modelID={match.params.itemID}
    headerKey={"name"}
    collection={"items"}
    isEditable={true}
    isDeletable={true}/>
)

export default withRouter(Item)
