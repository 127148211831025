import React from 'react'
import styled from "styled-components"
import { NavLink, withRouter } from "react-router-dom"
import { Button } from '@bitcine/cs-theme'
import { FaAngleRight } from "react-icons/fa"

const Container = styled.div`
  background: #fff;
  box-shadow: 0 1px 8px 0 rgba(146,153,159,0.21);
  border-bottom: 2px solid white;
  min-height: 51px;
`

const Back = styled.button`
  height: 100%;
  cursor: pointer;
  padding: 0 8px;
  color: #888;
  text-align: center;
  border: none;
  outline: none;
  background: none;
  &:hover {
    color: #000;
  }
`

const Title = styled.div`
  line-height: 1;
  padding: 0 10px;
  margin-top: 4px;
  margin-right: 12px;

  small {
    display: inline-block;
  }

  span {
    margin: 0;
    line-height: 0;
    font-size: 1.1em;
    font-weight: bold;
  }

  max-width: 380px;
`

const Tab = styled.span`
  margin-left: ${props => props.compact ? '12px' : '24px'};
  margin-right: ${props => props.compact ? '12px' : '24px'};
  font-size: ${props => props.compact ? '12px' : '14px'};
`

const SubNav = ({ subtitle, title, onBackButtonClick, links, compact = true, includeButton = null, history }) =>
  <Container className='flex items-center justify-between py1'>
    <div className='flex items-center'>
      {typeof onBackButtonClick === 'function' && <Back onClick={onBackButtonClick}>
        <i className="material-icons">keyboard_arrow_left</i>
      </Back>}
      {title && <Title className='truncate'>
        <span>{title}</span>
        {subtitle && <><br/><small>{subtitle}</small></>}
      </Title>}
      <div>
      {links.map(link =>
        <NavLink
          key={link.to}
          to={link.to}
          className='border-bottom border-white pt2'
          style={{ borderWidth: "4px", paddingBottom: "15px" }}
          activeStyle={{ borderColor: "#FB0F3B", backgroundColor: "#eee" }}
          activeClassName='bold'>
          <Tab compact={compact} className='capitalize'>{link.text}</Tab>
        </NavLink>
      )}
      </div>
    </div>
    {includeButton &&
      <Button className='mr2' small white onClick={() => history.push(includeButton.to)}>
        <div className='flex items-center'><span style={{ marginTop: '1px'}}>{includeButton.label}</span><FaAngleRight size='1.2em'/></div>
      </Button>}
  </Container>

export default withRouter(SubNav)