const OnDemandVideos = ({ data }) =>
  <div>
    <p className='center muted bold'>Top Videos</p>
    <div className='flex items-center small justify-between border-bottom border-gray-5 mt2 pb1'>
      <span>Title</span><span>Watched Count</span>
    </div>
    {data.map(video =>
      <div key={video.title} className='flex items-center border-bottom border-gray-5 justify-between'>
        <div className='truncate'>
          {video.title} <small className='muted'>({video.organization_name})</small>
        </div>
        <div>
          {video.watched_count} 
        </div>
      </div>
    )}
  </div>

export default OnDemandVideos
