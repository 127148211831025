import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Table, Modal, Button } from "@bitcine/cs-theme"
import { componentFetch } from "../../../helpers/component_fetch"
import CommonDropdown from "../../Common/dropdown"

class Bookings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmation: false,
      countriesStatus: "PENDING",
      new_country: {}
    }
  }
  addNewOverridePrice() {
    componentFetch(
      "POST",
      `admin/models/country-shipping-costs`,
      {
        country_id: this.state.new_country._id,
        country_code: this.state.new_country.country_code,
        organization_id: this.props.organization._id
      },
      res => this.props.history.push(`/country-shipping-costs/${res._id}`)
    )
  }
  render() {
    let list = this.props.organization.country_shipping_costs
    return (
      <div>
        <Table
          className='my3'
          widths={[200, "auto"]}
          header={{
            columns: [{ text: "Country" }, { text: "Prices" }]
          }}
          body={{
            data: list,
            row: {
              compact: true,
              onClick: (event, data, index) => this.props.history.push(`/country-shipping-costs/${data._id}`),
              render: [data => data.country_code, data => JSON.stringify(data.prices)]
            }
          }}/>
        <div className='flex items-center mr2'>
          <Button className='ml3 mt3' onClick={() => this.setState({ show_add_modal: true })}>
            Add Override Shipping Price
          </Button>
        </div>
        {this.state.show_add_modal && this.renderAddCountryShippingCostModal()}
      </div>
    )
  }
  renderAddCountryShippingCostModal() {
    return (
      <Modal
        open={true}
        width={2}
        onClose={() => this.setState({ show_add_modal: false })}
        title={`Add Override Country Shipping Price`}>
        <div>
          <CommonDropdown
            containerClassName='col-12'
            options='countries'
            label='Select which country price you would like to override'
            value={this.state.new_country.value}
            onChange={value => {
              this.setState({ new_country: value })
            }}
            clearable={false}/>
          <div className='mt2 right-align'>
            <Button
              className='ml3'
              onClick={() => {
                this.addNewOverridePrice()
                this.setState({ show_add_modal: false })
              }}>
              Add Override Country Shipping Price
            </Button>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  organization: state.organization.model
})

export default withRouter(connect(mapStateToProps)(Bookings))
