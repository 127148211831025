import React from "react"
import PropTypes from "prop-types"

const Address = ({ address, small = true }) => (
  <div className='truncate'>
    <strong>{address.organization}</strong>
    <div className={small ? 'small flex items-center' : ''}>
      <div>{address.address_line1}</div>
      {address.address_line2 && <div>{address.address_line2},</div>}
      <div>
        {address.city}, {address.state_or_province_code && <span>{address.state_or_province_code}, </span>}
        {address.postal_code} {address.country_code}
      </div>
    </div>
  </div>
)

Address.propTypes = {
  address: PropTypes.object.isRequired
}

export default Address
