import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toggleAddNote, updateNewNote, addNewNote, toggleViewAllNotes } from "../../api/job"
import { Modal, Button } from "@bitcine/cs-theme"
import Note from "./note"

const Notes = ({ notes, jobName, toggleAddNote, updateNewNote, addNewNote, toggleViewAllNotes }) => {
  return (
    <div className='box-shadow rounded p2 mb2 bg-white'>
      <div className='flex items-center mb2'>
        <h4 className='flex-auto'>Notes</h4>
        <Button onClick={toggleAddNote} className='outline small'>
          Add Note
        </Button>
      </div>
      {notes.list.length === 0 ? (
        <div className='py4 center light'>
          <span className='material-icons block gray h1'>speaker_notes</span>
          <strong>No Notes</strong>
        </div>
      ) : (
        <div>
          {notes.list.slice(0, 3).map((note, i) => (
            <Note key={note._id} hasBorder={i > 0} note={note}/>
          ))}
          {notes.list.length > 3 && (
            <div className='border-top border-gray-5 py2'>
              <Button onClick={toggleViewAllNotes} className='link bold col-12 flex justify-center items-center block'>
                View {notes.list.length - 3} More Note{notes.list.length - 3 === 1 ? "" : "s"}
              </Button>
            </div>
          )}
        </div>
      )}
      {notes.viewAll && (
        <Modal open={true} width={2} onClose={toggleViewAllNotes} title={`All Notes for ` + jobName}>
          {notes.list.map(note => (
            <Note key={note._id} hasBorder={true} note={note}/>
          ))}
        </Modal>
      )}
      {notes.newNote.visible && (
        <Modal open={true} width={2} onClose={toggleAddNote} title={`Add a Note for ` + jobName}>
          <label>Note</label>
          <textarea
            className='col-12 cs-textarea'
            value={notes.newNote.value}
            maxLength={1000}
            onChange={({ target }) => updateNewNote(target.value)}/>
          <small className='block light right-align'>{notes.newNote.value.length} / 1000 characters</small>
          <div className='flex justify-end mt2'>
            <Button
              disabled={!notes.newNote.value.length || notes.newNote.status === "PENDING"}
              className={notes.newNote.status === "PENDING" ? "pending" : ""}
              onClick={addNewNote}>
              Save Note
            </Button>
          </div>
        </Modal>
      )}
    </div>
  )
}

Notes.propTypes = {
  toggleAddNote: PropTypes.func.isRequired,
  notes: PropTypes.object.isRequired,
  updateNewNote: PropTypes.func.isRequired,
  addNewNote: PropTypes.func.isRequired,
  toggleViewAllNotes: PropTypes.func.isRequired,
  jobName: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  notes: state.job.notes,
  jobName: state.job.details.title
})

const mapDispatchToProps = dispatch => ({
  toggleAddNote: bindActionCreators(toggleAddNote, dispatch),
  updateNewNote: bindActionCreators(updateNewNote, dispatch),
  addNewNote: bindActionCreators(addNewNote, dispatch),
  toggleViewAllNotes: bindActionCreators(toggleViewAllNotes, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Notes)
