import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { updateJobActions } from "../../../api/job"
import CommonDropdown from "../../Common/dropdown"

const Rev = ({ project, updateJobActions, languageCode }) => {
  return (
    <div>
      <div className='mt2'>
        <strong>1. Select Language</strong>
        <CommonDropdown
          containerClassName='col-12'
          options='languages'
          label='Captions Language'
          value={languageCode}
          onChange={({ value }) => {
            updateJobActions("language_code", value)
          }}
          clearable={false}/>
      </div>
      <strong className='block mt3'>2. Place Order in Rev</strong>
      <p>This job requires creating closed captions in Rev for {project}.</p>
      <small className='right-align mt3 light block'>
        Ensure the order is place in Rev before completing this job.
      </small>
    </div>
  )
}

Rev.propTypes = {
  project: PropTypes.string.isRequired,
  updateJobActions: PropTypes.func.isRequired,
  languageCode: PropTypes.string.isRequired
}

function mapStateToProps(state) {
  return {
    project: state.job.details.project_title,
    languageCode: state.job.actions ? state.job.actions.language_code || "" : ""
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateJobActions: bindActionCreators(updateJobActions, dispatch)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Rev)
