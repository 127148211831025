import {
  GET_USER,
  CONVERT_USER_TO_ORGANIZATION,
  ADD_MANUAL_ORDER
} from '../../redux/reducers/user/types'
import { get, post } from '../fetch'
import { addMessage } from '../messages'

export const getUser = userID => dispatch => {
  dispatch(
    get(
      GET_USER.REQUEST,
      `admin/users/${userID}`
    )
  )
}

export const convertUser = (user, organizationName, accountType) => dispatch => {
  dispatch(
    post(
      CONVERT_USER_TO_ORGANIZATION,
      `admin/users/convertUser`,
      {
        ownerName: user.full_name,
        ownerEmail: user.email,
        currencyCode: user.currency_code,
        countryCode: user.country_code,
        name: organizationName,
        accountType: accountType,
        userID: user._id
      },
      () => dispatch(addMessage('Converted user to organization.'))
    )
  )
}

export const addNewManualOrder = (userID, lineItems, title) => dispatch => {
  dispatch(
    post(
      ADD_MANUAL_ORDER,
      `admin/addManualOrder`,
      {
        userID: userID,
        title: title,
        lineItems: lineItems
      },
      () => dispatch(addMessage('Manual order added.'))
    )
  )
}