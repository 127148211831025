import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { Table } from '@bitcine/cs-theme'
import formatDate from "../../helpers/format_date"

const SubTasks = ({ job, userID, history }) => {
  return (
    <div className='box-shadow rounded bg-white p2 mb2'>
      <h4 className='py1'>Sub Tasks</h4>
      <Table
        className='mt2'
        widths={["auto", 100, 180]}
        header={{
          columns: ['', '', '']
        }}
        body={{
          data: job.children_jobs,
          row: {
            compact: true,
            onClick: (event, data, index) => history.push(`/jobs/${data._id}`),
            render: [
              data => <span className='truncate'>{data.title}</span>,
              data => data.status,
              data => data.status === "Complete" ?
                <span className='green'>Completed: {formatDate(data.completed_date, false)}</span> :
                <span>Due: {formatDate(data.due_date, false)}</span>
              ]
          }
        }}/>
    </div>
  )
}

SubTasks.propTypes = {
  job: PropTypes.object.isRequired,
  userID: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  job: state.job,
  userID: state.auth.user._id
})

export default withRouter(connect(mapStateToProps, null)(SubTasks))
