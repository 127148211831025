import { Doughnut } from 'react-chartjs-2';
import getColors from './colors'

const buildData = data => ({
  labels: ['Organization Projects', 'User Projects'],
  datasets: [
    {
      data: [data.organization_projects_count, data.user_projects_count],
      ...getColors(2)
    }
  ]
})

const ProjectsChart = ({ data }) =>
  <Doughnut
    data={buildData(data)}
    options={{ maintainAspectRatio: false, plugins: { title: { display: true, text: 'Total Projects' } } }}/>

export default ProjectsChart
