import React from "react"
import PropTypes from "prop-types"
import formatDate from "../../helpers/format_date"

const AccessDates = ({ model }) =>
  <div>
    {formatDate(model.updated_at)}
    <span className='block light small'>
      Created: {formatDate(model.created_at)}
    </span>
  </div>

AccessDates.propTypes = {
  model: PropTypes.object.isRequired
}

export default AccessDates
