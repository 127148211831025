import React from "react"
import { connect } from "react-redux"
import CustomerProjects from "../../Customer/projects"

const Projects = ({ organizationID }) =>
  <CustomerProjects queries={{ organization_id: organizationID }}/>

const mapStateToProps = state => ({
  organizationID: state.organization.model._id
})

export default connect(mapStateToProps)(Projects)
