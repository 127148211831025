import React from "react"
import SharedTable from "../Common/table"
import humanFileSize from "../../helpers/human_file_size"
import AccessDates from "../Common/access_dates"

const Uploads = () =>
  <SharedTable
    collection={"uploads"}
    queries={{}}
    includeSearch={true}
    includeTitle={"Uploads"}
    isCompact={true}
    disableRowClick={true}
    tableKeys={[
      { text: "File", key: "file_name" },
      { text: 'Owner' },
      { text: "Customer", key: "customer_name" },
      { text: "Status", key: "status" },
      { text: 'Type' },
      { text: "Dates" }
    ]}
    tableWidths={["auto", 180, 180, 220, 100, 200]}
    tableValues={[
      upload => <span className='truncate'>{upload.file_name}</span>,
      upload => <span className='truncate'>{upload.parent_name}</span>,
      upload => <span className='truncate'>{upload.customer_name}</span>,
      upload => <span>{upload.status} / {`${humanFileSize(upload.current_size)} of ${humanFileSize(upload.total_size)}`}</span>,
      upload => upload.upload_method,
      upload => <AccessDates model={upload}/>
    ]}/>

export default Uploads
