import React from "react"
import SharedTable from "../Common/table"
import formatDate from "../../helpers/format_date"

const Serials = () => (
  <SharedTable
    collection={"serials"}
    includeSearch={true}
    includeTitle={"Serials"}
    isCompact={true}
    includeShowDeletedCheckbox={true}
    tableKeys={[
      { text: "Title", key: "title" },
      { text: "Organization" },
      { text: "Created", key: "created_at" }
    ]}
    tableWidths={["auto", 200, 200]}
    tableValues={[
      video => <span className='truncate'>{video.title}</span>,
      video => <span className='truncate'>{video.organization_name || 'N/A'}</span>,
      video => formatDate(video.created_at)
    ]}/>
)

export default Serials
