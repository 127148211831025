import React from "react"
import PropTypes from "prop-types"
import { Modal } from "@bitcine/cs-theme"
import StorageTable from '../../StorageTable'

const StorageModal = ({ close, file }) =>
  <Modal open={true} width={3} onClose={() => close()} title={`Storage Files For ${file.name}`}>
    <StorageTable file={file}/>
  </Modal>

StorageModal.propTypes = {
  file: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired
}

export default StorageModal
