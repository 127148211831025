import React, { useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { Status } from '@bitcine/cs-theme'
import SubNav from '../SubNav'
import Routes from './routes'
import { getUser } from "../../api/user"

const User = ({ user, isFetched, getUser, match, history }) => {
  const userID = match.params.userID
  useEffect(() => {
    getUser(userID)
  }, [getUser, userID])
  return (
    <Status pending={!isFetched}>
      {isFetched &&
        <>
          <div style={{ margin: '-1em' }}>
            <SubNav 
              subtitle={user.email}
              title={user.full_name}
              onBackButtonClick={() => history.push('/users')}
              links={[
                { name: 'General', to: "general", show: true },
                { name: 'Projects', to: "projects", show: !user.organization_id },
                { name: 'Invoices', to: "invoices", show: !user.organization_id },
                { name: 'Items', to: "items", show: !user.organization_id },
                { name: 'Emails', to: "emails", show: true },
                { name: 'Access Devices', to: "access-devices", show: true },
                { name: 'Payment', to: "payment", show: !user.organization_id },
                { name: 'Cart', to: "cart", show: true },
                { name: 'Jobs', to: "jobs", show: !user.organization_id },
                { name: 'Storage', to: "storage", show: !user.organization_id }
              ].filter(opt => opt.show).map(opt => ({
                text: opt.name,
                to: `/users/${user._id}/${opt.to}`
              }))}
              includeButton={
                user.organization_name ? {
                  label: user.organization_name,
                  to: `/organizations/${user.organization_id}`
                } : null
              }/>
          </div>
          <div className='box-shadow border-radius bg-white p2 mt3'>
            <Routes/>
          </div>
        </>}
    </Status>
  )
}

const mapStateToProps = state => ({
  user: state.user.model,
  isFetched: state.user.status === 'READY'
})

const mapDispatchToProps = dispatch => ({
  getUser: bindActionCreators(getUser, dispatch)
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(User))
