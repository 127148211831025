import React from "react"
import { withRouter } from 'react-router-dom'
import fileDownload from 'js-file-download';
import ReactTooltip from 'react-tooltip'
import formatDate from "../../../helpers/format_date"
import SharedTable from "../../Common/table"

const TimedTexts = ({ match }) =>
  <SharedTable
    collection={"timed-texts"}
    queries={{ video_id: match.params.videoID }}
    isCompact={true}
    includeTitle={"Timed Text Tracks"}
    tableKeys={[
      { text: "Name" },
      { text: "Language" },
      { text: "Status" },
      { text: "Updated" },
      { text: "Created" },
      { text: "" }
    ]}
    tableWidths={["auto", 180, 180, 180, 180, 65]}
    tableValues={[
      timedText => <span className='truncate'>{timedText.name}</span>,
      timedText => timedText.language,
      timedText => timedText.is_embedded ? 'Embedded' : timedText.is_embedding ? 'Embedding' : 'Uploaded',
      timedText => formatDate(timedText.updated_at),
      timedText => formatDate(timedText.created_at),
      timedText => <div className='mr1 flex items-center'>
        <ReactTooltip place='left' effect='solid' className='bold'/>
        <span
          className='material-icons tooltip link pointer muted'
          data-tip={`Download ${timedText.name}`}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            fetch(timedText.url).then(res => res.text()).then(res => fileDownload(res, timedText.name)).catch(err => window.alert(`An error occurred: ${err}`))
          }}>
          cloud_download
        </span>
      </div>
    ]}/>

export default withRouter(TimedTexts)