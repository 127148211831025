export const GET_FILE_STORAGE = {
  REQUEST: "GET_FILE_STORAGE",
  PENDING: "GET_FILE_STORAGE_PENDING",
  FAILED: "GET_FILE_STORAGE_FAILED",
  FULFILLED: "GET_FILE_STORAGE_FULFILLED"
}

export const REMOVE_FILE_STORAGE = {
  REQUEST: "REMOVE_FILE_STORAGE",
  PENDING: "REMOVE_FILE_STORAGE_PENDING",
  FAILED: "REMOVE_FILE_STORAGE_FAILED",
  FULFILLED: "REMOVE_FILE_STORAGE_FULFILLED"
}