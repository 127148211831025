import React from "react"
import PropTypes from "prop-types"
import { buildChart } from "../../../helpers/build_chart"

const DataChart = ({ data }) => (
  <div id='svg-wrap'>
    <svg
      className='col-12'
      style={{ minHeight: "300px" }}
      id='svg'
      ref={ref => setTimeout(() => buildChart(data), 100)}/>
  </div>
)

DataChart.propTypes = {
  data: PropTypes.array
}

export default DataChart
