export const GET_JOB = {
  REQUEST: "GET_JOB",
  PENDING: "GET_JOB_PENDING",
  FAILED: "GET_JOB_FAILED",
  FULFILLED: "GET_JOB_FULFILLED"
}

export const SET_JOB_TRANSFERRING = "SET_JOB_TRANSFERRING"
export const CANCEL_JOB_TRANSFERRING = "CANCEL_JOB_TRANSFERRING"
export const TOGGLE_ADD_NOTE = "TOGGLE_ADD_NOTE"
export const UPDATE_NEW_NOTE = "UPDATE_NEW_NOTE"
export const ADD_JOB_NOTE = "ADD_JOB_NOTE"
export const ADD_JOB_NOTE_PENDING = "ADD_JOB_NOTE_PENDING"
export const ADD_JOB_NOTE_FULFILLED = "ADD_JOB_NOTE_FULFILLED"
export const DELETE_NOTE = "DELETE_NOTE"
export const DELETE_NOTE_PENDING = "DELETE_NOTE_PENDING"
export const DELETE_NOTE_FULFILLED = "DELETE_NOTE_FULFILLED"
export const TOGGLE_VIEW_ALL_NOTES = "TOGGLE_VIEW_ALL_NOTES"
export const UPDATE_JOB_FULFILLED = "UPDATE_JOB_FULFILLED"
export const SET_JOB_STATUS = "SET_JOB_STATUS"
export const SET_JOB_STATUS_PENDING = "SET_JOB_STATUS_PENDING"
export const SET_JOB_STATUS_FULFILLED = "SET_JOB_STATUS_FULFILLED"
export const TOGGLE_QA_REPORT = "TOGGLE_QA_REPORT"
export const UPDATE_QA_REPORT = "UPDATE_QA_REPORT"
export const TOGGLE_SHOW_ADD_ISSUE = "TOGGLE_SHOW_ADD_ISSUE"
export const UPDATE_ISSUE = "UPDATE_ISSUE"
export const SAVE_QA_REPORT = "SAVE_QA_REPORT"
export const SAVE_QA_ISSUE = "SAVE_QA_ISSUE"
export const SAVE_QA_ISSUE_FULFILLED = "SAVE_QA_ISSUE_FULFILLED"
export const SAVE_QA_ISSUE_PENDING = "SAVE_QA_ISSUE_PENDING"
export const SET_QA_ISSUE_RESOLVED = "SET_QA_ISSUE_RESOLVED"
export const SAVE_QA_REPORT_PENDING = "SAVE_QA_REPORT_PENDING"
export const SAVE_QA_REPORT_FULFILLED = "SAVE_QA_REPORT_FULFILLED"
export const UPDATE_JOB_ACTIONS = "UPDATE_JOB_ACTIONS"
export const UPDATE_ERROR = "UPDATE_ERROR"
export const UPLOAD_BITRATE_ANALYSIS = "UPLOAD_BITRATE_ANALYSIS"
export const UPLOAD_BITRATE_ANALYSIS_FULFILLED = "UPLOAD_BITRATE_ANALYSIS_FULFILLED"
export const UPLOAD_BITRATE_ANALYSIS_FAILED = "UPLOAD_BITRATE_ANALYSIS_FAILED"
export const UPLOAD_BITRATE_ANALYSIS_PENDING = "UPLOAD_BITRATE_ANALYSIS_PENDING"
export const UPLOAD_CPL_FULFILLED = "UPLOAD_CPL_FULFILLED"
export const UPLOAD_CPL_FAILED = "UPLOAD_CPL_FAILED"
export const UPLOAD_CPL_PENDING = "UPLOAD_CPL_PENDING"
export const CHANGE_FACILITIES_FULFILLED = "CHANGE_FACILITIES_FULFILLED"
export const UPDATE_JOB = "UPDATE_JOB"
export const UPLOAD_CPL = "UPLOAD_CPL"

export const DELETE_JOB = {
  REQUEST: "DELETE_JOB",
  PENDING: "DELETE_JOB_PENDING",
  FAILED: "DELETE_JOB_FAILED",
  FULFILLED: "DELETE_JOB_FULFILLED"
}