import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import CustomerPayment from "../../Customer/payment"

const Payment = ({ userID, defaultCreditCardID }) =>
  <CustomerPayment
    defaultCreditCardID={defaultCreditCardID}
    queries={{ user_id: userID }}/>

Payment.propTypes = {
  defaultCreditCardID: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  defaultCreditCardID: state.user.model.default_credit_card_id,
  userID: state.user.model._id
})

export default connect(mapStateToProps, null)(Payment)