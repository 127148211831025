import React from "react"
import { connect } from "react-redux"
import CustomerInvoices from "../../Customer/invoices"

const Invoices = ({ userID }) =>
  <CustomerInvoices queries={{ user_id: userID }}/>

const mapStateToProps = state => ({
  userID: state.user.model._id
})

export default connect(mapStateToProps)(Invoices)