import {
  GET_ORGANIZATION,
  UPDATE_ORGANIZATION,
  SAVE_ORGANIZATION
} from "./types"

export const initialState = {
  status: "PENDING",
  model: {}
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ORGANIZATION.PENDING:
      return {
        ...state,
        status: "PENDING"
      }
    case GET_ORGANIZATION.FULFILLED:
    case SAVE_ORGANIZATION.FULFILLED:
      return {
        status: "READY",
        model: action.payload
      }
    case UPDATE_ORGANIZATION:
      return {
        ...state,
        model: {
          ...state.model,
          [action.payload.key]: action.payload.value
        }
      }
    default:
      return state
  }
}
