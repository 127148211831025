import React from "react"
import { withRouter } from "react-router-dom"
import SharedModel from "../Common/model"

const Shipment = ({ match }) => (
  <SharedModel
    modelID={match.params.shipmentID}
    headerKey={"title"}
    collection={"shipments"}
    isEditable={true}
    isDeletable={false}/>
)

export default withRouter(Shipment)
