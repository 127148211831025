import React from "react"
import { withRouter } from 'react-router-dom'
import SharedModel from "../Common/model"

const TimedText = ({ match }) =>
  <SharedModel
    modelID={match.params.timedTextID}
    headerKey={"name"}
    subheaderKey={"language"}
    collection={"timed-texts"}
    isEditable
    isDeletable />

export default withRouter(TimedText)