import {
  TRANSFER_FILE,
  DEFROST_PROJECT,
  FREEZE_PROJECT,
  FREEZE_FILE,
  DEFROST_FILE
} from "../../redux/reducers/files/types"

import { post, put } from "../fetch"
import { push } from "react-router-redux"
import { addMessage } from '../messages'

/**
 * Transfer File
 *
 * @param {String} fileType
 * @param {String} fileID
 * @param {String} destinationProjectID
 *
 * @returns {Function} dispatch
 */

export const transferFile = (fileID, fileType, destinationProjectID) => (
  dispatch,
  getState
) => {
  dispatch(
    put(
      TRANSFER_FILE,
      `admin/files/${fileID}/transfer`,
      { fileID, fileType, destinationProjectID },
      () => dispatch(push(`/projects/${destinationProjectID}/files`))
    )
  )
}

export const defrostProject = (projectID, expedited = 0) => dispatch => {
  dispatch(
    post(
      DEFROST_PROJECT,
      `project-defroster`,
      {
        id: projectID,
        expedited
      },
      () => dispatch(addMessage('Defrost project triggered! Please refresh the page.'))
    )
  )
}

export const freezeProject = (projectID) => dispatch => {
  dispatch(
    post(
      FREEZE_PROJECT,
      `freezer`,
      {
        id: projectID
      },
      () => dispatch(addMessage('Freeze project triggered! Please refresh the page.'))
    )
  )
}

export const defrostFile = (fileID, expedited = 0) => dispatch => {
  dispatch(
    post(
      DEFROST_FILE,
      `defroster`,
      {
        id: fileID,
        expedited
      },
      () => dispatch(addMessage('Defrost file triggered! Please refresh the page.'))
    )
  )
}

export const freezeFile = (fileID, expedited = 0) => dispatch => {
  dispatch(
    post(
      FREEZE_FILE,
      `file-freezer`,
      {
        id: fileID,
        expedited
      },
      () => dispatch(addMessage('Freeze file triggered! Please refresh the page.'))
    )
  )
}

