import React from "react"
import PropTypes from "prop-types"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Modal, Button } from "@bitcine/cs-theme"
import { addMessage } from "../../../api/messages"
import { componentFetch } from "../../../helpers/component_fetch"
import CommonDropdown from "../../Common/dropdown"

class DownloadToBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  triggerDownloadToMediaManager() {
    this.setState(
      { triggering_download: true },
      componentFetch(
        "POST",
        `admin/media-managers/${this.state.media_manager_id}/trigger-download`,
        {
          fileID: this.props.file._id,
          fileType: this.props.file.type
        },
        res => {
          this.props.addMessage("Successfully added download to queue.")
          this.props.close()
        }
      )
    )
  }
  render() {
    const { file } = this.props
    return (
      <Modal
        open={true}
        width={1}
        onClose={() => this.props.close()}
        title={`Download ${file.name} to a Media Manager`}>
        <React.Fragment>
          <div className='mb2'>
            <CommonDropdown
              containerClassName='col-12'
              options='media_managers'
              label='Select a Device'
              value={this.state.media_manager_id}
              onChange={({ value }) => this.setState({ media_manager_id: value })}
              clearable={false}/>
          </div>
          <div className='right-align'>
            <Button
              disabled={this.state.triggering_download || !this.state.media_manager_id}
              onClick={() => {
                this.triggerDownloadToMediaManager()
              }}>
              Trigger Download
            </Button>
          </div>
        </React.Fragment>
      </Modal>
    )
  }
}

DownloadToBox.propTypes = {
  file: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  addMessage: bindActionCreators(addMessage, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(DownloadToBox)
