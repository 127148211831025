import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"
import SharedTable from "../../Common/table"

const Screeners = ({ projectID }) =>
  <SharedTable
    collection={"screeners"}
    queries={{
      project_id: projectID
    }}
    includeSearch={false}
    isCompact={true}
    tableKeys={[
      { text: "Screener", key: "title" },
      { text: "Status", key: "status" },
      { text: "Proxy Status", key: "mp4_360p_job_status" },
      { text: "Created", key: "created_at" }
    ]}
    tableWidths={["auto", 200, 200, 140]}
    tableValues={[
      screener => screener.title,
      screener => screener.status,
      screener => screener.mp4_360p_job_status || "N/A",
      screener => moment.utc(screener.created_at).format("MMM Do, YYYY")
    ]}/>

Screeners.propTypes = {
  projectID: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  projectID: state.project.model._id
})

export default connect(mapStateToProps)(Screeners)
