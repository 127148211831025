import React from "react"
import { withRouter } from 'react-router-dom'
import SharedModel from "../Common/model"

const LiveChannel = ({ match }) =>
  <SharedModel
    modelID={match.params.liveChannelID}
    headerKey={"asset_title"}
    subheaderKey={"channel_name"}
    collection={"media-live-channels"}
    isEditable={false}
    isDeletable={true}/>

export default withRouter(LiveChannel)