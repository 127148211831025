import moment from "moment"

const formatDate = (date, withTime = true) => {
  const format = withTime ? "lll" : "ll"
  let formats = {
    lastWeek: format,
    nextWeek: format,
    sameElse: format
  }
  if (!withTime) {
    formats.lastDay = "[Yesterday]"
    formats.nextDay = "[Tomorrow]"
    formats.sameDay = "[Today]"
  }
  return date
    ? moment
      .utc(date)
      .local()
      .calendar(null, formats)
    : "N/A"
}

export default formatDate
