import React from "react"
import PropTypes from "prop-types"
import Aspera from "../../media/aspera.svg"

const AsperaLabel = ({ msg }) =>
  <div className='flex items-center nowrap'>
    <div>{msg}</div>
    <img
      src={Aspera}
      alt='Aspera'
      style={{ width: "25px" }}
      className={`mx1`}/>
  </div>

AsperaLabel.propTypes = {
  msg: PropTypes.string.isRequired,
  can: PropTypes.bool
}

export default AsperaLabel
