import React from "react"
import SharedTable from "../Common/table"
import AccessDates from '../Common/access_dates'

const Subscribers = () => (
  <SharedTable
    collection={"subscribers"}
    queries={{}}
    includeSearch={true}
    includeTitle={"Subscribers"}
    isCompact={true}
    includeShowDeletedCheckbox={true}
    tableKeys={[
      { text: "Name", key: "name" },
      { text: "Email", key: "email" },
      { text: "Organization", key: "organization_name" },
      { text: "Status", key: "status" },
      { text: "Updated", key: "updated_at" },
    ]}
    tableWidths={["auto", 240, 240, 120, 180]}
    tableValues={[
      subscriber => <span className='truncate'>{subscriber.name}</span>,
      subscriber => <span className='truncate'>{subscriber.email}</span>,
      subscriber => <span className='truncate'>{subscriber.organization_name}</span>,
      subscriber => <span className='truncate'>{subscriber.status}</span>,
      subscriber => <AccessDates model={subscriber}/>,
    ]}/>
)

export default Subscribers
