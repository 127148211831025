export const GET_COUNTRIES = {
  REQUEST: "GET_COUNTRIES",
  PENDING: "GET_COUNTRIES_PENDING",
  FAILED: "GET_COUNTRIES_FAILED",
  FULFILLED: "GET_COUNTRIES_FULFILLED"
}

export const GET_TIMEZONES = {
  REQUEST: "GET_TIMEZONES",
  PENDING: "GET_TIMEZONES_PENDING",
  FAILED: "GET_TIMEZONES_FAILED",
  FULFILLED: "GET_TIMEZONES_FULFILLED"
}

export const GET_LANGUAGES = {
  REQUEST: "GET_LANGUAGES",
  PENDING: "GET_LANGUAGES_PENDING",
  FAILED: "GET_LANGUAGES_FAILED",
  FULFILLED: "GET_LANGUAGES_FULFILLED"
}

export const GET_CURRENCIES = {
  REQUEST: "GET_CURRENCIES",
  PENDING: "GET_CURRENCIES_PENDING",
  FAILED: "GET_CURRENCIES_FAILED",
  FULFILLED: "GET_CURRENCIES_FULFILLED"
}

export const GET_ITEM_PRICES = {
  REQUEST: "GET_ITEM_PRICES",
  PENDING: "GET_ITEM_PRICES_PENDING",
  FAILED: "GET_ITEM_PRICES_FAILED",
  FULFILLED: "GET_ITEM_PRICES_FULFILLED"
}

export const GET_TAGS = {
  REQUEST: "GET_TAGS",
  PENDING: "GET_TAGS_PENDING",
  FAILED: "GET_TAGS_FAILED",
  FULFILLED: "GET_TAGS_FULFILLED"
}

export const CREATE_TAG = {
  REQUEST: "CREATE_TAG",
  PENDING: "CREATE_TAG_PENDING",
  FAILED: "CREATE_TAG_FAILED",
  FULFILLED: "CREATE_TAG_FULFILLED"
}