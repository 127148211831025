import React from "react"
import { withRouter } from "react-router-dom"
import SharedModel from "../Common/model"

const SharedFile = ({ match }) =>
  <SharedModel
    modelID={match.params.sharedFileID}
    headerKey={"project_title"}
    subheaderKey={"description"}
    collection={"downloads"}
    isEditable={false}
    isDeletable={true}/>

export default withRouter(SharedFile)
