import React from "react"
import { Switch, Route } from "react-router-dom"
import Videos from '../../components/OnDemandTables/videos'
import Playlists from '../../components/OnDemandTables/playlists'
import Playlist from '../../components/OnDemand/playlist'
import Events from '../../components/OnDemandTables/events'
import Event from '../../components/OnDemand/event'
import Serials from '../../components/OnDemandTables/serials'
import Serial from '../../components/OnDemand/serial'
import Subscribers from '../../components/OnDemandTables/subscribers'
import Subscriber from '../../components/OnDemand/subscriber'
import Vouchers from '../../components/OnDemandTables/vouchers'
import Voucher from '../../components/OnDemand/voucher'
import Session from '../../components/OnDemand/session'
import LiveChannels from '../../components/OnDemandTables/live_channels'
import LiveChannel from '../../components/OnDemand/live_channel'
import TimedText from '../../components/OnDemand/timed_text'

const Routes = () => 
  <Switch>
    <Route exact path='/ondemand/videos' component={Videos}/>
    <Route exact path='/ondemand/playlists' component={Playlists}/>
    <Route exact path='/ondemand/playlists/:playlistID' component={Playlist}/>
    <Route exact path='/ondemand/events' component={Events}/>
    <Route exact path='/ondemand/events/:eventID' component={Event}/>
    <Route exact path='/ondemand/serials' component={Serials}/>
    <Route exact path='/ondemand/serials/:serialID' component={Serial}/>
    <Route exact path='/ondemand/subscribers' component={Subscribers}/>
    <Route exact path='/ondemand/subscribers/:subscriberID' component={Subscriber}/>
    <Route exact path='/ondemand/vouchers' component={Vouchers}/>
    <Route exact path='/ondemand/vouchers/:voucherID' component={Voucher}/>
    <Route exact path='/ondemand/timed-texts/:timedTextID' component={TimedText}/>
    <Route exact path='/ondemand/sessions/:sessionID' component={Session}/>
    <Route exact path='/ondemand/media-live-channels' component={LiveChannels}/>
    <Route exact path='/ondemand/media-live-channels/:liveChannelID' component={LiveChannel}/>
  </Switch>

export default Routes