import React from "react"
import { withRouter } from "react-router-dom"
import SubNav from '../../components/SubNav'
import Routes from './routes'

const Settings = ({ history }) =>
  <>
    <div style={{ margin: '-1em' }}>
      <SubNav
        compact={false}
        links={[
          { name: 'Countries', to: "countries", show: true },
          { name: 'Languages', to: "languages", show: true },
          { name: 'Facilities', to: "facilities", show: true },
          { name: 'Staff Roles', to: "staff-roles", show: true },
          { name: 'Items', to: "items", show: true },
          { name: 'Currencies', to: "currencies", show: true },
          { name: 'Tax Rates', to: "tax-rates", show: true },
          { name: 'Tax Regions', to: "tax-regions", show: true },
          { name: 'Booking Rates', to: "booking-rates", show: true }
        ].filter(opt => opt.show).map(opt => ({
          text: opt.name,
          to: `/settings/${opt.to}`
        }))}/>
    </div>
    <div className='box-shadow border-radius bg-white p2 mt3'>
      <Routes/>
    </div>
  </>

export default withRouter(Settings)
