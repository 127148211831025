import React from "react"
import { withRouter } from "react-router-dom"
import SharedModel from "../Common/model"
import Files from "../ProjectFiles/"

const Request = ({ match }) =>
  <div>
    <SharedModel
      modelID={match.params.requestID}
      headerKey={"film_name"}
      subheaderKey={"requestee_email"}
      collection={"cinerequests"}
      isEditable={false}
      isDeletable={false}/>
    <div className='mt2'/>
    <Files
      title={"Submissions"}
      includeExpiryDate
      queries={{
        cinerequest_id: match.params.requestID
      }}/>
  </div>

export default withRouter(Request)
